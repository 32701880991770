import React from 'react';
import { ReactComponent as PhoneIcon } from '../../../assets/image/phone.svg';
import AppButton from '../../ui/AppButton';
import { useRootModel } from '../../../models/RootStore';

/**
 * Represents CreateReminder component
 * @returns {JSX.Element}
 */
const CreateReminder = () => {
  const {
    traders: { selectedTrader },
  } = useRootModel();
  const handleModalOpen = () => {
    selectedTrader.isModal.setModal('Create Reminder');
  };
  return (
    <AppButton type='text' text='Create reminder' icon={<PhoneIcon />} onClick={handleModalOpen} />
  );
};

export default CreateReminder;
