import { types } from 'mobx-state-tree';
import WorkersListModel from '../../commonModels/WorkersListModel';

const CommunicationModel = types.model('CommunicationModel', {
  id: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  author: types.maybeNull(WorkersListModel),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});

export default CommunicationModel;
