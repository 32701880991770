import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import SingleTraderTabs from './SingleTraderTabs';
import TraderProfile from './TraderProfile';
import TraderTickets from './TraderTickets';
import TraderPositions from './TraderPositions';
import TraderBalanceHistory from './TraderBalanceHistory';
import TraderCalendar from './TraderCalendar';
import TraderDocuments from './TraderDocuments';

import style from './style.module.less';

/**
 * Represents selected trader profile content
 * @returns {JSX.Element}
 */

const SingleTraderContent = () => {
  const {
    traders: {
      selectedTrader,
      fetchSelectedTrader,
      fetchComments,
      fetchActivities,
      isProfile,
      isTickets,
      isPositions,
      isBalanceHistory,
      isDocuments,
      isCalendar,
    },
  } = useRootModel();
  const { id } = useParams();
  const [tab, setTab] = useState('profile');
  const handleTabs = (value) => {
    setTab(value);
  };
  useEffect(() => {
    fetchSelectedTrader(id);
    fetchComments(id);
    fetchActivities(id);
  }, []);
  return (
    <section className={style.singleTrader}>
      <div className={style.singleTrader_header}>
        #{id} {selectedTrader?.name}
      </div>
      <div className={style.singleTrader_tabsContainer}>
        <div className={style.singleTrader_tabWrapper}>
          <SingleTraderTabs
            onTabChange={handleTabs}
            isProfile={isProfile}
            isTickets={isTickets}
            isPositions={isPositions}
            isBalanceHistory={isBalanceHistory}
            isDocuments={isDocuments}
            isCalendar={isCalendar}
          />
        </div>
        {tab === 'profile' && <TraderProfile trader={selectedTrader} />}
        {tab === 'tickets' && <TraderTickets />}
        {tab === 'positions' && <TraderPositions />}
        {tab === 'balanceHistory' && <TraderBalanceHistory />}
        {tab === 'documents' && <TraderDocuments />}
        {tab === 'calendar' && <TraderCalendar />}
      </div>
    </section>
  );
};

export default observer(SingleTraderContent);
