import React from 'react';
import { Input } from 'antd';

import style from '../AppSelect/AppSelect.module.less';

/**
 * Represents AppInput component
 * @param {string} title - select title
 * @param {function} onChange - change handler
 * @param {string} placeholder - select placeholder
 * @param {Object} filter - select placeholder
 * @returns {JSX.Element}
 */

const AppInput = ({ title, onChange, placeholder, filter }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <fieldset className={style.selectFieldset}>
      <legend className={style.selectLegend}>{title}</legend>
      <Input
        value={filter?.value === '' ? null : filter?.value}
        allowClear
        bordered={false}
        className={style.appSelect}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </fieldset>
  );
};

export default AppInput;
