import React from 'react';
import style from './Assets.module.less';

/**
 * Represents Assets page
 * @returns {JSX.Element}
 */
const Assets = () => {
  return <main className={style.assets}>Assets</main>;
};

export default Assets;
