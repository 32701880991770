import React from 'react';
import { Button, Select } from 'antd';
import { observer } from 'mobx-react';

import AddIcon from '../common/icons/AddIcon';
import style from './style.module.less';

/**
 * Represents Calendar Header
 * @param {Function} setIsModalOpen - item title
 * @param {Object} workersList - object with workers
 * @param {Function} fetchEvents - func for fetching calendar events
 * @returns {JSX.Element}
 */

const CalendarHeader = ({ setIsModalOpen, workersList, fetchEvents }) => {
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const onChange = (value) => {
    fetchEvents(value);
  };
  return (
    <div className={style.calendarHeader}>
      <Button type='primary' icon={<AddIcon />} onClick={handleModalOpen}>
        Create reminder
      </Button>
      <Select
        className={style.calendarEmployees}
        onChange={onChange}
        placeholder='Please select Employees'
      >
        {workersList.map((worker) => (
          <Select.Option key={worker?.id} value={worker?.id}>
            {worker?.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default observer(CalendarHeader);
