import { EventSourcePolyfill } from 'event-source-polyfill';
import axios from 'axios';

const { REACT_APP_NOTIFICATION_SERVICE } = process.env;

let eventSource;

const handleError = (eventSrc) => (event) => {
  const {
    target: { readyState, url, onmessage, onopen },
  } = event;
  if (readyState !== 2) return eventSrc;
  eventSrc = new EventSource(url);
  eventSrc.onerror = handleError;
  if (onmessage) eventSrc.onmessage = onmessage;
  if (onopen) eventSrc.onopen = onopen;
  return eventSrc;
};

const createEventSourceNotifications = () => {
  eventSource = new EventSourcePolyfill(
    `${REACT_APP_NOTIFICATION_SERVICE}/api/notifications/updates/`,
    {
      headers: { Authorization: axios.defaults.headers.common.Authorization },
      heartbeatTimeout: 36000 * 1000,
    },
  );
  eventSource.onerror = handleError(eventSource);
  return eventSource;
};

const closeStream = () => {
  if (eventSource) eventSource.close();
};

export default {
  createEventSourceNotifications,
  closeStream,
};
