import { flow, getRoot, types } from 'mobx-state-tree';
import axios from 'axios';

import WorkerModel from './models/WorkerModel';
import PaginationModel from '../commonModels/PaginationModel';
import DisplayOptionsModel from '../commonModels/DisplayOptionsModel';
import WorkersFiltersModel from './models/WorkersFiltersModel';
import WorkersListModel from '../commonModels/WorkersListModel';
import UserRolesModel from '../commonModels/UserRolesModel';
import UserDesksModel from '../commonModels/UserDesksModel';
import SelectedRowModel from './models/SelectedRowModel';
import workersDefaultFilters from './models/workersDefaultFilters';
import workersTableOptions from './models/workersTableOptions';
import PermissionsModel from '../commonModels/PermissionsModel';

import errorHandler from '../../util/errorHandler';
import filterHandler from '../../util/filterHandler';
import handleReadPermission from '../../util/handleReadPermission';
import queryString from '../../util/queryString';

const { REACT_APP_API } = process.env;

const WorkersModel = types
  .model('WorkersModel', {
    allWorkers: types.array(WorkerModel),
    selectedWorker: types.optional(WorkerModel, {}),
    selectedRows: types.array(SelectedRowModel),
    isLoading: types.optional(types.boolean, false),
    isPageLoading: types.optional(types.boolean, true),
    isModalOpen: types.optional(types.boolean, false),
    pagination: types.optional(PaginationModel, {}),
    userRoles: types.array(UserRolesModel),
    userDesks: types.array(UserDesksModel),
    filters: types.optional(WorkersFiltersModel, {}),
    displayOptions: types.array(DisplayOptionsModel),
    picker: types.optional(types.boolean, false),
    relatedTraders: types.array(WorkersListModel),
    permissions: types.array(PermissionsModel),
  })
  .actions((self) => ({
    getPermission: flow(function* getPermission(permission) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const {
        user: { userData },
      } = getRoot(self);
      const params = {
        category: permission,
        role: userData?.authorities,
      };
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/permissions'),
          { params },
        );
        self.permissions = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchWorkers: flow(function* fetchWorkers() {
      const { pagination } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = {
        page: pagination.number,
        size: pagination.size,
        sort: `${pagination.sortColumn},${pagination?.sortOrder}`,
      };
      const filters = filterHandler(self.filters);

      try {
        const { data } = yield axios.post(
          queryString(REACT_APP_API, 'api/backoffice/workers'),
          filters,
          { params },
        );
        self.allWorkers = data.data;
        pagination.size = data.page.size;
        pagination.number = data.page.number;
        pagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchSelectedWorker: flow(function* fetchSelectedWorker(id) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, `/api/backoffice/workers/${id}/profile`),
        );
        self.selectedWorker = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    getUserDesks: flow(function* getUserDesks() {
      self.isLoading = true;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/desks/simplified'),
        );
        self.userDesks = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    addNewWorker: flow(function* addNewWorker(worker) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      try {
        yield axios.post(queryString(REACT_APP_API, 'api/backoffice/workers/create'), worker);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
        self.isModalOpen = false;
      }
    }),
    fetchRelatedTraders: flow(function* fetchRelatedTraders(id) {
      if (!localStorage.crm_token) return;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, `api/backoffice/workers/${id}/traders/simplified`),
        );
        self.relatedTraders = data;
      } catch (e) {
        errorHandler(self, e);
      }
    }),
    handlePageChange(page, size) {
      const { pagination, fetchWorkers } = self;
      pagination.number = page - 1;
      pagination.size = size;
      fetchWorkers();
    },
    handleSorterChange(sorter) {
      const { pagination, fetchWorkers } = self;
      pagination.sortColumn = sorter.sortColumn;
      pagination.sortOrder = sorter.sortOrder;
      fetchWorkers();
    },
    setIsModalOpen(isOpen) {
      self.isModalOpen = isOpen;
    },
    setSelectedRows(rows) {
      self.selectedRows = rows;
    },
    clearFilters() {
      self.filters = workersDefaultFilters;
    },
    setPicker(open) {
      self.picker = open;
    },
    handlePageLoading() {
      self.isPageLoading = false;
    },
    afterCreate: () => {
      self.displayOptions = workersTableOptions;
    },
  }))
  .views((self) => ({
    get isFilters() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'WORKERS_FILTERS');
    },
    get isDisplayOptions() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'WORKERS_DISPLAY_OPTIONS');
    },
    get isAddWorker() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'WORKERS_ADD_NEW');
    },
    get isExecutionPlan() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'WORKERS_FINANCE_EXECUTION_PLAN');
    },
    get isWorkerProfile() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'WORKERS_PROFILE_INFO');
    },
    get isWorkerTickets() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'WORKERS_TICKETS');
    },
    get isTransactions() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'WORKERS_TRANSACTIONS');
    },
  }));

export default WorkersModel;
