import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../../assets/image/sidebar-logo.svg';
import style from './Logo.module.less';

/**
 * Represents a logo
 * @returns {JSX.Element}
 */
const Logo = () => {
  return (
    <Link to='/' className={style.logo}>
      <LogoIcon />
    </Link>
  );
};

export default Logo;
