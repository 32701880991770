import React from 'react';
import cn from 'classnames';
import { DatePicker } from 'antd';
import { useRootModel } from '../../../models/RootStore';

import { ReactComponent as CalendarIcon } from '../../../assets/image/calendar-mini.svg';
import style from './AppDatePicker.module.less';

const { RangePicker } = DatePicker;

/**
 * Represents AppDatePicker
 * @param {CSSStyleRule} className - className for date picker fieldset
 * @param {Function} onChange - function for passing dates to a parent component
 * @param {string} title - date picker title
 * @returns {JSX.Element}
 */

const AppDatePicker = ({ onChange, className, title }) => {
  const {
    workers: { setPicker },
  } = useRootModel();
  const onPick = (value, date) => {
    onChange(date);
  };
  const onOpenChange = (open) => {
    setPicker(open);
  };
  return (
    <fieldset className={cn(style.datePickerFieldset, className)}>
      <legend className={style.datePickerLegend}>{title}</legend>
      <RangePicker
        showTime={{ format: 'HH:mm' }}
        format='YYYY-MM-DD HH:mm'
        size='small'
        bordered={false}
        suffixIcon={<CalendarIcon />}
        onChange={onPick}
        className={style.datePicker}
        onOpenChange={onOpenChange}
      />
    </fieldset>
  );
};

export default AppDatePicker;
