import React from 'react';
import { Popover } from 'antd';
import { observer } from 'mobx-react';
import RemindInfoHeader from '../common/forms/ReminderInfoForm/RemindInfoHeader';
import ReminderInfoBody from '../common/forms/ReminderInfoForm/ReminderInfoBody';
import style from '../../containers/CalendarContent/style.module.less';
import { ReactComponent as EventPhone } from '../../assets/image/event-phone.svg';
import { ReactComponent as EventEmail } from '../../assets/image/event-email.svg';
import { ReactComponent as EventMessage } from '../../assets/image/event-message.svg';
import { ReactComponent as ExclamationIcon } from '../../assets/image/exclamation-icon.svg';

/**
 * Represents calendar event component
 * @param {object} params - params {event} object passed from calendar
 * @returns {JSX.Element}
 */

const CalendarEvent = observer((params) => {
  const { event } = params;
  return (
    <div>
      <Popover
        visible={event.popover}
        title={<RemindInfoHeader event={event} />}
        content={<ReminderInfoBody event={event} />}
        trigger='click'
      >
        <div className={style.event}>
          <span>{params.title}</span>
          {event.type === 'CALL' && <EventPhone />}
          {event.type === 'EMAIL' && <EventEmail />}
          {event.type === 'SMS' && <EventMessage />}
          {event.type === 'MYSELF' && <ExclamationIcon />}
        </div>
      </Popover>
    </div>
  );
});

export default CalendarEvent;
