import { types } from 'mobx-state-tree';

const Trader = types.model('Trader', {
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});
const BackofficeUserRole = types.model('BackofficeUserRole', {
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});
const TicketModel = types
  .model('TicketModel', {
    amount: types.maybeNull(types.number),
    closedAt: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    openedAt: types.maybeNull(types.string),
    openedBy: types.maybeNull(BackofficeUserRole),
    status: types.maybeNull(types.string),
    trader: types.maybeNull(Trader),
    type: types.maybeNull(types.string),
    popover: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    setPopover() {
      store.popover = !store.popover;
    },
  }));

export default TicketModel;
