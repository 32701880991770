const tradersTableOptions = [
  { title: 'ID', checked: true },
  { title: 'Name', checked: true },
  { title: 'Sale status', checked: true },
  { title: 'Email', checked: true },
  { title: 'Phone', checked: true },
  { title: 'Documents', checked: true },
  { title: 'Campaign', checked: true },
  { title: 'Affiliate', checked: true },
  { title: 'Broker', checked: true },
  { title: 'Desk', checked: true },
  { title: 'Balance', checked: true },
  { title: 'Retention', checked: true },
  { title: 'Last login', checked: true },
];

export default tradersTableOptions;
