import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import UserModel from './UserModel';
import errorHandler from '../../util/errorHandler';
import queryString from '../../util/queryString';

const { REACT_APP_API, REACT_APP_NOTIFICATION_SERVICE } = process.env;

const deleteToken = () => {
  delete axios.defaults.headers.common.Authorization;
};

const AuthUserModel = types
  .model('AuthUserModel', {
    userData: types.maybeNull(UserModel),
    isAuth: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    error: types.maybeNull(types.string),
    unreadMessages: types.maybeNull(types.number),
  })
  .actions((user) => ({
    login: flow(function* login(username, password) {
      user.isLoading = true;
      try {
        const config = {
          password,
          username,
        };
        const { data } = yield axios.post(queryString(REACT_APP_API, '/api/authenticate'), config);
        if (data.id_token) {
          axios.defaults.headers.common.Authorization = `Bearer ${data.id_token}`;
          localStorage.setItem('crm_token', data.id_token);
          user.isAuth = true;
        }
      } catch (e) {
        user.error = e.response?.data.message;
      } finally {
        user.isLoading = false;
      }
    }),
    getUser: flow(function* getUser() {
      if (!localStorage.crm_token) return;

      try {
        const { data } = yield axios.get(queryString(REACT_APP_API, '/api/account'));
        if (!data) return;
        user.userData = data;
      } catch (e) {
        errorHandler(user, e);
      } finally {
        user.isLoading = false;
      }
    }),
    sendFirebaseToken: flow(function* sendFirebaseToken(token) {
      if (!localStorage.crm_token) return;

      try {
        yield axios.post(
          queryString(
            REACT_APP_NOTIFICATION_SERVICE,
            '/services/notification/api/fcm-registration-tokens',
          ),
          token,
        );
      } catch (e) {
        errorHandler(user, e);
      } finally {
        user.isLoading = false;
      }
    }),
    getUnreadMessages(count) {
      user.unreadMessages = count;
    },
    logout() {
      localStorage.removeItem('crm_token');
      deleteToken();
      user.userData = null;
      user.isAuth = false;
    },
    afterCreate: () => {
      const token = localStorage.getItem('crm_token');
      if (token) {
        user.isAuth = true;
      }
    },
  }));

export default AuthUserModel;
