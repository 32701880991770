import React from 'react';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';
import CloseIcon from '../icons/CloseIcon';
import AddNewDeskForm from '../forms/AddNewDeskForm';

/**
 * Represents add new desk modal component
 * @returns {JSX.Element}
 */

const AddNewDeskModal = () => {
  const {
    desks: { isModalOpen, setIsModalOpen },
  } = useRootModel();
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    isModalOpen && (
      <Modal
        title='Add New Desk'
        onCancel={closeModal}
        visible={isModalOpen}
        bodyStyle={{ padding: 0 }}
        width={550}
        closeIcon={<CloseIcon />}
        footer={null}
      >
        <div>
          <AddNewDeskForm />
        </div>
      </Modal>
    )
  );
};

export default observer(AddNewDeskModal);
