import { types } from 'mobx-state-tree';
import TradersListModel from '../../commonModels/TradersListModel';

const TraderBalanceHistoryModel = types.model('TraderBalanceHistoryModel', {
  amount: types.maybeNull(types.number),
  assignedTo: types.maybeNull(TradersListModel),
  balanceBefore: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  transactionTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export default TraderBalanceHistoryModel;
