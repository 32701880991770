import React from 'react';
import { observer } from 'mobx-react';
import AppSelect from '../../../ui/AppSelect/AppSelect';

/**
 * Represents filter name entity component
 * @param {Object} filter - filter name entity
 * @param {Array} list - array of objects (list of entities)
 * @param {String} param - filter parameter
 * @param {String} title - filter title
 * @param {String} placeholder - filter placeholder
 * @returns {JSX.Element}
 */

const FilterNameEntity = ({ filter, list, param, title, placeholder }) => {
  const data = list.map((item) => {
    return { name: item.name, value: item.id };
  });
  const onChange = (value, info) => {
    filter.setValue(value.toString(), param, info.name);
  };
  return (
    <AppSelect
      filter={filter}
      title={title}
      data={data}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default observer(FilterNameEntity);
