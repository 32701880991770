import React from 'react';
import style from './Campaigns.module.less';

/**
 * Represents Campaigns page
 * @returns {JSX.Element}
 */
const Campaigns = () => {
  return <main className={style.campaigns}>Campaigns</main>;
};

export default Campaigns;
