import { types } from 'mobx-state-tree';
import ModalWindowModel from '../../commonModels/ModalWindowModel';

const Desk = types.model('Desk', {
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});
const Campaign = types.model('Campaign', {
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});
const Broker = types.model('Broker', {
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});
const RetentionStatus = types.model('RetentionStatus', {
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});
const SaleStatus = types.model('SaleStatus', {
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});
const TraderFinanceAttributes = types.model('TraderFinanceAttributes', {
  cfdActiveMargin: types.maybeNull(types.number),
  deposits: types.maybeNull(types.number),
  ftd: types.maybeNull(types.number),
  netDeposits: types.maybeNull(types.number),
  pnl: types.maybeNull(types.number),
  redeposits: types.maybeNull(types.number),
  withdrawals: types.maybeNull(types.number),
});
const Affiliate = types.model('Affiliate', {
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});
const PassportPage = types.model('PassportPage', {
  path: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
});
const PassportData = types.model('PassportData', {
  first: types.maybeNull(PassportPage),
  registration: types.maybeNull(PassportPage),
});
const TraderModel = types
  .model('TraderModel', {
    affiliate: types.maybeNull(Affiliate),
    broker: types.maybeNull(Broker),
    campaign: types.maybeNull(Campaign),
    desk: types.maybeNull(Desk),
    traderDocumentStatus: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    lastLogin: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    leadPhone: types.maybeNull(types.string),
    leadCountry: types.maybeNull(types.string),
    retentionStatus: types.maybeNull(RetentionStatus),
    saleStatus: types.maybeNull(SaleStatus),
    name: types.maybeNull(types.string),
    balance: types.maybeNull(types.number),
    isActive: types.maybeNull(types.boolean),
    isDemo: types.maybeNull(types.boolean),
    langKey: types.maybeNull(types.string),
    activeTrading: types.maybeNull(types.boolean),
    currency: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    registrationCountry: types.maybeNull(types.string),
    registrationDate: types.maybeNull(types.string),
    registrationIp: types.maybeNull(types.string),
    timeZoneId: types.maybeNull(types.string),
    traderFinanceAttributes: types.maybeNull(TraderFinanceAttributes),
    countryCode: types.maybeNull(types.string),
    passportData: types.maybeNull(PassportData),
    isCallHistoryModal: types.optional(types.boolean, false),
    isTraderReminderModal: types.optional(types.boolean, false),
    isEmailTemplateModal: types.optional(types.boolean, false),
    isModal: types.optional(ModalWindowModel, {}),
  })
  .actions((store) => ({
    setCallHistoryModal(isOpen) {
      store.isCallHistoryModal = isOpen;
    },
    setTraderReminderModal(isOpen) {
      store.isTraderReminderModal = isOpen;
    },
    setEmailTemplateModal(isOpen) {
      store.isEmailTemplateModal = isOpen;
    },
  }));

export default TraderModel;
