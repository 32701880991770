const desksDefaultFilters = {
  id: { field: '', value: '' },
  name: { field: '', value: '' },
  teamLead: { field: '', value: '' },
  active: { field: '', value: '' },
  traderCount: { field: '', value: '' },
  affiliateCampaign: { field: '', value: '' },
  created: { field: '', value: '' },
};

export default desksDefaultFilters;
