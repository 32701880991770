import React from 'react';
import AppSelect from '../../../ui/AppSelect/AppSelect';

/**
 * Represents filter boolean item component
 * @param {Object} filter - filter object
 * @param {String} title - filter title
 * @param {String} param - filter parameter
 * @param {String} compareString - string to compare value
 * @param {Array} data - select data
 * @param {String} placeholder - select placeholder
 * @returns {JSX.Element}
 */

const FilterBoolItem = ({ filter, title, param, compareString, data, placeholder }) => {
  const onChange = (value, info) => {
    let status;
    if (value === compareString) {
      status = false;
    } else {
      status = value;
    }
    filter.setValue(status, param, info.name);
  };
  return (
    <AppSelect
      filter={filter}
      title={title}
      data={data}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default FilterBoolItem;
