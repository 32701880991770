import React from 'react';
import FinanceIcon from '../../../../components/common/icons/FinanceIcon';
import style from '../style.module.less';
import ExecutionPlan from './ExecutionPlan';

/**
 * Represents selected worker finance
 * @param {Object} worker - object with the selected worker
 * @param {Object} user - object with the authenticated user
 * @param {Boolean} isExecutionPlan - boolean value to enable execution plan
 * @returns {JSX.Element}
 */

const Finance = ({ worker, user, isExecutionPlan }) => {
  return (
    <div className={style.personalInfo}>
      <div className={style.personalInfo_header}>
        <FinanceIcon />
        <p className={style.personalInfo_header_text}>Finance</p>
      </div>
      <div className={style.personalInfo_executionPlan}>
        <ExecutionPlan
          setExecutionPlan={worker.setExecutionPlan}
          initialExecutionPlan={worker.executionPlan}
          user={user}
          isExecutionPlan={isExecutionPlan}
          worker={worker}
        />
      </div>
      <div className={style.personalInfo_content_wrapper}>
        <div>
          <p
            className={`
            ${style.personalInfo_finance_heading}
            ${style.personalInfo_finance_heading__large}`}
          >
            Total
          </p>
          <p
            className={`
            ${style.personalInfo_finance_heading}
            ${style.personalInfo_finance_heading__colored}`}
          >
            Total finance attributes
          </p>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Total deposits</p>
            <div
              className={`
            ${style.personalInfo_content_dial} 
            ${style.personalInfo_content_dial__finance}`}
            >
              <span>{worker.totalFinanceAttributes?.depositsCount}</span>
              <span>${worker.totalFinanceAttributes?.deposits}</span>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Total FTDs</p>
            <div
              className={`
            ${style.personalInfo_content_dial} 
            ${style.personalInfo_content_dial__finance}`}
            >
              <span>{worker.totalFinanceAttributes?.ftdsCount}</span>
              <span>${worker.totalFinanceAttributes?.ftds}</span>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Total redeposits</p>
            <div
              className={`
            ${style.personalInfo_content_dial} 
            ${style.personalInfo_content_dial__finance}`}
            >
              <span>{worker.totalFinanceAttributes?.redepositsCount}</span>
              <span>${worker.totalFinanceAttributes?.redeposits}</span>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Total withdrawals</p>
            <div
              className={`
            ${style.personalInfo_content_dial} 
            ${style.personalInfo_content_dial__finance}`}
            >
              <span>{worker.totalFinanceAttributes?.withdrawalsCount}</span>
              <span>${worker.totalFinanceAttributes?.withdrawals}</span>
            </div>
          </div>
          <div>
            <p className={style.personalInfo_content_label}>Net deposits</p>
            <div className={style.personalInfo_content_dial}>
              <p>${worker.totalFinanceAttributes?.netDeposits}</p>
            </div>
          </div>
        </div>
        <div>
          <p
            className={`
            ${style.personalInfo_finance_heading}
            ${style.personalInfo_finance_heading__large}`}
          >
            This Month
          </p>
          <p
            className={`
            ${style.personalInfo_finance_heading}
            ${style.personalInfo_finance_heading__colored}`}
          >
            July finance attributes
          </p>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Deposits</p>
            <div
              className={`
            ${style.personalInfo_content_dial} 
            ${style.personalInfo_content_dial__finance}`}
            >
              <span>{worker.mouthFinanceAttributes?.depositsCount}</span>
              <span>${worker.mouthFinanceAttributes?.deposits}</span>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>FTDs</p>
            <div
              className={`
            ${style.personalInfo_content_dial} 
            ${style.personalInfo_content_dial__finance}`}
            >
              <span>{worker.mouthFinanceAttributes?.ftdsCount}</span>
              <span>${worker.mouthFinanceAttributes?.ftds}</span>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Redeposits</p>
            <div
              className={`
            ${style.personalInfo_content_dial} 
            ${style.personalInfo_content_dial__finance}`}
            >
              <span>${worker.mouthFinanceAttributes?.redepositsCount}</span>
              <span>${worker.mouthFinanceAttributes?.redeposits}</span>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Withdrawals</p>
            <div
              className={`
            ${style.personalInfo_content_dial} 
            ${style.personalInfo_content_dial__finance}`}
            >
              <span>{worker.mouthFinanceAttributes?.withdrawalsCount}</span>
              <span>${worker.mouthFinanceAttributes?.withdrawals}</span>
            </div>
          </div>
          <div>
            <p className={style.personalInfo_content_label}>Net deposits</p>
            <div className={style.personalInfo_content_dial}>
              <p>${worker.mouthFinanceAttributes?.netDeposits}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
