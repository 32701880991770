import axios from 'axios';

const AxiosConfig = () => {
  const token = localStorage.getItem('crm_token');

  // setting axios defaults
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  //  Add a request interceptor
  /*  axios.interceptors.request.use(function(config) {
        // Do something before request is sent
        return config;
      }, function(error) {
        // Do something with request error
        return Promise.reject(error);
      }); */

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { status } = error.response;
      if (status === 401) {
        localStorage.removeItem('crm_token');
      }
      return Promise.reject(error);
    },
  );
};

export default AxiosConfig;
