import React from 'react';
import './chatList.scss';
import { ChatItem } from '../ChatItem/ChatItem';

const CustomChatList = (props) => {
  const onClick = (item, i, e) => {
    if (props.onClick instanceof Function) props.onClick(item, i, e);
  };

  const onContextMenu = (item, i, e) => {
    e.preventDefault();
    if (props.onContextMenu instanceof Function) props.onContextMenu(item, i, e);
  };
  return (
    <div ref={props.cmpRef} className='chat-list-container'>
      {props.dataSource.map((x, i) => (
        <ChatItem
          id={x.id || i}
          key={i}
          lazyLoadingImage={props.lazyLoadingImage}
          className={props.activeChatId && x.id === props.activeChatId ? 'active-chat' : ''}
          {...x}
          isAvatar={false}
          onContextMenu={(e) => onContextMenu(x, i, e)}
          onClick={(e) => onClick(x, i, e)}
        />
      ))}
    </div>
  );
};

export default CustomChatList;
