import React from 'react';
import { ReactComponent as SendIcon } from '../../../../assets/image/send.svg';
import './SendButton.scss';

const SendButton = ({ onSend }) => (
  <button onClick={onSend} className='send-button'>
    <SendIcon />
  </button>
);

export default SendButton;
