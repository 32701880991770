import React from 'react';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';
import CloseIcon from '../icons/CloseIcon';
import NotificationsContent from '../../../containers/NotificationsContent';

/**
 * Represents notification modal component
 * @returns {JSX.Element}
 */

const NotificationModal = () => {
  const {
    notifications: { isNotificationsModal, setNotificationsModal },
  } = useRootModel();

  const closeModal = () => {
    setNotificationsModal(false);
  };

  return (
    isNotificationsModal && (
      <Modal
        style={{ top: 49, margin: '0 0 0 auto', right: 0 }}
        closeIcon={<CloseIcon />}
        title='Notifications'
        header={null}
        footer={null}
        onCancel={closeModal}
        visible={isNotificationsModal}
        width={280}
        bodyStyle={{ padding: '0px' }}
      >
        <NotificationsContent />
      </Modal>
    )
  );
};

export default observer(NotificationModal);
