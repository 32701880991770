import { flow, getRoot, types } from 'mobx-state-tree';
import axios from 'axios';
import LayoutModel from './models/LayoutModel';
import errorHandler from '../../util/errorHandler';
import LangKeyModel from './models/LangKeyModel';
import PermissionsModel from '../commonModels/PermissionsModel';
import handleReadPermission from '../../util/handleReadPermission';
import handleWritePermission from '../../util/handleWritePermission';
import queryString from '../../util/queryString';

const { REACT_APP_API, REACT_APP_NOTIFICATION_SERVICE } = process.env;

const MailLayoutsModel = types
  .model('MarketInfoModel', {
    mailLayouts: types.array(LayoutModel),
    selectedLayout: types.maybeNull(types.number),
    isLoading: types.optional(types.boolean, false),
    isModalOpen: types.optional(types.boolean, false),
    templateTitle: types.maybeNull(types.string),
    language: types.maybeNull(types.string),
    langKey: types.optional(LangKeyModel, {}),
    permissions: types.array(PermissionsModel),
  })
  .actions((self) => ({
    getPermission: flow(function* getPermission(permission) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const {
        user: { userData },
      } = getRoot(self);
      const params = {
        category: permission,
        role: userData?.authorities,
      };
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/permissions'),
          { params },
        );
        self.permissions = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    setSelectedLayout(layoutId, title) {
      self.selectedLayout = layoutId;
      self.templateTitle = title;
    },
    setLoading(value) {
      self.isLoading = value;
    },
    setIsModalOpen(value) {
      self.isModalOpen = value;
    },
    setTitle(title, language) {
      self.templateTitle = title;
      self.language = language;
    },
    setLangKey(langKey) {
      self.langKey = langKey;
    },
    fetchMailLayouts: flow(function* fetchMailLayouts() {
      const { langKey } = self;

      const params = {
        lang: langKey.key,
      };

      try {
        self.isLoading = true;
        const { data } = yield axios.get(
          queryString(REACT_APP_NOTIFICATION_SERVICE, '/services/notification/api/email-templates'),
          { params },
        );
        self.mailLayouts = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    createMailLayout: flow(function* createMailLayout(layout) {
      try {
        self.isLoading = true;
        const { data } = yield axios.post(
          queryString(REACT_APP_NOTIFICATION_SERVICE, '/services/notification/api/email-templates'),
          layout,
        );
        self.mailLayouts = [...self.mailLayouts, data];
        // eslint-disable-next-line no-console
        console.log('Created new layout', data);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    editMailLayout: flow(function* editMailLayout(layout) {
      const { selectedLayout } = self;
      try {
        self.isLoading = true;
        const { data } = yield axios.patch(
          queryString(
            REACT_APP_NOTIFICATION_SERVICE,
            `/services/notification/api/email-templates/${selectedLayout}`,
          ),
          layout,
        );
        // eslint-disable-next-line no-console
        console.log('Created new layout', data);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    deleteMailLayout: flow(function* deleteMailLayout(id) {
      try {
        self.isLoading = true;
        const { data } = yield axios.delete(
          queryString(REACT_APP_API, `/services/notification/api/email-templates/${id}`),
        );
        // eslint-disable-next-line no-console
        console.log('Deleted layout', data);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
  }))
  .views((self) => ({
    get layoutIndex() {
      const { mailLayouts, selectedLayout } = self;
      return mailLayouts.findIndex((item) => item.id === selectedLayout);
    },
    get isAddNewTemplate() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'MAIL_LAYOUTS_ADD_NEW');
    },
    get isMailLayouts() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'MAIL_LAYOUTS');
    },
    get isEditMailLayouts() {
      const { permissions } = self;
      return handleWritePermission(permissions, 'MAIL_LAYOUTS');
    },
  }));

export default MailLayoutsModel;
