import React from 'react';

import Preloader from '../../../common/Preloader';
import MessageBox from '../MessageBox/MessageBox';
import renderDay from '../../../../util/renderDay';

import './MessageList.css';

const MessageList = (props) => {

  const { dataSource } = props;

  const messagePosition = (senderId, userId) => {
    return userId === +senderId ? 'right' : 'left';
  };

  const messageTitle = (senderId, userId, firstName, lastName) => {
    return userId === +senderId ? 'You' : `${ firstName } ${ lastName }`;
  };
  const onOpen = (item, i, e) => {
    if (props.onOpen instanceof Function) props.onOpen(item, i, e);
  };

  const onClick = (item, i, e) => {
    if (props.onClick instanceof Function) props.onClick(item, i, e);
  };

  const onIncomingMessageHover = (item, i, e) => {
    if (props.onIncomingMessageHover instanceof Function) props.onIncomingMessageHover(item, i, e);
  };

  return (
    <div className={`rce-container-mlist ${props.className ? props.className : ''}`}>
      {props.isLoading && <div className='preloader-wrap'><Preloader spinColor='#9a9ac0b3' /></div>}
      <div className='rce-mlist'>
        {dataSource.map((x, i, arr) => (
          <div key={i} id={x.id}>
            {renderDay(x, arr[i - 1])}
            <MessageBox
              {...x}
              message={x}
              title={messageTitle(x.sender, props.userId, x.firstName, x.lastName)}
              position={messagePosition(x.sender, props.userId )}
              onOpen={props.onOpen && ((e) => onOpen(x, i, e))}
              onClick={props.onClick && ((e) => onClick(x, i, e))}
              onIncomingMessageHover={(e) => onIncomingMessageHover(x, i, e)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

MessageList.defaultProps = {
  onClick: null,
  onDownButtonClick: null,
  onOpen: null,
  dataSource: [],
  lockable: false,
  toBottomHeight: 300,
  downButton: true,
  downButtonBadge: null,
};

export default MessageList;
