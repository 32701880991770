import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Button } from 'antd';
import style from './style.module.less';

const ErrorPage = () => {
  const history = useHistory();
  const handleHome = () => history.push('/');

  return (
    <section className={style.errorPage}>
      <div className={style.wrap}>
        <div>
          <p className={style.errorNumber}>404</p>
        </div>
        <div className={style.textContainer}>
          <Typography.Text className={style.textBold}>Page not found.</Typography.Text>
          <Typography.Text className={style.text}>
            The page you are looking for might have been removed.
          </Typography.Text>
          <Button className={style.btn} type='primary' onClick={handleHome}>
            Return to Dashboard
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
