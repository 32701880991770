import { flow, getRoot, types } from 'mobx-state-tree';
import axios from 'axios';
import moment from 'moment';
import errorHandler from '../../util/errorHandler';
import WorkersListCalendarModel from './models/WorkersListCalendarModel';
import EventModel from './models/EventModel';
import eventsMapper from '../../util/eventsMapper';
import queryString from '../../util/queryString';

const { REACT_APP_API } = process.env;

const defaultDates = {
  end: moment().endOf('month').format('YYYY-MM-DD'),
  start: moment().startOf('month').format('YYYY-MM-DD'),
  currentDate: moment().toDate(),
};
const RangeDates = types.model('RangeDates', {
  end: types.optional(types.string, ''),
  start: types.optional(types.string, ''),
  currentDate: types.maybeNull(types.Date),
});

const CalendarModel = types
  .model('WorkersModel', {
    events: types.array(EventModel),
    workers: types.array(WorkersListCalendarModel),
    traders: types.array(WorkersListCalendarModel),
    isLoading: types.optional(types.boolean, false),
    isModalOpen: types.optional(types.boolean, false),
    isEditModalOpen: types.optional(types.boolean, false),
    rangeDates: types.optional(RangeDates, {}),
    editableEvent: types.maybeNull(EventModel),
  })
  .actions((self) => ({
    fetchEvents: flow(function* fetchEvents(id) {
      const {
        user: { userData },
      } = getRoot(self);
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = self.rangeDates;
      try {
        const { data } = yield axios.get(
          queryString(
            REACT_APP_API,
            `/api/backoffice/workers/${id || userData.id}/calendar-events`,
          ),
          {
            params,
          },
        );
        // eslint-disable-next-line no-console
        console.log('mapped events', eventsMapper(data));
        self.events = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchTradersEvents: flow(function* fetchTradersEvents(id) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = self.rangeDates;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/calendar-events`),
          {
            params,
          },
        );
        // eslint-disable-next-line no-console
        console.log('mapped events', eventsMapper(data));
        self.events = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    addReminder: flow(function* addReminder(event) {
      const { fetchEvents } = self;
      const {
        user: { userData },
      } = getRoot(self);
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        yield axios.post(queryString(REACT_APP_API, 'api/backoffice/calendar-events'), event);
        fetchEvents(userData?.id);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isModalOpen = false;
        self.isLoading = false;
      }
    }),
    deleteEvent: flow(function* deleteEvent(id) {
      const { events } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        yield axios.delete(queryString(REACT_APP_API, `/api/backoffice/calendar-events/${id}`));
        self.events = events.filter((el) => el.id !== id);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isModalOpen = false;
        self.isLoading = false;
      }
    }),
    updateEvent: flow(function* updateEvent(event) {
      const { fetchEvents } = self;
      const {
        user: { userData },
      } = getRoot(self);
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        yield axios.patch(queryString(REACT_APP_API, '/api/backoffice/calendar-events'), event);
        fetchEvents(userData?.id);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isEditModalOpen = false;
        self.isLoading = false;
      }
    }),
    getWorkers: flow(function* getWorkers() {
      self.isLoading = true;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/workers/simplified?active-status=ACTIVE'),
        );
        self.workers = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    getTraders: flow(function* getTraders() {
      self.isLoading = true;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/traders/simplified?active-status=ACTIVE'),
        );
        self.traders = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    changeView(newRange) {
      const { fetchEvents } = self;
      self.rangeDates = newRange;
      fetchEvents();
    },
    setEditableEvent(event) {
      self.editableEvent = event;
    },
    setIsModalOpen(isOpen) {
      self.isModalOpen = isOpen;
    },
    setIsEditModalOpen(isOpen) {
      self.isEditModalOpen = isOpen;
    },
    setPopover(id) {
      const { events } = self;
      const index = events.findIndex((e) => e.id === id);
      events[index].popover = !events[index].popover;
    },
    afterCreate: () => {
      self.rangeDates = defaultDates;
    },
  }));

export default CalendarModel;
