import React from 'react';
import { Col, Pagination, Row } from 'antd';

import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import AppliedFilters from '../../components/AppliedFilters/AppliedFilters';
import TradersTable from '../../components/common/tables/TradersTable';

import style from './style.module.less';

/**
 * Represents traders content component
 * @returns {JSX.Element}
 */

const TradersContent = () => {
  const {
    traders: { filters, fetchTraders, clearFilters, handlePageChange, pagination },
  } = useRootModel();
  const onPageChange = (page, size) => {
    handlePageChange(page, size);
  };
  return (
    <div className={style.tradersContentWrap}>
      <Row className={style.tradersHeader} align='middle'>
        <Col span={6}>
          <p>All platform traders</p>
        </Col>
        <Col span={18} className={style.options}>
          <Pagination
            onChange={onPageChange}
            current={pagination.number + 1}
            total={pagination.totalElements}
            showSizeChanger
            defaultPageSize={pagination.size}
          />
        </Col>
      </Row>
      <AppliedFilters filters={filters} fetch={fetchTraders} clearFilters={clearFilters} />
      <TradersTable />
    </div>
  );
};

export default observer(TradersContent);
