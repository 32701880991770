import React, { useState } from 'react';
import { Button, Form, Select, Typography } from 'antd';
import { observer } from 'mobx-react';
import Preloader from '../../Preloader';

import style from '../forms.module.less';

/**
 * Represents change sale status form
 * @param {array} saleStatuses - list of sale's statuses
 * @returns {JSX.Element}
 */

const ChangeSaleStatusForm = ({ saleStatuses }) => {
  let isLoading = false;
  const [saleStatus, setValues] = useState(null);
  const onSelectChange = (value) => {
    setValues(value);
  };
  const onSubmit = () => {
    isLoading = true;
    // eslint-disable-next-line no-console
    console.log(saleStatus);
    isLoading = false;
  };
  const validateMessages = {
    required: 'This field is required',
  };
  return (
    <div className={style.workerFormWrapper}>
      <Form
        className={style.workerForm}
        name='Change sale status'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
        requiredMark={false}
      >
        <Form.Item
          className={style.workerForm_formLabel}
          label='Select sale status'
          name='saleStatus'
          value={saleStatus}
          rules={[{ required: true }]}
        >
          <Select value={saleStatus} placeholder='Select status...' onChange={onSelectChange}>
            {saleStatuses.map((status) => (
              <Select.Option key={status.id} value={status.id}>
                <div className={style.selectOption}>
                  <Typography.Text>{status.value}</Typography.Text>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className={style.workerForm_submit_wrapper}>
          {isLoading ? (
            <Preloader />
          ) : (
            <Button className={style.workerForm_submit} htmlType='submit' type='primary'>
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(ChangeSaleStatusForm);
