import React from 'react';
import { Spin } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LoadingOutlined } from '@ant-design/icons';

import style from './Preloader.module.less';

/**
 * @desc Preloader component
 * @param {number} size - preloader size
 * @param {string} spinColor - spinner color
 * @returns {JSX.Element}
 */
const Preloader = ({ size = 24, spinColor = '#4671e0' }) => {
  return (
    <div className={style.preloader_wrap}>
      <Spin
        size='default'
        indicator={<LoadingOutlined style={{ fontSize: size, color: spinColor }} spin />}
      />
    </div>
  );
};

export default Preloader;
