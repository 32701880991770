import { types } from 'mobx-state-tree';

const FilterDateItemModel = types
  .model('FilterDateItemModel', {
    field: types.optional(types.string, ''),
    fromValue: types.optional(types.string, ''),
    toValue: types.optional(types.string, ''),
  })
  .actions((filter) => ({
    setValue(field, fromValue, toValue) {
      filter.field = field;
      filter.fromValue = fromValue;
      filter.toValue = toValue;
    },
    removeFilter() {
      filter.field = '';
      filter.fromValue = '';
      filter.toValue = '';
    },
  }));

export default FilterDateItemModel;
