import { types } from 'mobx-state-tree';

const Pagination = types.model('Pagination', {
  number: types.optional(types.number, 0),
  size: types.optional(types.number, 10),
  totalElements: types.maybeNull(types.number),
  sortColumn: types.optional(types.string, 'id'),
  sortOrder: types.optional(types.string, 'asc'),
  category: types.optional(types.string, 'crypto'),
});

export default Pagination;
