import React from 'react';
import { types, addMiddleware } from 'mobx-state-tree';
import { mstLog } from 'mst-log';
import AuthUserModel from './AuthUserModel';
import WorkersModel from './WorkersModel';
import TicketsModel from './TicketsModel';
import ErrorModel from './ErrorModel';
import TradersModel from './TradersModel';
import CalendarModel from './CalendarModel';
import DesksModel from './DesksModel';
import NotificationsModel from './NotificationsModel';
import MarketInfoModel from './MarketInfoModel';
import ChatModel from './ChatModel';
import SupportModel from './SupportModel';
import MailLayoutsModel from './MailLayoutsModel';
import PositionsModel from './PositionsModel';
import LogsModel from './LogsModel';
import SettingsModel from './SettingsModel';
// import persist from '../util/persist';

/**
 * @typedef {Object} RootStore
 * @property {AuthUserModel} user
 */
const RootStore = types.model('RootStore', {
  user: types.optional(AuthUserModel, {}),
  workers: types.optional(WorkersModel, {}),
  tickets: types.optional(TicketsModel, {}),
  traders: types.optional(TradersModel, {}),
  calendar: types.optional(CalendarModel, {}),
  error: types.optional(ErrorModel, {}),
  desks: types.optional(DesksModel, {}),
  notifications: types.optional(NotificationsModel, {}),
  marketInfo: types.optional(MarketInfoModel, {}),
  chat: types.optional(ChatModel, {}),
  support: types.optional(SupportModel, {}),
  mailLayouts: types.optional(MailLayoutsModel, {}),
  positions: types.optional(PositionsModel, {}),
  logs: types.optional(LogsModel, {}),
  settings: types.optional(SettingsModel, {}),
});

const initialState = RootStore.create({});
if (process.env.NODE_ENV !== 'production') {
  addMiddleware(initialState, mstLog());
}

// Function for persisting data
// persist('workers', initialState?.workers, {
//   storage: sessionStorage,
//   jsonify: true,
// });
// persist('tickets', initialState?.tickets, {
//   storage: sessionStorage,
//   jsonify: true,
// });
// persist('traders', initialState?.traders, {
//   storage: sessionStorage,
//   jsonify: true,
// });

export const rootStore = initialState;

/**
 * RootStore context
 * @type {React.Context<RootStore | null>}
 */
export const RootStoreContext = React.createContext(null);

export function useRootModel() {
  const store = React.useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
