import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const PositionsIconSvg = () => (
  <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='6.5' cy='6.5' r='6' stroke='#9A9AC0' />
    <circle cx='6.5' cy='6.49998' r='1.66667' stroke='#9A9AC0' />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const PositionsIcon = (props) => <Icon component={PositionsIconSvg} {...props} />;

export default PositionsIcon;
