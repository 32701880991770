import React from 'react';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';
import ErrorHandler from '../ErrorHandler';
import CloseIcon from '../icons/CloseIcon';

import style from './modals.module.less';

/**
 * @desc function for create error modal window
 * @returns {JSX.Element}
 */

const ErrorModal = () => {
  const {
    error: { error, isModalOpen, setIsModalOpen },
  } = useRootModel();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    isModalOpen && (
      <Modal
        centered
        closeIcon={<CloseIcon />}
        footer={null}
        header={null}
        onCancel={closeModal}
        visible={isModalOpen}
        width={404}
      >
        <div className={style.modal_content}>
          {error && (
            <div className={style.error_wrapper}>
              <ErrorHandler error={error} />
            </div>
          )}
        </div>
      </Modal>
    )
  );
};

export default observer(ErrorModal);
