import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useRootModel } from '../../models/RootStore';
import EditReminderModal from '../../components/common/modals/EditReminderModal';
import CalendarEvent from '../../components/CalendarEvent';
import Preloader from '../../components/common/Preloader';

import style from './style.module.less';

/**
 * Represents Calendar component
 * @returns {JSX.Element}
 */

const CalendarContent = () => {
  const {
    calendar: { events, changeView, isLoading, setPopover, rangeDates },
  } = useRootModel();
  const localize = momentLocalizer(moment);
  const components = {
    event: CalendarEvent,
  };
  const eventPropGetter = (event) => {
    const backgroundColor =
      (event.type === 'CALL' && '#334d6e') ||
      (event.type === 'EMAIL' && '#e64545') ||
      (event.type === 'SMS' && 'darkolivegreen');
    return { style: { backgroundColor } };
  };
  const getDate = (dates) => {
    const startOfMonth = moment(dates).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(dates).endOf('month').format('YYYY-MM-DD');
    const currentDate = moment(dates).toDate();
    changeView({ end: endOfMonth, start: startOfMonth, currentDate });
  };
  const onSelectEvent = (event) => {
    setPopover(event.id);
  };
  return (
    <section className={style.calendarContainer}>
      {!isLoading ? (
        <Calendar
          localizer={localize}
          events={toJS(events)}
          startAccessor='startDate'
          endAccessor='endDate'
          tooltipAccessor='tooltip'
          style={{ height: 800 }}
          views={['month', 'week', 'day']}
          date={rangeDates.currentDate}
          onNavigate={getDate}
          eventPropGetter={eventPropGetter}
          components={components}
          onDoubleClickEvent={onSelectEvent}
        />
      ) : (
        <Preloader />
      )}
      <EditReminderModal />
    </section>
  );
};

export default observer(CalendarContent);
