import React from 'react';

import style from './HeaderOptions.module.less';

/**
 * Represents HeaderOptions component
 * @param {JSX.Element | Array<JSX.Element>} children - elements what will be rendered
 * @returns {JSX.Element}
 */
const HeaderOptions = ({ children }) => {
  return <div className={style.options}>{children}</div>;
};

export default HeaderOptions;
