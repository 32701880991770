import React, { useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useParams } from 'react-router-dom';
import { useRootModel } from '../../../models/RootStore';
import CalendarEvent from '../../../components/CalendarEvent';
import EditReminderModal from '../../../components/common/modals/EditReminderModal';
import Preloader from '../../../components/common/Preloader';

const TraderCalendar = () => {
  const {
    calendar: { events, changeView, isLoading, setPopover, rangeDates, fetchTradersEvents },
  } = useRootModel();
  const { id } = useParams();
  useEffect(() => {
    fetchTradersEvents(id);
  }, []);
  const localize = momentLocalizer(moment);
  const components = {
    event: CalendarEvent,
  };
  const eventPropGetter = (event) => {
    const backgroundColor =
      (event.type === 'CALL' && '#334d6e') ||
      (event.type === 'EMAIL' && '#e64545') ||
      (event.type === 'SMS' && 'darkolivegreen');
    return { style: { backgroundColor } };
  };
  const getDate = (dates) => {
    const startOfMonth = moment(dates).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(dates).endOf('month').format('YYYY-MM-DD');
    const currentDate = moment(dates).toDate();
    changeView({ end: endOfMonth, start: startOfMonth, currentDate });
  };
  const onSelectEvent = (event) => {
    setPopover(event.id);
  };
  return (
    <div style={{ paddingTop: '10px' }}>
      {!isLoading ? (
        <Calendar
          localizer={localize}
          events={toJS(events)}
          date={rangeDates.currentDate}
          onNavigate={getDate}
          startAccessor='startDate'
          endAccessor='endDate'
          tooltipAccessor='tooltip'
          style={{ height: 680 }}
          views={['month', 'week', 'day']}
          eventPropGetter={eventPropGetter}
          components={components}
          onDoubleClickEvent={onSelectEvent}
        />
      ) : (
        <Preloader />
      )}
      <EditReminderModal />
    </div>
  );
};

export default observer(TraderCalendar);
