import React, { useState } from 'react';
import { Button, Form, Select, Typography } from 'antd';
import { observer } from 'mobx-react';
import Preloader from '../../Preloader';

import style from '../forms.module.less';

/**
 * Represents change broker form
 * @param {array} desks - list of desks
 * @param {array} brokers - list of brokers
 * @param {array} teamLeads - list of teamLeads
 * @returns {JSX.Element}
 */

const ChangeBrokerForm = ({ desks, brokers, teamLeads }) => {
  let isLoading = false;
  const [broker, setValues] = useState({
    desk: '',
    teamLead: '',
    broker: '',
  });
  const onSelectChange = (event, prop) => {
    setValues({ ...broker, [prop]: event.target.value });
  };
  const onSubmit = () => {
    isLoading = true;
    // eslint-disable-next-line no-console
    console.log(broker);
    isLoading = false;
  };
  const validateMessages = {
    required: 'This field is required',
  };
  return (
    <div className={style.workerFormWrapper}>
      <Form
        className={style.workerForm}
        name='Change broker'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
        requiredMark={false}
      >
        <Form.Item
          className={style.workerForm_formLabel}
          label='Desk'
          name='desk'
          value={broker.desk}
          rules={[{ required: true }]}
        >
          <Select
            value={broker.desk}
            placeholder='Select Desk or start typing...'
            onChange={(value) => onSelectChange(value, 'desk')}
          >
            {desks.map((status) => (
              <Select.Option key={status.id} value={status.id}>
                <div className={style.selectOption}>
                  <Typography.Text>{status.value}</Typography.Text>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={style.workerForm_formLabel}
          label='Team Lead'
          name='teamLead'
          value={broker.teamLead}
          rules={[{ required: true }]}
        >
          <Select
            value={broker.teamLead}
            placeholder='Select Team Lead or start typing...'
            onChange={(value) => onSelectChange(value, 'teamLead')}
          >
            {teamLeads.map((teamLead) => (
              <Select.Option key={teamLead.id} value={teamLead.id}>
                <div className={style.selectOption}>
                  <Typography.Text>{teamLead.value}</Typography.Text>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={style.workerForm_formLabel}
          label='Broker'
          name='broker'
          value={broker.broker}
          rules={[{ required: true }]}
        >
          <Select
            value={broker.broker}
            placeholder='Select Broker or start typing...'
            onChange={(value) => onSelectChange(value, 'broker')}
          >
            {brokers.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                <div className={style.selectOption}>
                  <Typography.Text>{item.value}</Typography.Text>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className={style.workerForm_submit_wrapper}>
          {isLoading ? (
            <Preloader />
          ) : (
            <Button className={style.workerForm_submit} htmlType='submit' type='primary'>
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(ChangeBrokerForm);
