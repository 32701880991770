import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import CalendarHeader from '../../components/CalendarHeader';
import CalendarContent from '../../containers/CalendarContent';
import CalendarReminderModal from '../../components/common/modals/CalendarReminderModal';

import style from './style.module.less';

/**
 * Represents Calendar's page
 * @returns {JSX.Element}
 */
const Calendar = () => {
  const {
    user: { userData },
    calendar: { setIsModalOpen, getWorkers, workers, fetchEvents },
  } = useRootModel();
  useEffect(() => {
    getWorkers();
  }, []);
  useEffect(() => {
    if (userData) {
      fetchEvents(userData.id);
    }
  }, [userData]);
  return (
    <main>
      <section className={style.calendarHeader}>
        <CalendarHeader
          setIsModalOpen={setIsModalOpen}
          workersList={workers}
          fetchEvents={fetchEvents}
        />
      </section>
      <section className={style.calendar}>
        <CalendarContent />
      </section>
      <CalendarReminderModal />
    </main>
  );
};

export default observer(Calendar);
