import { io } from 'socket.io-client';

const token = localStorage.getItem('id_token');
const socket = io(`${process.env.REACT_APP_CHAT}/`, {
  auth: { token: `Bearer ${token}` },
});

const handleNewMessage = (setUnreadCount, handleMessage) => {
  socket.on('message', (message) => {
    const { createdMessage } = message;
    // eslint-disable-next-line no-console
    console.log('Пришло по сокету:', createdMessage);
    handleMessage(createdMessage);
    if (+createdMessage.sender !== +createdMessage.operatorId) setUnreadCount();
  });
};

const handleDisconnect = () => {
  socket.on('disconnect', (e) => {
    // eslint-disable-next-line no-console
    console.log(e, 'socket disconnect');
  });
};

const handleError = () => {
  socket.on('connect_error', (e) => {
    // eslint-disable-next-line no-console
    console.log('GET ERROR FROM SERVER', e.message);
  });
};

const handleNewChat = () => {
  socket.on('createdNewChat', (message) => {
    // eslint-disable-next-line no-console
    console.log('Created a new chat:', message.createdChat);
  });
};

const handleUpdateChat = () => {
  socket.on('updatedChat', (message) => {
    // eslint-disable-next-line no-console
    console.log('Updated chat:', message.updatedChat);
  });
};

const handleUpdateMessage = () => {
  socket.on('updatedMessage', (message) => {
    // eslint-disable-next-line no-console
    console.log('Updated message:', message.updatedMessage);
  });
};

const handleConnection = (listener) => {
  socket.on('handleConnection', listener);
};

const handleOnline = (listener) => {
  socket.on('clientNetworkStatus', (message) => {
    listener(message);
  });
};

const removeListeners = () => {
  socket.removeAllListeners();
};

export default {
  handleNewMessage,
  handleConnection,
  handleUpdateMessage,
  handleNewChat,
  handleUpdateChat,
  handleOnline,
  handleDisconnect,
  handleError,
  removeListeners,
};
