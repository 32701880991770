import React, { useState } from 'react';
import { Radio, Space } from 'antd';
import { observer } from 'mobx-react';
import prettyLog from '../../../../util/prettyLog';

/**
 * Represents phone filter component
 // * @param {Object} filter - phone filter
 * @returns {JSX.Element}
 */

const RadioFilters = () => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    // filter.setValue(value, 'TRADERS');
    setValue(e.target.value);
  };
  prettyLog('Radio', value);
  return (
    <div style={{ flex: 1 }}>
      <Radio.Group onChange={onChange} value={value}>
        <Space direction='vertical'>
          <Radio value={1}>Show all Traders</Radio>
          <Radio value={2}>Show Traders from i88.io</Radio>
          <Radio value={3}>Show Traders...</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default observer(RadioFilters);
