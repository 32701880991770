import React from 'react';
import { Col, Pagination, Row } from 'antd';

import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import SupportTable from '../../components/common/tables/SupportTable';
import style from './style.module.less';

/**
 * Represents support content component
 * @returns {JSX.Element}
 */

const SupportContent = () => {
  const {
    support: { handlePageChange, pagination },
  } = useRootModel();
  const onPageChange = (page, size) => {
    handlePageChange(page, size);
  };
  return (
    <div className={style.supportContentWrap}>
      <Row className={style.supportHeader} align='middle'>
        <Col span={6}>
          <p>Support</p>
        </Col>
        <Col span={18} className={style.options}>
          <Pagination
            onChange={onPageChange}
            current={pagination.number + 1}
            total={pagination.totalElements}
            showSizeChanger
            defaultPageSize={pagination.size}
          />
        </Col>
      </Row>
      <SupportTable />
    </div>
  );
};

export default observer(SupportContent);
