import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const TicketIconSvg = () => (
  <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.0068 6.86562L9.38525 7.48718L10.0093 8.11123L10.6309 7.48967L10.0068 6.86562Z'
      fill='#9A9AC0'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M13.1449 3.75642L12.8341 4.0672C12.5802 4.32111 12.2426 4.46095 11.8835 4.46095C11.5243 4.46095 11.1868 4.32111 10.9328 4.0672C10.6789 3.81328 10.5391 3.47566 10.5391 3.11657C10.5391 2.75748 10.6789 2.41986 10.9328 2.16592L11.2436 1.85514L9.38845 0L0 9.38845L1.85514 11.2436L2.16592 10.9328C2.41983 10.6789 2.75745 10.5391 3.11657 10.5391C3.47569 10.5391 3.81325 10.6789 4.0672 10.9328C4.32111 11.1867 4.46095 11.5243 4.46095 11.8834C4.46095 12.2425 4.32111 12.5801 4.0672 12.8341L3.75642 13.1449L5.61155 15L15 5.61155L13.1449 3.75642ZM4.96904 13.1143C5.21036 12.7527 5.34 12.328 5.34 11.8834C5.34 11.2895 5.10873 10.7312 4.68876 10.3112C4.26882 9.89127 3.71045 9.66 3.11657 9.66C2.67196 9.66 2.24728 9.78964 1.88566 10.031L1.24312 9.38845L9.38845 1.24312L10.031 1.88566C9.78964 2.24728 9.66 2.67196 9.66 3.11657C9.66 3.71048 9.89127 4.26882 10.3112 4.68876C10.7312 5.10873 11.2896 5.34 11.8834 5.34C12.328 5.34 12.7527 5.21036 13.1143 4.96904L13.7569 5.61155L5.61155 13.7569L4.96904 13.1143Z'
      fill='#9A9AC0'
    />
    <path
      d='M8.75876 5.61758L8.13721 6.23914L8.76125 6.86318L9.3828 6.24162L8.75876 5.61758Z'
      fill='#9A9AC0'
    />
    <path
      d='M7.51071 4.36905L6.88916 4.9906L7.5132 5.61464L8.13475 4.99309L7.51071 4.36905Z'
      fill='#9A9AC0'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const TicketIcon = (props) => <Icon component={TicketIconSvg} {...props} />;

export default TicketIcon;
