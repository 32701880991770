import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const CloseIconSvg = () => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='12.5' cy='12.5' r='12.5' fill='#E64545' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      /* eslint-disable-next-line max-len */
      d='M8.00761 18.0966C7.69522 18.1157 7.40323 18.01 7.19586 17.8026C6.9885 17.5953 6.88275 17.3033 6.90188 16.9909C6.92101 16.6785 7.06346 16.3713 7.29788 16.1369L10.9359 12.4989L7.29898 8.86203C7.06456 8.62761 6.92212 8.32041 6.90299 8.00802C6.88386 7.69563 6.98961 7.40364 7.19697 7.19627C7.40434 6.98891 7.69633 6.88316 8.00872 6.90229C8.32111 6.92142 8.6283 7.06387 8.86272 7.29829L12.4996 10.9352L16.1367 7.29803C16.3711 7.06361 16.6783 6.92117 16.9907 6.90204C17.3031 6.88291 17.5951 6.98865 17.8025 7.19602C18.0098 7.40338 18.1156 7.69538 18.0964 8.00777C18.0773 8.32016 17.9349 8.62735 17.7005 8.86177L14.0633 12.4989L17.7016 16.1371C17.936 16.3715 18.0784 16.6787 18.0976 16.9911C18.1167 17.3035 18.0109 17.5955 17.8036 17.8029C17.5962 18.0102 17.3042 18.116 16.9918 18.0969C16.6794 18.0777 16.3722 17.9353 16.1378 17.7009L12.4996 14.0626L8.86162 17.7006C8.6272 17.935 8.32 18.0775 8.00761 18.0966Z'
      fill='white'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const CloseIcon = (props) => <Icon component={CloseIconSvg} {...props} />;

export default CloseIcon;
