import { types } from 'mobx-state-tree';

const ModalWindowModel = types
  .model('ModalWindowModel', {
    name: types.maybeNull(types.string),
    isOpen: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setModal(name) {
      self.name = name;
      self.isOpen = !self.isOpen;
    },
  }));

export default ModalWindowModel;
