import React from 'react';
import { Spin } from 'antd';
import { download } from '../../utils/downloadFile';
import './FileMessage.css';
import { FileFilled, LoadingOutlined, CloudDownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

export class FileMessage extends React.PureComponent {
  getFileName() {
    let original = this.props?.data?.originalName;
    if (original) return original;
    let uri = this.props?.data?.uri;
    if (!uri) return '';
    const parts = uri.split('/');
    return parts[parts.length - 1];
  };

  onClick() {
    download(this.props?.data?.uri, this.getFileName(), this.props.data.setIsFileLoading);
  }

  render() {
    const error = this.props.data.status && this.props.data.status.error === true;

    return (
      <div className='rce-mbox-file'>
        <button onClick={this.onClick.bind(this)}>
          <div className='rce-mbox-file--icon'>
            <FileFilled className='file-image'/>
          </div>
          <div className='rce-mbox-file--text'>{this.props?.data?.originalName}</div>
          <div className='rce-mbox-file--buttons'>
            {error && (
              <span className='rce-error-button'>
                <ExclamationCircleOutlined style={{ color: '#ff3d3d' }} />
              </span>
            )}
            {!error &&
            this.props.position === 'left' &&
            <div className='preloader-wrapper'>
              {this.props.isFileLoading
                ?
                <Spin
                  size={24}
                  indicator={<LoadingOutlined style={{ fontSize: 24, color: '#aaa' }} spin />} />
                : <CloudDownloadOutlined style={{ color: '#aaa' }} />
              }
            </div>
            }
          </div>
        </button>
        {this.props.text && <div className='rce-mbox-file--desc'><p dangerouslySetInnerHTML={{ __html: this.props.text }} /></div>}
      </div>
    );
  }
}

FileMessage.defaultProps = {
  text: '',
  position: '',
  data: {},
  onClick: null,
  onDownload: null,
  onOpen: null,
};

export default FileMessage;
