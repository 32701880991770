const positionsDefaultFilters = {
  id: { field: '', value: '' },
  amount: { field: '', value: '' },
  trader: { field: '', value: '' },
  status: { field: '', value: '' },
  type: { field: '', value: '' },
  method: { field: '', value: '' },
  assignedTo: { field: '', value: '' },
  openedBy: { field: '', value: '' },
  openedAt: { field: '', value: '' },
  closedAt: { field: '', fromValue: '', toValue: '' },
};

export default positionsDefaultFilters;
