import React from 'react';
import { Col, Row, Pagination } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import WorkerTable from '../../components/common/tables/WorkerTable';
import AppliedFilters from '../../components/AppliedFilters/AppliedFilters';

import style from './WorkersContent.module.less';

/**
 * Represents workers content
 * @returns {JSX.Element}
 */

const WorkersContent = () => {
  const {
    workers: { filters, fetchWorkers, clearFilters, pagination, handlePageChange },
  } = useRootModel();
  const onPageChange = (page, size) => {
    handlePageChange(page, size);
  };
  return (
    <div className={style.workersContentWrap}>
      <Row className={style.workersHeader} align='middle'>
        <Col span={6}>
          <p>Workers</p>
        </Col>
        <Col span={18} className={style.options}>
          <Pagination
            onChange={onPageChange}
            current={pagination.number + 1}
            total={pagination.totalElements}
            showSizeChanger
            defaultPageSize={pagination.size}
          />
        </Col>
      </Row>
      <AppliedFilters filters={filters} fetch={fetchWorkers} clearFilters={clearFilters} />
      <WorkerTable />
    </div>
  );
};

export default observer(WorkersContent);
