import { types } from 'mobx-state-tree';
import WorkersListModel from '../../commonModels/WorkersListModel';

const PositionsModel = types.model('PositionsModel', {
  category: types.maybeNull(types.string),
  closedAt: types.maybeNull(types.string),
  instrument: types.maybeNull(types.string),
  openedAt: types.maybeNull(types.string),
  openedById: types.maybeNull(types.number),
  openedByUserVM: types.maybeNull(WorkersListModel),
  openingPrice: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  traderId: types.maybeNull(types.number),
});

export default PositionsModel;
