import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const UserIconSvg = () => (
  <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M4.08317 3.58329C4.08317 2.24798 5.16452 1.16663 6.49984 1.16663C7.83515 1.16663 8.9165 2.24798 8.9165 3.58329C8.9165 4.91861 7.83515 5.99996 6.49984 5.99996C5.16452 5.99996 4.08317 4.91861 4.08317 3.58329ZM1.1665 10.1458C1.1665 9.84454 1.31344 9.54366 1.6433 9.23923C1.97655 8.93166 2.46206 8.65539 3.03903 8.42426C4.19378 7.96167 5.5889 7.72913 6.49984 7.72913C7.41077 7.72913 8.8059 7.96167 9.96065 8.42426C10.5376 8.65539 11.0231 8.93166 11.3564 9.23923C11.6862 9.54366 11.8332 9.84454 11.8332 10.1458V11.8333H1.1665V10.1458Z'
      stroke='#9A9AC0'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const UserIcon = (props) => <Icon component={UserIconSvg} {...props} />;

export default UserIcon;
