import React from 'react';
import TraderPositionsTable from '../../../components/common/tables/TraderPositionsTable';

const TraderPositions = () => {
  return (
    <div>
      <TraderPositionsTable />
    </div>
  );
};

export default TraderPositions;
