import React from 'react';
import cn from 'classnames';
import { Typography } from 'antd';

// eslint-disable-next-line max-len
import { ReactComponent as CalendarIcon } from '../../../../assets/image/reminderInfo-calendar.svg';
// eslint-disable-next-line max-len
import { ReactComponent as PhoneIcon } from '../../../../assets/image/reminderInfo-phone.svg';
// eslint-disable-next-line max-len
import { ReactComponent as WorkerIcon } from '../../../../assets/image/reminderInfo-worker.svg';
// eslint-disable-next-line max-len
import { ReactComponent as TraderIcon } from '../../../../assets/image/reminderInfo-trader.svg';
// eslint-disable-next-line max-len
import { ReactComponent as DescIcon } from '../../../../assets/image/reminderInfo-description.svg';

import dateFormatter from '../../../../util/dateFormatter';
import style from '../forms.module.less';

/**
 * Represents reminder popover body
 * @param {Object} event - event object
 * @returns {JSX.Element}
 */
const ReminderInfoBody = ({ event }) => {
  return (
    <div className={style.reminderInfoForm}>
      <div className={cn(style.dialWrapper, style.container)}>
        <Typography.Text className={style.dialLabel}>Date & time</Typography.Text>
        <div className={style.dateDial}>
          <CalendarIcon />
          <Typography.Text className={style.dialText}>
            {dateFormatter(event?.startDate)}
          </Typography.Text>
        </div>
      </div>
      <div className={cn(style.container, style.containerFlex)}>
        <PhoneIcon className={style.reminderInfoIcon} />
        <div>
          <span>Type of communication: </span>
          <span className={style.link}>{event?.type}</span>
          <p className={style.phoneNumber}>(GB) +44 20 3957 1577:864 964 786#</p>
        </div>
      </div>
      <div className={cn(style.container)}>
        <WorkerIcon className={style.reminderInfoIcon} />
        <span>Worker: </span>
        <span className={style.link}>{event?.worker.name}</span>
      </div>
      <div className={cn(style.container)}>
        <TraderIcon className={style.reminderInfoIcon} />
        <span>Trader: </span>
        <span className={style.link}>{event?.trader.name}</span>
      </div>
      <div className={style.containerFlex}>
        <DescIcon className={style.reminderInfoIcon} />
        <div>
          <p>Description:</p>
          <p>{event?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ReminderInfoBody;
