/**
 * Represents function for date formatting
 * @param {string} date - ISO format date
 * @returns {function}
 */
const dateFormatter = (date) => {
  const options1 = {
    hour12: false,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const options2 = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  return `${new Date(date).toLocaleString('en-GB', options1)} ${new Date(date).toLocaleString(
    'en-GB',
    options2,
  )}`;
};
export default dateFormatter;
