import { toJS } from 'mobx';

/**
 * Prettify console.log
 * @param {string} name - the name of object
 * @param {object|array|string|number} object - value to console.log
 */

const prettyLog = (name, object) => {
  // eslint-disable-next-line no-console
  return console.log(name, toJS(object));
};

export default prettyLog;
