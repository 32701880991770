import React, { useEffect } from 'react';
import { Table, Typography } from 'antd';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useRootModel } from '../../../models/RootStore';

import dateFormatter from '../../../util/dateFormatter';
import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';
import displayOptionsFilter from '../../../util/displayOptionsFilter';

import style from './tables.module.less';

/**
 * Represents logs table
 * @returns {JSX.Element}
 */

const LogsTable = () => {
  const {
    logs: {
      logs,
      isLoading,
      pagination,
      handleSorterChange,
      displayOptions,
      // fetchLogs,
    },
  } = useRootModel();
  useEffect(() => {
    // fetchLogs();
  }, []);
  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handleSorterChange({ sortColumn: column, sortOrder });
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: true,
      sortOrder: pagination.sortColumn === 'id' && sortOrderToString(pagination.sortOrder),
      render: (id) => (
        <div className={style.linkCell}>
          <Typography.Text className={style.link}>#{id}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Worker',
      dataIndex: 'worker',
      key: 'worker',
      width: 210,
      sorter: true,
      sortOrder: pagination.sortColumn === 'worker' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Namespace',
      dataIndex: 'namespace',
      key: 'namespace',
      width: 100,
      sorter: true,
      sortOrder: pagination.sortColumn === 'namespace' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'action' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Pretty Object View',
      dataIndex: 'prettyObjectView',
      key: 'prettyObjectView',
      className: `${style.column}`,
      width: 143,
      sorter: true,
      sortOrder:
        pagination.sortColumn === 'prettyObjectView' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Before',
      dataIndex: 'before',
      key: 'before',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'before' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'After',
      dataIndex: 'after',
      key: 'after',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'after' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Executed at',
      dataIndex: 'executedAt',
      key: 'executedAt',
      className: `${style.column}`,
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'executedAt' && sortOrderToString(pagination.sortOrder),
      render: (executedAt) => (
        <>
          {executedAt === null ? (
            <Typography.Text type='danger'>Not closed yet</Typography.Text>
          ) : (
            dateFormatter(executedAt)
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        className={style.table}
        onChange={onPageChange}
        columns={displayOptionsFilter(displayOptions, columns)}
        loading={isLoading}
        dataSource={toJS(logs)}
        scroll={{ x: 'max-content', y: '620px' }}
        rowKey='id'
        pagination={false}
      />
    </>
  );
};

export default observer(LogsTable);
