import React, { Component } from 'react';

import cn from 'classnames';

import { FileImageTwoTone } from '@ant-design/icons';

import './ChatItem.css';
import { getLastTextTime } from "../../utils/getLastTextTime";

export class ChatItem extends Component {
  render() {
    return (
      <div
        className={cn('rce-container-citem', this.props.className)}
        onClick={this.props.onClick}
        onContextMenu={this.props.onContextMenu}
      >
        <div className='rce-citem'>
          <div className='rce-citem-body'>
            <div className='rce-citem-body--top'>
              <div className='rce-citem-body--top-title'>
                <div className={cn('client-status', { ['online']: this.props.online })} />
                <div className='title-container'>
                  <span>{this.props.firstName} {this.props.lastName}</span>
                  <span className='title-id'>#{this.props.clientId}</span>
                </div>
              </div>
              <div className='rce-citem-body--top-time'>
                {this.props.date &&
                  !isNaN(this.props.date) &&
                  (<div className='timeago-container'>
                    <span>{getLastTextTime(this.props.lastTextTime)}</span>
                  </div>)
                }
              </div>
            </div>

            <div className='rce-citem-body--bottom'>
              <div className='rce-citem-body--bottom-title'>
                {this.props.lastText ?
                  this.props.lastText :
                  <div><FileImageTwoTone twoToneColor='#9A9AC0' /> Attachment...</div>
                }
              </div>
              <div className='rce-citem-body--bottom-status'>
                {this.props.unreadCount > 0 && <span>{this.props.unreadCount}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChatItem.defaultProps = {
  id: '',
  onClick: null,
  avatarFlexible: false,
  title: '',
  subtitle: '',
  date: new Date(),
  unread: 0,
  statusColor: null,
  statusColorType: 'badge',
  statusText: null,
  dateString: null,
  lazyLoadingImage: undefined,
};

export default ChatItem;
