import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';
import { observer } from 'mobx-react';
import style from '../../style.module.less';
import useDebounce from '../../../../../hooks/useDebounce';

const ExecutionPlan = ({
  initialExecutionPlan,
  setExecutionPlan,
  user,
  isExecutionPlan,
  worker,
}) => {
  const [values, setValue] = useState({
    plan: null,
  });

  const debouncedValue = useDebounce(values.plan, 300);

  const onChange = (sum) => {
    setValue({ plan: sum });
  };

  useEffect(() => {
    if (debouncedValue >= 0 && debouncedValue !== null && isExecutionPlan)
      setExecutionPlan(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    setValue({ plan: initialExecutionPlan });
  }, [initialExecutionPlan]);

  return (
    <div className={style.personalInfo_executionPlan_content}>
      <div className={style.personalInfo_executionPlan_content_inputWrapper}>
        <span className={style.personalInfo_executionPlan_content_inputWrapper_headerText}>
          Execution plan
        </span>
        <InputNumber
          disabled={!isExecutionPlan || user.id === worker.id}
          style={{ width: '271px' }}
          value={values?.plan}
          min={0}
          size='large'
          onChange={onChange}
          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        />
      </div>
      <div>
        <span className={style.personalInfo_executionPlan_content_inputWrapper_bottomText}>
          For month
        </span>
      </div>
    </div>
  );
};

export default observer(ExecutionPlan);
