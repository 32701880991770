import { types } from 'mobx-state-tree';

const ChatListItemModel = types.model('ChatListItemModel', {
  clientId: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  id: types.number,
  imageUrl: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  lastText: types.maybeNull(types.string),
  lastTextTime: types.maybeNull(types.string),
  online: types.optional(types.boolean, false),
  operatorId: types.number,
  status: types.maybeNull(types.string),
  unreadCount: types.maybeNull(types.number),
});

export default ChatListItemModel;
