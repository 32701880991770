import { types } from 'mobx-state-tree';

const DisplayOptions = types
  .model('DisplayOptionsModel', {
    title: types.maybeNull(types.string),
    checked: types.boolean,
  })
  .actions((option) => ({
    setDisplayOptions() {
      option.checked = !option.checked;
    },
  }));

export default DisplayOptions;
