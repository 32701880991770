import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from 'antd';
import AppDropdown from '../../ui/AppDropdown/AppDropdown';
import { ReactComponent as OptionsIcon } from '../../../assets/image/display-options.svg';

import style from './DisplayOptions.module.less';

/**
 * Represents DisplayOptionsModel component
 * @param {Array} options - array of display option's objects
 * @returns {JSX.Element}
 */

const DisplayOptions = ({ options }) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <AppDropdown
      buttonTitle='Display Options'
      ButtonIcon={OptionsIcon}
      showMenu={showMenu}
      setShowMenu={setShowMenu}
    >
      <div className={style.displayOptions}>
        {options.map((el) => (
          <div key={el.title}>
            <Checkbox checked={el.checked} onChange={el.setDisplayOptions}>
              <span className={style.title}>{el.title}</span>
            </Checkbox>
          </div>
        ))}
      </div>
    </AppDropdown>
  );
};

export default observer(DisplayOptions);
