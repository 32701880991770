const download = (uri, setLoadPending, fileName) => {
  const extractName = (url) => {
    const splitUrl = url.split('?');
    const name = splitUrl[0].split('/');
    const extractImage = name[name.length - 1].split('-');
    return extractImage.pop();
  };
  if (typeof setLoadPending === 'function') setLoadPending(true);
  fetch(uri, {
    method: 'GET',
  })
    .then((response) => {
      // eslint-disable-next-line func-names
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName || extractName(response.url));
        document.body.appendChild(link);
        if (typeof setLoadPending === 'function') setLoadPending(false);
        link.click();
      });
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      if (typeof setLoadPending === 'function') setLoadPending(false);
    });
};

export default download;
