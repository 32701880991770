import React from 'react';
import { Button } from 'antd';
import AddIcon from '../../common/icons/AddIcon';

import style from '../HeaderOptions.module.less';

/**
 * Represents create new template button
 * @returns {JSX.Element}
 */
const AddNewRole = ({ setIsModalOpen }) => {
  const handleClick = () => {
    setIsModalOpen(true);
  };
  return (
    <div>
      <Button
        onClick={handleClick}
        className={style.addWorkerBtn}
        type='primary'
        icon={<AddIcon />}
      >
        Add New Template
      </Button>
    </div>
  );
};

export default AddNewRole;
