import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import AppRangeInput from '../../../ui/AppRangeInput';

/**
 * Represents ftd data filter component
 * @param {Object} filter - filter object
 * @param {String} title - filter title
 * @param {String} param - filter parameter
 * @returns {JSX.Element}
 */

const FilterAmountItem = ({ filter, title, param }) => {
  const [amount, setAmount] = useState({
    fromValue: null,
    toValue: null,
  });
  useEffect(() => {
    if (amount.fromValue !== null || amount.toValue !== null) {
      filter.setValue(param, amount.fromValue, amount.toValue);
    }
  }, [amount]);
  return (
    <AppRangeInput filter={toJS(filter)} title={title} amount={amount} setAmount={setAmount} />
  );
};

export default observer(FilterAmountItem);
