import React from 'react';
import { observer } from 'mobx-react';
import { Typography } from 'antd';
import ApprovedDocs from './ApprovedDocs';
import PendingDocs from './PendingDocs';
import { useRootModel } from '../../../models/RootStore';
import prettyLog from '../../../util/prettyLog';

import style from './style.module.less';

/**
 * Represents trader's documents component
 * @returns {JSX.Element}
 */

const TraderDocuments = () => {
  const {
    traders: { selectedTrader, setDocumentStatus },
  } = useRootModel();
  const { first } = selectedTrader.passportData;
  const { registration } = selectedTrader.passportData;
  prettyLog('Here', selectedTrader?.passportData);
  return (
    <>
      {first && registration ? (
        <div className={style.traderDocsWrapper}>
          <PendingDocs
            firstPage={first}
            secondPage={registration}
            setDocumentStatus={setDocumentStatus}
            selectedTrader={selectedTrader.id}
          />
          <ApprovedDocs firstPage={first} secondPage={registration} />
        </div>
      ) : (
        <div className={style.traderDocsWrapper__noData}>
          <Typography.Text>No data</Typography.Text>
        </div>
      )}
    </>
  );
};

export default observer(TraderDocuments);
