import { flow, getRoot, types } from 'mobx-state-tree';
import axios from 'axios';
import chatQuery from '../../constants/chatQuery';
import errorHandler from '../../util/errorHandler';
import ChatListItemModel from './models/ChatListItemModel';
import MessageItemModel from './models/MessageItemModel';
import ChatPaginationModel from './models/ChatPaginationModel';
import resizeFile from '../../components/Chat/utils/resizeFile';
import PermissionsModel from '../commonModels/PermissionsModel';
import handleReadPermission from '../../util/handleReadPermission';
import queryString from '../../util/queryString';

const { REACT_APP_API } = process.env;

const ChatModel = types
  .model('ChatModel', {
    chatList: types.array(ChatListItemModel),
    currentChat: types.maybeNull(types.number),
    currentChatMessages: types.array(MessageItemModel),
    unreadMessages: types.maybeNull(types.number),
    isChatListLoading: types.optional(types.boolean, false),
    isMessageListLoading: types.optional(types.boolean, false),
    previewVisible: types.optional(types.boolean, false),
    messageListPagination: types.optional(ChatPaginationModel, {}),
    chatListPagination: types.optional(ChatPaginationModel, {}),
    permissions: types.array(PermissionsModel),
  })
  .actions((self) => ({
    addItems(items) {
      items.forEach((msg, index) => {
        if (self.currentChatMessages.findIndex((item) => item.id === msg.id) < 0) {
          self.currentChatMessages.unshift(msg);
          if (index === items.length - 1) {
            self.currentChatMessages = [...self.currentChatMessages];
          }
        }
      });
    },
    addChatList(items) {
      const newItems = items.filter((item) => !self.chatList.find((m) => m.id === item.id));
      self.chatList = [...self.chatList, ...newItems];
    },
    addCurrentChatMessage(message) {
      message.operatorId = Number(message.operatorId);
      self.currentChatMessages = [...self.currentChatMessages, message];
    },
    addChatItemMessage(message) {
      const index = self.chatList.findIndex((chat) => chat.id === message.chatId);
      if (message.sender !== message.operatorId) self.chatList[index].unreadCount += 1;
      self.chatList[index].lastText = message.type === 'text' ? message.text : null;
      self.chatList[index].lastTextTime = message.createdAt;
    },
    handleMessage(message) {
      const { currentChat, addChatItemMessage, addCurrentChatMessage } = self;

      if (message.chatId === currentChat) {
        addCurrentChatMessage(message);
        addChatItemMessage(message);
      } else {
        addChatItemMessage(message);
      }
    },
    setChatStatus(message) {
      const { networkStatus, clientId } = message;
      const index = self.chatList.findIndex((chat) => chat.clientId === clientId);
      if (index >= 0) {
        self.chatList[index].online = networkStatus;
      }
    },
    setCurrentChat(chat) {
      self.currentChat = chat;
    },
    setCurrentChatMessages(messages) {
      self.currentChatMessages = messages;
    },
    setPreview(value) {
      self.previewVisible = value;
    },
    setUnreadCount() {
      self.unreadMessages += 1;
    },
    reduceUnreadCount() {
      self.unreadMessages -= 1;
    },
    setPagination() {
      const { messageListPagination } = self;
      messageListPagination.page = 1;
      messageListPagination.pagesCount = 1;
    },
    setPagesCount(pagination, count) {
      pagination.pagesCount = count;
    },
    getMessagesNextPage() {
      const { messageListPagination } = self;
      if (messageListPagination.page < messageListPagination.pagesCount) {
        messageListPagination.page += 1;
      }
    },
    getChatsNextPage() {
      const { chatListPagination } = self;
      if (chatListPagination.page < chatListPagination.pagesCount) {
        chatListPagination.page += 1;
      }
    },
    getPermission: flow(function* getPermission(permission) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const {
        user: { userData },
      } = getRoot(self);
      const params = {
        category: permission,
        role: userData?.authorities,
      };
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/permissions'),
          { params },
        );
        self.permissions = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    getMyChats: flow(function* getMyChats() {
      const { addChatList, setPagesCount, chatListPagination } = self;
      self.isChatListLoading = true;
      try {
        const { data } = yield chatQuery.post(`/chat/operatorChats`, {
          page: chatListPagination.page,
          size: 12,
        });
        setPagesCount(chatListPagination, data.pagesCount);
        const chatListData = data.chats.map((m) => {
          return {
            ...m,
            clientId: Number(m.clientId),
            operatorId: Number(m.operatorId),
            unreadCount: Number(m.unreadCount),
          };
        });
        addChatList(chatListData);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isChatListLoading = false;
      }
    }),
    getFilteredChats: flow(function* getFilteredChats(operatorId, name) {
      const { addChatList } = self;
      self.isChatListLoading = true;
      try {
        const { data } = yield chatQuery.post(`/chat/clientName`, {
          operatorId,
          name,
        });
        const chatListData = data.map((m) => {
          return {
            ...m,
            clientId: Number(m.clientId),
            operatorId: Number(m.operatorId),
            unreadCount: Number(m.unreadCount),
          };
        });
        self.chatList = [];
        addChatList(chatListData);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isChatListLoading = false;
      }
    }),
    getChatMessages: flow(function* getChatMessages() {
      const { currentChat, addItems, setPagesCount, messageListPagination } = self;
      self.isMessageListLoading = true;
      try {
        const { data } = yield chatQuery.get(
          `/message/${currentChat}/${messageListPagination.page}`,
        );
        setPagesCount(messageListPagination, data.pagesCount);
        const messagesData = data.messages.map((m) => {
          return {
            ...m,
            clientId: Number(m.clientId),
            operatorId: Number(m.operatorId),
            sender: Number(m.sender),
          };
        });
        addItems(messagesData);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isMessageListLoading = false;
      }
    }),
    uploadFileQuery: flow(function* uploadFileQuery(fd, message) {
      const { sendMessage } = self;
      try {
        const { data } = yield chatQuery.post(`/file-s3/upload`, fd);
        message.uri = `${process.env.REACT_APP_CHAT}/file-s3?fileS3Key=${data.fileS3Key}`;
        message.size = data.size;
        message.originalName = data.originalName;
        sendMessage(message);
      } catch (e) {
        errorHandler(self, e);
      }
    }),
    async uploadFile(file, isPhoto = false, message) {
      const { uploadFileQuery } = self;
      const { name } = file;
      const fd = new FormData();
      fd.append('type', file.name);
      if (isPhoto) {
        try {
          const image = await resizeFile(file);
          fd.append('file', image, name);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error resize image', err);
          fd.append('file', file);
        }
      } else {
        fd.append('file', file, name);
      }
      return uploadFileQuery(fd, message);
    },
    sendMessage: flow(function* sendMessage(message, userId) {
      const { chatList, currentChat } = self;
      const index = chatList.findIndex((item) => item.id === currentChat);
      const newMessage = {
        sender: userId,
        clientId: chatList[index].clientId,
        operatorId: chatList[index].operatorId,
        chatId: chatList[index].id,
        type: message.type || 'text',
        text: message.text,
        status: 'sent',
        size: 0,
        viewedByOperator: false,
        focus: true,
      };
      if (message.type === 'file' || message.type === 'photo') {
        newMessage.uri = message.uri;
        newMessage.size = message.size;
        newMessage.originalName = message.originalName;
      }
      try {
        yield chatQuery.post(`/message`, newMessage);
      } catch (e) {
        errorHandler(self, e);
      }
    }),
    updateMessageStatus: flow(function* updateMessageStatus(message) {
      const { chatList, currentChatMessages, reduceUnreadCount } = self;
      const index = currentChatMessages.findIndex((item) => item.id === message.id);
      if (currentChatMessages[index].status === 'read') return;
      try {
        yield chatQuery.put(`/message/${message.id}`, {
          status: 'read',
        });
        const chatIndex = chatList.findIndex((item) => item.id === message.chatId);
        chatList[chatIndex].unreadCount -= 1;
        currentChatMessages[index].status = 'read';
        reduceUnreadCount();
      } catch (e) {
        errorHandler(self, e);
      }
    }),
    createNewChat: flow(function* createNewChat(clientId, operatorId) {
      const { chatList } = self;
      try {
        const { data } = yield chatQuery.post(`/chat`, {
          clientId,
          operatorId,
          status: 'new',
        });
        const index = chatList.findIndex((item) => item.id === data.id);
        if (index >= 0) {
          self.currentChat = data.id;
        } else {
          data.clientId = Number(data.clientId);
          data.operatorId = Number(data.operatorId);
          data.unreadCount = Number(data.unreadCount);
          self.chatList = [data, ...self.chatList];
        }
      } catch (e) {
        errorHandler(self, e);
      }
    }),
    getUnreadCount: flow(function* getUnreadCount() {
      try {
        const { data } = yield chatQuery.get(`/message/unreadByOperator/${11}`);
        self.unreadMessages = data.length;
      } catch (e) {
        errorHandler(self, e);
      }
    }),
  }))
  .views((self) => ({
    get sortedChatList() {
      const { chatList } = self;
      return chatList.slice().sort((a, b) => (b.lastTextTime > a.lastTextTime ? 1 : -1));
    },
    get isChat() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'CHAT');
    },
    get isCreateNewChat() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'CHAT_CREATE_NEW');
    },
  }));

export default ChatModel;
