import { types } from 'mobx-state-tree';
import FilterItemModel from '../../commonModels/FilterItemModel';
import FilterAmountItemModel from '../../commonModels/FilterAmountItemModel';
import FilterDateItemModel from '../../commonModels/FilterDateItemModel';
import FilterBoolItemModel from '../../commonModels/FilterBoolItemModel';

const DeskFiltersModel = types.model('DeskFiltersModel', {
  id: types.optional(FilterItemModel, {}),
  name: types.optional(FilterItemModel, {}),
  traderCount: types.optional(FilterAmountItemModel, {}),
  active: types.optional(FilterBoolItemModel, {}),
  createdAt: types.optional(FilterDateItemModel, {}),
  teamLead: types.optional(FilterItemModel, {}),
  affiliate: types.optional(FilterItemModel, {}),
  campaign: types.optional(FilterItemModel, {}),
});

export default DeskFiltersModel;
