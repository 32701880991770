import { types } from 'mobx-state-tree';

const ErrorModel = types
  .model('ErrorModel', {
    error: types.maybeNull(types.string),
    isModalOpen: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    setIsModalOpen(isOpen) {
      store.isModalOpen = isOpen;
    },
    setError(e) {
      store.error = e;
    },
  }));

export default ErrorModel;
