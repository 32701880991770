const DASHBOARD_SECTION = 'DASHBOARD_SECTION';
const TRADERS_SECTION = 'TRADERS_SECTION';
const TRADERS_PROFILE = 'TRADERS_PROFILE';
const DESKS_SECTION = 'DESKS_SECTION';
const TICKETS_SECTION = 'TICKETS_SECTION';
const WORKERS_SECTION = 'WORKERS_SECTION';
const WORKERS_PROFILE = 'WORKERS_PROFILE';
const ANALYTICS_SECTION = 'ANALYTICS_SECTION';
const MAIL_LAYOUTS_SECTION = 'MAIL_LAYOUTS_SECTION';
const CHAT_SECTION = 'CHAT_SECTION';
const MARKET_INFO_SECTION = 'MARKET_INFO_SECTION';
const SUPPORT_SECTION = 'SUPPORT_SECTION';
const CALENDAR_SECTION = 'CALENDAR_SECTION';

export default {
  DASHBOARD_SECTION,
  TRADERS_SECTION,
  TRADERS_PROFILE,
  DESKS_SECTION,
  TICKETS_SECTION,
  WORKERS_SECTION,
  WORKERS_PROFILE,
  ANALYTICS_SECTION,
  MAIL_LAYOUTS_SECTION,
  CHAT_SECTION,
  MARKET_INFO_SECTION,
  SUPPORT_SECTION,
  CALENDAR_SECTION,
};
