import React from 'react';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';
import CloseIcon from '../icons/CloseIcon';
import AddNewWorkerForm from '../forms/AddNewWorkerForm';

/**
 * Represents add new worker modal component
 * @returns {JSX.Element}
 */

const AddNewWorkerModal = () => {
  const {
    workers: { isModalOpen, setIsModalOpen },
  } = useRootModel();
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    isModalOpen && (
      <Modal
        style={{ top: 0, margin: '0 0 0 auto', right: 0 }}
        title='Add New Worker'
        onCancel={closeModal}
        visible={isModalOpen}
        bodyStyle={{ padding: 0 }}
        width={550}
        closeIcon={<CloseIcon />}
        footer={null}
      >
        <div>
          <AddNewWorkerForm />
        </div>
      </Modal>
    )
  );
};

export default observer(AddNewWorkerModal);
