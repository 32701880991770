/**
 * Represents list of languages
 * @returns {array}
 */

const languageList = [
  { key: 'en', name: 'English', id: 1 },
  { key: 'de', name: 'German', id: 2 },
  { key: 'it', name: 'Italian', id: 3 },
  { key: 'ru', name: 'Russian', id: 4 },
  { key: 'fr', name: 'French', id: 5 },
  { key: 'pt', name: 'Portuguese', id: 6 },
  { key: 'ja', name: 'Japanese', id: 7 },
  { key: 'pl', name: 'Polish', id: 8 },
  { key: 'es', name: 'Spanish', id: 9 },
  { key: 'nl', name: 'Dutch', id: 10 },
  { key: 'tr', name: 'Turkish', id: 11 },
  { key: 'zh', name: 'Chinese', id: 12 },
];

export default languageList;
