import React from 'react';
import { Tabs } from 'antd';

import UserIcon from '../../../components/common/icons/UserIcon';
import TicketIcon from '../../../components/common/icons/TicketIcon';
import TransactionIcon from '../../../components/common/icons/TransactionIcon';
import PositionsIcon from '../../../components/common/icons/PositionsIcon';
import DocumentsIcon from '../../../components/common/icons/DocumentsIcon';
import CalendarIcon from '../../../components/common/icons/CalendarIcon';

const { TabPane } = Tabs;

/**
 * Represents selected trader tabs
 * @returns {JSX.Element}
 */

const SingleTraderTabs = (props) => {
  const onChange = (tabValue) => {
    props.onTabChange(tabValue);
  };
  return (
    <Tabs defaultActiveKey='profile' onChange={onChange}>
      {props.isProfile && (
        <TabPane
          tab={
            <span>
              <UserIcon />
              Profile info
            </span>
          }
          key='profile'
        />
      )}
      {props.isTickets && (
        <TabPane
          tab={
            <span>
              <TicketIcon />
              Tickets
            </span>
          }
          key='tickets'
        />
      )}
      {props.isPositions && (
        <TabPane
          tab={
            <span>
              <PositionsIcon />
              Positions
            </span>
          }
          key='positions'
        />
      )}
      {props.isBalanceHistory && (
        <TabPane
          tab={
            <span>
              <TransactionIcon />
              Balance history
            </span>
          }
          key='balanceHistory'
        />
      )}
      {props.isDocuments && (
        <TabPane
          tab={
            <span>
              <DocumentsIcon />
              Documents
            </span>
          }
          key='documents'
        />
      )}
      {props.isCalendar && (
        <TabPane
          tab={
            <span>
              <CalendarIcon />
              Calendar
            </span>
          }
          key='calendar'
        />
      )}
    </Tabs>
  );
};

export default SingleTraderTabs;
