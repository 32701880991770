import React from 'react';
import { Button } from 'antd';

import style from './style.module.less';

const AppButton = ({ text, icon, type, onClick }) => {
  return (
    <div className={style.menuBtnWrapper}>
      <Button type={type} className={style.menuBtn} icon={icon} onClick={onClick}>
        {text}
      </Button>
    </div>
  );
};

export default AppButton;
