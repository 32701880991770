/**
 * Represents function for local sorting
 * @param {string, null} order - way of sorting
 * @returns {function}
 */
const sortOrderToString = (order) => {
  let orderToString;
  if (order === 'DESC') {
    orderToString = 'descend';
  }
  if (order === 'ASC') {
    orderToString = 'ascend';
  }
  if (order === null) {
    orderToString = undefined;
  }

  return orderToString;
};

export default sortOrderToString;
