import React from 'react';
import { observer } from 'mobx-react';
import AppSelect from '../../../ui/AppSelect/AppSelect';

/**
 * Represents type item filter component
 * @param {Object} filter - type filter
 * @param {Array} data - list of filter's data
 * @param {String} title - filter's title
 * @param {String} param - filter's parameter
 * @param {String} placeholder - filter's placeholder
 * @returns {JSX.Element}
 */
const FilterTypeItem = ({ filter, data, title, param, placeholder }) => {
  const onChange = (value) => {
    filter.setValue(value.toUpperCase(), param, value);
  };
  return (
    <AppSelect
      filter={filter}
      title={title}
      placeholder={placeholder}
      onChange={onChange}
      data={data}
    />
  );
};

export default observer(FilterTypeItem);
