import React, { useLayoutEffect, useRef, useState } from 'react';
import './MessageBox.scss';
import PhotoMessage from '../PhotoMessage/PhotoMessage';
import FileMessage from '../FileMessage/FileMessage';
import { ReactComponent as ChatReadIcon } from '../../../../assets/image/chatReadIcon.svg';
import { ReactComponent as ChatSentIcon } from '../../../../assets/image/chatSentIcon.svg';
import cn from "classnames";


const MessageBox = ({
  type = 'text',
  notch = true,
  text,
  position = 'left',
  createdAt,
  originalName,
  uri,
  onClick,
  onOpen,
  renderAddCmp,
  firstName,
  lastName,
  title = `${firstName} ${lastName}`,
  onDownload,
  status,
  message,
  onIncomingMessageHover,
}) => {
  const [isFileLoading, setIsFileLoading] = useState(false);
  const messageRef = useRef(null);
  const isNonConverted = (originalName) => {
    if (!originalName) return true;
    const parts = originalName.split('.');
    return parts[parts.length - 1] === 'mp4';
  };
  const urlifiedText = text
    .replace(
      // eslint-disable-next-line max-len
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
      `<a href="$1" target='_blank'>$1</a>`,
    )
    .replace(/([a-zA-Z0-9._-]{3,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,4})/, `<a href="mailto:$1">$1</a>`);

  const data = {
    width: 200,
    height: 200,
    originalName,
    uri:
      type === 'photo' || type === 'file'
        ? uri
        : null,
    status: {
      click: false,
      loading: 0,
      download: isNonConverted(originalName),
    },
  };

  const positionCls = `rce-mbox  ${position === 'right' ? 'rce-mbox-right' : ''}`;
  const thatAbsoluteTime =
    !/(text|video|file|meeting|audio)/g.test(type) && !(type === 'location' && urlifiedText);

  useLayoutEffect(() => {
    if (position === 'left' && message.status !== 'read') {
      messageRef.current.onmouseenter = () => {
        {
          onIncomingMessageHover(message);
        }
      };
    }
  }, []);

  return (
    <div
      ref={messageRef}
      className={cn('rce-container-mbox', 'msg-box')}
      onClick={onClick}
    >
      {renderAddCmp instanceof Function && renderAddCmp()}
      {type !== 'system' && (
        <div
          className={`${positionCls} 
                      ${thatAbsoluteTime ? 'rce-mbox--clear-padding' : ''}
                      ${notch ? '' : 'rce-mbox--clear-notch'}`}
        >
          <div className='rce-mbox-body'>
            {title && (
              <div
                className={`rce-mbox-title ${type === 'text' ? 'rce-mbox-title--clear' : ''}`}
              >
                {title && <span>{title}</span>}
              </div>
            )}

            {type === 'text' && <div className='rce-mbox-text'>
              <p dangerouslySetInnerHTML={{ __html: urlifiedText }} />
            </div>}

            {type === 'photo' && (
              <PhotoMessage
                onOpen={onOpen}
                onDownload={onDownload}
                data={data}
                text={urlifiedText}
              />
            )}

            {type === 'file' && (
              <FileMessage
                position={position}
                data={data}
                text={urlifiedText}
                isFileLoading={isFileLoading}
                setIsFileLoading={setIsFileLoading}
              />
            )}

            <div
              className={`rce-mbox-time ${thatAbsoluteTime ? 'rce-mbox-time-block' : ''} 'non-copiable'`}
            >
              {createdAt &&
                new Date(createdAt).toLocaleTimeString(navigator.language, {hour: '2-digit',
                  minute:'2-digit'})}

              {status && (
                  <span className='rce-mbox-status'>
                    {status === 'waiting' && <i className='pi pi-clock' />}
                    {status === 'sent' && <ChatSentIcon />}
                    {status === 'received' && <i className='pi pi-check-circle' />}
                    {status === 'read' && <ChatReadIcon />}
                  </span>
              )}
            </div>
          </div>

          {notch &&
            (position === 'right' ? (
              <svg
                className={'rce-mbox-right-notch'}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
              >
                <path d='M0 0v20L20 0' />
              </svg>
            ) : (
              <div>
                <svg
                  className={'rce-mbox-left-notch'}
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <defs>
                    <filter id='filter1' x='0' y='0'>
                      <feOffset result='offOut' in='SourceAlpha' dx='-2' dy='-5' />
                      <feGaussianBlur result='blurOut' in='offOut' stdDeviation='3' />
                      <feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
                    </filter>
                  </defs>
                  <path d='M20 0v20L0 0' filter='url(#filter1)' />
                </svg>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MessageBox;
