import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, Typography } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EyeInvisibleTwoTone, EyeOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';

import Preloader from '../Preloader';
import ErrorHandler from '../ErrorHandler';

import style from './forms.module.less';

/**
 * Represents login form
 * @returns {JSX.Element}
 */

const LoginForm = () => {
  const {
    user: { login, isAuth, isLoading, error },
  } = useRootModel();
  const history = useHistory();

  useEffect(() => {
    if (isAuth) {
      history.push('/');
    }
  }, [isAuth, history]);

  const [user, setValues] = useState({
    username: '',
    password: '',
  });

  const validateMessages = {
    required: 'This field is required',
  };

  const handleChange = (event, prop) => {
    setValues({ ...user, [prop]: event.target.value });
  };

  const onSubmitLogin = () => {
    login(user.username, user.password);
  };

  return (
    <section className={style.login_form}>
      <div>
        <Typography.Title level={2} className={style.title}>
          CRM for i88.io
        </Typography.Title>
        <p className={style.description}>
          <Typography.Text type='secondary'>Sign in to your account to continue</Typography.Text>
        </p>
        <div className={style.form_wrap}>
          <Form
            className={style.form}
            layout='vertical'
            name='Sign in form'
            onFinish={() => onSubmitLogin(user)}
            validateMessages={validateMessages}
          >
            <div>
              <Form.Item
                className={style.input_wrap}
                label='Login'
                name='username'
                value={user.username}
                onChange={(e) => handleChange(e, 'username')}
                rules={[{ required: true, message: 'Please input your login!' }]}
              >
                <Input
                  className={style.input}
                  size='large'
                  placeholder='Enter your login'
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                className={style.input_wrap}
                label='Password'
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}
                value={user.password}
                onChange={(e) => handleChange(e, 'password')}
              >
                <Input.Password
                  className={style.input}
                  type='password'
                  name='password'
                  placeholder='Enter your password'
                  size='large'
                  iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleTwoTone />)}
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item className={style.btn_wrap}>
                {isLoading ? (
                  <Preloader />
                ) : (
                  <Button type='primary' className={style.login_btn} htmlType='submit'>
                    Sign in
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
          {!!error && (
            <div className={style.error_wrapper}>
              <ErrorHandler error={error} className={style.error_handler_text} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default observer(LoginForm);
