import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LoadingOutlined } from '@ant-design/icons';
import CloseIcon from '../icons/CloseIcon';
import { download } from '../../Chat/utils/downloadFile';

/**
 * Represents preview image modal component
 * @param {boolean} previewVisible - value to show modal window
 * @param {function} closePreview - function to close modal window
 * @param {function} getFileName - function to display file name
 * @param {object} previewFile - preview message file
 * @returns {JSX.Element}
 */

const PreviewImageModal = ({ previewVisible, closePreview, getFileName, previewFile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isDownloadBtn = !!previewFile && previewFile?.sender === previewFile?.clientId;
  return (
    <Modal
      title={getFileName()}
      visible={previewVisible}
      onCancel={closePreview}
      closeIcon={<CloseIcon />}
      footer={
        isDownloadBtn && (
          <Button
            style={{ width: '70px' }}
            type='primary'
            onClick={() => {
              download(previewFile?.uri, getFileName(), setIsLoading);
            }}
          >
            {!isLoading ? (
              'Save'
            ) : (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />} />
            )}
          </Button>
        )
      }
      width={700}
    >
      {previewFile?.type === 'photo' && (
        <img style={{ width: '100%' }} src={previewFile.uri} alt='' />
      )}
    </Modal>
  );
};

export default PreviewImageModal;
