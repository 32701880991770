import React, { useState } from 'react';
import { Button } from 'antd';
import cn from 'classnames';
import MarketInfoContent from '../../containers/MarketInfoContent';

import style from './MarketInfo.module.less';

/**
 * Represents MarketInfo page
 * @returns {JSX.Element}
 */
const MarketInfo = () => {
  const types = ['Market info', 'Second', 'Third'];
  const [active, setActive] = useState(types[0]);
  return (
    <section>
      <div className={style.marketInfoHeader}>
        {types.map((type) => (
          <Button
            key={type}
            type={active === type ? 'primary' : 'text'}
            onClick={() => setActive(type)}
            className={cn({ [style.active]: active !== type })}
          >
            {type}
          </Button>
        ))}
      </div>
      <div className={style.marketInfo}>
        {active === types[0] && <MarketInfoContent />}
        {active === types[1] && 'Second'}
        {active === types[2] && 'Third'}
      </div>
    </section>
  );
};

export default MarketInfo;
