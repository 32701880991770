import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EyeInvisibleTwoTone, EyeOutlined } from '@ant-design/icons';
import InfoIcon from '../../../../components/common/icons/InfoIcon';

import dateFormatter from '../../../../util/dateFormatter';
import getItemByValue from '../../../../util/getItemByValue';
import languageList from '../../../../constants/languageList';
import timeZoneList from '../../../../constants/timeZoneList';

import style from '../style.module.less';

/**
 * Represents selected worker personal information
 * @param {object} worker - object with the selected worker
 * @returns {JSX.Element}
 */

const PersonalInfo = ({ worker }) => {
  const [open, setOpen] = useState({
    totalLeeds: false,
    fullDeposit: false,
  });
  const workerLang = getItemByValue(languageList, worker?.language);
  const workerTimeZone = getItemByValue(timeZoneList, worker?.timeZoneId);
  const handleOpen = (prop, value) => {
    setOpen({ ...open, [prop]: value });
  };

  return (
    <div className={style.personalInfo}>
      <div className={style.personalInfo_header}>
        <InfoIcon />
        <p className={style.personalInfo_header_text}>Personal information</p>
      </div>
      <div className={style.personalInfo_content_wrapper}>
        <div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Name</p>
            <div className={style.personalInfo_content_dial}>
              <p className={style.personalInfo_content_dial_text}>{worker.name}</p>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Login</p>
            <div className={style.personalInfo_content_dial}>
              <p className={style.personalInfo_content_dial_text}>{worker.username}</p>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Department</p>
            <div className={style.personalInfo_content_dial}>
              <p className={style.personalInfo_content_dial_text}>{worker?.department}</p>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Timezone</p>
            <div className={style.personalInfo_content_dial}>
              <p className={style.personalInfo_content_dial_text}>{workerTimeZone?.value}</p>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Email</p>
            <div className={style.personalInfo_content_dial}>
              <p className={style.personalInfo_content_dial_text}>{worker.email}</p>
            </div>
          </div>
          <div>
            <p className={style.personalInfo_content_label}>Language</p>
            <div className={style.personalInfo_content_dial}>
              <p className={style.personalInfo_content_dial_text}>{workerLang?.name}</p>
            </div>
          </div>
        </div>
        <div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Active</p>
            <div className={style.personalInfo_content_dial}>
              <p className={style.personalInfo_content_dial_text}>{worker.active ? 'Yes' : 'No'}</p>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Created</p>
            <div className={style.personalInfo_content_dial}>
              <p>{dateFormatter(worker.createdDate)}</p>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Logined at</p>
            <div className={style.personalInfo_content_dial}>
              <p>{dateFormatter(worker.lastLoginDate)}</p>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Desk</p>
            <div className={style.personalInfo_content_dial}>
              <p className={style.personalInfo_content_dial_text}>{worker.desk?.name}</p>
            </div>
          </div>
          <div className={style.personalInfo_content_dial_container}>
            <p className={style.personalInfo_content_label}>Total Leads</p>
            <div
              className={`
            ${style.personalInfo_content_dial} 
            ${style.personalInfo_content_dial__finance}`}
            >
              {open.totalLeeds ? <p>{worker.totalLeads}</p> : <p>***</p>}
              {open.totalLeeds ? (
                <EyeOutlined onClick={() => handleOpen('totalLeeds', false)} />
              ) : (
                <EyeInvisibleTwoTone onClick={() => handleOpen('totalLeeds', true)} />
              )}
            </div>
          </div>
          <div>
            <p className={style.personalInfo_content_label}>
              Leads by statuses count (Full Deposit)
            </p>
            <div
              className={`
            ${style.personalInfo_content_dial} 
            ${style.personalInfo_content_dial__finance}`}
            >
              {open.fullDeposit ? <p>{worker.leadsByStatusesCount}</p> : <p>***</p>}
              {open.fullDeposit ? (
                <EyeOutlined onClick={() => handleOpen('fullDeposit', false)} />
              ) : (
                <EyeInvisibleTwoTone onClick={() => handleOpen('fullDeposit', true)} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
