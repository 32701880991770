import React, { useState } from 'react';
import { Button } from 'antd';
import AppDropdown from '../../ui/AppDropdown/AppDropdown';
import { ReactComponent as FiltersIcon } from '../../../assets/image/filters.svg';

import style from '../MenuFilters/MenuFilters.module.less';

/**
 * Represents AppDropdown component
 * @param {JSX.Element | Array<JSX.Element>} children - filters components
 * @param {Function} apply - func for applying filters
 * @returns {JSX.Element}
 */

const FilterOptions = ({ children, apply }) => {
  const [showMenu, setShowMenu] = useState(false);
  const onClick = () => {
    apply();
    setShowMenu(false);
  };
  return (
    <AppDropdown
      buttonTitle='Filters'
      ButtonIcon={FiltersIcon}
      showMenu={showMenu}
      setShowMenu={setShowMenu}
    >
      <div className={style.menu}>{children}</div>
      <div className={style.buttonWrapper}>
        <Button className={style.btn} type='primary' onClick={onClick}>
          Apply
        </Button>
      </div>
    </AppDropdown>
  );
};

export default FilterOptions;
