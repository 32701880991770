import { types } from 'mobx-state-tree';
import TradersListModel from '../../commonModels/TradersListModel';

const TraderTicketModel = types.model('TraderTicketModel', {
  amount: types.maybeNull(types.number),
  assignee: types.maybeNull(types.string),
  closedAt: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  openedAt: types.maybeNull(types.string),
  openedBy: types.maybeNull(TradersListModel),
  status: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export default TraderTicketModel;
