import React from 'react';
import { Typography } from 'antd';
import numeral from 'numeral';
import style from '../../DashboardCard/style.module.less';
// eslint-disable-next-line max-len
import { ReactComponent as IndicatorUp } from '../../../../assets/image/arrow-indicator-up.svg';
// eslint-disable-next-line max-len
import { ReactComponent as IndicatorDown } from '../../../../assets/image/arrow-indicator-down.svg';

/**
 * Represents dashboard footer data item
 * @returns {JSX.Element}
 */

const DataItem = ({ icon, title, amount, indicator, indicatorAmount }) => {
  return (
    <div className={style.dataItemsWrapper}>
      <div className={style.iconWrapper}>
        <div className={style.iconEllipse}>{icon}</div>
      </div>
      <div className={style.textContainer}>
        <Typography.Text className={style.title}>{title}</Typography.Text>
        <Typography.Text className={style.amount}>{amount}</Typography.Text>
        <div>
          {indicator === 'up' ? <IndicatorUp /> : <IndicatorDown />}{' '}
          <Typography.Text type={indicator === 'up' ? 'success' : 'danger'}>
            {numeral(indicatorAmount).format('0.00')}% from last month
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default DataItem;
