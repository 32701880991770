import { types } from 'mobx-state-tree';

const FilterAmountItemModel = types
  .model('FilterAmountItemModel', {
    field: types.optional(types.string, ''),
    fromValue: types.maybeNull(types.string),
    toValue: types.maybeNull(types.string),
  })
  .actions((filter) => ({
    setValue(field, from, to) {
      filter.field = field;
      filter.fromValue = from;
      filter.toValue = to;
    },
    removeFilter() {
      filter.field = '';
      filter.fromValue = '';
      filter.toValue = '';
    },
  }));

export default FilterAmountItemModel;
