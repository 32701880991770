import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import DesksContent from '../../containers/DesksContent';
import FilterOptions from '../../components/HeaderOptions/FilterOptions';
import HeaderOptions from '../../components/HeaderOptions/HeaderOptions';
import DisplayOptions from '../../components/HeaderOptions/DisplayOptions/DisplayOptions';
import AddNewDeskModal from '../../components/common/modals/AddNewDeskModal';
// eslint-disable-next-line max-len
import RadioFilters from '../../components/HeaderOptions/FilterOptions/RadioFilters';
import AddDeskBtn from '../../components/HeaderOptions/AddDeskBtn';
import FilterItem from '../../components/HeaderOptions/FilterOptions/FilterItem';
import filtersListData from '../../constants/filtersListData';
import FilterBoolItem from '../../components/HeaderOptions/FilterOptions/FilterBoolItem';
import FilterAmountItem from '../../components/HeaderOptions/FilterOptions/FilterAmountItem';
import FilterDateItem from '../../components/HeaderOptions/FilterOptions/FilterDateItem';
import FilterByKeyItem from '../../components/HeaderOptions/FilterOptions/FilterByKeyItem';
import permission from '../../constants/permission';

/**
 * Represents Desks page
 * @returns {JSX.Element}
 */

const Desks = () => {
  const {
    desks: {
      fetchDesks,
      displayOptions,
      filters,
      getPermission,
      isAddDesk,
      isDeskFilters,
      isDeskDisplayOptions,
    },
  } = useRootModel();
  useEffect(() => {
    getPermission(permission.DESKS_SECTION);
  }, []);
  return (
    <section className='page'>
      <div className='page__header'>
        <HeaderOptions>
          {isDeskFilters && (
            <FilterOptions apply={fetchDesks}>
              <div>
                <RadioFilters />
                <div className='page__filters-wrapper'>
                  <FilterItem
                    filter={filters?.id}
                    title='Filter by ID'
                    placeholder='Enter here...'
                    param='ID'
                  />
                  <FilterItem
                    filter={filters?.name}
                    title='Search by desk name'
                    placeholder='Write here...'
                    param='NAME'
                  />
                  <FilterByKeyItem
                    filter={filters?.teamLead}
                    data={[]}
                    title='Team Lead'
                    param='TEAM_LEAD'
                    placeholder='Select team lead'
                  />
                  <FilterBoolItem
                    filter={filters?.active}
                    title='Status'
                    param='ACTIVE'
                    data={filtersListData.statusData}
                    compareString='Disabled'
                    placeholder='Select status'
                  />
                  <FilterAmountItem
                    filter={filters?.traderCount}
                    title='Filter by traders count'
                    param='TRADERS_COUNT'
                  />
                  <FilterByKeyItem
                    filter={filters?.affiliateCampaign}
                    data={[]}
                    title='Affiliate/Campaign'
                    param='affiliateCampaign'
                    placeholder='Select affiliate/campaign'
                  />
                  <FilterDateItem
                    filter={filters?.createdAt}
                    title='Created date'
                    param='CREATED_DATE'
                  />
                </div>
              </div>
            </FilterOptions>
          )}
          {isDeskDisplayOptions && <DisplayOptions options={displayOptions} />}
          {isAddDesk && <AddDeskBtn />}
        </HeaderOptions>
      </div>
      <section className='page__content'>
        <DesksContent />
      </section>
      <AddNewDeskModal />
    </section>
  );
};

export default observer(Desks);
