import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const AddIconSvg = () => (
  <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M15.1196 2.30093H6.11963C5.29238 2.30093 4.61963 2.97368 4.61963 3.80093V12.8009C4.61963 13.6282 5.29238 14.3009 6.11963 14.3009H15.1196C15.9469 14.3009 16.6196 13.6282 16.6196 12.8009V3.80093C16.6196 2.97368 15.9469 2.30093 15.1196 2.30093ZM6.11963 12.8009V3.80093H15.1196L15.1211 12.8009H6.11963Z'
      fill='white'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M3.11963 6.80093H1.61963V15.8009C1.61963 16.6282 2.29238 17.3009 3.11963 17.3009H12.1196V15.8009H3.11963V6.80093ZM11.3696 5.30093H9.86963V7.55093H7.61963V9.05093H9.86963V11.3009H11.3696V9.05093H13.6196V7.55093H11.3696V5.30093Z'
      fill='white'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const AddIcon = (props) => <Icon component={AddIconSvg} {...props} />;

export default AddIcon;
