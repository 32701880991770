import React from 'react';
import cn from 'classnames';
import { ReactComponent as Image } from '../../../assets/image/image-support.svg';
import { ReactComponent as File } from '../../../assets/image/file-support.svg';
import { ReactComponent as Preview } from '../../../assets/image/eye-support.svg';
import { ReactComponent as Download } from '../../../assets/image/download-support.svg';
import mockUp from '../../../assets/image/support-doc.png';
import SupportPreview from '../../../components/common/modals/SupportPreview';

import { imgMimeTypes } from '../../../constants/commonConstants';
import downloadFile from '../../../util/downloadFile';

import style from './style.module.less';

/**
 * Represents item for navigation menu
 * @param {string} url - file's url
 * @param {string} name - filename
 * @param {boolean} isModal - indicator to show modal
 * @param {function} setIsModal - function set a modal
 * @param {string} type - file's type
 * @returns {JSX.Element}
 */

const SupportMessageFile = ({ url, name, isModal, setIsModal, type }) => {
  const image = imgMimeTypes.includes(type);
  return (
    <div className={style.file}>
      <div className={style.hoveredFile}>
        <div className={style.fileNameContainer}>
          {image ? <Image /> : <File />}
          <span className={style.fileName}>{name || 'image'}</span>
        </div>
        <div className={style.actionsContainer}>
          <div className={style.actionsWrapper}>
            {image && <Preview className={style.eyeIcon} onClick={() => setIsModal()} />}
            <Download onClick={() => downloadFile(url, '', name)} />
          </div>
        </div>
      </div>
      <div>
        <img className={style.imageContent} src={image ? url : mockUp} alt='item-bg' />
      </div>
      <div className={style.fileDecoration}>
        <div className={style.decorationParent}>
          <div className={style.decorationChild} />
          <div className={cn(style.decorationChild, style.decorationChild__absolute)} />
        </div>
      </div>
      <SupportPreview
        url={url}
        isImage={image}
        fileName={name}
        previewVisible={isModal}
        closePreview={setIsModal}
        fileType={type}
      />
    </div>
  );
};

export default SupportMessageFile;
