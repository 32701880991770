import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const DeleteIconSvg = () => (
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M8.25302 0.500743C3.75399 0.439977 0.0810897 4.11289 0.141857 8.6119C0.201853 12.9267 3.71515 16.44 8.02995 16.5C12.5298 16.5615 16.2018 12.8886 16.1403 8.38961C16.0811 4.07404 12.5678 0.56074 8.25302 0.500743Z'
      fill='#E64545'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      /* eslint-disable-next-line max-len */
      d='M11.497 5.1438C11.1737 4.82064 10.6498 4.82064 10.3267 5.1438L8.14166 7.32882L5.95665 5.1438C5.63349 4.82064 5.10953 4.82064 4.78636 5.1438C4.4632 5.46697 4.4632 5.99093 4.78636 6.3141L6.97137 8.4991L4.78387 10.6866C4.46071 11.0097 4.46071 11.5337 4.78387 11.8569C5.10704 12.1801 5.631 12.1801 5.95416 11.8569L8.14166 9.66939L10.3291 11.8569C10.6523 12.1801 11.1762 12.1801 11.4995 11.8569C11.8226 11.5337 11.8226 11.0097 11.4995 10.6866L9.31195 8.4991L11.497 6.3141C11.8201 5.99093 11.8201 5.46697 11.497 5.1438Z'
      fill='white'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const DeleteIcon = (props) => <Icon component={DeleteIconSvg} {...props} />;

export default DeleteIcon;
