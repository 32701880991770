import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'react-chat-elements';
import { Button } from 'antd';

import 'react-chat-elements/dist/main.css';
import './Chat.scss';
import AttachButton from '../Components/AttachButton/AttachButton';
import SendButton from '../Components/SendButton/SendButton';
import ChatList from './ChatList';
import ChatMessages from './ChatMessages';
import PreviewImageModal from '../../common/modals/PreviewImageModal';
import CreateChatModal from "../../common/modals/CreateChatModal";
import { useRootModel } from "../../../models/RootStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import useDebounce from "../../../hooks/useDebounce";
import AppSearch from "../../ui/AppSearch";

const Chat = ({ isOperator }) => {
  const {
    chat: {
      getMyChats,
      isChatListLoading,
      isMessageListLoading,
      setCurrentChat,
      currentChat,
      getChatMessages,
      setCurrentChatMessages,
      currentChatMessages,
      previewVisible,
      setPreview,
      getChatsNextPage,
      chatListPagination,
      getMessagesNextPage,
      messageListPagination,
      setPagination,
      sendMessage,
      uploadFile,
      createNewChat,
      sortedChatList,
      getFilteredChats,
      updateMessageStatus,
      isCreateNewChat,
    },
    user: {
      userData,
    }
  } = useRootModel();
  const [messageType, setMessageType] = useState('text');
  const [search, setSearch] = useState('')
  const [previewFile, setPreviewFile] = useState(null);
  const [isCreateChat, setCreateChat] = useState(null);

  const fileRef = useRef(null);
  const mListRef = useRef(null);
  const chatListRef = useRef(null);
  const inputRef = useRef(null);
  const debouncedSearchTerm = useDebounce(search, 300);
  const handleSendMessage = () => {
    if (!inputRef.current?.input?.value?.trim?.() && !fileRef.current?.files?.length) return;
    const message = {
      text: inputRef.current?.input?.value || '',
      type: messageType,
    };
    if (fileRef.current?.files?.length) {
      uploadFile(fileRef.current.files[0], messageType === 'photo', message);
      clearFile();
    } else {
      sendMessage(message, userData.id);
    }
    clearFile();
    inputRef.current?.clear();
  };

  const clearFile = () => {
    fileRef.current.value = '';
    setMessageType('text');
  };

  const checkNextPage = (e) => {
    if (e?.target?.scrollTop === 0) {
      getMessagesNextPage();
    }
  };

  const checkChatsNextPage = (e) => {
    if (e === 0) {
      getChatsNextPage();
    }
  };

  const onMessageClick = (message) => {
    if (message.sender !== userData.id && message.status !== 'read') {
      message.status = 'read';
      updateMessageStatus(message);
    }
  };

  const onIncomingMessageHover = (message) => {
    if (message.sender !== userData.id && message.status !== 'read') {
      updateMessageStatus(message);
    }
  };
  const onMessageAttachmentOpen = (message) => {
    if (!message || message?.type === 'text') return;
    if (message?.type === 'photo') {
      setPreviewFile(message);
      setPreview(true);
    } else if (message?.type === 'file' && message?.uri) {
      window.open(message.uri);
    }
  };

  const closePreview = () => {
    setPreview(false);
    setPreviewFile(null);
  };

  const getFileName = () => {
    let original = previewFile?.originalName;
    if (original) return original;
    let uri = previewFile?.uri;
    if (!uri) return '';
    const parts = uri.split('/');
    return parts[parts.length - 1];
  };

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      getFilteredChats(userData.id, debouncedSearchTerm);
    } else {
      getMyChats();
    }
  }, [debouncedSearchTerm.length >= 3]);

  useEffect(() => {
    if (currentChat) {
      setPagination();
      setCurrentChatMessages([]);
      getChatMessages();
    }
  }, [currentChat]);

  useEffect(() => {
    if (chatListPagination.page !== 1) {
      getMyChats();
    }
  }, [chatListPagination.page]);

  useEffect(() => {
    if (currentChat && messageListPagination.page !== 1) {
      getChatMessages();
    }
  }, [messageListPagination.page]);
  return (
    <>
      <div className='chat-header'>
        <div className='options-container'>
          <div className='chat-search'>
            <AppSearch
              search={search}
              setSearch={setSearch}
              searchWidth={400}
              placeholder='Search for trader (min 3 symbols)'
            />
          </div>
          {isCreateNewChat && <Button onClick={() => setCreateChat(true)} type='primary'>Create new chat</Button>}
        </div>
      </div>
    <div style={{ display: 'flex' }}>
      <ChatList
        chatList={toJS(sortedChatList)}
        dataSource={toJS(sortedChatList)}
        activeChatId={currentChat}
        onClick={(item) => setCurrentChat(item.id)}
        onScroll={checkChatsNextPage}
        chatListRef={chatListRef}
        isChatsLoading={isChatListLoading}
      />
      <div style={{ width: '65%', padding: '12px', position: 'relative' }}>
      <ChatMessages
        currentChat={currentChat}
        mlistRef={mListRef}
        downButton={true}
        toBottomHeight={100}
        dataSource={toJS(currentChatMessages)}
        userId={userData?.id}
        isMessageListLoading={isMessageListLoading}
        onScroll={checkNextPage}
        onOpen={onMessageAttachmentOpen}
        onClick={onMessageClick}
        onIncomingMessageHover={onIncomingMessageHover}
      />
        <div
          tabIndex='0'
          className='input-container'
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              if (e.getModifierState('Alt')) {
                console.log('ALT+Enter pressed!');
                inputRef.current.input.value = inputRef.current.input.value + '\n';
              } else {
                handleSendMessage();
              }
            }
          }}
        >
          {currentChat &&
            <Input
              className='message-input'
              ref={inputRef}
              inputRef={inputRef.current}
              placeholder='Write message here...'
              multiline={true}
              disabled={true}
              rightButtons={<SendButton onSend={handleSendMessage} />}
              leftButtons={
                isOperator ? (
                  <AttachButton
                    setType={setMessageType}
                    type={messageType}
                    fileRef={fileRef}
                    clearFile={clearFile}
                  />
                ) : null
              }
            />
          }
        </div>
      </div>
      <PreviewImageModal
        getFileName={getFileName}
        previewVisible={previewVisible}
        closePreview={closePreview}
        previewFile={previewFile}
      />
      <CreateChatModal
        setCreateChat={setCreateChat}
        isCreateChat={isCreateChat}
        createNewChat={createNewChat}
      />
    </div>
    </>
  );
};

export default observer(Chat);
