import React, { useRef } from 'react';
import EmailEditor from 'react-email-editor';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrowLeftOutlined } from '@ant-design/icons';
// import { observer } from 'mobx-react';
import { Button } from 'antd';
import HeaderOptions from '../../components/HeaderOptions/HeaderOptions';
import templateJSON from '../../constants/template.json';
import style from './style.module.less';
import ExportTemplate from '../../components/HeaderOptions/ExportTemplateBtn';
import { useRootModel } from '../../models/RootStore';

/**
 * Represents MailLayouts template page
 * @returns {JSX.Element}
 */

const MailLayoutsTemplate = () => {
  const {
    mailLayouts: { mailLayouts, layoutIndex, setLoading },
  } = useRootModel();

  const history = useHistory();

  const emailEditorRef = useRef(null);

  const tools = {
    menu: {
      enabled: false,
    },
  };

  const template = layoutIndex >= 0 ? JSON.parse(mailLayouts[layoutIndex]?.styles) : templateJSON;

  const onLoad = () => {
    if (template) {
      // eslint-disable-next-line no-unused-expressions
      emailEditorRef.current.editor.loadDesign(template);
    }
  };

  const handleReload = () => {
    setLoading(true);
    history.push('/mail-layouts');
    window.location.reload();
    setLoading(false);
  };

  const onReady = () => {
    // eslint-disable-next-line no-console
    console.log('onReady');
  };

  const onLoadEditor = () => {
    // eslint-disable-next-line no-console
    console.log('Editor loaded');
  };

  return (
    <>
      <section className={style.mailLayoutsHeader}>
        <HeaderOptions>
          <div className={style.linkWrapper}>
            <Button type='link' onClick={handleReload} className={style.link}>
              <ArrowLeftOutlined />
              <span className={style.link_text}>Mail layouts</span>
            </Button>
          </div>
          <ExportTemplate emailEditorRef={emailEditorRef} handleReload={handleReload} />
        </HeaderOptions>
      </section>
      <section className={style.mailLayouts}>
        <EmailEditor
          ref={emailEditorRef}
          onLoad={onLoad}
          onLoadEditor={onLoadEditor}
          onReady={onReady}
          tools={tools}
        />
      </section>
    </>
  );
};

export default MailLayoutsTemplate;
