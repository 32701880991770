import { flow, getRoot, types } from 'mobx-state-tree';
import axios from 'axios';

import TicketModel from './models/TicketModel';
import PaginationModel from '../commonModels/PaginationModel';
import DisplayOptionsModel from '../commonModels/DisplayOptionsModel';
import TicketsFiltersModel from './models/TicketsFiltersModel';
import TradersListModel from '../commonModels/TradersListModel';
import WorkersListModel from '../commonModels/WorkersListModel';
import ticketsTableOptions from './models/ticketsTableOptions';
import ticketsDefaultFilters from './models/ticketsDefaultFilters';

import errorHandler from '../../util/errorHandler';
import filterHandler from '../../util/filterHandler';
import handleReadPermission from '../../util/handleReadPermission';
import PermissionsModel from '../commonModels/PermissionsModel';
import queryString from '../../util/queryString';

const { REACT_APP_API } = process.env;

const TicketsModel = types
  .model('TicketsModel', {
    tickets: types.array(TicketModel),
    isLoading: types.optional(types.boolean, false),
    pagination: types.optional(PaginationModel, {}),
    filters: types.optional(TicketsFiltersModel, {}),
    displayOptions: types.array(DisplayOptionsModel),
    traders: types.array(TradersListModel),
    workers: types.array(WorkersListModel),
    permissions: types.array(PermissionsModel),
  })
  .actions((self) => ({
    getPermission: flow(function* getPermission(permission) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const {
        user: { userData },
      } = getRoot(self);
      const params = {
        category: permission,
        role: userData?.authorities,
      };
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/permissions'),
          { params },
        );
        self.permissions = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchTickets: flow(function* fetchTickets() {
      const { pagination } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = {
        page: pagination.number,
        size: pagination.size,
        sort: `${pagination.sortColumn},${pagination?.sortOrder}`,
      };
      const filters = filterHandler(self?.filters);

      try {
        const { data } = yield axios.post(
          queryString(REACT_APP_API, 'api/backoffice/tickets'),
          filters,
          { params },
        );
        self.tickets = data.data;
        pagination.size = data.page.size;
        pagination.number = data.page.number;
        pagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    changeTicketStatus: flow(function* changeTicketStatus(status, id) {
      const { tickets } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const ticket = { id, status };
      try {
        const { data } = yield axios.patch(
          queryString(REACT_APP_API, 'api/backoffice/tickets'),
          ticket,
        );
        const index = tickets.findIndex((obj) => obj.id === data.id);
        tickets[index].status = data.status;
        tickets[index].closedAt = data.closedAt;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    getTraders: flow(function* getTraders() {
      self.isLoading = true;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/traders/simplified?active-status=ACTIVE'),
        );
        self.traders = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    getWorkers: flow(function* getWorkers() {
      self.isLoading = true;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/workers/simplified?active-status=ACTIVE'),
        );
        self.workers = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    handlePageChange(page, size) {
      const { pagination, fetchTickets } = self;
      pagination.number = page - 1;
      pagination.size = size;
      fetchTickets();
    },
    handleSorterChange(sorter) {
      const { pagination, fetchTickets } = self;
      pagination.sortColumn = sorter.sortColumn;
      pagination.sortOrder = sorter.sortOrder;
      fetchTickets();
    },
    clearFilters() {
      self.filters = ticketsDefaultFilters;
    },
    afterCreate: () => {
      self.displayOptions = ticketsTableOptions;
    },
  }))
  .views((self) => ({
    get isTicketsFilters() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TICKETS_FILTERS');
    },
    get isTicketsDisplayOptions() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TICKETS_DISPLAY_OPTIONS');
    },
  }));

export default TicketsModel;
