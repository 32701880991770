import React from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as Envelope } from '../../../assets/image/envelope.svg';
import AppButton from '../../ui/AppButton';
import { useRootModel } from '../../../models/RootStore';

/**
 * Represents Send email by template button
 * @returns {JSX.Element}
 */
const SendEmailByTemplate = () => {
  const {
    traders: { selectedTrader },
  } = useRootModel();
  const handleModalOpen = () => {
    selectedTrader.isModal.setModal('Send email by template');
  };
  return (
    <AppButton
      type='text'
      text='Send email by template'
      icon={<Envelope />}
      onClick={handleModalOpen}
    />
  );
};

export default observer(SendEmailByTemplate);
