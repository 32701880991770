import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import TicketsContent from '../../containers/TicketsContent';
import HeaderOptions from '../../components/HeaderOptions/HeaderOptions';
import FilterOptions from '../../components/HeaderOptions/FilterOptions';
import DisplayOptions from '../../components/HeaderOptions/DisplayOptions/DisplayOptions';

import FilterItem from '../../components/HeaderOptions/FilterOptions/FilterItem';
import FilterAmountItem from '../../components/HeaderOptions/FilterOptions/FilterAmountItem';
import FilterDateItem from '../../components/HeaderOptions/FilterOptions/FilterDateItem';
import FilterNameEntity from '../../components/HeaderOptions/FilterOptions/FilterNameEntity';
import filtersListData from '../../constants/filtersListData';
import FilterTypeItem from '../../components/HeaderOptions/FilterOptions/FilterTypeItem';
import permission from '../../constants/permission';

/**
 * Represents Tickets page
 * @returns {JSX.Element}
 */

const Tickets = () => {
  const {
    tickets: {
      displayOptions,
      filters,
      fetchTickets,
      getTraders,
      getWorkers,
      traders,
      workers,
      getPermission,
      isTicketsFilters,
      isTicketsDisplayOptions,
    },
    workers: { getUserDesks, userDesks },
  } = useRootModel();
  useEffect(() => {
    getPermission(permission.TICKETS_SECTION);
    getUserDesks();
    getTraders();
    getWorkers();
  }, []);
  return (
    <section className='page'>
      <div className='page__header'>
        <HeaderOptions>
          {isTicketsFilters && (
            <FilterOptions apply={fetchTickets}>
              <FilterItem
                filter={filters?.id}
                title='Filter by ID'
                placeholder='Enter here...'
                param='ID'
              />
              <FilterAmountItem filter={filters?.amount} title='Filter by Amount' param='AMOUNT' />
              <FilterNameEntity
                filter={filters?.trader}
                title='Filter by Trader'
                list={traders}
                param='TRADER'
                placeholder='Please select...'
              />
              <FilterTypeItem
                filter={filters?.type}
                data={filtersListData.typeData}
                title='Filter by Type'
                placeholder='Please select'
                param='TYPE'
              />
              <FilterTypeItem
                filter={filters?.method}
                data={filtersListData.methodData}
                title='Filter by Method'
                placeholder='Please select'
                param='METHOD'
              />
              <FilterNameEntity
                filter={filters?.assignedTo}
                title='Assigned to'
                list={workers}
                param='ASSIGNED_TO'
                placeholder='Please select...'
              />
              <FilterNameEntity
                filter={filters?.broker}
                title='Filter by Broker'
                list={workers}
                param='BROKER'
                placeholder='Please select...'
              />
              <FilterNameEntity
                filter={filters?.desk}
                list={userDesks}
                title='Filter by Desk'
                param='DESK'
                placeholder='Select desk...'
              />
              <FilterTypeItem
                filter={filters?.status}
                data={filtersListData.ticketStatusData}
                title='Status'
                placeholder='Select status'
                param='STATUS'
              />
              <FilterNameEntity
                filter={filters?.openedBy}
                title='Opened by'
                list={workers}
                param='OPENED_BY'
                placeholder='Please select...'
              />
              <FilterDateItem filter={filters?.openedAt} title='Opened' param='OPENED_DATE' />
              <FilterDateItem filter={filters?.closedAt} title='Closed' param='CLOSED_DATE' />
            </FilterOptions>
          )}
          {isTicketsDisplayOptions && <DisplayOptions options={displayOptions} />}
        </HeaderOptions>
      </div>
      <div className='page__content'>
        <TicketsContent />
      </div>
    </section>
  );
};

export default observer(Tickets);
