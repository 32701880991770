const workersTableOptions = [
  { title: 'Username', checked: true },
  { title: 'Worker name', checked: true },
  { title: 'Email', checked: true },
  { title: 'Language', checked: true },
  { title: 'Desk', checked: true },
  { title: 'Role', checked: true },
  { title: 'Active', checked: true },
  { title: 'Created', checked: true },
];

export default workersTableOptions;
