import React, { useEffect } from 'react';
import { Table, Typography } from 'antd';
import numeral from 'numeral';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useRootModel } from '../../../models/RootStore';

import dateFormatter from '../../../util/dateFormatter';
import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';
import displayOptionsFilter from '../../../util/displayOptionsFilter';

import style from './tables.module.less';

/**
 * Represents desks table
 * @returns {JSX.Element}
 */

const DesksTable = () => {
  const {
    desks: { desks, fetchDesks, isLoading, pagination, handleSorterChange, displayOptions },
  } = useRootModel();
  useEffect(() => {
    fetchDesks();
  }, []);
  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handleSorterChange({ sortColumn: column, sortOrder });
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: true,
      sortOrder: pagination.sortColumn === 'id' && sortOrderToString(pagination.sortOrder),
      render: (id) => (
        <div className={style.linkCell}>
          <Typography.Text className={style.link}>#{id}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Desk name',
      dataIndex: 'name',
      key: 'name',
      className: `${style.column}`,
      width: 150,
      sorter: true,
      sortOrder: pagination.sortColumn === 'name' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Team Lead',
      dataIndex: 'teamLead',
      key: 'teamLead',
      className: `${style.column}`,
      width: 150,
      sorter: true,
      sortOrder: pagination.sortColumn === 'teamLead' && sortOrderToString(pagination.sortOrder),
      render: (teamLead) => <div>{teamLead?.name}</div>,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      width: 140,
      sorter: true,
      sortOrder: pagination.sortColumn === 'active' && sortOrderToString(pagination.sortOrder),
      render: (active) => (
        <div
          className={`${style.status} ${!active ? style.status__danger : style.status__success}`}
        >
          {active ? (
            <Typography.Text className={style.text__success}>Yes</Typography.Text>
          ) : (
            <Typography.Text className={style.text__danger}>No</Typography.Text>
          )}
        </div>
      ),
    },
    {
      title: 'Traders count',
      dataIndex: 'tradersCount',
      key: 'tradersCount',
      className: `${style.column}`,
      width: 150,
      sorter: true,
      sortOrder:
        pagination.sortColumn === 'tradersCount' && sortOrderToString(pagination.sortOrder),
      render: (tradersCount) => <div>{numeral(tradersCount).format('0,0.00')}</div>,
    },
    {
      title: 'Affiliate/Campaign',
      dataIndex: `${'campaign' || 'affiliate'}`,
      key: `${'campaign' || 'affiliate'}`,
      width: 143,
      sorter: true,
      sortOrder:
        pagination.sortColumn === `${'campaign' || 'affiliate'}` &&
        sortOrderToString(pagination.sortOrder),
      render: (campaign, data) => (
        <div>
          {data.campaign ? <div>{data.campaign?.name}</div> : <div>{data?.affiliate?.name}</div>}
        </div>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'createdAt' && sortOrderToString(pagination.sortOrder),
      render: (createdAt) => <div>{dateFormatter(createdAt)}</div>,
    },
  ];
  return (
    <>
      <Table
        className={style.table}
        onChange={onPageChange}
        columns={displayOptionsFilter(displayOptions, columns)}
        loading={isLoading}
        dataSource={toJS(desks)}
        scroll={{ x: 'max-content', y: '620px' }}
        rowKey='id'
        pagination={false}
      />
    </>
  );
};

export default observer(DesksTable);
