import { types } from 'mobx-state-tree';

const UserModel = types.model('UserModel', {
  authorities: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  imageUrl: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
});

export default UserModel;
