const positionsTableOptions = [
  { title: 'ID', checked: true },
  { title: 'Trading type', checked: true },
  { title: 'Type', checked: true },
  { title: 'Asset', checked: true },
  { title: 'Opening date', checked: true },
  { title: 'Opening price', checked: true },
  { title: 'Used funds', checked: true },
  { title: 'Results', checked: true },
  { title: 'Profit', checked: true },
  { title: 'Status', checked: true },
  { title: 'Execution conditions', checked: true },
  { title: 'Profit limit', checked: true },
  { title: 'Loss limit', checked: true },
  { title: 'Amount', checked: true },
  { title: 'Closing date', checked: true },
];

export default positionsTableOptions;
