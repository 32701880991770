import { types } from 'mobx-state-tree';
import CampaignModel from '../../commonModels/CampaignModel';
import TeamLeadModel from '../../commonModels/TeamLeadModel';
import AffiliateModel from '../../commonModels/AffiliateModel';

const DeskModel = types.model('DeskModel', {
  active: types.maybeNull(types.boolean),
  affiliate: types.maybeNull(AffiliateModel),
  campaign: types.maybeNull(CampaignModel),
  createdAt: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  teamLead: types.maybeNull(TeamLeadModel),
  tradersCount: types.maybeNull(types.number),
});

export default DeskModel;
