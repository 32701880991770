const workersDefaultFilters = {
  username: { field: '', value: '' },
  name: { field: '', value: '' },
  email: { field: '', value: '' },
  language: { field: '', value: '' },
  role: { field: '', value: '' },
  desk: { field: '', value: '' },
  active: { field: '', value: null },
  date: { field: '', fromValue: '', toValue: '' },
};

export default workersDefaultFilters;
