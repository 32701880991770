import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import errorHandler from '../../../util/errorHandler';

const Desk = types.model('Desk', {
  name: types.maybeNull(types.string),
});

const mouthFinanceAttributes = types.model('MouthFinanceAttributes', {
  deposits: types.maybeNull(types.number),
  depositsCount: types.maybeNull(types.number),
  ftds: types.maybeNull(types.number),
  ftdsCount: types.maybeNull(types.number),
  netDeposits: types.maybeNull(types.number),
  redeposits: types.maybeNull(types.number),
  redepositsCount: types.maybeNull(types.number),
  withdrawals: types.maybeNull(types.number),
  withdrawalsCount: types.maybeNull(types.number),
});

const totalFinanceAttributes = types.model('TotalFinanceAttributes', {
  deposits: types.maybeNull(types.number),
  depositsCount: types.maybeNull(types.number),
  ftds: types.maybeNull(types.number),
  ftdsCount: types.maybeNull(types.number),
  netDeposits: types.maybeNull(types.number),
  redeposits: types.maybeNull(types.number),
  redepositsCount: types.maybeNull(types.number),
  withdrawals: types.maybeNull(types.number),
  withdrawalsCount: types.maybeNull(types.number),
});

const WorkerModel = types
  .model('WorkerModel', {
    active: types.optional(types.boolean, false),
    createdDate: types.maybeNull(types.string),
    department: types.maybeNull(types.string),
    desk: types.maybeNull(Desk),
    email: types.maybeNull(types.string),
    executionPlan: types.maybeNull(types.number),
    id: types.maybeNull(types.number),
    language: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    timeZoneId: types.maybeNull(types.string),
    lastLoginDate: types.maybeNull(types.string),
    totalLeads: types.maybeNull(types.number),
    leadsByStatusesCount: types.maybeNull(types.number),
    mouthFinanceAttributes: types.maybeNull(mouthFinanceAttributes),
    totalFinanceAttributes: types.maybeNull(totalFinanceAttributes),
  })
  .actions((self) => ({
    setExecutionPlan: flow(function* setExecutionPlan(executionPlan) {
      const { id } = self;
      try {
        yield axios.patch(`/api/backoffice/workers/${id}`, { executionPlan });
        self.executionPlan = executionPlan;
      } catch (e) {
        errorHandler(self, e);
      }
    }),
  }));

export default WorkerModel;
