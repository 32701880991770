import React, { useEffect } from 'react';
import cn from 'classnames';
import { Badge, Tooltip, Button } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import LiveClock from 'react-live-clock';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import { ReactComponent as Calendar } from '../../assets/image/calendar.svg';
import { ReactComponent as Notifications } from '../../assets/image/notifications.svg';
import { ReactComponent as Clock } from '../../assets/image/clock.svg';
import { ReactComponent as Logout } from '../../assets/image/logout.svg';

import style from './Header.module.less';

/**
 * Represents Header
 * @returns {JSX.Element}
 */
const Header = () => {
  const {
    notifications: {
      setNotificationsModal,
      isNotificationsModal,
      fetchNotifications,
      fetchNotificationsUpdates,
      unReadNotifications,
      createNotification,
      page,
    },
    user: { logout },
    support: { fetchSupportUpdates },
  } = useRootModel();
  const { pathname } = useLocation();
  useEffect(() => {
    fetchNotifications();
    fetchNotificationsUpdates();
    fetchSupportUpdates();
  }, [page]);
  const handleModalOpen = () => {
    setNotificationsModal(true);
  };

  return (
    <header className={style.header}>
      <div className={style.common}>
        <div
          className={
            pathname === '/calendar'
              ? `${cn(style.wrapper, style.wrapper__active)}`
              : `${cn(style.wrapper)}`
          }
        >
          <Tooltip title='Calendar'>
            <NavLink to='/calendar' activeClassName={cn(style.active, 'active')}>
              <Calendar />
            </NavLink>
          </Tooltip>
        </div>
        {/* fixme test btn for sending new notification */}
        {process.env.NODE_ENV === 'development' && (
          <div>
            <Button onClick={createNotification}>btn</Button>
          </div>
        )}
        <div
          className={
            isNotificationsModal
              ? `${cn(style.wrapper, style.wrapper__active)}`
              : `${cn(style.wrapper)}`
          }
        >
          <Tooltip title='Notifications'>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <div
              className={
                isNotificationsModal
                  ? `${cn(style.notificationIcon, style.active)}`
                  : `${cn(style.notificationIcon)}`
              }
              role='button'
              onClick={handleModalOpen}
            >
              <Badge count={unReadNotifications} offset={[-3, 17]} size='small'>
                <Notifications />
              </Badge>
            </div>
          </Tooltip>
        </div>
        <div className={cn(style.wrapper, style.clockWrapper)}>
          <Clock />
          <div className={style.timeWrapper}>
            <LiveClock className={style.time} format='HH:mm:ss' ticking />
          </div>
        </div>
        <div className={style.wrapper}>
          <Tooltip title='Log out'>
            <Logout className={style.logout} onClick={logout} />
          </Tooltip>
        </div>
      </div>
    </header>
  );
};

export default observer(Header);
