import React from 'react';
import AppDatePicker from '../../../ui/AppDatePicker/AppDatePicker';

/**
 * Represents date filter item component
 * @param {Object} filter - last comment filter
 * @param {String} title - select title
 * @param {String} param - filter param
 * @returns {JSX.Element}
 */

const FilterDateItem = ({ filter, title, param }) => {
  const onChange = (value) => {
    if (value[0] !== '') {
      const fromValue = new Date(value[0]).toISOString();
      const toValue = new Date(value[1]).toISOString();
      filter.setValue(param, fromValue, toValue);
    }
  };
  return <AppDatePicker onChange={onChange} title={title} />;
};

export default FilterDateItem;
