import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Tooltip } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { ReactComponent as Eye } from '../../../assets/image/eye.svg';
import { ReactComponent as CrossedEye } from '../../../assets/image/eye-crossed.svg';
import { ReactComponent as Edit } from '../../../assets/image/edit-success.svg';
import { ReactComponent as NoEdit } from '../../../assets/image/edit-danger.svg';
import style from '../style.module.less';
import userRoles from '../../../constants/userRoles';

const SettingsItem = ({ name, item, role }) => {
  const handlePermission = (field) => {
    if (role === userRoles.admin || role === userRoles.systemAdmin) item.updatePermission(field);
    return null;
  };
  return (
    <li className={style.settingsItemWrapper}>
      <div className={style.settingsItem}>
        <span>{name}</span>
        <div className={style.actionsContainer}>
          <Tooltip
            title={item.read ? 'View only' : 'View disabled'}
            color='#fff'
            overlayInnerStyle={{ color: '#334D6E' }}
          >
            {item.read ? (
              <Eye
                className={cn(style.viewBtn, style.iconBtn)}
                onClick={() => handlePermission('read')}
              />
            ) : (
              <CrossedEye className={style.iconBtn} onClick={() => handlePermission('read')} />
            )}
          </Tooltip>
          <Tooltip
            title={item.write ? 'Editing allowed' : 'Editing disabled'}
            color='#fff'
            overlayInnerStyle={{ color: '#334D6E' }}
          >
            {item.write ? (
              <Edit className={style.iconBtn} onClick={() => handlePermission('write')} />
            ) : (
              <NoEdit className={style.iconBtn} onClick={() => handlePermission('write')} />
            )}
          </Tooltip>
        </div>
      </div>
    </li>
  );
};

export default observer(SettingsItem);
