/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { Typography } from 'antd';
import { useRootModel } from '../../../../models/RootStore';

import ProfileCard from '../ProfileCard';
// eslint-disable-next-line max-len
import { ReactComponent as ActivityIcon } from '../../../../assets/image/activityIcon.svg';

import style from './style.module.less';

/**
 * Represents trader activity
 * @returns {JSX.Element}
 */

const TraderActivity = () => {
  const {
    traders: { activities },
  } = useRootModel();
  return (
    <div className={style.activityContainer}>
      <div className={style.activityHeader}>
        <ActivityIcon />
        <Typography.Text>Activity</Typography.Text>
      </div>
      <div className={style.activityBody}>
        {!!activities.length ? (
          activities.map((activity) => (
            <ProfileCard activity={activity} activityCard key={activity.id} />
          ))
        ) : (
          <div>
            <Typography.Text className={style.noText}>No data</Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default TraderActivity;
