import { types } from 'mobx-state-tree';
import WorkersListCalendarModel from './WorkersListCalendarModel';
import TradersListModel from '../../commonModels/TradersListModel';

const EventModel = types.model('EventModel', {
  title: types.maybeNull(types.string),
  startDate: types.maybeNull(types.Date),
  endDate: types.maybeNull(types.Date),
  trader: types.maybeNull(WorkersListCalendarModel),
  worker: types.maybeNull(TradersListModel),
  id: types.maybeNull(types.number),
  type: types.optional(types.string, ''),
  popover: types.optional(types.boolean, false),
  tooltip: types.optional(types.string, 'Double click to see the event'),
  description: types.maybeNull(types.string),
});

export default EventModel;
