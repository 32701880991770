import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Table } from 'antd';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import cn from 'classnames';
import { useRootModel } from '../../../models/RootStore';

import dateFormatter from '../../../util/dateFormatter';
import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';

import { ReactComponent as ReadTicks } from '../../../assets/image/done-ticks.svg';

import style from './tables.module.less';

/**
 * Represents support table
 * @returns {JSX.Element}
 */

const SupportTable = () => {
  const history = useHistory();
  const {
    support: { pagination, isLoading, handleSorterChange, support, fetchSupport },
  } = useRootModel();
  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handleSorterChange({ sortColumn: column, sortOrder });
  };
  const handleOnRow = (id) => {
    history.push(`/support/${id}`);
  };
  useEffect(() => {
    fetchSupport();
  }, []);
  const columns = [
    {
      title: 'Trader',
      dataIndex: 'trader',
      key: 'trader',
      width: 150,
      render: (trader) => <div>{trader?.name}</div>,
    },
    {
      className: `${style.column}`,
      title: 'Trader ID',
      dataIndex: 'trader',
      key: 'trader',
      width: 100,
      sorter: true,
      sortOrder: pagination.sortColumn === 'trader' && sortOrderToString(pagination.sortOrder),
      render: (trader) => (
        <div className={style.linkCell}>
          <Link to={`/trader/${trader?.id}`} className={style.link}>
            #{trader?.id}
          </Link>
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 110,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: 150,
      render: (subject) => <div className={style.ellipsis}>{subject}</div>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 570,
      render: (description) => (
        <div className={cn(style.ellipsis, style.ellipsis__large)}>{description}</div>
      ),
    },
    {
      title: 'Viewed',
      dataIndex: 'isRead',
      key: 'isRead',
      width: 100,
      align: 'center',
      sorter: true,
      sortOrder: pagination.sortColumn === 'isRead' && sortOrderToString(pagination.sortOrder),
      render: (isRead) => (
        <div className={cn(style.viewedTicks, { [style.viewedTicks__read]: isRead })}>
          <ReadTicks />
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'isProcessed',
      key: 'isProcessed',
      width: 200,
      sorter: true,
      sortOrder: pagination.sortColumn === 'isProcessed' && sortOrderToString(pagination.sortOrder),
      render: (isProcessed) => (
        <div className={style.ticketStatus_wrapper}>
          <div
            className={cn(
              style.ticketStatus,
              { [style.ticketStatus__declined]: !isProcessed },
              { [style.ticketStatus__active]: isProcessed },
            )}
          >
            <div
              className={cn(
                style.ellipse,
                { [style.ellipse__danger]: !isProcessed },
                { [style.ellipse__active]: isProcessed },
              )}
            />
            <p
              className={cn(
                style.ticketText,
                { [style.ticketText__declined]: !isProcessed },
                { [style.ticketText__active]: isProcessed },
              )}
            >
              {isProcessed ? 'Processed' : 'Not Processed'}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 160,
      sorter: true,
      sortOrder: pagination.sortColumn === 'createdAt' && sortOrderToString(pagination.sortOrder),
      render: (createdAt) => <div>{dateFormatter(createdAt)}</div>,
    },
  ];
  return (
    <>
      <Table
        onRow={(trader) => {
          return {
            onDoubleClick: () => {
              handleOnRow(trader.id);
            },
          };
        }}
        className={style.table}
        onChange={onPageChange}
        columns={columns}
        loading={isLoading}
        dataSource={toJS(support)}
        scroll={{ x: 'max-content', y: '720px' }}
        rowKey='id'
        pagination={false}
      />
    </>
  );
};

export default observer(SupportTable);
