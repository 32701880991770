import React from 'react';
import style from './Support.module.less';
import SupportContent from '../../containers/SupportContent';

/**
 * Represents Support page
 * @returns {JSX.Element}
 */
const Support = () => {
  return (
    <section className={style.support}>
      <SupportContent />
    </section>
  );
};

export default Support;
