import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import TradersContent from '../../containers/TradersContent';
import HeaderOptions from '../../components/HeaderOptions/HeaderOptions';
import FilterOptions from '../../components/HeaderOptions/FilterOptions';
import DisplayOptions from '../../components/HeaderOptions/DisplayOptions/DisplayOptions';
import SelectedItems from '../../components/HeaderOptions/SelectedItems/SelectedItems';

import FilterItem from '../../components/HeaderOptions/FilterOptions/FilterItem';
import FilterAmountItem from '../../components/HeaderOptions/FilterOptions/FilterAmountItem';
import FilterBoolItem from '../../components/HeaderOptions/FilterOptions/FilterBoolItem';
import FilterSelectItem from '../../components/HeaderOptions/FilterOptions/FilterSelectItem';
// eslint-disable-next-line max-len
import FilterCountrySelectItem from '../../components/HeaderOptions/FilterOptions/FilterCountrySelectItem';
import countryList from '../../constants/countryList';
import FilterDateItem from '../../components/HeaderOptions/FilterOptions/FilterDateItem';
import filtersListData from '../../constants/filtersListData';
import FilterNameEntity from '../../components/HeaderOptions/FilterOptions/FilterNameEntity';
import permission from '../../constants/permission';

/**
 * Represents Traders page
 * @returns {JSX.Element}
 */
const Traders = () => {
  const {
    traders: {
      displayOptions,
      filters,
      fetchTraders,
      getAffiliates,
      getCampaigns,
      affiliates,
      campaigns,
      getPermission,
      isFilters,
      isDisplayOptions,
    },
    workers: { getUserDesks, userDesks },
    desks: { getWorkers, workers },
  } = useRootModel();
  useEffect(() => {
    getPermission(permission.TRADERS_SECTION);
    getUserDesks();
    getWorkers();
    getAffiliates();
    getCampaigns();
  }, []);

  return (
    <section className='page'>
      <div className='page__header'>
        <HeaderOptions>
          {isFilters && (
            <FilterOptions apply={fetchTraders}>
              <FilterItem
                filter={filters?.email}
                title='Email'
                placeholder='Write here...'
                param='EMAIL'
              />
              <FilterItem
                filter={filters?.id}
                title='Filter by id'
                placeholder='Enter id'
                param='ID'
              />
              <FilterItem
                filter={filters?.name}
                title='Search by name'
                placeholder='Write here...'
                param='NAME'
              />
              <FilterItem
                filter={filters?.phone}
                title='Filter by phone'
                placeholder='Enter here...'
                param='PHONE'
              />
              <FilterAmountItem
                filter={filters?.balance}
                title='Filter by balance'
                param='BALANCE'
              />
              <FilterAmountItem
                filter={filters?.ftdData}
                title='Filter by FTD data'
                param='FTD_DATA'
              />
              <FilterBoolItem
                filter={filters?.online}
                title='Filter by online'
                param='ONLINE'
                data={filtersListData.onlineStatusData}
                compareString='Offline'
                placeholder='Please select'
              />
              <FilterBoolItem
                filter={filters?.balanceStatus}
                title='Balance status'
                param='BALANCE_STATUS'
                data={filtersListData.balanceStatusData}
                compareString='Negative'
                placeholder='Select status'
              />
              <FilterBoolItem
                filter={filters?.demo}
                title='Filter by demo account'
                param='DEMO'
                data={filtersListData.demoAccountData}
                compareString='Real'
                placeholder='Select account'
              />
              <FilterBoolItem
                filter={filters?.brokerAssign}
                title='Filter by broker assignment'
                param='BROKER_ASSIGN'
                data={filtersListData.brokerAssignData}
                compareString='Not assigned'
                placeholder='Select assignment'
              />
              <FilterSelectItem
                filter={filters?.retentionStatus}
                data={filtersListData.retentionStatusData}
                title='Retention status'
                param='RETENTION_STATUS'
                placeholder='Please select...'
              />
              <FilterSelectItem
                filter={filters?.saleStatus}
                data={filtersListData.saleStatusData}
                title='Sale status'
                param='SALE_STATUS'
                placeholder='Please select...'
              />
              <FilterSelectItem
                filter={filters?.desk}
                data={userDesks}
                title='Filter by Desk'
                param='DESK'
                placeholder='Select desk...'
              />
              <FilterNameEntity
                filter={filters?.affiliate}
                list={affiliates}
                title='Filter by affiliate'
                param='AFFILIATE'
                placeholder='Select affiliate'
              />
              <FilterNameEntity
                filter={filters?.campaign}
                list={campaigns}
                title='Filter by campaign'
                param='CAMPAIGN'
                placeholder='Select campaign'
              />
              <FilterCountrySelectItem
                filter={filters?.country}
                title='Filter by country'
                placeholder='Select country'
                param='COUNTRY'
                data={countryList}
              />
              <FilterCountrySelectItem
                filter={filters?.registrationCountry}
                title='Filter by registration country'
                placeholder='Select country'
                param='REGISTRATION_COUNTRY'
                data={countryList}
              />
              <FilterNameEntity
                filter={filters?.broker}
                title='Filter by Broker'
                list={workers}
                param='BROKER'
                placeholder='Please select...'
              />
              <FilterDateItem
                filter={filters?.lastLoginDate}
                title='Last login date'
                param='LAST_LOGIN_DATE'
              />
              <FilterDateItem
                filter={filters?.lastCommentDate}
                title='Last comment'
                param='LAST_COMMENT_DATE'
              />
              <FilterDateItem
                filter={filters?.registrationDate}
                title='Registration date'
                param='REGISTRATION_DATE'
              />
            </FilterOptions>
          )}
          {isDisplayOptions && <DisplayOptions options={displayOptions} />}
          <SelectedItems />
        </HeaderOptions>
      </div>
      <div className='page__content'>
        <TradersContent />
      </div>
    </section>
  );
};

export default observer(Traders);
