import React, { useEffect, useState } from "react";

import { Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import MessageList from '../../Components/MessageList/MessageList';

import './ChatMesaages.scss';
import { observer } from "mobx-react";

const ChatMessages = (props) => {
  const [scrollBottom, setScrollBottom] = useState(0);
  const [downButton, setDownButton] = useState(false);
  const lockable = true;
  const {
    mlistRef,
    isMessageListLoading,
    toBottomHeight,
    dataSource,
    onOpen,
    onClick,
    onIncomingMessageHover,
    currentChat,
    userId,
  } = props
  const checkScroll = () => {
    const e = mlistRef.current;
    if (!e) return;

    if (toBottomHeight === '100%' || scrollBottom < toBottomHeight) {
      // scroll to bottom
      e.scrollTop = e.scrollHeight;
    } else {
      if (lockable === true) {
        e.scrollTop = e.scrollHeight - e.offsetHeight - scrollBottom;
      }
    }
  };

  useEffect(() => {
    if (!mlistRef) return;
    if (dataSource.length) {
      setScrollBottom(getBottom(mlistRef.current));
      checkScroll();
    }
  }, [dataSource]);
  const getBottom = (e) => {
    return e.scrollHeight - e.scrollTop - e.offsetHeight;
  };
  const onScroll = (e) => {
    const bottom = getBottom(e.target);
    setScrollBottom(bottom);
    if (toBottomHeight === '100%' || bottom > toBottomHeight) {
      if (downButton !== true) {
        setDownButton(true);
        setScrollBottom(bottom);
      }
    } else {
      if (downButton !== false) {
        setDownButton(false);
        setScrollBottom(bottom);
      }
    }
    if (props.onScroll instanceof Function) {
      props.onScroll(e);
    }
  };
  const toBottom = (e) => {
    if (!mlistRef) return;
    mlistRef.current.scrollTo({ top: mlistRef.current.scrollHeight, behavior: 'smooth' });
    if (props.onDownButtonClick instanceof Function) {
      props.onDownButtonClick(e);
    }
  };
  return (
    <div ref={mlistRef} onScroll={onScroll} className='chat_messages_container'>
      {currentChat ? (
        <>
          <MessageList
                      className='message-list'
                      downButton={downButton}
                      toBottomHeight={toBottomHeight}
                      dataSource={dataSource}
                      onScroll={onScroll}
                      onOpen={onOpen}
                      onClick={onClick}
                      userId={userId}
                      locable={lockable}
                      onIncomingMessageHover={onIncomingMessageHover}
                      isLoading={isMessageListLoading}
                    />
          {props.downButton === true && downButton && toBottomHeight !== '100%' && (
            <div className='rce-mlist-down-button' onClick={toBottom}>
              <DownOutlined className='down-arrow' />
            </div>
          )}
        </>
      ) : (
        <div className='chat_empty_wrap'>
          <div className='chat_empty'>
            <Typography.Title level={4}>Messages will be displayed here.</Typography.Title>
            <Typography.Text className='chat_empty_descr'>
              First, select a user in the left sidebar and then you can continue chatting.
            </Typography.Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(ChatMessages);
