import React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import AppInput from '../../../ui/AppInput';

/**
 * Represents filter item component
 * @param {Object} filter - filter object
 * @param {String} title - filter title
 * @param {String} placeholder - filter placeholder
 * @param {String} param - filter parameter
 * @returns {JSX.Element}
 */

const FilterItem = ({ filter, title, placeholder, param }) => {
  const onChange = (value) => {
    filter.setValue(value, param);
  };
  return (
    <AppInput filter={toJS(filter)} title={title} placeholder={placeholder} onChange={onChange} />
  );
};

export default observer(FilterItem);
