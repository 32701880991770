import { useEffect } from 'react';

const isNumInRange = (num, from, to) => from <= num && num <= to;
/**
 * @typedef {Object} AdditionalSpace
 * @property {number} [top] - additional space top
 * @property {number} [bottom] - additional space bottom
 * @property {number} [left] - additional space left
 * @property {number} [right] - additional space right
 */
/**
 * Hook click outside / inside an element
 * @param {Ref} ref - Ref for element
 * @param {function} callback - callback what will be execute
 * @param {boolean} [outside] - is to listen click outside
 * @param {AdditionalSpace} [additionalSpace] - additional space for the element area
 */
const useOnClick = (ref, callback, outside = true, additionalSpace = {}) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref || !ref.current) return;

      const { top, bottom, left, right } = ref.current.getBoundingClientRect();
      const additionalTop = additionalSpace?.top || 0;
      const additionalBottom = additionalSpace?.bottom || 0;
      const additionalLeft = additionalSpace?.left || 0;
      const additionalRight = additionalSpace?.right || 0;
      const { clientX, clientY } = event;

      const isInnerArea =
        isNumInRange(clientX, left + additionalLeft, right + additionalRight) &&
        isNumInRange(clientY, top + additionalTop, bottom + additionalBottom);
      if ((outside && isInnerArea) || (!outside && !isInnerArea)) return;
      if (callback) callback(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, callback]);
};

export default useOnClick;
