import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Badge, Tooltip } from 'antd';

import style from './NavigationItem.module.less';
import getAnimationClasses from '../../util/getAnimationClasses';

/**
 * Represents item for navigation menu
 * @param {string} text - item title
 * @param {JSX.Element} Icon - item icon
 * @param {string} url - item url
 * @param {boolean} showText - for showing text set true
 * @param {function} onClick - onClick handler
 * @param {CSSStyleRule} className - wrapper className
 * @param {number} unreadMessages - unread messages
 * @param {number || null} unprocessedTicketsAmount - unprocessed support tickets
 * @returns {JSX.Element}
 */
const NavigationItem = ({
  text = '',
  icon: Icon,
  url = '',
  showText = false,
  onClick,
  className,
  unreadMessages,
  unprocessedTicketsAmount,
}) => {
  return (
    <NavLink
      onClick={onClick}
      className={cn(style.item, className)}
      to={url}
      activeClassName={cn(style.active, 'active')}
      exact
    >
      {Icon && (
        <div className={style.iconWrapper}>
          {showText ? (
            <>
              {/* eslint-disable-next-line no-nested-ternary */}
              {text === 'Chats' ? (
                <Badge count={unreadMessages} offset={[3, -3]} size='small'>
                  <Icon />
                </Badge>
              ) : text === 'Support' ? (
                <Badge count={unprocessedTicketsAmount} offset={[3, -3]} size='small'>
                  <Icon />
                </Badge>
              ) : (
                <Icon />
              )}
            </>
          ) : (
            <>
              {/* eslint-disable-next-line no-nested-ternary */}
              {text === 'Chats' ? (
                <Tooltip title={text}>
                  <Badge count={unreadMessages} offset={[-3, 2]} size='small'>
                    <Icon />
                  </Badge>
                </Tooltip>
              ) : text === 'Support' ? (
                <Tooltip title={text}>
                  <Badge count={unprocessedTicketsAmount} offset={[-3, 2]} size='small'>
                    <Icon />
                  </Badge>
                </Tooltip>
              ) : (
                <Tooltip title={text}>
                  <div>
                    <Icon />
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </div>
      )}

      <CSSTransition
        in={showText}
        timeout={500}
        classNames={getAnimationClasses(style)}
        unmountOnExit
      >
        <span className={style.text}>{text}</span>
      </CSSTransition>
    </NavLink>
  );
};

export default NavigationItem;
