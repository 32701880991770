import React from 'react';
import { Tabs } from 'antd';

import style from './style.module.less';

const { TabPane } = Tabs;

/**
 * Represents market info table tabs
 * @param {Function} onTabChange - passing tab value to a parent component
 * @returns {JSX.Element}
 */

const MarketInfoTabs = ({ onTabChange }) => {
  const onChange = (tabValue) => {
    onTabChange(tabValue);
  };
  return (
    <div className={style.tabsContainer}>
      <Tabs defaultActiveKey='crypto' onChange={onChange}>
        <TabPane tab={<span>Cryptocurrencies</span>} key='crypto' />
        <TabPane tab={<span>Indices</span>} key='indices' />
        <TabPane tab={<span>Oil and gas</span>} key='oil_gas' />
        <TabPane tab={<span>Metals</span>} key='metals' />
        <TabPane tab={<span>Shares</span>} key='shares' />
        <TabPane tab={<span>Currencies</span>} key='currencies' />
        <TabPane tab={<span>Agriculture</span>} key='agriculture' />
        <TabPane tab={<span>ETFs</span>} key='etfs' />
      </Tabs>
    </div>
  );
};

export default MarketInfoTabs;
