import React from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrowLeftOutlined } from '@ant-design/icons';
import CustomLink from '../../components/HeaderOptions/CustomLink';
import SingleWorkerContent from '../../containers/SingleWorkerContent';
import HeaderOptions from '../../components/HeaderOptions/HeaderOptions';
import Actions from '../../components/HeaderOptions/Actions/Actions';

/**
 * Represents Single traders page
 * @returns {JSX.Element}
 */

const SingleWorker = () => {
  return (
    <main className='page'>
      <section className='page__header'>
        <HeaderOptions>
          <Link to='/workers' className='page__link'>
            <ArrowLeftOutlined />
            <span className='page__link__text'>All Workers</span>
          </Link>
          <Actions className='page__actions-item' />
          <CustomLink link='/login' text='Login as' />
        </HeaderOptions>
      </section>
      <section className='page__content'>
        <SingleWorkerContent />
      </section>
    </main>
  );
};

export default SingleWorker;
