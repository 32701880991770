import React, { useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useRootModel } from './models/RootStore';
import './SVGColors.less';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';

import Dashboard from './pages/Dashboard/Dashboard';
import Traders from './pages/Traders/Traders';
import Desks from './pages/Desks/Desks';
import Tickets from './pages/Tickets/Tickets';
import Workers from './pages/Workers/Workers';
import Analytics from './pages/Analytics/Analytics';
import Campaigns from './pages/Campaigns/Campaigns';
import MailLayouts from './pages/MailLayouts/MailLayouts';
import Chats from './pages/Chats/Chats';
import Logs from './pages/Logs/Logs';
import Positions from './pages/Positions/Positions';
import Assets from './pages/Assets/Assets';
import Index from './pages/Settings';
import Support from './pages/Support/Support';
import MarketInfo from './pages/MarketInfo/MarketInfo';
import SingleWorker from './pages/SingleWorker';
import Login from './pages/Login';
import ErrorPage from './pages/ErrorPage';
import Calendar from './pages/Calendar';
import SingleTrader from './pages/SingleTrader';
import SupportMessage from './pages/SupportMessage';
import MailLayoutsTemplate from './pages/MailLayotsTemplate';

import NotificationsModal from './components/common/modals/NotificationsModal';
import ErrorModal from './components/common/modals/ErrorModal';

import { onMessageListener } from './init-fcm';
import FirebaseNotifications from './components/FirebaseNotifications';
import PushNotification from './components/PushNotification';

import style from './App.module.less';
import socketUtils from './util/socketUtils';
import Preloader from './components/common/Preloader';
import AxiosConfig from './AxiosConfig';

/**
 * Main component
 * @returns {JSX.Element}
 */

const App = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState(null);
  const [initialization, setInitialization] = useState(true);
  const {
    user: { isAuth, getUser },
    chat: { getUnreadCount, setUnreadCount, handleMessage, setChatStatus },
  } = useRootModel();

  useEffect(() => {
    const fetchData = async () => {
      await AxiosConfig();
      await getUser();
      setInitialization(false);
    };
    fetchData();
  }, []);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      // eslint-disable-next-line no-console
      console.log('received push notification');
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.log('failed: ', err));
  if (!isAuth) {
    history.push('/login');
  }

  useEffect(() => {
    setTimeout(() => setShow(false), 4000);
  }, [show]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Starting socket...');
    // eslint-disable-next-line no-useless-return
    if (isAuth) {
      socketUtils.handleConnection((message) => {
        // eslint-disable-next-line no-console
        console.log('Socket is ===>', message);
        getUnreadCount();
      });
      socketUtils.handleDisconnect();
      socketUtils.handleError();
      socketUtils.handleNewMessage(setUnreadCount, handleMessage);
      socketUtils.handleOnline(setChatStatus);
    }
    return () => {
      socketUtils.removeListeners();
    };
  }, [isAuth]);

  if (initialization)
    return (
      <div className='page__preloader'>
        <Preloader size={60} />
      </div>
    );

  return (
    <div className={style.app}>
      {isAuth && <Sidebar />}
      <div className={style.container}>
        <ErrorBoundary>
          {isAuth && <Header />}
          <main className={style.main}>
            <Switch>
              <Route exact path='/login' component={Login} />
              <Route exact path='/' component={Dashboard} />
              <Route exact path='/traders' component={Traders} />
              <Route path='/trader/:id' component={SingleTrader} />
              <Route exact path='/calendar' component={Calendar} />
              <Route exact path='/desks' component={Desks} />
              <Route exact path='/tickets' component={Tickets} />
              <Route exact path='/workers' component={Workers} />
              <Route exact path='/worker/:id' component={SingleWorker} />
              <Route exact path='/analytics' component={Analytics} />
              <Route exact path='/campaigns' component={Campaigns} />
              <Route exact path='/mail-layouts' component={MailLayouts} />
              <Route exact path='/mail-layouts/create' component={MailLayoutsTemplate} />
              <Route exact path='/chats' component={Chats} />
              <Route exact path='/logs' component={Logs} />
              <Route exact path='/positions' component={Positions} />
              <Route exact path='/assets' component={Assets} />
              <Route exact path='/support' component={Support} />
              <Route path='/support/:id' component={SupportMessage} />
              <Route exact path='/market-info' component={MarketInfo} />
              <Route exact path='/settings' component={Index} />
              <Route exact path='*' component={ErrorPage} />
            </Switch>
          </main>
        </ErrorBoundary>
      </div>
      <ErrorModal />
      <NotificationsModal />
      <FirebaseNotifications />
      <PushNotification
        show={show}
        message={style.pushMessageChat}
        container={style.pushContainer}
        notification={notification?.title}
        source={notification?.body}
      />
    </div>
  );
};

export default observer(App);
