import React from 'react';
import { Tabs } from 'antd';
import UserIcon from '../../../components/common/icons/UserIcon';
import TicketIcon from '../../../components/common/icons/TicketIcon';
import TransactionIcon from '../../../components/common/icons/TransactionIcon';

const { TabPane } = Tabs;

/**
 * Represents selected worker tabs
 * @returns {JSX.Element}
 */

const SingleWorkerTabs = (props) => {
  const onChange = (tabValue) => {
    props.onTabChange(tabValue);
  };

  return (
    <Tabs defaultActiveKey='profile' onChange={onChange}>
      {props.isWorkerProfile && (
        <TabPane
          tab={
            <span>
              <UserIcon />
              Profile info
            </span>
          }
          key='profile'
        />
      )}
      {props.isWorkerTickets && (
        <TabPane
          tab={
            <span>
              <TicketIcon />
              Tickets
            </span>
          }
          key='tickets'
        />
      )}
      {props.isTransactions && (
        <TabPane
          tab={
            <span>
              <TransactionIcon />
              Transactions
            </span>
          }
          key='transactions'
        />
      )}
    </Tabs>
  );
};

export default SingleWorkerTabs;
