import { getParent } from 'mobx-state-tree';
import * as Sentry from '@sentry/react';

const errorHandler = (store, e) => {
  const { error } = getParent(store);
  Sentry.captureException(e);
  error.setError(e.message);
  error.setIsModalOpen(true);
};

export default errorHandler;
