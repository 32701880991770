import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const DeleteBinIconSvg = () => (
  <svg width='27' height='26' viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect
      width='26.0001'
      height='26'
      rx='3'
      transform='matrix(1 1.74846e-07 1.74846e-07 -1 0.997559 26)'
      fill='#E64545'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M8.42613 19.5C8.42613 20.5215 9.26184 21.3572 10.2833 21.3572H17.7118C18.7333 21.3572 19.569 20.5215 19.569 19.5V8.35717H8.42613V19.5ZM10.2833 10.2143H17.7118V19.5H10.2833V10.2143ZM17.2476 5.57145L16.319 4.64288H11.6761L10.7476 5.57145H7.49756V7.4286H20.4976V5.57145H17.2476Z'
      fill='white'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const DeleteBinIcon = (props) => <Icon component={DeleteBinIconSvg} {...props} />;

export default DeleteBinIcon;
