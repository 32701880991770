import React from 'react';
import { Col } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EditFilled } from '@ant-design/icons';

import style from './style.module.less';

const MailLayoutItem = ({ layout, handleEditLayout, isEditMailLayouts }) => {
  return (
    <Col xxl={{ span: 12 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
      <div className={style.layout}>
        <div className={style.layoutHeader}>
          <span>{layout.title}</span>
          {isEditMailLayouts && (
            <EditFilled
              onClick={() => handleEditLayout(layout.id, layout.title)}
              className={style.editIcon}
            />
          )}
        </div>
        <div className={style.layoutBody}>
          <div className={style.emailWrapper}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: layout.content }} />
          </div>
        </div>
      </div>
    </Col>
  );
};

export default MailLayoutItem;
