import React from 'react';
import cn from 'classnames';
import { Typography } from 'antd';
import DataItem from './DataItem';
import ProgressBar from '../../../components/ProgressBar';
import { ReactComponent as Users } from '../../../assets/image/users.svg';
import { ReactComponent as UserClock } from '../../../assets/image/user-clock.svg';
import { ReactComponent as UserCheck } from '../../../assets/image/user-check.svg';
import { ReactComponent as Money } from '../../../assets/image/money-check-edit-alt.svg';

import style from './style.module.less';

/**
 * Represents dashboard footer component
 * @returns {JSX.Element}
 */

const DashboardFooter = () => {
  return (
    <div className={style.dashboardFooter}>
      <div className={style.itemsContainer}>
        <DataItem
          icon={<Users />}
          indicator='down'
          indicatorAmount={9}
          title='Total Leads'
          amount={4032}
        />
        <DataItem
          icon={<UserClock />}
          indicator='up'
          indicatorAmount={9}
          title='Processing'
          amount={2018}
        />
        <DataItem
          icon={<UserCheck />}
          indicator='down'
          indicatorAmount={9}
          title='Processed'
          amount={1427}
        />
        <DataItem
          icon={<Money />}
          indicator='up'
          indicatorAmount={9}
          title='Injected money'
          amount={322}
        />
      </div>
      <ProgressBar />
      <div className={style.dashboardAgenda}>
        <div className={style.agendaItem}>
          <div className={cn(style.agendaCircle, style.processing)} />
          <Typography.Text className={style.agendaText}>Processing</Typography.Text>
        </div>
        <div className={style.agendaItem}>
          <div className={cn(style.agendaCircle, style.processed)} />
          <Typography.Text className={style.agendaText}>Processed</Typography.Text>
        </div>
        <div className={style.agendaItem}>
          <div className={cn(style.agendaCircle, style.injectedMoney)} />
          <Typography.Text className={style.agendaText}>Injected Money</Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default DashboardFooter;
