import React from 'react';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useRootModel } from '../../../models/RootStore';
import CloseIcon from '../icons/CloseIcon';
import ChangeSaleStatusForm from '../forms/SingleTraderActions/ChangeSaleStatusForm';
import ChangeBrokerForm from '../forms/SingleTraderActions/ChangeBrokerForm';
import ChangeDeskForm from '../forms/SingleTraderActions/ChangeDeskForm';
import BlockTraderActions from '../forms/SingleTraderActions/BlockTraderActions';
import TraderReminderForm from '../forms/TraderReminderForm';
import CallHistoryForm from '../forms/CallHistoryForm';
import SendEmailForm from '../forms/SendEmailForm';

const desks = [
  { id: 1, value: 'Desk 1' },
  { id: 2, value: 'Desk 2' },
];
const teamLeads = [
  { id: 1, value: 'Team Lead 1' },
  { id: 2, value: 'Team Lead 2' },
];
const brokers = [
  { id: 1, value: 'Broker 1' },
  { id: 2, value: 'Broker 2' },
];
const saleStatuses = [
  { id: 1, value: 'Interested' },
  { id: 2, value: 'Not interested' },
];
/**
 * Represents send email by template modal component
 * @returns {JSX.Element}
 */

const TraderActionsModal = () => {
  const { id } = useParams();
  const {
    traders: { selectedTrader },
  } = useRootModel();

  const closeModal = () => {
    selectedTrader.isModal.setModal('');
  };

  return (
    <Modal
      title={selectedTrader?.isModal.name}
      onCancel={closeModal}
      visible={selectedTrader?.isModal.isOpen}
      bodyStyle={{ padding: 0 }}
      width={550}
      closeIcon={<CloseIcon />}
      footer={null}
    >
      {selectedTrader?.isModal.name === 'Block trader login' && (
        <BlockTraderActions action={selectedTrader?.isModal.name} id={id} closeModal={closeModal} />
      )}
      {selectedTrader?.isModal.name === 'Block trader trading' && (
        <BlockTraderActions action={selectedTrader?.isModal.name} id={id} closeModal={closeModal} />
      )}
      {selectedTrader?.isModal.name === 'Change sale status' && (
        <ChangeSaleStatusForm saleStatuses={saleStatuses} />
      )}
      {selectedTrader?.isModal.name === 'Change broker' && (
        <ChangeBrokerForm brokers={brokers} desks={desks} teamLeads={teamLeads} />
      )}
      {selectedTrader?.isModal.name === 'Change desk' && (
        <ChangeDeskForm teamLeads={teamLeads} desks={desks} />
      )}
      {selectedTrader?.isModal.name === 'Create Reminder' && <TraderReminderForm />}
      {selectedTrader?.isModal.name === 'Create call history' && <CallHistoryForm />}
      {selectedTrader?.isModal.name === 'Send email by template' && <SendEmailForm />}
    </Modal>
  );
};

export default observer(TraderActionsModal);
