import React from 'react';
import HeaderOptions from '../../components/HeaderOptions/HeaderOptions';
import DisplayOptions from '../../components/HeaderOptions/DisplayOptions/DisplayOptions';
import PositionsContent from '../../containers/PositionsContent';
import { useRootModel } from '../../models/RootStore';

/**
 * Represents Positions page
 * @returns {JSX.Element}
 */
const Positions = () => {
  const {
    positions: { displayOptions },
  } = useRootModel();
  return (
    <section className='page'>
      <div className='page__header'>
        <HeaderOptions>
          <DisplayOptions options={displayOptions} />
        </HeaderOptions>
      </div>
      <div className='page__content'>
        <PositionsContent />
      </div>
    </section>
  );
};

export default Positions;
