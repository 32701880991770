const systemAdmin = 'ROLE_SYSTEM_ADMIN';
const admin = 'ROLE_ADMIN';
const teamLead = 'ROLE_TEAM_LEAD';
const worker = 'ROLE_WORKER';

export default {
  systemAdmin,
  admin,
  teamLead,
  worker,
};
