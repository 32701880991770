import React, { useEffect } from 'react';
import numeral from 'numeral';
import { Link, useHistory } from 'react-router-dom';
import { Table, Typography } from 'antd';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useRootModel } from '../../../models/RootStore';

import dateFormatter from '../../../util/dateFormatter';
import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';
import displayOptionsFilter from '../../../util/displayOptionsFilter';

import PendingIcon from '../icons/PendingIcon';
import SuccessIcon from '../icons/SuccessIcon';
import DeclineIcon from '../icons/DeclineIcon';
import PhoneIcon from '../icons/PhoneIcon';

import style from './tables.module.less';

/**
 * Represents traders table
 * @returns {JSX.Element}
 */

const TradersTable = () => {
  const history = useHistory();
  const {
    traders: {
      pagination,
      displayOptions,
      isLoading,
      handleSorterChange,
      setSelectedRows,
      fetchTraders,
      traders,
    },
  } = useRootModel();
  useEffect(() => {
    fetchTraders();
  }, []);
  const onSelectChange = (selectedRowKeys, rows) => {
    setSelectedRows(rows);
  };
  const rowSelection = {
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };
  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handleSorterChange({ sortColumn: column, sortOrder });
  };
  const handleOnRow = (id) => {
    history.push(`/trader/${id}`);
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: true,
      sortOrder: pagination.sortColumn === 'id' && sortOrderToString(pagination.sortOrder),
      render: (id) => (
        <div className={style.linkCell}>
          <Link to={`/trader/${id}`} className={style.link}>
            #{id}
          </Link>
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      sorter: true,
      sortOrder: pagination.sortColumn === 'name' && sortOrderToString(pagination.sortOrder),
    },
    {
      className: `${style.column}`,
      title: 'Sale status',
      dataIndex: 'saleStatus',
      key: 'saleStatus',
      width: 210,
      sorter: true,
      sortOrder: pagination.sortColumn === 'saleStatus' && sortOrderToString(pagination.sortOrder),
      render: (saleStatus) => <div className={style.linkCell}>{saleStatus?.name}</div>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      sorter: true,
      sortOrder: pagination.sortColumn === 'email' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 158,
      sorter: true,
      sortOrder: pagination.sortColumn === 'phone' && sortOrderToString(pagination.sortOrder),
      render: (phone) => (
        <div>
          {phone && (
            <a className={style.link} href={`tel:${phone}`}>
              <span style={{ marginRight: '3px' }}>{phone}</span>
              <PhoneIcon />
            </a>
          )}
        </div>
      ),
    },
    {
      title: 'Documents',
      dataIndex: 'traderDocumentStatus',
      key: 'traderDocumentStatus',
      width: 140,
      sorter: true,
      sortOrder:
        pagination.sortColumn === 'traderDocumentStatus' && sortOrderToString(pagination.sortOrder),
      render: (documents) => (
        <div className={style.ticketStatus_wrapper}>
          {documents === 'MISSING' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__created}`}>
              <div className={style.ellipse} />
              <p className={`${style.ticketText} ${style.ticketText__created}`}>{documents}</p>
            </div>
          )}
          {documents === 'WAITING' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__pending}`}>
              <PendingIcon />
              <p className={`${style.ticketText} ${style.ticketText__pending}`}>{documents}</p>
            </div>
          )}
          {documents === 'APPROVED' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__approved}`}>
              <SuccessIcon />
              <p className={`${style.ticketText} ${style.ticketText__approved}`}>{documents}</p>
            </div>
          )}
          {documents === 'DECLINED' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__declined}`}>
              <DeclineIcon />
              <p className={`${style.ticketText} ${style.ticketText__declined}`}>{documents}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      key: 'campaign',
      width: 150,
      sorter: true,
      sortOrder: pagination.sortColumn === 'campaign' && sortOrderToString(pagination.sortOrder),
      render: (campaign) => <div className={style.linkCell}>{campaign?.name}</div>,
    },
    {
      title: 'Affiliate',
      dataIndex: 'affiliate',
      key: 'affiliate',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'affiliate' && sortOrderToString(pagination.sortOrder),
      render: (affiliate) => <div className={style.linkCell}>{affiliate?.name}</div>,
    },
    {
      title: 'Broker',
      dataIndex: 'broker',
      key: 'broker',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'broker' && sortOrderToString(pagination.sortOrder),
      render: (broker) => (
        <div className={style.linkCell}>
          <Link className={style.link} to='/traders'>
            {broker?.name}
          </Link>
        </div>
      ),
    },
    {
      title: 'Desk',
      dataIndex: 'desk',
      key: 'desk',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'desk' && sortOrderToString(pagination.sortOrder),
      render: (desk) => <div className={style.linkCell}>{desk?.name}</div>,
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'balance' && sortOrderToString(pagination.sortOrder),
      render: (balance) => (
        <div>
          <Typography.Text>{numeral(balance).format('0,0.00')}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Retention',
      dataIndex: 'retention',
      key: 'retention',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'retention' && sortOrderToString(pagination.sortOrder),
      // render: (retention) => (
      //   <div
      //     className={`${style.status} ${
      //       retention === 'OLD' ? style.status__danger : style.status__success
      //     }`}
      //   >
      //     {retention === 'OLD' ? (
      //       <Typography.Text className={style.text__danger}>Old</Typography.Text>
      //     ) : (
      //       <Typography.Text className={style.text__success}>New</Typography.Text>
      //     )}
      //   </div>
      // ),
    },
    {
      className: `${style.column}`,
      title: 'Last login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      width: 170,
      sorter: true,
      sortOrder: pagination.sortColumn === 'lastLogin' && sortOrderToString(pagination.sortOrder),
      render: (lastLogin) => <div>{lastLogin && dateFormatter(lastLogin)}</div>,
    },
  ];
  return (
    <>
      <Table
        onRow={(trader) => {
          return {
            onDoubleClick: () => {
              handleOnRow(trader.id);
            },
          };
        }}
        className={style.table}
        onChange={onPageChange}
        rowSelection={rowSelection}
        columns={displayOptionsFilter(displayOptions, columns)}
        loading={isLoading}
        dataSource={toJS(traders)}
        scroll={{ x: 'max-content', y: '620px' }}
        rowKey='id'
        pagination={false}
      />
    </>
  );
};

export default observer(TradersTable);
