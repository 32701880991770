import React from 'react';
import cn from 'classnames';
import { ReactComponent as ChatIcon } from '../../assets/image/notification-chat.svg';
import { ReactComponent as Calendar } from '../../assets/image/notification-calendar.svg';

import style from './style.module.less';

/**
 * Represents push notification component
 * @param {boolean} show - indicator to show a notification
 * @param {string} notification - notification text
 * @param {string} source - shows a source of a notification
 * @returns {JSX.Element}
 */

const PushNotification = ({ show, notification, source }) => {
  return (
    <div className={cn(style.pushContainer, { [style.pushContainer__visible]: show })}>
      <div
        className={cn(
          style.pushMessage,
          { [style.pushMessage__chat]: source === 'chat' },
          { [style.pushMessage__calendar]: source === 'calendar' },
        )}
      >
        {source === 'chat' ? (
          <div className={style.innerMessage}>
            <ChatIcon />
            <span className={style.text}>You have a {notification}</span>
          </div>
        ) : (
          <div className={style.innerMessage}>
            <Calendar />
            <span className={style.text}>You have an event {notification}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PushNotification;
