import React, { useState } from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react';
import style from './SelectedItems.module.less';
import AppDropdown from '../../ui/AppDropdown/AppDropdown';
import { useRootModel } from '../../../models/RootStore';

/**
 * Represents SelectedItems component
 * @returns {JSX.Element}
 */

const SelectedItems = () => {
  const [showMenu, setShowMenu] = useState(false);
  const {
    traders: {
      selectedRows,
      isBlockLogin,
      isUnblockLogin,
      isBlockTrading,
      isUnblockTrading,
      isSendNotification,
      isSendEmail,
      isChangeDesk,
      isChangeBroker,
      isChangeAffiliate,
      isChangeCampaign,
    },
  } = useRootModel();
  return (
    <>
      <Typography.Text type='secondary' style={{ width: '17px' }}>
        {selectedRows.length}
      </Typography.Text>
      <AppDropdown
        buttonTitle='Selected Items'
        menuClassName={style.menu}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      >
        {isBlockLogin && (
          <button type='button' key='Block trader login' className={style.item}>
            Block trader login
          </button>
        )}
        {isUnblockLogin && (
          <button type='button' key='Unblock trader login' className={style.item}>
            Unblock trader login
          </button>
        )}
        {isBlockTrading && (
          <button type='button' key='Block trader trading' className={style.item}>
            Block trader trading
          </button>
        )}
        {isUnblockTrading && (
          <button type='button' key='Unblock trader trading' className={style.item}>
            Unblock trader trading
          </button>
        )}
        {isSendNotification && (
          <button type='button' key='Send Push Notification' className={style.item}>
            Send Push Notification
          </button>
        )}
        {isSendEmail && (
          <button type='button' key='Send Email by Template' className={style.item}>
            Send Email by Template
          </button>
        )}
        {isChangeDesk && (
          <button type='button' key='Change desk' className={style.item}>
            Change desk
          </button>
        )}
        {isChangeBroker && (
          <button type='button' key='Change broker' className={style.item}>
            Change broker
          </button>
        )}
        {isChangeAffiliate && (
          <button type='button' key='Change affiliate' className={style.item}>
            Change affiliate
          </button>
        )}
        {isChangeCampaign && (
          <button type='button' key='Change Campaign' className={style.item}>
            Change Campaign
          </button>
        )}
      </AppDropdown>
    </>
  );
};

export default observer(SelectedItems);
