const tradersDefaultFilters = {
  balanceStatus: { field: '', value: null },
  email: { field: '', value: '' },
  saleStatus: { field: '', value: '' },
  broker: { field: '', value: '' },
  registrationCountry: { field: '', value: '' },
  online: { field: '', value: null },
  lastCommentDate: { field: '', value: '' },
  affiliate: { field: '', value: '' },
  name: { field: '', value: '' },
  phone: { field: '', value: '' },
  retentionStatus: { field: '', value: '' },
  country: { field: '', value: '' },
  registrationDate: { field: '', fromValue: '', toValue: '' },
  balance: { field: '', value: '' },
  desk: { field: '', value: '' },
  id: { field: '', value: '' },
  brokerAssign: { field: '', value: null },
  demo: { field: '', value: null },
  lastLoginDate: { field: '', fromValue: '', toValue: '' },
  ftdData: { field: '', value: '' },
  campaign: { field: '', value: '' },
};

export default tradersDefaultFilters;
