import React from 'react';

import style from './style.module.less';
import SupportMessageContent from '../../containers/SupportMessageContent';

/**
 * Represents Support message page
 * @returns {JSX.Element}
 */

const SupportMessage = () => {
  return (
    <section className={style.supportMessage}>
      <SupportMessageContent />
    </section>
  );
};

export default SupportMessage;
