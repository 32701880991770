import { types } from 'mobx-state-tree';
import FilterBoolItemModel from '../../commonModels/FilterBoolItemModel';
import FilterItemModel from '../../commonModels/FilterItemModel';
import FilterAmountItemModel from '../../commonModels/FilterAmountItemModel';
import FilterDateItemModel from '../../commonModels/FilterDateItemModel';

const TradersFiltersModel = types.model('TradersFiltersModel', {
  balanceStatus: types.optional(FilterBoolItemModel, {}),
  email: types.optional(FilterItemModel, {}),
  saleStatus: types.optional(FilterItemModel, {}),
  broker: types.optional(FilterItemModel, {}),
  registrationCountry: types.optional(FilterItemModel, {}),
  online: types.optional(FilterBoolItemModel, {}),
  lastCommentDate: types.optional(FilterDateItemModel, {}),
  affiliate: types.optional(FilterItemModel, {}),
  name: types.optional(FilterItemModel, {}),
  phone: types.optional(FilterItemModel, {}),
  retentionStatus: types.optional(FilterItemModel, {}),
  country: types.optional(FilterItemModel, {}),
  registrationDate: types.optional(FilterDateItemModel, {}),
  balance: types.optional(FilterAmountItemModel, {}),
  desk: types.optional(FilterItemModel, {}),
  id: types.optional(FilterItemModel, {}),
  brokerAssign: types.optional(FilterBoolItemModel, {}),
  demo: types.optional(FilterBoolItemModel, {}),
  lastLoginDate: types.optional(FilterDateItemModel, {}),
  ftdData: types.optional(FilterAmountItemModel, {}),
  campaign: types.optional(FilterItemModel, {}),
});

export default TradersFiltersModel;
