import { flow, getRoot, types } from 'mobx-state-tree';
import axios from 'axios';

import errorHandler from '../../util/errorHandler';
import PermissionsModel from '../commonModels/PermissionsModel';
import queryString from '../../util/queryString';

const { REACT_APP_API } = process.env;

const SettingsModel = types
  .model('SettingsModel', {
    settings: types.array(PermissionsModel),
    isLoading: types.optional(types.boolean, false),
    isModalOpen: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    getPermission: flow(function* getPermission(role) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const {
        user: { userData },
      } = getRoot(self);
      try {
        const { data } = yield axios.get(
          queryString(
            REACT_APP_API,
            `/api/backoffice/permissions?role=${role || userData?.authorities}`,
          ),
        );
        self.settings = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    updatePermission: flow(function* updatePermission() {
      const { settings } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const {
        user: { userData },
      } = getRoot(self);
      try {
        const { data } = yield axios.put(
          queryString(REACT_APP_API, `/api/backoffice/permissions`),
          {
            items: settings,
            role: userData?.authorities,
          },
        );
        self.settings = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    setIsModalOpen(value) {
      self.isModalOpen = value;
    },
  }))
  .views((self) => ({
    get dashboard() {
      const { settings } = self;
      return settings.filter((el) => el.category.includes('DASHBOARD_SECTION'));
    },
    get tickets() {
      const { settings } = self;
      return settings.filter((el) => el.category.includes('TICKETS_SECTION'));
    },
    get traders() {
      const { settings } = self;
      return settings.filter(
        (el) => el.category.includes('TRADERS_SECTION') || el.category.includes('TRADERS_PROFILE'),
      );
    },
    get workers() {
      const { settings } = self;
      return settings.filter(
        (el) => el.category.includes('WORKERS_SECTION') || el.category.includes('WORKERS_PROFILE'),
      );
    },
    get desks() {
      const { settings } = self;
      return settings.filter((el) => el.category.includes('DESKS_SECTION'));
    },
    get analytics() {
      const { settings } = self;
      return settings.filter((el) => el.category.includes('ANALYTICS_SECTION'));
    },
    get mailLayouts() {
      const { settings } = self;
      return settings.filter((el) => el.category.includes('MAIL_LAYOUTS_SECTION'));
    },
    get chat() {
      const { settings } = self;
      return settings.filter((el) => el.category.includes('CHAT_SECTION'));
    },
    get marketInfo() {
      const { settings } = self;
      return settings.filter((el) => el.category.includes('MARKET_INFO_SECTION'));
    },
    get support() {
      const { settings } = self;
      return settings.filter((el) => el.category.includes('SUPPORT_SECTION'));
    },
    get calendar() {
      const { settings } = self;
      return settings.filter((el) => el.category.includes('CALENDAR_SECTION'));
    },
  }));

export default SettingsModel;
