const ticketsTableOptions = [
  { title: 'ID', checked: true },
  { title: 'Amount', checked: true },
  { title: 'Trader', checked: true },
  { title: 'Status', checked: true },
  { title: 'Type', checked: true },
  { title: 'Opened by', checked: true },
  { title: 'Opened', checked: true },
  { title: 'Closed', checked: true },
];

export default ticketsTableOptions;
