import React from 'react';
import { Select, Typography } from 'antd';
import moment from 'moment';
import { ReactComponent as CalendarMini } from '../../../assets/image/calendar-mini.svg';
import style from './style.module.less';

/**
 * Represents dashboard header component
 * @param {string} role - user role
 * @returns {JSX.Element}
 */

const DashboardHeader = ({ role }) => {
  const teams = [
    { name: 'First Team', id: 1 },
    { name: 'Second Team', id: 2 },
    { name: 'Third Team', id: 3 },
  ];
  const workersList = [
    { name: 'Vlad Vlad', id: 1 },
    { name: 'Mykola Bumper', id: 2 },
    { name: 'Cliff Bush', id: 3 },
    { name: 'Michael Jackson', id: 4 },
  ];
  return (
    <div className={style.dashboardContentHeader}>
      <div className={style.dashboardContentHeader}>
        <Typography.Text className={style.title}>Dashboard</Typography.Text>
        {role === 'WORKER_SALES' || role === 'WORKER_RETENTION' ? (
          ''
        ) : (
          <div className={style.headerOptions}>
            <Select className={style.select} placeholder='Team name'>
              {teams.map((team) => (
                <Select.Option key={team?.id} value={team?.id}>
                  {team?.name}
                </Select.Option>
              ))}
            </Select>
            <Select className={style.select} placeholder='Worker name'>
              {workersList.map((worker) => (
                <Select.Option key={worker?.id} value={worker?.id}>
                  {worker?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
      </div>
      <div className={style.monthIndicator}>
        <Typography.Text>{moment().format('MMMM YYYY')}</Typography.Text>
        <CalendarMini />
      </div>
    </div>
  );
};

export default DashboardHeader;
