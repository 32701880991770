import moment from 'moment';

export const getLastTextTime = (lastTimeText) => {
  const date = moment(lastTimeText).format('DD-MM-YYYY');
  const day = moment(lastTimeText).format('dddd');

  const today = moment().format('DD-MM-YYYY');
  const yesterday = moment().subtract(1, 'day').format('DD-MM-YYYY');
  const twoDaysAgo = moment().subtract(2, 'day').format('dddd');
  const threeDaysAgo = moment().subtract(3, 'day').format('dddd');
  const fourDaysAgo = moment().subtract(4, 'day').format("dddd");
  const fiveDaysAgo = moment().subtract(5, 'day').format('dddd');
  const sixDaysAgo = moment().subtract(6, 'day').format('dddd');

  const todayToDisplay = moment(lastTimeText).format('HH:mm');
  const otherDatesToDisplay = moment(lastTimeText).format('DD.MM.YYYY');

  if (lastTimeText) {
    if (date === today) return todayToDisplay;
    if (date === yesterday) return 'Yesterday';
    if (day === twoDaysAgo) return twoDaysAgo;
    if (day === threeDaysAgo) return threeDaysAgo;
    if (day === fourDaysAgo) return fourDaysAgo;
    if (day === fiveDaysAgo) return fiveDaysAgo;
    if (day === sixDaysAgo) return sixDaysAgo;
    return otherDatesToDisplay;
  }
  return lastTimeText;
};
