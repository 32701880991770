import { getRoot, types } from 'mobx-state-tree';

const PermissionsModel = types
  .model('PermissionsModel', {
    category: types.array(types.string),
    item: types.maybeNull(types.string),
    read: types.maybeNull(types.boolean),
    write: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    updatePermission(field) {
      const {
        settings: { updatePermission },
      } = getRoot(self);
      if (field === 'read') self.read = !self.read;
      if (field === 'write') self.write = !self.write;
      updatePermission();
    },
  }));

export default PermissionsModel;
