import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import style from './style.module.less';
import permissionList from '../../constants/permissionList';
import getItemByValue from '../../util/getItemByValue';
import SettingsItem from './SettingsItem';
import RoleSelect from '../../components/HeaderOptions/RoleSelect';
import { ReactComponent as UserIcon } from '../../assets/image/user.svg';
import AddNewRoleModal from '../../components/common/modals/AddNewRoleModal';
import AddRoleBtn from '../../components/HeaderOptions/AddRoleBtn';
import userRoles from '../../constants/userRoles';

const SettingsContent = () => {
  const {
    settings: {
      getPermission,
      dashboard,
      tickets,
      traders,
      workers,
      desks,
      analytics,
      mailLayouts,
      chat,
      marketInfo,
      support,
      calendar,
      setIsModalOpen,
    },
    user: { userData },
  } = useRootModel();

  // TODO role changing

  const onRoleChange = (value) => {
    getPermission(value);
  };

  useEffect(() => {
    getPermission();
  }, []);

  return (
    <div className={style.settingsContentWrap}>
      <Row className={style.settingsHeader} align='middle'>
        <Col flex='80px'>
          <p>Settings</p>
        </Col>
        <Col flex='150px'>
          {userData?.authorities === userRoles.admin ? (
            <RoleSelect onChange={onRoleChange} role={userData.authorities} />
          ) : (
            <div className={style.role}>
              <UserIcon />
              <span>
                {userData?.authorities === userRoles.teamLead ? 'Team lead' : 'Worker (manager)'}
              </span>
            </div>
          )}
        </Col>
        {(userData?.authorities === userRoles.admin ||
          userData?.authorities === userRoles.systemAdmin) && (
          <Col flex='100px'>
            <AddRoleBtn setIsModalOpen={setIsModalOpen} />
          </Col>
        )}
      </Row>
      <div className={style.listWrapper}>
        <div className={style.list}>
          <ul className={style.listContainer}>
            {dashboard.map((el) => (
              <SettingsItem
                role={userData}
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
          <ul className={style.listContainer}>
            <p className={style.listHeading}>Tickets</p>
            {tickets.map((el) => (
              <SettingsItem
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
          <ul className={style.listContainer}>
            <p className={style.listHeading}>Desks</p>
            {desks.map((el) => (
              <SettingsItem
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
          <ul className={style.listContainer}>
            {analytics.map((el) => (
              <SettingsItem
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
          <ul className={style.listContainer}>
            <p className={style.listHeading}>Mail layouts</p>
            {mailLayouts.map((el) => (
              <SettingsItem
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
          <ul className={style.listContainer}>
            <p className={style.listHeading}>Chat</p>
            {chat.map((el) => (
              <SettingsItem
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
          <ul className={style.listContainer}>
            {marketInfo.map((el) => (
              <SettingsItem
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
          <ul className={style.listContainer}>
            {support.map((el) => (
              <SettingsItem
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
          <ul className={style.listContainer}>
            {calendar.map((el) => (
              <SettingsItem
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
        </div>
        <div className={style.list}>
          <ul className={style.listContainer}>
            <p className={style.listHeading}>Traders</p>
            {traders.map((el) => (
              <SettingsItem
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
        </div>
        <div className={style.list}>
          <ul className={style.listContainer}>
            <p className={style.listHeading}>Workers</p>
            {workers.map((el) => (
              <SettingsItem
                key={el.item}
                item={el}
                name={getItemByValue(permissionList, el?.item)?.value}
              />
            ))}
          </ul>
        </div>
      </div>
      <AddNewRoleModal />
    </div>
  );
};

export default observer(SettingsContent);
