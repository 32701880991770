import { types } from 'mobx-state-tree';

const LayoutModel = types.model('LayoutModel', {
  content: types.maybeNull(types.string),
  styles: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  language: types.maybeNull(types.string),
});

export default LayoutModel;
