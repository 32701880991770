import React from 'react';
import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import numeral from 'numeral';

import TraderCommunication from './TraderCommunication';
import TraderActivity from './TraderActivity';
import { ReactComponent as Envelope } from '../../../assets/image/envelop-colored.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/image/phone-colored.svg';

import dateFormatter from '../../../util/dateFormatter';
import style from './style.module.less';
import getItemByValue from '../../../util/getItemByValue';
import countryList from '../../../constants/countryList';

/**
 * Represents trader profile component
 * @param {object} trader - object with trader
 * @returns {JSX.Element}
 */

const TraderProfile = ({ trader }) => {
  const traderCountry = getItemByValue(countryList, trader?.countryCode);
  return (
    <div className={style.profileInfo}>
      <div className={style.profileInfoContainer}>
        <div className={style.profileInfoItemWrapper}>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Name:</Typography.Text>
            <Typography.Text className={style.coloredText}>{trader?.name}</Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Account Type:</Typography.Text>
            {trader.isDemo ? (
              <Typography.Text className={style.coloredText}>Real</Typography.Text>
            ) : (
              <Typography.Text className={style.coloredText}>Demo</Typography.Text>
            )}
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Currency:</Typography.Text>
            <Typography.Text className={style.coloredText}>{trader?.currency}</Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Timezone:</Typography.Text>
            <Typography.Text className={style.coloredText}>{trader?.timeZoneId}</Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Language:</Typography.Text>
            <Typography.Text className={style.coloredText}>{trader?.langKey}</Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Is Active:</Typography.Text>
            {trader?.isActive ? (
              <Typography.Text className={style.coloredText}>Yes</Typography.Text>
            ) : (
              <Typography.Text type='danger'>No</Typography.Text>
            )}
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Is Active Trading:</Typography.Text>
            {trader.activeTrading ? (
              <Typography.Text className={style.coloredText}>Yes</Typography.Text>
            ) : (
              <Typography.Text type='danger'>No</Typography.Text>
            )}
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Registration country:</Typography.Text>
            <Typography.Text>{trader?.registrationCountry}</Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Registered at:</Typography.Text>
            <Typography.Text>
              {trader.registrationDate && dateFormatter(trader?.registrationDate)}
            </Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Registration ip:</Typography.Text>
            <Typography.Text>{trader?.registrationIp}</Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Last login:</Typography.Text>
            <Typography.Text>
              {trader?.lastLogin && dateFormatter(trader?.lastLogin)}
            </Typography.Text>
          </div>
          <div className={cn(style.profileInfoItem, style.profileInfoItem__flex)}>
            <Typography.Text className={style.fieldName}>Email:</Typography.Text>
            <div className={cn(style.innerContentWrapper, style.flexContent)}>
              <Typography.Text className={style.coloredText}>{trader?.email}</Typography.Text>
              <Envelope />
            </div>
          </div>
          <div className={cn(style.profileInfoItem, style.profileInfoItem__flex)}>
            <Typography.Text className={style.fieldName}>Mobile:</Typography.Text>
            <div className={cn(style.innerContentWrapper, style.flexContent)}>
              <Typography.Text className={style.coloredText}>{trader?.phone}</Typography.Text>
              <PhoneIcon />
            </div>
          </div>
          <div className={cn(style.profileInfoItem, style.profileInfoItem__flex)}>
            <div className={cn(style.fieldName, style.profileInfoItem__flex)}>
              <Typography.Text className={style.leadCountry}>Lead</Typography.Text>
              <Typography.Text className={style.leadCountry}>Mobile:</Typography.Text>
            </div>
            <div className={cn(style.innerContentWrapper, style.flexContent)}>
              <Typography.Text className={style.coloredText}>{trader?.leadPhone}</Typography.Text>
              <PhoneIcon />
            </div>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>City:</Typography.Text>
            {trader.city ? (
              <Typography.Text className={style.coloredText}>{trader?.city}</Typography.Text>
            ) : (
              <Typography.Text type='danger'>Empty</Typography.Text>
            )}
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Country/Region:</Typography.Text>
            {trader.countryCode ? (
              <Typography.Text className={style.coloredText}>{traderCountry?.name}</Typography.Text>
            ) : (
              <Typography.Text type='danger'>Empty</Typography.Text>
            )}
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Lead Country:</Typography.Text>
            {trader.leadCountry ? (
              <Typography.Text className={style.coloredText}>{trader?.leadCountry}</Typography.Text>
            ) : (
              <Typography.Text type='danger'>Empty</Typography.Text>
            )}
          </div>
        </div>
        <div className={style.profileInfoItemWrapper}>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Balance:</Typography.Text>
            <Typography.Text>{numeral(trader?.balance).format('$0,0.00')}</Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>PnL:</Typography.Text>
            <Typography.Text>
              {numeral(trader.traderFinanceAttributes?.pnl).format('$0,0.00')}
            </Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>FTD amount:</Typography.Text>
            <Typography.Text>
              {numeral(trader?.traderFinanceAttributes?.ftd).format('$0,0.00')}
            </Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Total deposits:</Typography.Text>
            <Typography.Text>
              {numeral(trader?.traderFinanceAttributes?.deposits).format('$0,0.00')}
            </Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Total redeposits:</Typography.Text>
            <Typography.Text>
              {numeral(trader?.traderFinanceAttributes?.redeposits).format('$0,0.00')}
            </Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Total withdrawals:</Typography.Text>
            <Typography.Text>
              {numeral(trader?.traderFinanceAttributes?.withdrawals).format('$0,0.00')}
            </Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Net deposits:</Typography.Text>
            <Typography.Text>
              {numeral(trader?.traderFinanceAttributes?.netDeposits).format('$0,0.00')}
            </Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>CFD active margin:</Typography.Text>
            <Typography.Text>
              {numeral(trader?.traderFinanceAttributes?.cfdActiveMargin).format('$0,0.00')}
            </Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Sale status:</Typography.Text>
            <Typography.Text className={style.coloredText}>
              {trader.saleStatus?.type} {trader.saleStatus?.name}
            </Typography.Text>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Retention status:</Typography.Text>
            {trader.retentionStatus?.type ? (
              <Typography.Text className={style.coloredText}>
                {trader.retentionStatus?.type}
              </Typography.Text>
            ) : (
              <Typography.Text type='danger'>None</Typography.Text>
            )}
          </div>
          <div className={cn(style.profileInfoItem, style.profileInfoItem__flex)}>
            <Typography.Text className={style.fieldName}>Assigment Affiliate:</Typography.Text>
            <div className={style.innerContentWrapper}>
              <Typography.Text className={style.coloredText}>
                {trader.affiliate?.name}
              </Typography.Text>
            </div>
          </div>
          <div className={cn(style.profileInfoItem, style.profileInfoItem__flex)}>
            <Typography.Text className={style.fieldName}>Assigment Broker:</Typography.Text>
            <div className={style.innerContentWrapper}>
              <Typography.Text className={style.coloredText}>{trader.broker?.name}</Typography.Text>
            </div>
          </div>
          <div className={style.profileInfoItem}>
            <Typography.Text className={style.fieldName}>Desk:</Typography.Text>
            <Typography.Text className={style.coloredText}>{trader.desc?.name}</Typography.Text>
          </div>
        </div>
      </div>
      <div className={style.traderInteractionContainer}>
        <TraderCommunication />
        <TraderActivity />
      </div>
    </div>
  );
};

export default observer(TraderProfile);
