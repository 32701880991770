import React from 'react';
import DashboardContainer from '../../containers/DashboardContainer';
import { useRootModel } from '../../models/RootStore';
import Preloader from '../../components/common/Preloader';

/**
 * Represents Dashboard page
 * @returns {JSX.Element}
 */
const Dashboard = () => {
  const {
    traders: { isLoading },
  } = useRootModel();
  return (
    <section className='page'>
      <div className='page__content'>
        <DashboardContainer />
      </div>
      {isLoading && (
        <div className='page__preloader'>
          <Preloader size={60} />
        </div>
      )}
    </section>
  );
};

export default Dashboard;
