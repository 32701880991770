import React, { useEffect } from 'react';
import { Table, Typography } from 'antd';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import numeral from 'numeral';
import { useParams } from 'react-router-dom';
import { useRootModel } from '../../../models/RootStore';

import dateFormatter from '../../../util/dateFormatter';
import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';

import PendingIcon from '../icons/PendingIcon';
import SuccessIcon from '../icons/SuccessIcon';

import style from './tables.module.less';

/**
 * Represents trader [tickets] table
 * @returns {JSX.Element}
 */

const TraderTickets = () => {
  const {
    traders: {
      traderTickets,
      ticketPagination,
      isLoading,
      handleTicketPageChange,
      fetchTraderTickets,
    },
  } = useRootModel();
  const { id } = useParams();
  useEffect(() => {
    fetchTraderTickets(id);
  }, [ticketPagination]);
  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handleTicketPageChange(
      {
        page: page.current,
        size: page.pageSize,
        sortColumn: column,
        sortOrder,
      },
      id,
    );
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: true,
      sortOrder:
        ticketPagination.sortColumn === 'id' && sortOrderToString(ticketPagination.sortOrder),
      render: (ticketId) => (
        <div className={style.linkCell}>
          <Typography.Text className={style.link}>#{ticketId}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      sorter: true,
      sortOrder:
        ticketPagination.sortColumn === 'amount' && sortOrderToString(ticketPagination.sortOrder),
      render: (amount) => <div>USD {numeral(amount).format('0,0.00')}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: true,
      sortOrder:
        ticketPagination.sortColumn === 'status' && sortOrderToString(ticketPagination.sortOrder),
      render: (status) => (
        <div className={style.ticketStatus_wrapper}>
          {status === 'PENDING' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__pending}`}>
              <PendingIcon />
              <p className={`${style.ticketText} ${style.ticketText__pending}`}>{status}</p>
            </div>
          )}
          {status === 'APPROVED' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__approved}`}>
              <SuccessIcon />
              <p className={`${style.ticketText} ${style.ticketText__approved}`}>{status}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      sorter: true,
      sortOrder:
        ticketPagination.sortColumn === 'type' && sortOrderToString(ticketPagination.sortOrder),
    },
    {
      className: `${style.column}`,
      title: 'Opened by',
      dataIndex: 'openedBy',
      key: 'openedBy',
      width: 150,
      sorter: true,
      sortOrder:
        ticketPagination.sortColumn === 'openedBy' && sortOrderToString(ticketPagination.sortOrder),
      render: (openedBy) => (
        <div className={style.linkCell}>
          {!openedBy?.name ? (
            <Typography.Text>Self deposit</Typography.Text>
          ) : (
            <Typography.Text className={style.link}>{openedBy?.name}</Typography.Text>
          )}
        </div>
      ),
    },
    {
      className: `${style.column}`,
      title: 'Assigned to',
      dataIndex: 'assignee',
      key: 'assignee',
      width: 140,
      sorter: true,
      sortOrder:
        ticketPagination.sortColumn === 'assignee' && sortOrderToString(ticketPagination.sortOrder),
      render: (assignee) => (
        <div className={style.linkCell}>
          <Typography.Text className={style.link}>{assignee}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Opened',
      dataIndex: 'openedAt',
      key: 'openedAt',
      width: 150,
      sorter: true,
      sortOrder:
        ticketPagination.sortColumn === 'openedAt' && sortOrderToString(ticketPagination.sortOrder),
      render: (openedAt) => <div>{dateFormatter(openedAt)}</div>,
    },
    {
      title: 'Closed',
      dataIndex: 'closedAt',
      key: 'closedAt',
      width: 143,
      sorter: true,
      sortOrder:
        ticketPagination.sortColumn === 'closedAt' && sortOrderToString(ticketPagination.sortOrder),
      render: (closedAt) => (
        <>
          {closedAt === null ? (
            <Typography.Text type='danger'>Not closed yet</Typography.Text>
          ) : (
            dateFormatter(closedAt)
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        className={style.table}
        onChange={onPageChange}
        columns={columns}
        loading={isLoading}
        dataSource={toJS(traderTickets)}
        scroll={{ x: 'max-content', y: '620px' }}
        rowKey='id'
        pagination={{
          position: ['topRight'],
          showSizeChanger: true,
          current: ticketPagination.number + 1,
          total: ticketPagination.totalElements,
          defaultPageSize: ticketPagination.size,
        }}
      />
    </>
  );
};

export default observer(TraderTickets);
