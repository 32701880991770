import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import './style/main.less';
import { rootStore, RootStoreContext } from './models/RootStore';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

// Sentry settings for errors tracking
// eslint-disable-next-line no-unused-expressions
process.env.NODE_ENV !== 'production' &&
  process.env.NODE_ENV !== 'development' &&
  Sentry.init({
    dsn: 'https://01698f89386443de8c008bf89a96bafe@o983786.ingest.sentry.io/5939691',
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

ReactDOM.render(
  <BrowserRouter>
    <RootStoreContext.Provider value={rootStore}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </RootStoreContext.Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
