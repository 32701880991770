import { types } from 'mobx-state-tree';
import FilterItemModel from '../../commonModels/FilterItemModel';
import FilterBoolItemModel from '../../commonModels/FilterBoolItemModel';
import FilterDateItemModel from '../../commonModels/FilterDateItemModel';

const WorkersFiltersModel = types.model('WorkersFiltersModel', {
  username: types.optional(FilterItemModel, {}),
  name: types.optional(FilterItemModel, {}),
  email: types.optional(FilterItemModel, {}),
  language: types.optional(FilterItemModel, {}),
  role: types.optional(FilterItemModel, {}),
  desk: types.optional(FilterItemModel, {}),
  active: types.optional(FilterBoolItemModel, {}),
  date: types.optional(FilterDateItemModel, {}),
});

export default WorkersFiltersModel;
