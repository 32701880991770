import React, { useState } from 'react';
import { Col, Pagination, Row } from 'antd';
import { observer } from 'mobx-react';

import MarketInfoTable from '../../components/common/tables/MarketInfoTable';
import MarketInfoTabs from '../../components/MarketInfoTabs';

import style from './style.module.less';
import { useRootModel } from '../../models/RootStore';
import AppSearch from '../../components/ui/AppSearch';
import useDebounce from '../../hooks/useDebounce';

/**
 * Represents market info content component
 * @returns {JSX.Element}
 */

const MarketInfoContent = () => {
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 400);
  const {
    marketInfo: {
      fetchMarket,
      marketInfo,
      isLoading,
      pagination,
      handlePageChange,
      setEditing,
      submitEditing,
      updateMarketInfo,
      setCategory,
      handleSorterChange,
      setSearchString,
    },
  } = useRootModel();
  const handleTabs = (category) => {
    setCategory(category);
  };
  const onPageChange = (page, size) => {
    handlePageChange(page, size);
  };
  return (
    <div className={style.marketInfoContentWrap}>
      <Row className={style.marketInfoHeader} align='middle'>
        <Col span={3}>
          <p>Market Info</p>
        </Col>
        <Col span={3}>
          <AppSearch
            search={search}
            setSearch={setSearch}
            searchWidth={241}
            placeholder='Search for instrument...'
          />
        </Col>
        <Col span={18} className={style.options}>
          <Pagination
            onChange={onPageChange}
            current={pagination.number + 1}
            total={pagination.totalElements}
            showSizeChanger
            defaultPageSize={pagination.size}
          />
        </Col>
      </Row>
      <MarketInfoTabs onTabChange={handleTabs} />
      <MarketInfoTable
        fetchMarket={fetchMarket}
        debouncedSearchTerm={debouncedSearchTerm}
        setSearchString={setSearchString}
        marketInfo={marketInfo}
        isLoading={isLoading}
        pagination={pagination}
        handlePageChange={handlePageChange}
        setEditing={setEditing}
        submitEditing={submitEditing}
        updateMarketInfo={updateMarketInfo}
        handleSorterChange={handleSorterChange}
      />
    </div>
  );
};

export default observer(MarketInfoContent);
