import React, { useEffect, useState } from 'react';
import { Button, Form, Select } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';

/**
 * Represents item for navigation menu
 * @param {function} createNewChat - function to create a new chat
 * @param {function} closeModal - function to close a modal
 * @returns {JSX.Element}
 */

const CreateNewChatForm = ({ createNewChat, closeModal }) => {
  const {
    user: { userData },
    workers: { relatedTraders, fetchRelatedTraders },
  } = useRootModel();

  useEffect(() => {
    fetchRelatedTraders(userData?.id);
  }, []);

  const [trader, setTrader] = useState(null);
  const onSelectChange = (value) => {
    setTrader(value);
  };
  const onSubmit = () => {
    createNewChat(+trader, userData?.id);
    closeModal();
  };
  const validateMessages = {
    required: 'This field is required',
  };
  return (
    <div>
      <Form
        name='Create new chat'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
        requiredMark={false}
      >
        <Form.Item label='Trader' name='trader' value={trader} rules={[{ required: true }]}>
          <Select
            value={trader}
            showSearch
            placeholder='Select or start typing for search'
            optionFilterProp='children'
            onChange={onSelectChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {relatedTraders.map((item) => (
              <Select.Option key={item?.id} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit' type='primary'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(CreateNewChatForm);
