import { flow, getRoot, types } from 'mobx-state-tree';
import axios from 'axios';

import DeskModel from './models/DeskModel';
import AffiliateModel from '../commonModels/AffiliateModel';
import CampaignModel from '../commonModels/CampaignModel';
import PaginationModel from '../commonModels/PaginationModel';
import DisplayOptionsModel from '../commonModels/DisplayOptionsModel';
import DeskFiltersModel from './models/DeskFiltersModel';
import desksTableOptions from './models/desksTableOptions';
import desksDefaultFilters from './models/desksDefaultFilters';
import WorkersListModel from '../commonModels/WorkersListModel';

import errorHandler from '../../util/errorHandler';
import filterHandler from '../../util/filterHandler';
import PermissionsModel from '../commonModels/PermissionsModel';
import handleReadPermission from '../../util/handleReadPermission';
import queryString from '../../util/queryString';

const { REACT_APP_API } = process.env;

const DesksModel = types
  .model('DesksModel', {
    affiliates: types.array(AffiliateModel),
    desks: types.array(DeskModel),
    campaigns: types.array(CampaignModel),
    isLoading: types.optional(types.boolean, false),
    isModalOpen: types.optional(types.boolean, false),
    pagination: types.optional(PaginationModel, {}),
    filters: types.optional(DeskFiltersModel, {}),
    displayOptions: types.array(DisplayOptionsModel),
    workers: types.array(WorkersListModel),
    permissions: types.array(PermissionsModel),
  })
  .actions((self) => ({
    getPermission: flow(function* getPermission(permission) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const {
        user: { userData },
      } = getRoot(self);
      const params = {
        category: permission,
        role: userData?.authorities,
      };
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/permissions'),
          { params },
        );
        self.permissions = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchDesks: flow(function* fetchDesks() {
      const { pagination } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = {
        page: pagination.number,
        size: pagination.size,
        sort: `${pagination.sortColumn},${pagination?.sortOrder}`,
      };
      const filters = filterHandler(self?.filters);

      try {
        const { data } = yield axios.post(
          queryString(REACT_APP_API, 'api/backoffice/desks'),
          filters,
          { params },
        );
        self.desks = data.data;
        pagination.size = data.page.size;
        pagination.number = data.page.number;
        pagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    getDeskAffiliates: flow(function* getDeskAffiliates() {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, 'api/backoffice/affiliates/simplified'),
        );
        self.affiliates = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    getWorkers: flow(function* getWorkers() {
      self.isLoading = true;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/workers/simplified?active-status=ACTIVE'),
        );
        self.workers = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    getDeskCampaigns: flow(function* getDeskCampaigns() {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, 'api/backoffice/campaigns/simplified'),
        );
        self.campaigns = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    handlePageChange(page, size) {
      const { pagination, fetchDesks } = self;
      pagination.number = page - 1;
      pagination.size = size;
      fetchDesks();
    },
    handleSorterChange(sorter) {
      const { pagination, fetchDesks } = self;
      pagination.sortColumn = sorter.sortColumn;
      pagination.sortOrder = sorter.sortOrder;
      fetchDesks();
    },
    setIsModalOpen(isOpen) {
      self.isModalOpen = isOpen;
    },
    clearFilters() {
      self.filters = desksDefaultFilters;
    },
    afterCreate: () => {
      self.displayOptions = desksTableOptions;
    },
  }))
  .views((self) => ({
    get isAddDesk() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'DESKS_ADD_NEW');
    },
    get isDeskFilters() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'DESKS_FILTERS');
    },
    get isDeskDisplayOptions() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'DESKS_DISPLAY_OPTIONS');
    },
  }));

export default DesksModel;
