import React, { useEffect } from 'react';
import numeral from 'numeral';
import { Table, Typography } from 'antd';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import cn from 'classnames';
import { useRootModel } from '../../../models/RootStore';

import dateFormatter from '../../../util/dateFormatter';
import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';
import displayOptionsFilter from '../../../util/displayOptionsFilter';

import PendingIcon from '../icons/PendingIcon';

import style from './tables.module.less';

/**
 * Represents positions table
 * @returns {JSX.Element}
 */

const PositionsTable = () => {
  const {
    positions: {
      positions,
      isLoading,
      pagination,
      handleSorterChange,
      displayOptions,
      // fetchPositions,
    },
  } = useRootModel();
  useEffect(() => {
    // fetchPositions();
  }, []);
  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handleSorterChange({ sortColumn: column, sortOrder });
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: true,
      sortOrder: pagination.sortColumn === 'id' && sortOrderToString(pagination.sortOrder),
      render: (id) => (
        <div className={style.linkCell}>
          <Typography.Text className={style.link}>#{id}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Trading type',
      dataIndex: 'tradingType',
      key: 'tradingType',
      className: `${style.column}`,
      width: 210,
      sorter: true,
      sortOrder: pagination.sortColumn === 'tradingType' && sortOrderToString(pagination.sortOrder),
      render: (tradingType) => (
        <div className={style.status}>
          {tradingType ? (
            <Typography.Text className={style.text__success}>Buy</Typography.Text>
          ) : (
            <Typography.Text className={style.text__danger}>Sell</Typography.Text>
          )}
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      className: `${style.column}`,
      width: 210,
      sorter: true,
      sortOrder: pagination.sortColumn === 'type' && sortOrderToString(pagination.sortOrder),
      render: (type) => (
        <div className={style.ticketStatus_wrapper}>
          {type === 'PENDING' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__pending}`}>
              <PendingIcon />
              <p className={`${style.ticketText} ${style.ticketText__pending}`}>Pending</p>
            </div>
          )}
          {type === 'ACTIVE' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__approved}`}>
              <div className={cn(style.ellipse, style.ellipse__success)} />
              <p className={`${style.ticketText} ${style.ticketText__approved}`}>Active</p>
            </div>
          )}
          {type === 'CLOSED' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__created}`}>
              <div className={style.ellipse} />
              <p className={`${style.ticketText} ${style.ticketText__created}`}>Closed</p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Asset',
      dataIndex: 'asset',
      key: 'asset',
      width: 100,
      sorter: true,
      sortOrder: pagination.sortColumn === 'asset' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Opening date',
      dataIndex: 'openedAt',
      key: 'openedAt',
      className: `${style.column}`,
      width: 170,
      sorter: true,
      sortOrder: pagination.sortColumn === 'openedAt' && sortOrderToString(pagination.sortOrder),
      render: (openedAt) => <div>{dateFormatter(openedAt)}</div>,
    },
    {
      title: 'Opening price',
      dataIndex: 'openingPrice',
      key: 'openingPrice',
      className: `${style.column}`,
      width: 143,
      sorter: true,
      sortOrder:
        pagination.sortColumn === 'openingPrice' && sortOrderToString(pagination.sortOrder),
      render: (openingPrice) => <div>$ {numeral(openingPrice).format('0,0.00')}</div>,
    },
    {
      title: 'Used funds',
      dataIndex: 'usedFunds',
      key: 'usedFunds',
      className: `${style.column}`,
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'usedFunds' && sortOrderToString(pagination.sortOrder),
      render: (funds) => <div>$ {numeral(funds).format('0,0.00')}</div>,
    },
    {
      title: 'Results',
      dataIndex: 'results',
      key: 'results',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'results' && sortOrderToString(pagination.sortOrder),
      render: (results) => <div>$ {numeral(results).format('0,0.00')}</div>,
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'profit' && sortOrderToString(pagination.sortOrder),
      render: (profit) => <div>$ {numeral(profit).format('0,0.00')}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 180,
      sorter: true,
      sortOrder: pagination.sortColumn === 'status' && sortOrderToString(pagination.sortOrder),
      render: (status) => (
        <div className={style.ticketStatus_wrapper}>
          {status ? (
            <div className={`${style.ticketStatus} ${style.ticketStatus__created}`}>
              <div className={style.ellipse} />
              <p className={`${style.ticketText} ${style.ticketText__created}`}>Closed</p>
            </div>
          ) : (
            <div className={`${style.ticketStatus} ${style.ticketStatus__pending}`}>
              <PendingIcon />
              <p className={`${style.ticketText} ${style.ticketText__pending}`}>Not finished</p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Execution conditions',
      dataIndex: 'conditions',
      key: 'conditions',
      className: `${style.column}`,
      width: 180,
      sorter: true,
      sortOrder: pagination.sortColumn === 'conditions' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Profit limit',
      dataIndex: 'profitLimit',
      key: 'profitLimit',
      width: 143,
      className: `${style.column}`,
      sorter: true,
      sortOrder: pagination.sortColumn === 'profitLimit' && sortOrderToString(pagination.sortOrder),
      render: (profitLimit) => <div>$ {numeral(profitLimit).format('0,0.00')}</div>,
    },
    {
      title: 'Loss limit',
      dataIndex: 'lossLimit',
      key: 'lossLimit',
      className: `${style.column}`,
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'lossLimit' && sortOrderToString(pagination.sortOrder),
      render: (lossLimit) => <div>$ {numeral(lossLimit).format('0,0.00')}</div>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      sorter: true,
      sortOrder: pagination.sortColumn === 'amount' && sortOrderToString(pagination.sortOrder),
      render: (amount) => <div>$ {numeral(amount).format('0,0.00')}</div>,
    },
    {
      title: 'Closing date',
      dataIndex: 'closedAt',
      key: 'closedAt',
      className: `${style.column}`,
      width: 170,
      sorter: true,
      sortOrder: pagination.sortColumn === 'closedAt' && sortOrderToString(pagination.sortOrder),
      render: (closedAt) => (
        <>
          {closedAt === null ? (
            <Typography.Text type='danger'>Not closed yet</Typography.Text>
          ) : (
            dateFormatter(closedAt)
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        className={style.table}
        onChange={onPageChange}
        columns={displayOptionsFilter(displayOptions, columns)}
        loading={isLoading}
        dataSource={toJS(positions)}
        scroll={{ x: 'max-content', y: '620px' }}
        rowKey='id'
        pagination={false}
      />
    </>
  );
};

export default observer(PositionsTable);
