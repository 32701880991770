import React from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as CallHistoryIcon } from '../../../assets/image/bell.svg';
import AppButton from '../../ui/AppButton';
import { useRootModel } from '../../../models/RootStore';

/**
 * Represents CreateCallHistory component
 * @returns {JSX.Element}
 */
const CreateCallHistory = () => {
  const {
    traders: { selectedTrader },
  } = useRootModel();
  const handleModalOpen = () => {
    selectedTrader.isModal.setModal('Create call history');
  };
  return (
    <AppButton
      type='text'
      text='Create call history'
      icon={<CallHistoryIcon />}
      onClick={handleModalOpen}
    />
  );
};

export default observer(CreateCallHistory);
