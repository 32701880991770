import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import SingleMarketModel from './models/SingleMarketModel';
import PaginationModel from '../commonModels/PaginationModel';

import errorHandler from '../../util/errorHandler';
import queryString from '../../util/queryString';

const { REACT_APP_API } = process.env;

const MarketInfoModel = types
  .model('MarketInfoModel', {
    marketInfo: types.array(SingleMarketModel),
    isLoading: types.optional(types.boolean, false),
    pagination: types.optional(PaginationModel, {}),
    editingId: types.maybeNull(types.number),
    searchString: types.maybeNull(types.string),
  })
  .actions((self) => ({
    fetchMarket: flow(function* fetchMarket() {
      const { pagination, searchString } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      const params = {
        page: pagination.number,
        size: pagination.size,
        sort: `${pagination.sortColumn},${pagination?.sortOrder}`,
        category: pagination.category,
        instrument: searchString,
      };

      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/market/instruments/settings'),
          {
            params,
          },
        );
        self.marketInfo = data.data;
        pagination.size = data.page.size;
        pagination.number = data.page.number;
        pagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    updateMarketInfo: flow(function* updateMarketInfo(info) {
      const { editingId, marketInfo } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        const { data } = yield axios.put(
          queryString(REACT_APP_API, `/api/backoffice/market/instruments/${editingId}/settings`),
          info,
        );
        const index = marketInfo.findIndex((obj) => obj.id === data.id);
        marketInfo[index].maxMultiplier = data.maxMultiplier;
        marketInfo[index].overnightCommission = data.overnightCommission;
        marketInfo[index].swapBuyCommission = data.swapBuyCommission;
        marketInfo[index].swapSellCommission = data.swapSellCommission;
        marketInfo[index].isEditing = false;
        // self.marketInfo = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    setSearchString(value) {
      self.searchString = value;
    },
    handlePageChange(page, size) {
      const { pagination, fetchMarket } = self;
      pagination.number = page - 1;
      pagination.size = size;
      fetchMarket();
    },
    handleSorterChange(sorter) {
      const { pagination, fetchMarket } = self;
      pagination.sortColumn = sorter.sortColumn;
      pagination.sortOrder = sorter.sortOrder;
      fetchMarket();
    },
    setCategory(category) {
      const { fetchMarket, pagination } = self;
      pagination.category = category;
      fetchMarket();
    },
    setEditing(id) {
      self.editingId = id;
      const index = self.marketInfo.findIndex((obj) => obj.id === id);
      self.marketInfo[index].isEditing = !self.marketInfo[index].isEditing;
    },
  }));

export default MarketInfoModel;
