import React from 'react';
import { Select } from 'antd';
import { ReactComponent as UserIcon } from '../../../assets/image/user.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/arrow-down.svg';
import style from '../LanguageSelect/style.module.less';

const roles = [
  { id: 1, value: 'ROLE_ADMIN', name: 'Admin' },
  { id: 2, value: 'ROLE_TEAM_LEAD', name: 'Team lead' },
  { id: 3, value: 'ROLE_WORKER', name: 'Worker' },
];

const RoleSelect = ({ onChange, role }) => {
  return (
    <div className={style.languageSelect}>
      <UserIcon />
      <Select onChange={onChange} suffixIcon={<ArrowDown />} bordered={false} defaultValue={role}>
        {roles.map((el) => (
          <Select.Option value={el.value} key={el.id}>
            {el.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default RoleSelect;
