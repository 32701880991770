import React from 'react';
import { Modal } from 'antd';
import CloseIcon from '../icons/CloseIcon';
import CreateNewChatForm from '../forms/CreateNewChatForm';

/**
 * Represents item for navigation menu
 * @param {boolean} isCreateChat - indicator to open a modal
 * @param {function} setCreateChat - function to close a modal
 * @param {function} createNewChat - function to create a new chat
 * @returns {JSX.Element}
 */

const CreateChatModal = ({ isCreateChat, setCreateChat, createNewChat }) => {
  const closeModal = () => {
    setCreateChat(false);
  };
  return (
    <Modal
      title='Create new chat'
      visible={isCreateChat}
      onCancel={closeModal}
      closeIcon={<CloseIcon />}
      bodyStyle={{ padding: 0 }}
      footer={false}
      width={550}
    >
      <CreateNewChatForm createNewChat={createNewChat} closeModal={closeModal} />
    </Modal>
  );
};

export default CreateChatModal;
