import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import WorkersContent from '../../containers/WorkersContent/WorkersContent';
import AddNewWorkerModal from '../../components/common/modals/AddNewWorkerModal';
import DisplayOptions from '../../components/HeaderOptions/DisplayOptions/DisplayOptions';
import SelectedItems from '../../components/HeaderOptions/SelectedItems/SelectedItems';
import AddWorkerBtn from '../../components/HeaderOptions/AddWorkerBtn';
import HeaderOptions from '../../components/HeaderOptions/HeaderOptions';
import FilterOptions from '../../components/HeaderOptions/FilterOptions';

import FilterItem from '../../components/HeaderOptions/FilterOptions/FilterItem';
import filtersListData from '../../constants/filtersListData';
import FilterBoolItem from '../../components/HeaderOptions/FilterOptions/FilterBoolItem';
// eslint-disable-next-line max-len
import FilterCountrySelectItem from '../../components/HeaderOptions/FilterOptions/FilterCountrySelectItem';
import languageList from '../../constants/languageList';
import FilterDateItem from '../../components/HeaderOptions/FilterOptions/FilterDateItem';
import FilterByKeyItem from '../../components/HeaderOptions/FilterOptions/FilterByKeyItem';
import permission from '../../constants/permission';

/**
 * Represents Workers page
 * @returns {JSX.Element}
 */
const Workers = () => {
  const {
    workers: {
      displayOptions,
      selectedRows,
      filters,
      userRoles,
      userDesks,
      fetchWorkers,
      getPermission,
      isAddWorker,
      isFilters,
      isDisplayOptions,
    },
  } = useRootModel();

  useEffect(() => {
    getPermission(permission.WORKERS_SECTION);
  }, []);

  return (
    <section className='page'>
      <div className='page__header'>
        <HeaderOptions>
          {isFilters && (
            <FilterOptions apply={fetchWorkers}>
              <FilterItem
                filter={filters?.username}
                title='Username'
                placeholder='Enter here...'
                param='USERNAME'
              />
              <FilterItem
                filter={filters?.name}
                title='Search by name'
                placeholder='Enter here...'
                param='NAME'
              />
              <FilterItem
                filter={filters?.email}
                title='Email'
                placeholder='Enter here...'
                param='EMAIL'
              />
              <FilterCountrySelectItem
                filter={filters?.language}
                title='Language'
                placeholder='Select language'
                param='LANGUAGE'
                data={languageList}
              />
              <FilterByKeyItem
                filter={filters?.desk}
                data={userDesks}
                title='Filter by Desk'
                param='DESK'
                placeholder='Select desk'
              />
              <FilterByKeyItem
                filter={filters?.role}
                data={userRoles}
                title='Filter by Role'
                param='ROLE'
                placeholder='Select role'
              />
              <FilterBoolItem
                filter={filters?.active}
                title='Status'
                param='ACTIVE'
                data={filtersListData.statusData}
                compareString='Disabled'
                placeholder='Select status'
              />
              <FilterDateItem filter={filters?.date} title='Date' param='CREATED_DATE' />
            </FilterOptions>
          )}
          {isDisplayOptions && <DisplayOptions options={displayOptions} />}
          <SelectedItems selectedItems={selectedRows} />
          {isAddWorker && <AddWorkerBtn />}
        </HeaderOptions>
      </div>
      <div className='page__content'>
        <WorkersContent />
      </div>
      <AddNewWorkerModal />
    </section>
  );
};

export default observer(Workers);
