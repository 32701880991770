import React, { useState } from 'react';
import AppDropdown from '../../ui/AppDropdown/AppDropdown';
import { ReactComponent as DotsIcon } from '../../../assets/image/dots.svg';
import { useRootModel } from '../../../models/RootStore';

/**
 * Represents Actions component
 * @returns {JSX.Element}
 */

const Actions = ({ className }) => {
  const {
    traders: { selectedTrader, isBlockLogin, isBlockTrading, isChangeBroker, isChangeDesk },
  } = useRootModel();
  const [showMenu, setShowMenu] = useState(false);

  const handleTraderModal = (name) => {
    setShowMenu(false);
    selectedTrader?.isModal.setModal(name);
  };

  return (
    <AppDropdown
      buttonTitle='Actions'
      ButtonIcon={DotsIcon}
      showMenu={showMenu}
      setShowMenu={setShowMenu}
    >
      <div>
        {isBlockLogin && (
          <button
            className={className}
            onClick={() => handleTraderModal('Block trader login')}
            type='button'
            key='Block trader login'
          >
            Block trader login
          </button>
        )}
        {isBlockTrading && (
          <button
            className={className}
            onClick={() => handleTraderModal('Block trader trading')}
            type='button'
            key='Block trader trading'
          >
            Block trader trading
          </button>
        )}
        <button
          className={className}
          onClick={() => handleTraderModal('Change sale status')}
          type='button'
          key='Change sale status'
        >
          Change sale status
        </button>
        {isChangeBroker && (
          <button
            className={className}
            onClick={() => handleTraderModal('Change broker')}
            type='button'
            key='Change broker'
          >
            Block trader login
          </button>
        )}
        {isChangeDesk && (
          <button
            className={className}
            onClick={() => handleTraderModal('Change desk')}
            type='button'
            key='Change desk'
          >
            Block trader login
          </button>
        )}
      </div>
    </AppDropdown>
  );
};

export default Actions;
