import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import NotificationModel from './models/NotificationModel';
import errorHandler from '../../util/errorHandler';
import eventSource from '../../eventSource';
import queryString from '../../util/queryString';

const { REACT_APP_API, REACT_APP_NOTIFICATION_SERVICE } = process.env;

const NotificationsModel = types
  .model('NotificationsModel', {
    notifications: types.array(NotificationModel),
    isLoading: types.optional(types.boolean, false),
    isNotificationsModal: types.optional(types.boolean, false),
    sortDirection: types.optional(types.union(types.literal('asc'), types.literal('desc')), 'desc'),
    pageSize: types.optional(types.number, 5),
    page: types.optional(types.number, 1),
    totalNotifications: types.maybeNull(types.number),
    unReadNotifications: types.maybeNull(types.number),
  })
  .actions((store) => ({
    fetchNotifications: flow(function* fetchNotifications() {
      store.isLoading = true;
      if (!localStorage.crm_token) return;

      const params = {
        page: store.page - 1,
        size: store.pageSize,
        sort: `notificationDate,${store.sortDirection}`,
      };

      try {
        const { data, headers } = yield axios.get(
          queryString(REACT_APP_NOTIFICATION_SERVICE, '/services/notification/api/notifications'),
          {
            params,
          },
        );
        store.notifications = data;
        store.totalNotifications = parseInt(headers['x-total-count'], 10);
        store.unReadNotifications = parseInt(headers['x-total-unread-count'], 10);
      } catch (e) {
        errorHandler(store, e);
      } finally {
        store.isLoading = false;
      }
    }),
    fetchNotificationsUpdates() {
      const { incrementUnread } = store;
      try {
        const event = eventSource.createEventSourceNotifications();
        event.onmessage = (e) => {
          const data = JSON.parse(e.data);
          store?.merge(data);
          incrementUnread();
        };
      } catch (e) {
        errorHandler(store, e);
      }
    },
    setAllIsRead: flow(function* setAllIsRead() {
      const { decrementUnread } = store;
      store.isLoading = true;
      const filteredNotifications = store.notifications.filter((el) => el.isRead === false);
      try {
        yield axios.put(
          queryString(REACT_APP_NOTIFICATION_SERVICE, '/services/notification/api/notifications'),
          filteredNotifications,
        );
        store.notifications = store.notifications.map((i) => ({
          ...i,
          isRead: true,
        }));
        decrementUnread(filteredNotifications.length);
      } catch (e) {
        errorHandler(store, e);
      } finally {
        store.isLoading = false;
      }
    }),
    deleteNotification: flow(function* deleteNotification(notification) {
      const { decrementUnread } = store;
      store.isLoading = true;
      try {
        yield axios.delete(
          queryString(REACT_APP_NOTIFICATION_SERVICE, '/services/notification/api/notifications'),
          {
            data: notification,
          },
        );
        store.notifications = store.notifications.filter((i) => i.id !== notification[0].id);
        if (notification[0].isRead === false) decrementUnread(1);
      } catch (e) {
        errorHandler(store, e);
      } finally {
        store.isLoading = false;
      }
    }),
    createNotification: flow(function* createNotification() {
      store.isLoading = true;
      const newNotification = {
        message: 'My message',
        recipientType: 'SSE',
        subject: 'hello',
      };
      try {
        yield axios.post(
          queryString(REACT_APP_API, `/api/backoffice/traders/${11}/notifications`),
          newNotification,
        );
      } catch (e) {
        errorHandler(store, e);
      } finally {
        store.isLoading = false;
      }
    }),
    closeStream() {
      eventSource.closeStream();
    },
    setNotificationsModal(isOpen) {
      store.isNotificationsModal = isOpen;
    },
    merge(item) {
      store.notifications = [item, ...store.notifications];
    },
    decrementUnread(quantity) {
      store.unReadNotifications -= quantity;
    },
    incrementUnread() {
      store.unReadNotifications += 1;
    },
    switchSortDirection() {
      store.sortDirection = store.sortDirection === 'asc' ? 'desc' : 'asc';
    },
    setPage(page) {
      store.page = page;
    },
    setNextPage() {
      const { totalPages, page, setPage } = store;
      if (page < totalPages) setPage(page + 1);
    },
    setPreviousPage() {
      const { page, setPage } = store;
      if (page > 1) setPage(page - 1);
    },
  }))
  .views((store) => ({
    get isFirstPage() {
      const { page } = store;
      return page === 1;
    },
    get isLastPage() {
      const { totalPages, page } = store;
      return totalPages === page;
    },
    get totalPages() {
      const { totalNotifications, pageSize } = store;
      return Math.ceil(totalNotifications / pageSize);
    },
  }));

export default NotificationsModel;
