import { flow, getRoot, types } from 'mobx-state-tree';
import axios from 'axios';

import TraderModel from './models/TraderModel';
import PaginationModel from '../commonModels/PaginationModel';
import TraderTicketModel from './models/TraderTicketModel';
import DisplayOptionsModel from '../commonModels/DisplayOptionsModel';
import TradersFiltersModel from './models/TradersFiltersModel';
import SelectedRowModel from '../WorkersModel/models/SelectedRowModel';
import CommunicationModel from './models/CommunicationModel';
import ActivityModel from './models/ActivityModel';
import tradersDefaultFilters from './models/tradersDefaultFilters';
import tradersTableOptions from './models/tradersTableOptions';
import TraderBalanceHistoryModel from './models/TraderBalanceHistoryModel';
import PositionsModel from './models/PostionsModel';
import WorkersListModel from '../commonModels/WorkersListModel';
import PermissionsModel from '../commonModels/PermissionsModel';

import errorHandler from '../../util/errorHandler';
import filterHandler from '../../util/filterHandler';
import handleReadPermission from '../../util/handleReadPermission';
import queryString from '../../util/queryString';

const { REACT_APP_API, REACT_APP_NOTIFICATION_SERVICE } = process.env;

const TradersModel = types
  .model('TradersModel', {
    traders: types.array(TraderModel),
    isLoading: types.optional(types.boolean, false),
    pagination: types.optional(PaginationModel, {}),
    traderTickets: types.array(TraderTicketModel),
    ticketPagination: types.optional(PaginationModel, {}),
    traderBalanceHistory: types.array(TraderBalanceHistoryModel),
    balanceHistoryPagination: types.optional(PaginationModel, {}),
    filters: types.optional(TradersFiltersModel, {}),
    displayOptions: types.array(DisplayOptionsModel),
    selectedRows: types.array(SelectedRowModel),
    selectedTrader: types.optional(TraderModel, {}),
    comments: types.array(CommunicationModel),
    activities: types.array(ActivityModel),
    positions: types.array(PositionsModel),
    positionsPagination: types.optional(PaginationModel, {}),
    emailTemplates: types.array(WorkersListModel),
    affiliates: types.array(WorkersListModel),
    campaigns: types.array(WorkersListModel),
    permissions: types.array(PermissionsModel),
  })
  .actions((self) => ({
    getPermission: flow(function* getPermission(permission) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const {
        user: { userData },
      } = getRoot(self);
      const params = {
        category: permission,
        role: userData?.authorities,
      };
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/permissions'),
          { params },
        );
        self.permissions = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchTraders: flow(function* fetchTraders() {
      const { pagination } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = {
        page: pagination.number,
        size: pagination.size,
        sort: `${pagination.sortColumn},${pagination?.sortOrder}`,
      };
      const filters = filterHandler(self?.filters);

      try {
        const { data } = yield axios.post(
          queryString(REACT_APP_API, 'api/backoffice/traders'),
          filters,
          { params },
        );
        self.traders = data.data;
        pagination.size = data.page.size;
        pagination.number = data.page.number;
        pagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchSelectedTrader: flow(function* fetchSelectedTrader(id) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/profile`),
        );
        self.selectedTrader = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchComments: flow(function* fetchCommunication(id) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/communications`),
        );
        self.comments = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    addComment: flow(function* addComment(id, comment) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        const { data } = yield axios.post(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/comments`),
          comment,
        );
        self.comments = [data, ...self.comments];
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchActivities: flow(function* fetchActivities(id) {
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/notifications`),
        );
        self.activities = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchTraderTickets: flow(function* fetchTraderTickets(id) {
      const { ticketPagination } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = {
        page: ticketPagination.number,
        size: ticketPagination.size,
        sort: `${ticketPagination.sortColumn},${ticketPagination?.sortOrder}`,
      };

      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/tickets`),
          { params },
        );
        self.traderTickets = data.data;
        ticketPagination.size = data.page.size;
        ticketPagination.number = data.page.number;
        ticketPagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchTraderBalanceHistory: flow(function* fetchTraderBalanceHistory(id) {
      const { balanceHistoryPagination } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = {
        page: balanceHistoryPagination.number,
        size: balanceHistoryPagination.size,
        sort: `${balanceHistoryPagination.sortColumn},${balanceHistoryPagination?.sortOrder}`,
      };

      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/balance-history`),
          {
            params,
          },
        );
        self.traderBalanceHistory = data.data;
        balanceHistoryPagination.size = data.page.size;
        balanceHistoryPagination.number = data.page.number;
        balanceHistoryPagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchPositions: flow(function* fetchPositions(id) {
      const { positionsPagination } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = {
        page: positionsPagination.number,
        size: positionsPagination.size,
        sort: `${positionsPagination.sortColumn},${positionsPagination?.sortOrder}`,
      };

      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/positions`),
          { params },
        );
        self.positions = data.data;
        positionsPagination.size = data.page.size;
        positionsPagination.number = data.page.number;
        positionsPagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    addReminder: flow(function* addReminder(id, reminder) {
      const { fetchComments, selectedTrader } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        yield axios.post(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/reminder`),
          reminder,
        );
        fetchComments(id);
        selectedTrader.isModal.setModal('Create Reminder');
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    addCallHistory: flow(function* addCallHistory(id, callHistory) {
      const { fetchActivities, selectedTrader } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        yield axios.post(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/call-history`),
          callHistory,
        );
        fetchActivities(id);
        selectedTrader.isModal.setModal('Create call history');
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchEmailTemplates: flow(function* fetchEmailTemplates() {
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        const { data } = yield axios.get(
          queryString(
            REACT_APP_NOTIFICATION_SERVICE,
            '/services/notification/api/email-templates/simplified',
          ),
        );
        self.emailTemplates = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    sendEmail: flow(function* sendEmail(id, email) {
      const { selectedTrader } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        yield axios.post(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/notifications`),
          email,
        );
        selectedTrader.isModal.setModal('Send email by template');
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    setDocumentStatus: flow(function* setDocumentStatus(status, id) {
      const { fetchSelectedTrader } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        yield axios.put(
          queryString(REACT_APP_API, `/api/backoffice/traders/${id}/documents/status`),
          status,
        );
        fetchSelectedTrader(id);
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    getAffiliates: flow(function* getAffiliates() {
      self.isLoading = true;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/affiliates/simplified'),
        );
        self.affiliates = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    getCampaigns: flow(function* getCampaigns() {
      self.isLoading = true;
      try {
        const { data } = yield axios.get(
          queryString(REACT_APP_API, '/api/backoffice/campaigns/simplified'),
        );
        self.campaigns = data;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    handlePageChange(page, size) {
      const { pagination, fetchTraders } = self;
      pagination.number = page - 1;
      pagination.size = size;
      fetchTraders();
    },
    handleSorterChange(sorter) {
      const { pagination, fetchTraders } = self;
      pagination.sortColumn = sorter.sortColumn;
      pagination.sortOrder = sorter.sortOrder;
      fetchTraders();
    },
    handleTicketPageChange(pagination, id) {
      const { ticketPagination, fetchTraderTickets } = self;
      ticketPagination.number = pagination.page - 1;
      ticketPagination.size = pagination.size;
      ticketPagination.sortColumn = pagination.sortColumn;
      ticketPagination.sortOrder = pagination.sortOrder;
      fetchTraderTickets(id);
    },
    handleBalanceHistoryPageChange(pagination, id) {
      const { balanceHistoryPagination, fetchTraderBalanceHistory } = self;
      balanceHistoryPagination.number = pagination.page - 1;
      balanceHistoryPagination.size = pagination.size;
      balanceHistoryPagination.sortColumn = pagination.sortColumn;
      balanceHistoryPagination.sortOrder = pagination.sortOrder;
      fetchTraderBalanceHistory(id);
    },
    handlePositionsPageChange(pagination, id) {
      const { positionsPagination, fetchPositions } = self;
      positionsPagination.number = pagination.page - 1;
      positionsPagination.size = pagination.size;
      positionsPagination.sortColumn = pagination.sortColumn;
      positionsPagination.sortOrder = pagination.sortOrder;
      fetchPositions(id);
    },
    clearFilters() {
      self.filters = tradersDefaultFilters;
    },
    setSelectedRows(rows) {
      self.selectedRows = rows;
    },
    afterCreate: () => {
      self.displayOptions = tradersTableOptions;
    },
  }))
  .views((self) => ({
    get isFilters() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_FILTERS');
    },
    get isDisplayOptions() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_DISPLAY_OPTIONS');
    },
    get isBlockLogin() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_BLOCK_TRADER_LOGIN');
    },
    get isUnblockLogin() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_UNBLOCK_TRADER_LOGIN');
    },
    get isBlockTrading() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_BLOCK_TRADER_TRADING');
    },
    get isUnblockTrading() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_UNBLOCK_TRADER_TRADING');
    },
    get isSendNotification() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_SEND_PUSH_NOTIFICATION');
    },
    get isSendEmail() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_SEND_EMAIL_BY_TEMPLATE');
    },
    get isChangeDesk() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_CHANGE_DESK');
    },
    get isChangeBroker() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_CHANGE_BROKER');
    },
    get isChangeAffiliate() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_CHANGE_AFFILIATE');
    },
    get isChangeCampaign() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_CHANGE_CAMPAIGN');
    },
    get isCreateCallHistory() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_CREATE_CALL_HISTORY');
    },
    get isCreateReminder() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_CREATE_REMINDER');
    },
    get isProfile() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_PROFILE_INFO');
    },
    get isTickets() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_TICKETS');
    },
    get isPositions() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_POSITIONS');
    },
    get isBalanceHistory() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_BALANCE_HISTORY');
    },
    get isDocuments() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_DOCUMENTS');
    },
    get isCalendar() {
      const { permissions } = self;
      return handleReadPermission(permissions, 'TRADERS_CALENDAR');
    },
  }));
export default TradersModel;
