import React from 'react';
import HeaderOptions from '../../components/HeaderOptions/HeaderOptions';
import DisplayOptions from '../../components/HeaderOptions/DisplayOptions/DisplayOptions';
import { useRootModel } from '../../models/RootStore';
import LogsContent from '../../containers/LogsContent';

/**
 * Represents Logs page
 * @returns {JSX.Element}
 */
const Positions = () => {
  const {
    logs: { displayOptions },
  } = useRootModel();
  return (
    <section className='page'>
      <div className='page__header'>
        <HeaderOptions>
          <DisplayOptions options={displayOptions} />
        </HeaderOptions>
      </div>
      <div className='page__content'>
        <LogsContent />
      </div>
    </section>
  );
};

export default Positions;
