import React from 'react';
import LoginForm from '../../components/common/forms/LoginForm';

/**
 * @desc Auth sign in UI
 * @return {JSX.Element}
 */

const Login = () => (
  <>
    <LoginForm />
  </>
);

export default Login;
