import React from 'react';
import { Typography } from 'antd';
import { useRootModel } from '../../../../models/RootStore';

import { ReactComponent as Edit } from '../../../../assets/image/reminderInfo-edit.svg';
import { ReactComponent as Delete } from '../../../../assets/image/reminderInfo-bin.svg';
import CloseIcon from '../../icons/CloseIcon';

import style from '../forms.module.less';

/**
 * Represents reminder popover header
 * @param {Object} event - event object
 * @returns {JSX.Element}
 */
const RemindInfoHeader = ({ event }) => {
  const {
    calendar: { setIsEditModalOpen, setPopover, deleteEvent, setEditableEvent },
  } = useRootModel();
  const handleEditModal = () => {
    setPopover(event.id);
    setEditableEvent(event);
    setIsEditModalOpen(true);
  };
  const handlePopover = () => {
    setPopover(event.id);
  };
  const handleDelete = () => {
    deleteEvent(event.id);
  };
  return (
    <div className={style.reminderInfoHeader}>
      <Typography.Text className={style.title}>Reminder info</Typography.Text>
      <div className={style.iconsContainer}>
        <Edit className={style.icon} onClick={handleEditModal} />
        <Delete className={style.icon} onClick={handleDelete} />
        <CloseIcon className={style.icon} onClick={handlePopover} />
      </div>
    </div>
  );
};

export default RemindInfoHeader;
