import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const SuccessIconSvg = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M7 0C5.61553 0 4.26215 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.00303297 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0ZM11.5719 4.65062L5.82313 10.395L2.42813 7C2.3121 6.88397 2.24691 6.72659 2.24691 6.5625C2.24691 6.3984 2.3121 6.24103 2.42813 6.125C2.54416 6.00897 2.70153 5.94378 2.86563 5.94378C3.02972 5.94378 3.1871 6.00897 3.30313 6.125L5.83188 8.65375L10.7056 3.78437C10.7631 3.72692 10.8313 3.68135 10.9064 3.65025C10.9814 3.61916 11.0619 3.60316 11.1431 3.60316C11.2244 3.60316 11.3048 3.61916 11.3799 3.65025C11.455 3.68135 11.5232 3.72692 11.5806 3.78437C11.6381 3.84183 11.6837 3.91003 11.7147 3.9851C11.7458 4.06017 11.7618 4.14062 11.7618 4.22187C11.7618 4.30313 11.7458 4.38358 11.7147 4.45865C11.6837 4.53371 11.6381 4.60192 11.5806 4.65937L11.5719 4.65062Z'
      fill='#53A642'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const SuccessIcon = (props) => <Icon component={SuccessIconSvg} {...props} />;

export default SuccessIcon;
