import { types } from 'mobx-state-tree';

// const WorkerModel = types.model('WorkerModel', {
//   name: types.maybeNull(types.string),
// });

const ActivityModel = types.model('CommunicationModel', {
  id: types.maybeNull(types.number),
  deliveryDate: types.maybeNull(types.string),
  message: types.maybeNull(types.string),
  subject: types.maybeNull(types.string),
});

export default ActivityModel;
