import React from 'react';
import TraderBalanceHistoryTable from '../../../components/common/tables/TraderBalanceHistoryTable';

const TraderBalanceHistory = () => {
  return (
    <div>
      <TraderBalanceHistoryTable />
    </div>
  );
};

export default TraderBalanceHistory;
