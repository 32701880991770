import React, { useEffect, useState } from 'react';
import { getToken } from '../../init-fcm';
import { useRootModel } from '../../models/RootStore';

/**
 * Represents firebase notifications component to send a token
 * @returns {JSX.Element}
 */

const FirebaseNotifications = () => {
  const {
    user: { sendFirebaseToken },
  } = useRootModel();
  const [isTokenFound, setTokenFound] = useState(false);
  // eslint-disable-next-line no-console
  console.log('Token found', isTokenFound);
  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        sendFirebaseToken({ token: data });
      }
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);
  return <></>;
};
export default FirebaseNotifications;
