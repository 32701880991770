import React from 'react';
import cn from 'classnames';
import { Tooltip } from 'antd';
import style from './style.module.less';

/**
 * Represents dashboard progress bar
 * @returns {JSX.Element}
 */

const ProgressBar = () => {
  return (
    <>
      <div className={style.textProgressBar}>
        <span>Leads</span>
      </div>
      <div className={style.progressBar}>
        <div className={cn(style.filler, style.processing)}>
          <Tooltip title='25%'>
            <div className={cn(style.indicator, style.processing)}>
              <div className={style.indicatorInner} />
            </div>
          </Tooltip>
        </div>
        <div className={cn(style.filler, style.processed)}>
          <Tooltip title='25%'>
            <div className={cn(style.indicator, style.processed)}>
              <div className={style.indicatorInner} />
            </div>
          </Tooltip>
        </div>
        <div className={cn(style.filler, style.injectedMoney)}>
          <Tooltip title='25%'>
            <div className={cn(style.indicator, style.injectedMoney)}>
              <div className={style.indicatorInner} />
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
