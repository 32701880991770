import { types } from 'mobx-state-tree';

const MessageItemModel = types.model('MessageItemModel', {
  chatId: types.number,
  clientId: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  id: types.number,
  // imageUrl: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  operatorId: types.number,
  originalName: types.maybeNull(types.string),
  sender: types.maybeNull(types.number),
  size: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  uri: types.maybeNull(types.string),
  // viewedByOperator: types.maybeNull(types.boolean),
});

export default MessageItemModel;
