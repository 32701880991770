import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const DocumentsIconSvg = () => (
  <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M1.6615 13.25H9.53125C9.89306 13.25 10.1875 12.9556 10.1875 12.5938V4.28125C10.1875 4.27119 10.1831 4.26244 10.1818 4.25281C10.1806 4.24364 10.1787 4.23458 10.1761 4.22569C10.1676 4.1884 10.1491 4.1541 10.1227 4.12637L7.06069 1.06431C7.02599 1.03153 6.98165 1.01082 6.93425 1.00525C6.92506 1.00438 6.91631 1 6.90625 1H1.65887C1.29575 1 1 1.29444 1 1.65625V12.5938C1 12.9556 1.29662 13.25 1.6615 13.25ZM7.125 1.74681L9.44069 4.0625H7.34375C7.28573 4.0625 7.23009 4.03945 7.18907 3.99843C7.14805 3.95741 7.125 3.90177 7.125 3.84375V1.74681ZM1.4375 1.65625C1.4375 1.5355 1.53681 1.4375 1.65887 1.4375H6.6875V3.84375C6.6875 4.20556 6.98194 4.5 7.34375 4.5H9.75V12.5938C9.75 12.6518 9.72695 12.7074 9.68593 12.7484C9.64491 12.7895 9.58927 12.8125 9.53125 12.8125H1.6615C1.63243 12.8128 1.60357 12.8075 1.57658 12.7967C1.54959 12.7859 1.52499 12.7698 1.50419 12.7495C1.48339 12.7292 1.46679 12.705 1.45535 12.6783C1.44391 12.6515 1.43784 12.6228 1.4375 12.5938V1.65625Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M2.96872 6.25H8.21872C8.27674 6.25 8.33238 6.22695 8.3734 6.18593C8.41442 6.14491 8.43747 6.08927 8.43747 6.03125C8.43747 5.97323 8.41442 5.91759 8.3734 5.87657C8.33238 5.83555 8.27674 5.8125 8.21872 5.8125H2.96872C2.9107 5.8125 2.85506 5.83555 2.81404 5.87657C2.77302 5.91759 2.74997 5.97323 2.74997 6.03125C2.74997 6.08927 2.77302 6.14491 2.81404 6.18593C2.85506 6.22695 2.9107 6.25 2.96872 6.25Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M2.96872 8H8.21872C8.27674 8 8.33238 7.97695 8.3734 7.93593C8.41442 7.89491 8.43747 7.83927 8.43747 7.78125C8.43747 7.72323 8.41442 7.66759 8.3734 7.62657C8.33238 7.58555 8.27674 7.5625 8.21872 7.5625H2.96872C2.9107 7.5625 2.85506 7.58555 2.81404 7.62657C2.77302 7.66759 2.74997 7.72323 2.74997 7.78125C2.74997 7.83927 2.77302 7.89491 2.81404 7.93593C2.85506 7.97695 2.9107 8 2.96872 8Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M2.96872 4.5H5.15622C5.21424 4.5 5.26988 4.47695 5.3109 4.43593C5.35192 4.39491 5.37497 4.33927 5.37497 4.28125C5.37497 4.22323 5.35192 4.16759 5.3109 4.12657C5.26988 4.08555 5.21424 4.0625 5.15622 4.0625H2.96872C2.9107 4.0625 2.85506 4.08555 2.81404 4.12657C2.77302 4.16759 2.74997 4.22323 2.74997 4.28125C2.74997 4.33927 2.77302 4.39491 2.81404 4.43593C2.85506 4.47695 2.9107 4.5 2.96872 4.5Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M2.96872 9.75H8.21872C8.27674 9.75 8.33238 9.72695 8.3734 9.68593C8.41442 9.64491 8.43747 9.58927 8.43747 9.53125C8.43747 9.47323 8.41442 9.41759 8.3734 9.37657C8.33238 9.33555 8.27674 9.3125 8.21872 9.3125H2.96872C2.9107 9.3125 2.85506 9.33555 2.81404 9.37657C2.77302 9.41759 2.74997 9.47323 2.74997 9.53125C2.74997 9.58927 2.77302 9.64491 2.81404 9.68593C2.85506 9.72695 2.9107 9.75 2.96872 9.75Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M2.96872 11.5H8.21872C8.27674 11.5 8.33238 11.477 8.3734 11.4359C8.41442 11.3949 8.43747 11.3393 8.43747 11.2813C8.43747 11.2232 8.41442 11.1676 8.3734 11.1266C8.33238 11.0855 8.27674 11.0625 8.21872 11.0625H2.96872C2.9107 11.0625 2.85506 11.0855 2.81404 11.1266C2.77302 11.1676 2.74997 11.2232 2.74997 11.2813C2.74997 11.3393 2.77302 11.3949 2.81404 11.4359C2.85506 11.477 2.9107 11.5 2.96872 11.5Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M10.4063 3.1875H12.375V5.59375C12.375 5.95556 12.6695 6.25 13.0313 6.25H15.4375V14.3438C15.4375 14.4018 15.4145 14.4574 15.3735 14.4984C15.3324 14.5395 15.2768 14.5625 15.2188 14.5625H7.34903C7.31996 14.5628 7.2911 14.5575 7.26411 14.5467C7.23712 14.5359 7.21252 14.5198 7.19172 14.4995C7.17092 14.4792 7.15433 14.455 7.14288 14.4283C7.13144 14.4015 7.12537 14.3728 7.12503 14.3438V13.9062C7.12503 13.8482 7.10198 13.7926 7.06096 13.7516C7.01994 13.7105 6.9643 13.6875 6.90628 13.6875C6.84826 13.6875 6.79262 13.7105 6.7516 13.7516C6.71058 13.7926 6.68753 13.8482 6.68753 13.9062V14.3438C6.68753 14.7056 6.98416 15 7.34903 15H15.2188C15.5806 15 15.875 14.7056 15.875 14.3438V6.03125C15.875 6.02119 15.8707 6.01244 15.8693 6.00281C15.8681 5.99364 15.8662 5.98458 15.8637 5.97569C15.8551 5.9384 15.8366 5.9041 15.8103 5.87637L12.7482 2.81431C12.7138 2.78126 12.6693 2.76063 12.6218 2.75569C12.6126 2.75438 12.6038 2.75 12.5938 2.75H10.4063C10.3483 2.75 10.2926 2.77305 10.2516 2.81407C10.2106 2.85509 10.1875 2.91073 10.1875 2.96875C10.1875 3.02677 10.2106 3.08241 10.2516 3.12343C10.2926 3.16445 10.3483 3.1875 10.4063 3.1875ZM13.0313 5.8125C12.9733 5.8125 12.9176 5.78945 12.8766 5.74843C12.8356 5.70741 12.8125 5.65177 12.8125 5.59375V3.49681L15.1282 5.8125H13.0313Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M11.2812 8H13.9062C13.9643 8 14.0199 7.97695 14.0609 7.93593C14.102 7.89491 14.125 7.83927 14.125 7.78125C14.125 7.72323 14.102 7.66759 14.0609 7.62657C14.0199 7.58555 13.9643 7.5625 13.9062 7.5625H11.2812C11.2232 7.5625 11.1676 7.58555 11.1266 7.62657C11.0855 7.66759 11.0625 7.72323 11.0625 7.78125C11.0625 7.83927 11.0855 7.89491 11.1266 7.93593C11.1676 7.97695 11.2232 8 11.2812 8Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M11.2812 9.75H13.9062C13.9643 9.75 14.0199 9.72695 14.0609 9.68593C14.102 9.64491 14.125 9.58927 14.125 9.53125C14.125 9.47323 14.102 9.41759 14.0609 9.37657C14.0199 9.33555 13.9643 9.3125 13.9062 9.3125H11.2812C11.2232 9.3125 11.1676 9.33555 11.1266 9.37657C11.0855 9.41759 11.0625 9.47323 11.0625 9.53125C11.0625 9.58927 11.0855 9.64491 11.1266 9.68593C11.1676 9.72695 11.2232 9.75 11.2812 9.75Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M11.2812 11.5H13.9062C13.9643 11.5 14.0199 11.477 14.0609 11.4359C14.102 11.3949 14.125 11.3393 14.125 11.2813C14.125 11.2232 14.102 11.1676 14.0609 11.1266C14.0199 11.0855 13.9643 11.0625 13.9062 11.0625H11.2812C11.2232 11.0625 11.1676 11.0855 11.1266 11.1266C11.0855 11.1676 11.0625 11.2232 11.0625 11.2813C11.0625 11.3393 11.0855 11.3949 11.1266 11.4359C11.1676 11.477 11.2232 11.5 11.2812 11.5Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
    <path
      /* eslint-disable-next-line max-len */
      d='M11.2812 13.2501H13.9062C13.9643 13.2501 14.0199 13.227 14.0609 13.186C14.102 13.145 14.125 13.0893 14.125 13.0313C14.125 12.9733 14.102 12.9177 14.0609 12.8766C14.0199 12.8356 13.9643 12.8126 13.9062 12.8126H11.2812C11.2232 12.8126 11.1676 12.8356 11.1266 12.8766C11.0855 12.9177 11.0625 12.9733 11.0625 13.0313C11.0625 13.0893 11.0855 13.145 11.1266 13.186C11.1676 13.227 11.2232 13.2501 11.2812 13.2501Z'
      fill='#9A9AC0'
      stroke='#9A9AC0'
      strokeWidth='0.3'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const DocumentsIcon = (props) => <Icon component={DocumentsIconSvg} {...props} />;

export default DocumentsIcon;
