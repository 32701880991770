import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';
import Preloader from '../Preloader';

/**
 * Represents add new desk form
 * @returns {JSX.Element}
 */

const AddNewDeskForm = () => {
  const {
    desks: {
      desks,
      addNewDesk,
      isLoading,
      affiliates,
      campaigns,
      workers,
      getDeskAffiliates,
      getDeskCampaigns,
      getWorkers,
    },
  } = useRootModel();

  useEffect(() => {
    getDeskAffiliates();
    getDeskCampaigns();
    getWorkers();
  }, [desks]);

  const [desk, setValues] = useState({
    name: '',
    teamLead: { id: null, name: '' },
    affiliate: { id: null, name: '' },
    campaign: { id: null, name: '' },
    broker: { id: null, name: '' },
  });

  const handleChange = (event, prop) => {
    setValues({ ...desk, [prop]: event.target.value });
  };

  const onSelectChange = (value, prop) => {
    setValues({ ...desk, [prop]: value });
  };

  const onSubmit = () => {
    addNewDesk(desk);
  };

  const validateMessages = {
    required: 'This field is required',
  };

  return (
    <div>
      <Form
        name='Add Desk'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
      >
        <Form.Item
          label='Desk name'
          name='name'
          value={desk.deskName}
          onChange={(e) => handleChange(e, 'name')}
          rules={[{ required: true }]}
        >
          <Input size='large' placeholder='Enter name for a new desk' maxLength={100} />
        </Form.Item>
        <Form.Item
          label='Team Lead'
          name='teamLead'
          value={desk.teamLead}
          rules={[{ required: true }]}
        >
          <Select
            value={desk.teamLead}
            placeholder='Select Team Lead for a new desk...'
            onChange={(value) => onSelectChange(value, 'teamLead')}
          >
            <Select.Option value='fff' key='fff'>
              Johny
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Affiliate'
          name='affiliate'
          value={desk.affiliate}
          rules={[{ required: true }]}
        >
          <Select
            disabled={desk.campaign.name && true}
            value={desk.affiliate.name}
            placeholder='Select Affiliate for a new desk...'
            onChange={(value, data) =>
              onSelectChange({ ...desk.affiliate, name: value, id: Number(data.key) }, 'affiliate')
            }
          >
            {affiliates.map((affiliate) => (
              <Select.Option value={affiliate.name} key={affiliate.id}>
                {affiliate.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Campaign'
          name='campaign'
          value={desk.campaign}
          rules={[{ required: true }]}
        >
          <Select
            disabled={desk.affiliate.name && true}
            value={desk.campaign.name}
            placeholder='Select Campaign for a new desk...'
            onChange={(value, data) =>
              onSelectChange({ ...desk.campaign, name: value, id: Number(data.key) }, 'campaign')
            }
          >
            {campaigns.map((campaign) => (
              <Select.Option value={campaign.name} key={campaign.id}>
                {campaign.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Choose broker'
          name='broker'
          value={desk.broker.name}
          rules={[{ required: true }]}
        >
          <Select
            value={desk.broker.name}
            placeholder='Select role...'
            onChange={(value, data) =>
              onSelectChange({ ...desk.broker, name: value, id: Number(data.key) }, 'broker')
            }
          >
            {workers.map((worker) => (
              <Select.Option value={worker.name} key={worker.id}>
                {worker.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          {isLoading ? (
            <Preloader />
          ) : (
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(AddNewDeskForm);
