import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const InfoIconSvg = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333Z'
      stroke='white'
      strokeWidth='2'
    />
    <path d='M9.58301 5.83325H9.99967' stroke='white' strokeWidth='2' strokeLinecap='round' />
    <path
      d='M8.33301 9.16663H9.99967V13.3333'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.33301 13.3333H11.6663'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const InfoIcon = (props) => <Icon component={InfoIconSvg} {...props} />;

export default InfoIcon;
