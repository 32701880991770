import React from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrowLeftOutlined } from '@ant-design/icons';
import LoginAsIcon from '../../common/icons/LoginAsIcon';

import style from './style.module.less';

/**
 * Represents Actions component
 * @param {string} link - link
 * @param {string} text - link text
 * @returns {JSX.Element}
 */

const CustomLink = ({ link, text }) => {
  return (
    <Link to={link} className={style.link}>
      {link === 'workers' ? <ArrowLeftOutlined /> : <LoginAsIcon />}
      <span className={style.link_text}>{text}</span>
    </Link>
  );
};

export default CustomLink;
