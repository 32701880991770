import React from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrowLeftOutlined } from '@ant-design/icons';
import SupportMessageItem from './SupportMessageItem';

import style from './style.module.less';

/**
 * Represents support message content component
 * @returns {JSX.Element}
 */

const SupportMessageContent = () => {
  return (
    <div className={style.supportContentWrap}>
      <Row className={style.supportHeader} align='middle'>
        <Col span={6}>
          <Link to='/support' className={style.link}>
            <ArrowLeftOutlined />
            <span className={style.link_text}>All Messages</span>
          </Link>
        </Col>
      </Row>
      <SupportMessageItem />
    </div>
  );
};

export default SupportMessageContent;
