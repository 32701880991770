import React from 'react';
import cn from 'classnames';
import DashboardHeader from './DashboardHeader';
import DashboardCard from './DashboardCard';
import DashboardCardCompletion from './DashboardCardCompletion';
import DashboardChart from './DashboardChart';
import DashboardFooter from './DashboardFooter';
import { ReactComponent as DollarHand } from '../../assets/image/hand-holding-usd.svg';
import { ReactComponent as ArrowUp } from '../../assets/image/arrow-to-top.svg';
import { ReactComponent as ArrowDown } from '../../assets/image/arrow-to-bottom.svg';
import { ReactComponent as FileChart } from '../../assets/image/file-chart-line.svg';

import style from './style.module.less';

/**
 * Represents dashboard page
 * @returns {JSX.Element}
 */

const DashboardContainer = () => {
  const data = 'ADMIN';
  const getAuth = (role) => {
    if (role === 'WORKER_SALES' || role === 'TEAMLEAD_SALES') return false;
    return role;
  };
  const role = getAuth(data);
  return (
    <>
      <DashboardHeader role={data} />
      <div className={style.headerBody}>
        <div
          className={cn(style.cardsContainer, {
            [style.sales]: !role,
          })}
        >
          <DashboardCardCompletion statusPercent={78} statusText='Almost Done' />
          <DashboardCard
            icon={<DollarHand />}
            indicator='up'
            indicatorAmount={9}
            title='Total Balance'
            amount={245678}
          />
          {role && (
            <>
              <DashboardCard
                icon={<ArrowUp />}
                indicator='down'
                indicatorAmount={2}
                title='Total Withdraw funds'
                amount={1594930}
              />
              <DashboardCard
                icon={<ArrowDown />}
                indicator='up'
                amount={1594930}
                indicatorAmount={4}
                title='Total Revenue'
              />
            </>
          )}
        </div>
        <DashboardChart role={role} icon={<FileChart />} amount={1594930} />
      </div>
      <DashboardFooter />
    </>
  );
};

export default DashboardContainer;
