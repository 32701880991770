/**
 * Represents list of timezones
 * @returns {array}
 */

const timeZoneList = [
  { key: 'GMT', value: 'GMT Greenwich Mean Time', id: 1 },
  { key: 'UTC', value: 'GMT Universal Coordinated Time', id: 2 },
  { key: 'ECT', value: 'GMT +1:00 European Central Time', id: 3 },
  { key: 'EET', value: 'GMT +2:00 Eastern European Time', id: 4 },
  { key: 'ART', value: 'GMT +2:00 (Arabic) Egypt Standard Time', id: 5 },
  { key: 'EAT', value: 'GMT +3:00 Eastern African Time', id: 6 },
  { key: 'MET', value: 'GMT +3:30 Middle East Time', id: 7 },
  { key: 'NET', value: 'GMT +4:00 Near East Time', id: 8 },
  { key: 'PLT', value: 'GMT +5:00 Pakistan Lahore Time', id: 9 },
  { key: 'IST', value: 'GMT +5:30 India Standard Time', id: 10 },
  { key: 'BST', value: 'GMT +6:00 Bangladesh Standard Time', id: 11 },
  { key: 'VST', value: 'GMT +7:00 Vietnam Standard Time', id: 12 },
  { key: 'CTT', value: 'GMT +8:00 China Taiwan Time', id: 13 },
  { key: 'JST', value: 'GMT +9:00 Japan Standard Time', id: 14 },
  { key: 'ACT', value: 'GMT +9:30 Australia Central Time', id: 15 },
  { key: 'AET', value: 'GMT +10:00 Australia Eastern Time', id: 16 },
  { key: 'SST', value: 'GMT +11:00 Solomon Standard Time', id: 17 },
  { key: 'NST', value: 'GMT +12:00 New Zealand Standard Time', id: 18 },
  { key: 'MIT', value: 'GMT -11:00 Midway Islands Time', id: 19 },
  { key: 'HST', value: 'GMT -10:00 Hawaii Standard Time', id: 20 },
  { key: 'AST', value: 'GMT -9:00 Alaska Standard Time', id: 21 },
  { key: 'PST', value: 'GMT -8:00 Pacific Standard Time', id: 22 },
  { key: 'PNT', value: 'GMT -7:00 Phoenix Standard Time', id: 23 },
  { key: 'MST', value: 'GMT -7:00 Mountain Standard Time', id: 24 },
  { key: 'CST', value: 'GMT -6:00 Central Standard Time', id: 25 },
  { key: 'EST', value: 'GMT -5:00 Eastern Standard Time', id: 26 },
  { key: 'IET', value: 'GMT -5:00 Indiana Eastern Standard Time', id: 27 },
  { key: 'PRT', value: 'GMT -4:00 Puerto Rico and US Virgin Islands Time', id: 28 },
  { key: 'CNT', value: 'GMT -3:30 Canada Newfoundland Time', id: 29 },
  { key: 'AGT', value: 'GMT -3:00 Argentina Standard Time', id: 30 },
  { key: 'BET', value: 'GMT -3:00 Brazil Eastern Time', id: 31 },
  { key: 'CAT', value: 'GMT -1:00 Central African Time', id: 32 },
];

export default timeZoneList;
