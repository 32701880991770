import { types } from 'mobx-state-tree';

const PositionModel = types
  .model('PositionModel', {
    amount: types.maybeNull(types.number),
    asset: types.maybeNull(types.string),
    conditions: types.maybeNull(types.string),
    closedAt: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    lossLimit: types.maybeNull(types.number),
    openedAt: types.maybeNull(types.string),
    openingPrice: types.maybeNull(types.number),
    profit: types.maybeNull(types.number),
    profitLimit: types.maybeNull(types.number),
    results: types.maybeNull(types.number),
    status: types.maybeNull(types.boolean),
    tradingType: types.maybeNull(types.boolean),
    type: types.maybeNull(types.string),
    usedFunds: types.maybeNull(types.number),
  })
  .actions((store) => ({
    setPopover() {
      store.popover = !store.popover;
    },
  }));

export default PositionModel;
