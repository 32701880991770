import React from 'react';
import { observer } from 'mobx-react';
// import Preloader from '../../Preloader';

import { Button } from 'antd';
import cn from 'classnames';
import style from '../forms.module.less';

/**
 * Represents block trader actions
 * @param {string} action - title of an action
 * @param {number} id - trader's id
 * @param {function} closeModal - function to close modal
 * @returns {JSX.Element}
 */

const BlockTraderActions = ({ action, id, closeModal }) => {
  return (
    <div className={style.blockTraderContainer}>
      <div className={style.textWrapper}>
        <div>
          <span>Are you sure you want to block trader </span>
          <span className={style.traderId}>{id} </span>
          <span>{action === 'Block trader login' ? 'login' : 'trading'}?</span>
        </div>
        <span>This action cannot be reversed.</span>
      </div>
      <div className={style.buttonsWrapper}>
        <Button onClick={closeModal} className={cn(style.btn, style.cancelBtn)}>
          Cancel
        </Button>
        <Button className={style.btn} type='primary'>
          Block
        </Button>
      </div>
    </div>
  );
};

export default observer(BlockTraderActions);
