import React from 'react';
import { Typography } from 'antd';
import DocumentCard from '../DocumentCard';

import style from '../style.module.less';

/**
 * Represents trader's documents for approve
 * @param {object} firstPage - first page of the document
 * @param {object} secondPage - second page of the document
 * @param {number} selectedTrader - selected trader id
 * @param {function} setDocumentStatus - api for setting document's status
 * @returns {JSX.Element}
 */

const PendingDocs = ({ firstPage, secondPage, setDocumentStatus, selectedTrader }) => {
  return (
    <div className={style.docsContainer}>
      <div>
        <Typography.Text>Documents (need approve)</Typography.Text>
      </div>
      {firstPage.status === 'IN_PROCESSING' || secondPage.status === 'IN_PROCESSING' ? (
        <div className={style.cardsContainer}>
          <>
            {firstPage.status === 'IN_PROCESSING' && (
              <DocumentCard
                docsType={firstPage?.status}
                image={firstPage?.path}
                setDocumentStatus={setDocumentStatus}
                page='FIRST'
                selectedTrader={selectedTrader}
              />
            )}
            {secondPage.status === 'IN_PROCESSING' && (
              <DocumentCard
                docsType={secondPage?.status}
                image={secondPage?.path}
                setDocumentStatus={setDocumentStatus}
                page='REGISTRATION'
                selectedTrader={selectedTrader}
              />
            )}
          </>
        </div>
      ) : (
        <Typography.Text>No Data</Typography.Text>
      )}
    </div>
  );
};

export default PendingDocs;
