const countryList = [
  { name: 'Albania', key: 'AL', id: 1 },
  { name: 'Åland Islands', key: 'AX', id: 2 },
  { name: 'Algeria', key: 'DZ', id: 3 },
  { name: 'American Samoa', key: 'AS', id: 4 },
  { name: 'Andorra', key: 'AD', id: 5 },
  { name: 'Angola', key: 'AO', id: 6 },
  { name: 'Anguilla', key: 'AI', id: 7 },
  { name: 'Antarctica', key: 'AQ', id: 8 },
  { name: 'Antigua and Barbuda', key: 'AG', id: 9 },
  { name: 'Argentina', key: 'AR', id: 10 },
  { name: 'Armenia', key: 'AM', id: 11 },
  { name: 'Aruba', key: 'AW', id: 12 },
  { name: 'Australia', key: 'AU', id: 13 },
  { name: 'Austria', key: 'AT', id: 14 },
  { name: 'Azerbaijan', key: 'AZ', id: 15 },
  { name: 'Bahamas (the)', key: 'BS', id: 16 },
  { name: 'Bahrain', key: 'BH', id: 17 },
  { name: 'Bangladesh', key: 'BD', id: 18 },
  { name: 'Barbados', key: 'BB', id: 19 },
  { name: 'Belarus', key: 'BY', id: 20 },
  { name: 'Belgium', key: 'BE', id: 21 },
  { name: 'Belize', key: 'BZ', id: 22 },
  { name: 'Benin', key: 'BJ', id: 23 },
  { name: 'Bermuda', key: 'BM', id: 24 },
  { name: 'Bhutan', key: 'BT', id: 25 },
  { name: 'Bolivia (Plurinational State of)', key: 'BO', id: 26 },
  { name: 'Bonaire, Sint Eustatius and Saba', key: 'BQ', id: 27 },
  { name: 'Bosnia and Herzegovina', key: 'BA', id: 288 },
  { name: 'Botswana', key: 'BW', id: 28 },
  { name: 'Bouvet Island', key: 'BV', id: 29 },
  { name: 'Brazil', key: 'BR', id: 30 },
  { name: 'British Indian Ocean Territory (the)', key: 'IO', id: 31 },
  { name: 'Brunei Darussalam', key: 'BN', id: 32 },
  { name: 'Bulgaria', key: 'BG', id: 33 },
  { name: 'Burkina Faso', key: 'BF', id: 34 },
  { name: 'Burundi', key: 'BI', id: 35 },
  { name: 'Cabo Verde', key: 'CV', id: 36 },
  { name: 'Cambodia', key: 'KH', id: 37 },
  { name: 'Cameroon', key: 'CM', id: 38 },
  { name: 'Canada', key: 'CA', id: 39 },
  { name: 'Cayman Islands (the)', key: 'KY', id: 40 },
  { name: 'Central African Republic (the)', key: 'CF', id: 41 },
  { name: 'Chad', key: 'TD', id: 42 },
  { name: 'Chile', key: 'CL', id: 43 },
  { name: 'China', key: 'CN', id: 44 },
  { name: 'Christmas Island', key: 'CX', id: 45 },
  { name: 'Cocos (Keeling) Islands (the)', key: 'CC', id: 46 },
  { name: 'Colombia', key: 'CO', id: 47 },
  { name: 'Comoros (the)', key: 'KM', id: 48 },
  { name: 'Congo (the Democratic Republic of the)', key: 'CD', id: 49 },
  { name: 'Congo (the)', key: 'CG', id: 50 },
  { name: 'Cook Islands (the)', key: 'CK', id: 51 },
  { name: 'Costa Rica', key: 'CR', id: 52 },
  { name: 'Croatia', key: 'HR', id: 53 },
  { name: 'Cuba', key: 'CU', id: 54 },
  { name: 'Curaçao', key: 'CW', id: 55 },
  { name: 'Cyprus', key: 'CY', id: 56 },
  { name: 'Czechia', key: 'CZ', id: 57 },
  { name: "Côte d'Ivoire", key: 'CI', id: 58 },
  { name: 'Denmark', key: 'DK', id: 59 },
  { name: 'Djibouti', key: 'DJ', id: 60 },
  { name: 'Dominica', key: 'DM', id: 61 },
  { name: 'Dominican Republic (the)', key: 'DO', id: 62 },
  { name: 'Ecuador', key: 'EC', id: 63 },
  { name: 'Egypt', key: 'EG', id: 64 },
  { name: 'El Salvador', key: 'SV', id: 65 },
  { name: 'Equatorial Guinea', key: 'GQ', id: 66 },
  { name: 'Eritrea', key: 'ER', id: 67 },
  { name: 'Estonia', key: 'EE', id: 68 },
  { name: 'Eswatini', key: 'SZ', id: 69 },
  { name: 'Ethiopia', key: 'ET', id: 70 },
  { name: 'Falkland Islands (the) [Malvinas]', key: 'FK', id: 71 },
  { name: 'Faroe Islands (the)', key: 'FO', id: 72 },
  { name: 'Fiji', key: 'FJ', id: 73 },
  { name: 'Finland', key: 'FI', id: 74 },
  { name: 'France', key: 'FR', id: 75 },
  { name: 'French Guiana', key: 'GF', id: 76 },
  { name: 'French Polynesia', key: 'PF', id: 77 },
  { name: 'French Southern Territories (the)', key: 'TF', id: 78 },
  { name: 'Gabon', key: 'GA', id: 79 },
  { name: 'Gambia (the)', key: 'GM', id: 80 },
  { name: 'Georgia', key: 'GE', id: 81 },
  { name: 'Germany', key: 'DE', id: 82 },
  { name: 'Ghana', key: 'GH', id: 83 },
  { name: 'Gibraltar', key: 'GI', id: 84 },
  { name: 'Greece', key: 'GR', id: 85 },
  { name: 'Greenland', key: 'GL', id: 86 },
  { name: 'Grenada', key: 'GD', id: 87 },
  { name: 'Guadeloupe', key: 'GP', id: 88 },
  { name: 'Guam', key: 'GU', id: 89 },
  { name: 'Guatemala', key: 'GT', id: 90 },
  { name: 'Guernsey', key: 'GG', id: 91 },
  { name: 'Guinea', key: 'GN', id: 92 },
  { name: 'Guinea-Bissau', key: 'GW', id: 93 },
  { name: 'Guyana', key: 'GY', id: 94 },
  { name: 'Haiti', key: 'HT', id: 95 },
  { name: 'Heard Island and McDonald Islands', key: 'HM', id: 96 },
  { name: 'Holy See (the)', key: 'VA', id: 97 },
  { name: 'Honduras', key: 'HN', id: 98 },
  { name: 'Hong Kong', key: 'HK', id: 99 },
  { name: 'Hungary', key: 'HU', id: 100 },
  { name: 'Iceland', key: 'IS', id: 101 },
  { name: 'India', key: 'IN', id: 102 },
  { name: 'Indonesia', key: 'ID', id: 103 },
  { name: 'Iran (Islamic Republic of)', key: 'IR', id: 104 },
  { name: 'Iraq', key: 'IQ', id: 105 },
  { name: 'Ireland', key: 'IE', id: 106 },
  { name: 'Isle of Man', key: 'IM', id: 107 },
  { name: 'Israel', key: 'IL', id: 108 },
  { name: 'Italy', key: 'IT', id: 109 },
  { name: 'Jamaica', key: 'JM', id: 110 },
  { name: 'Japan', key: 'JP', id: 111 },
  { name: 'Jersey', key: 'JE', id: 112 },
  { name: 'Jordan', key: 'JO', id: 113 },
  { name: 'Kazakhstan', key: 'KZ', id: 114 },
  { name: 'Kenya', key: 'KE', id: 115 },
  { name: 'Kiribati', key: 'KI', id: 116 },
  { name: "Korea (the Democratic People's Republic of)", key: 'KP', id: 117 },
  { name: 'Korea (the Republic of)', key: 'KR', id: 118 },
  { name: 'Kuwait', key: 'KW', id: 119 },
  { name: 'Kyrgyzstan', key: 'KG', id: 120 },
  { name: "Lao People's Democratic Republic (the)", key: 'LA', id: 121 },
  { name: 'Latvia', key: 'LV', id: 122 },
  { name: 'Lebanon', key: 'LB', id: 123 },
  { name: 'Lesotho', key: 'LS', id: 124 },
  { name: 'Liberia', key: 'LR', id: 125 },
  { name: 'Libya', key: 'LY', id: 126 },
  { name: 'Liechtenstein', key: 'LI', id: 127 },
  { name: 'Lithuania', key: 'LT', id: 128 },
  { name: 'Luxembourg', key: 'LU', id: 129 },
  { name: 'Macao', key: 'MO', id: 130 },
  { name: 'Madagascar', key: 'MG', id: 131 },
  { name: 'Malawi', key: 'MW', id: 132 },
  { name: 'Malaysia', key: 'MY', id: 133 },
  { name: 'Maldives', key: 'MV', id: 134 },
  { name: 'Mali', key: 'ML', id: 135 },
  { name: 'Malta', key: 'MT', id: 136 },
  { name: 'Marshall Islands (the)', key: 'MH', id: 137 },
  { name: 'Martinique', key: 'MQ', id: 138 },
  { name: 'Mauritania', key: 'MR', id: 139 },
  { name: 'Mauritius', key: 'MU', id: 140 },
  { name: 'Mayotte', key: 'YT', id: 141 },
  { name: 'Mexico', key: 'MX', id: 142 },
  { name: 'Micronesia (Federated States of)', key: 'FM', id: 143 },
  { name: 'Moldova (the Republic of)', key: 'MD', id: 144 },
  { name: 'Monaco', key: 'MC', id: 145 },
  { name: 'Mongolia', key: 'MN', id: 146 },
  { name: 'Montenegro', key: 'ME', id: 147 },
  { name: 'Montserrat', key: 'MS', id: 148 },
  { name: 'Morocco', key: 'MA', id: 149 },
  { name: 'Mozambique', key: 'MZ', id: 150 },
  { name: 'Myanmar', key: 'MM', id: 151 },
  { name: 'Namibia', key: 'NA', id: 152 },
  { name: 'Nauru', key: 'NR', id: 153 },
  { name: 'Nepal', key: 'NP', id: 154 },
  { name: 'Netherlands (the)', key: 'NL', id: 155 },
  { name: 'New Caledonia', key: 'NC', id: 156 },
  { name: 'New Zealand', key: 'NZ', id: 157 },
  { name: 'Nicaragua', key: 'NI', id: 158 },
  { name: 'Niger (the)', key: 'NE', id: 159 },
  { name: 'Nigeria', key: 'NG', id: 160 },
  { name: 'Niue', key: 'NU', id: 161 },
  { name: 'Norfolk Island', key: 'NF', id: 162 },
  { name: 'Northern Mariana Islands (the)', key: 'MP', id: 163 },
  { name: 'Norway', key: 'NO', id: 164 },
  { name: 'Oman', key: 'OM', id: 165 },
  { name: 'Pakistan', key: 'PK', id: 166 },
  { name: 'Palau', key: 'PW', id: 167 },
  { name: 'Palestine, State of', key: 'PS', id: 168 },
  { name: 'Panama', key: 'PA', id: 169 },
  { name: 'Papua New Guinea', key: 'PG', id: 170 },
  { name: 'Paraguay', key: 'PY', id: 171 },
  { name: 'Peru', key: 'PE', id: 172 },
  { name: 'Philippines (the)', key: 'PH', id: 173 },
  { name: 'Pitcairn', key: 'PN', id: 174 },
  { name: 'Poland', key: 'PL', id: 175 },
  { name: 'Portugal', key: 'PT', id: 176 },
  { name: 'Puerto Rico', key: 'PR', id: 177 },
  { name: 'Qatar', key: 'QA', id: 178 },
  { name: 'Republic of North Macedonia', key: 'MK', id: 179 },
  { name: 'Romania', key: 'RO', id: 180 },
  { name: 'Russian Federation (the)', key: 'RU', id: 181 },
  { name: 'Rwanda', key: 'RW', id: 182 },
  { name: 'Réunion', key: 'RE', id: 183 },
  { name: 'Saint Barthélemy', key: 'BL', id: 184 },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', key: 'SH', id: 185 },
  { name: 'Saint Kitts and Nevis', key: 'KN', id: 186 },
  { name: 'Saint Lucia', key: 'LC', id: 187 },
  { name: 'Saint Martin (French part)', key: 'MF', id: 188 },
  { name: 'Saint Pierre and Miquelon', key: 'PM', id: 189 },
  { name: 'Saint Vincent and the Grenadines', key: 'VC', id: 190 },
  { name: 'Samoa', key: 'WS', id: 191 },
  { name: 'San Marino', key: 'SM', id: 192 },
  { name: 'Sao Tome and Principe', key: 'ST', id: 193 },
  { name: 'Saudi Arabia', key: 'SA', id: 194 },
  { name: 'Senegal', key: 'SN', id: 195 },
  { name: 'Serbia', key: 'RS', id: 196 },
  { name: 'Seychelles', key: 'SC', id: 197 },
  { name: 'Sierra Leone', key: 'SL', id: 198 },
  { name: 'Singapore', key: 'SG', id: 199 },
  { name: 'Sint Maarten (Dutch part)', key: 'SX', id: 200 },
  { name: 'Slovakia', key: 'SK', id: 201 },
  { name: 'Slovenia', key: 'SI', id: 202 },
  { name: 'Solomon Islands', key: 'SB', id: 203 },
  { name: 'Somalia', key: 'SO', id: 204 },
  { name: 'South Africa', key: 'ZA', id: 205 },
  { name: 'South Georgia and the South Sandwich Islands', key: 'GS', id: 206 },
  { name: 'South Sudan', key: 'SS', id: 207 },
  { name: 'Spain', key: 'ES', id: 208 },
  { name: 'Sri Lanka', key: 'LK', id: 209 },
  { name: 'Sudan (the)', key: 'SD', id: 210 },
  { name: 'Suriname', key: 'SR', id: 211 },
  { name: 'Svalbard and Jan Mayen', key: 'SJ', id: 212 },
  { name: 'Sweden', key: 'SE', id: 213 },
  { name: 'Switzerland', key: 'CH', id: 214 },
  { name: 'Syrian Arab Republic', key: 'SY', id: 215 },
  { name: 'Taiwan (Province of China)', key: 'TW', id: 216 },
  { name: 'Tajikistan', key: 'TJ', id: 217 },
  { name: 'Tanzania, United Republic of', key: 'TZ', id: 218 },
  { name: 'Thailand', key: 'TH', id: 219 },
  { name: 'Timor-Leste', key: 'TL', id: 220 },
  { name: 'Togo', key: 'TG', id: 221 },
  { name: 'Tokelau', key: 'TK', id: 222 },
  { name: 'Tonga', key: 'TO', id: 223 },
  { name: 'Trinidad and Tobago', key: 'TT', id: 224 },
  { name: 'Tunisia', key: 'TN', id: 225 },
  { name: 'Turkey', key: 'TR', id: 226 },
  { name: 'Turkmenistan', key: 'TM', id: 227 },
  { name: 'Turks and Caicos Islands (the)', key: 'TC', id: 228 },
  { name: 'Tuvalu', key: 'TV', id: 229 },
  { name: 'Uganda', key: 'UG', id: 230 },
  { name: 'Ukraine', key: 'UA', id: 231 },
  { name: 'United Arab Emirates (the)', key: 'AE', id: 232 },
  { name: 'United Kingdom of Great Britain and Northern Ireland (the)', key: 'GB', id: 233 },
  { name: 'United States Minor Outlying Islands (the)', key: 'UM', id: 234 },
  { name: 'United States of America (the)', key: 'US', id: 235 },
  { name: 'Uruguay', key: 'UY', id: 236 },
  { name: 'Uzbekistan', key: 'UZ', id: 237 },
  { name: 'Vanuatu', key: 'VU', id: 238 },
  { name: 'Venezuela (Bolivarian Republic of)', key: 'VE', id: 239 },
  { name: 'Viet Nam', key: 'VN', id: 240 },
  { name: 'Virgin Islands (British)', key: 'VG', id: 241 },
  { name: 'Virgin Islands (U.S.)', key: 'VI', id: 242 },
  { name: 'Wallis and Futuna', key: 'WF', id: 243 },
  { name: 'Western Sahara', key: 'EH', id: 244 },
  { name: 'Yemen', key: 'YE', id: 245 },
  { name: 'Zambia', key: 'ZM', id: 246 },
  { name: 'Zimbabwe', key: 'ZW', id: 247 },
];

export default countryList;
