import React, { useState } from 'react';
import { Button, Form, Input, DatePicker, Select, Typography } from 'antd';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useRootModel } from '../../../models/RootStore';
import Preloader from '../Preloader';

import style from './forms.module.less';

/**
 * Represents create call history form
 * @returns {JSX.Element}
 */

const CallHistoryForm = () => {
  const { id } = useParams();
  const {
    traders: { addCallHistory, isLoading },
  } = useRootModel();
  const [callHistory, setValues] = useState({
    description: '',
    reminderDate: '',
    reminderText: '',
    retentionStatus: '',
    saleStatus: '',
    title: '',
  });
  const handleChange = (event, prop) => {
    setValues({ ...callHistory, [prop]: event.target.value });
  };
  const onDatePick = (value, date, prop) => {
    if (date) {
      setValues({ ...callHistory, [prop]: new Date(date).toISOString() });
    }
  };
  const onSelectChange = (value, prop) => {
    setValues({ ...callHistory, [prop]: value });
  };
  const onSubmit = () => {
    addCallHistory(id, callHistory);
  };
  const validateMessages = {
    required: 'This field is required',
  };
  return (
    <div>
      <Form
        name='Create reminder'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
        requiredMark={false}
      >
        <Form.Item
          label='Enter method of title'
          name='title'
          value={callHistory.title}
          rules={[{ required: true }]}
        >
          <Input placeholder='Enter method...' onChange={(event) => handleChange(event, 'title')} />
        </Form.Item>
        <Form.Item
          label='Description'
          name='description'
          value={callHistory.description}
          rules={[{ required: true }]}
          onChange={(e) => handleChange(e, 'description')}
        >
          <Input.TextArea rows={6} placeholder='Enter description...' maxLength={200} />
        </Form.Item>
        <Form.Item
          label='Select sale status'
          name='saleStatus'
          value={callHistory.saleStatus}
          rules={[{ required: true }]}
        >
          <Select
            value={callHistory.type}
            placeholder='Select sale status...'
            onChange={(value) => onSelectChange(value, 'saleStatus')}
          >
            <Select.Option key='interested' value='INTERESTED'>
              <Typography.Text>Interested</Typography.Text>
            </Select.Option>
            <Select.Option key='not interested' value='NOT_INTERESTED'>
              <Typography.Text>Not Interested</Typography.Text>
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Select retention status'
          name='retentionStatus'
          value={callHistory.retentionStatus}
          rules={[{ required: true }]}
        >
          <Select
            value={callHistory.retentionStatus}
            placeholder='Select or start typing for search...'
            onChange={(value) => onSelectChange(value, 'retentionStatus')}
          >
            <Select.Option key='new' value='NEW'>
              New
            </Select.Option>
            <Select.Option key='old' value='OLD'>
              Old
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Remind to call back'
          name='reminderDate'
          value={callHistory.reminderDate}
          rules={[{ required: true }]}
        >
          <DatePicker
            className={style.reminderDatePicker}
            format='YYYY-MM-DD HH:mm'
            showTime={{ format: 'HH:mm' }}
            onChange={(value, date) => onDatePick(value, date, 'reminderDate')}
          />
        </Form.Item>
        <Form.Item
          label='Remind text'
          name='reminderText'
          value={callHistory.reminderText}
          rules={[{ required: true }]}
        >
          <Input
            placeholder='Enter remind text...'
            onChange={(event) => handleChange(event, 'reminderText')}
          />
        </Form.Item>
        <Form.Item>
          {isLoading ? (
            <Preloader />
          ) : (
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(CallHistoryForm);
