const onlineStatusData = [
  { name: 'Online', value: true },
  { name: 'Offline', value: false },
];
const balanceStatusData = [
  { name: 'Positive', value: true },
  { name: 'Negative', value: false },
];
const retentionStatusData = [
  { name: 'Old', value: 'OLD' },
  { name: 'New', value: 'NEW' },
];
const saleStatusData = [
  { name: 'Interested', value: 'INTERESTED' },
  { name: 'Not interested', value: 'NOT_INTERESTED' },
];
const demoAccountData = [
  { name: 'Demo', value: true },
  { name: 'Real', value: false },
];
const brokerAssignData = [
  { name: 'Assigned', value: true },
  { name: 'Not assigned', value: false },
];
const statusData = [
  { name: 'Active', value: true },
  { name: 'Disabled', value: false },
];
const typeData = [{ value: 'withdrawal' }, { value: 'deposit' }];
const methodData = [{ value: 'Card' }];
const ticketStatusData = [
  { value: 'created' },
  { value: 'pending' },
  { value: 'approved' },
  { value: 'declined' },
];

export default {
  onlineStatusData,
  balanceStatusData,
  retentionStatusData,
  saleStatusData,
  demoAccountData,
  brokerAssignData,
  statusData,
  typeData,
  methodData,
  ticketStatusData,
};
