import React, { useState } from 'react';
import {Popover} from "antd";
import './AttachButton.scss';
import { ReactComponent as AttachIcon } from '../../../../assets/image/attach-btn.svg';
import { ReactComponent as ImageIcon } from '../../../../assets/image/fsImage.svg';
import { ReactComponent as FileIcon } from '../../../../assets/image/fsFile.svg';
import { CloseOutlined } from '@ant-design/icons';

const AttachButton = ({ setType, type, fileRef, clearFile, disabled }) => {
  const [fileName, setFileName] = useState();

  const chooseFile = (type) => {
    type === 'photo'
      ?
      fileRef.current.accept = ['.png', '.jpeg','.jpg']
      :
      fileRef.current.accept = ['.doc', '.docx',  '.pdf', '.txt', '.word', '.odt', '.xls', '.xlsx',];

    fileRef.current.click();
    setType(type);
  };
  const content = (
    <div className='fs_popover'>
      <div
        className='fs_popover_btn'
        onClick={() => chooseFile('photo')}
      >
        <ImageIcon/>
        <span className='fs_popover_btn_text'>Image</span>
      </div>
      <div
        className='fs_popover_btn'
        onClick={() => chooseFile('file')}
      >
        <FileIcon/>
        <span className='fs_popover_btn_text'>File</span>
      </div>
    </div>
  );

  const setPreview = () => {
    setFileName(fileRef.current?.files?.[0]?.name);
  };

  return (
    <>
      {fileRef.current?.files?.[0] ? (
        <div className='file-preview'>
          {type === 'photo' && fileRef.current?.files?.[0] && (
            <img src={window.URL.createObjectURL(fileRef.current?.files?.[0])} alt='' />
          )}
          {type === 'video' && <i className='pi pi-video' />}
          {type === 'file' && <i className='pi pi-file' />}
          <span>{fileRef.current?.files?.[0]?.name}</span>
          <button
            onClick={() => {
              clearFile();
              // setFileName('');
            }}
          >
            <CloseOutlined />
          </button>
        </div>
      ) : (
        <Popover content={content} trigger='click'>
          <button
            disabled={disabled}
            className='p-button-rounded p-button-outlined attach-button'
            // onClick={(e) => menu.current.toggle(e)}
            // onClick={() => chooseFile('photo')}
          >
            <AttachIcon />
          </button>
        </Popover>
      )}
      <input type='file' ref={fileRef} className='message-file-input' onChange={setPreview} />
    </>
  );
};
export default AttachButton;
