import React, { useState } from 'react';
import { Button, Form, Input, DatePicker, Select, Typography } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';

import Preloader from '../Preloader';
import { ReactComponent as PhoneIcon } from '../../../assets/image/phone-reminder.svg';
import { ReactComponent as SmsIcon } from '../../../assets/image/sms-reminder.svg';
import { ReactComponent as EmailIcon } from '../../../assets/image/email-reminder.svg';

import style from './forms.module.less';

/**
 * Represents calendar reminder form
 * @returns {JSX.Element}
 */

const CalendarReminderForm = () => {
  const {
    calendar: { addReminder, isLoading, workers, traders },
  } = useRootModel();
  const [reminder, setValues] = useState({
    title: '',
    startDate: '',
    endDate: '',
    type: '',
    description: '',
    worker: null,
    trader: null,
  });
  const handleChange = (event, prop) => {
    setValues({ ...reminder, [prop]: event.target.value });
  };
  const onDatePick = (value, date, prop) => {
    if (date) {
      setValues({ ...reminder, [prop]: new Date(date).toISOString() });
    }
  };
  const onSelectChange = (value, prop) => {
    setValues({ ...reminder, [prop]: value });
  };
  const onSubmit = () => {
    addReminder(reminder);
  };
  const validateMessages = {
    required: 'This field is required',
  };
  return (
    <div>
      <Form
        name='Create reminder'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
        requiredMark={false}
      >
        <Form.Item
          label='Title'
          name='title'
          value={reminder.title}
          rules={[{ required: true }]}
          onChange={(e) => handleChange(e, 'title')}
        >
          <Input placeholder='Enter title...' />
        </Form.Item>
        <Form.Item
          label='Start date'
          name='startDate'
          value={reminder.startDate}
          rules={[{ required: true }]}
        >
          <DatePicker
            className={style.reminderDatePicker}
            format='YYYY-MM-DD HH:mm'
            showTime={{ format: 'HH:mm' }}
            onChange={(value, date) => onDatePick(value, date, 'startDate')}
          />
        </Form.Item>
        <Form.Item
          label='End date'
          name='endDate'
          value={reminder.endDate}
          rules={[{ required: true }]}
        >
          <DatePicker
            className={style.reminderDatePicker}
            format='YYYY-MM-DD HH:mm'
            showTime={{ format: 'HH:mm' }}
            onChange={(value, date) => onDatePick(value, date, 'endDate')}
          />
        </Form.Item>
        <Form.Item
          label='Type of communication'
          name='type'
          value={reminder.type}
          rules={[{ required: true }]}
        >
          <Select
            value={reminder.type}
            placeholder='Select type...'
            onChange={(value) => onSelectChange(value, 'type')}
          >
            <Select.Option key='call' value='CALL'>
              <div className={style.selectOption}>
                <PhoneIcon className={style.icon} />
                <Typography.Text>Call</Typography.Text>
              </div>
            </Select.Option>
            <Select.Option key='sms' value='SMS'>
              <div className={style.selectOption}>
                <SmsIcon className={style.icon} />
                <Typography.Text>SMS</Typography.Text>
              </div>
            </Select.Option>
            <Select.Option key='email' value='EMAIL'>
              <div className={style.selectOption}>
                <EmailIcon className={style.icon} />
                <Typography.Text>Email</Typography.Text>
              </div>
            </Select.Option>
            <Select.Option key='myself' value='MYSELF'>
              <div className={style.selectOption}>
                <PhoneIcon className={style.icon} />
                <Typography.Text>Myself</Typography.Text>
              </div>
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Worker'
          name='worker'
          value={reminder.worker}
          rules={[{ required: true }]}
        >
          <Select
            value={reminder.worker}
            placeholder='Select or start typing for search...'
            onChange={(value) => onSelectChange({ id: value }, 'worker')}
          >
            {workers.map((worker) => (
              <Select.Option key={worker.id} value={worker.id}>
                {worker?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Trader'
          name='trader'
          value={reminder.trader}
          rules={[{ required: true }]}
        >
          <Select
            value={reminder.trader}
            placeholder='Select or start typing for search...'
            onChange={(value) => onSelectChange({ id: value }, 'trader')}
          >
            {traders.map((trader) => (
              <Select.Option key={trader.id} value={trader.id}>
                {trader?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Description'
          name='description'
          value={reminder.text}
          rules={[{ required: true }]}
          onChange={(e) => handleChange(e, 'description')}
        >
          <Input.TextArea rows={4} placeholder='Enter description...' maxLength={200} />
        </Form.Item>
        <Form.Item>
          {isLoading ? (
            <Preloader />
          ) : (
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(CalendarReminderForm);
