import React from 'react';
import { Progress, Typography } from 'antd';
import style from './style.module.less';

/**
 * Represents dashboard card [completion] component
 * @returns {JSX.Element}
 */

const DashboardCardCompletion = ({ statusText, statusPercent }) => {
  return (
    <div className={style.completionCard}>
      <div className={style.cardContent}>
        <div className={style.progressWrapper}>
          <Progress
            className={style.progress}
            type='circle'
            width={80}
            strokeWidth={12}
            percent={statusPercent}
            strokeColor='#79D782'
            trailColor='#b5affd'
          />
        </div>
        <div className={style.textContainer}>
          <Typography.Text className={style.textThin}>Plan completion</Typography.Text>
          <Typography.Text className={style.textThick}>{statusText}</Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default DashboardCardCompletion;
