import React from 'react';
import { Space } from 'antd';
import Text from 'antd/es/typography/Text';
import { observer } from 'mobx-react';

import { ReactComponent as AppliedIcon } from '../../assets/image/filters-presets.svg';
import DeleteIcon from '../common/icons/DeleteIcon';
import DeleteBinIcon from '../common/icons/DeleteBinIcon';

import filterHandler from '../../util/filterHandler';
import style from './AppliedFilters.module.less';

/**
 * Represents AppliedFilters component
 * * @param {Object} filters - object with filters
 * * @param {Function} fetch - func for fetching data
 * * @param {Function} clearFilters - func for removing filters
 * @returns {JSX.Element}
 */
const AppliedFilters = ({ filters, fetch, clearFilters }) => {
  const appliedFilters = filterHandler(filters);
  const handleApplyFilters = (removeFilter) => {
    removeFilter();
    fetch();
  };
  const removeAllFilters = () => {
    clearFilters();
    fetch();
  };
  return (
    <Space className={style.appliedFilters} align='center'>
      <div className={style.appliedFilters__wrapper}>
        <AppliedIcon />
        <Text className={style.filtersLabel}>Applied Filters:</Text>
        {appliedFilters.length ? (
          appliedFilters.map((item) => (
            <div key={item.field} className={style.filterWrapper}>
              <span>Filter by: </span>
              <span className={style.filterText}>[{item.field}]</span>
              <DeleteIcon onClick={() => handleApplyFilters(item.removeFilter)} />
            </div>
          ))
        ) : (
          <span>None</span>
        )}
      </div>
      {appliedFilters.length ? <DeleteBinIcon onClick={removeAllFilters} /> : ''}
    </Space>
  );
};

export default observer(AppliedFilters);
