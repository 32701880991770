import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import SingleWorkerTabs from './SingleWorkerTabs';
import SingleWorkerProfile from './SingleWorkerProfile';

import permission from '../../constants/permission';

import style from './style.module.less';

/**
 * Represents selected worker profile content
 * @returns {JSX.Element}
 */

const SingleWorkerContent = () => {
  const { id } = useParams();
  const {
    user: { userData },
    workers: {
      selectedWorker,
      fetchSelectedWorker,
      getPermission,
      isExecutionPlan,
      isWorkerProfile,
      isWorkerTickets,
      isTransactions,
    },
  } = useRootModel();

  const [tab, setTab] = useState('profile');

  useEffect(() => {
    const fetchData = async () => {
      await getPermission(permission.WORKERS_PROFILE);
      fetchSelectedWorker(id);
    };
    fetchData();
  }, []);

  const handleTabs = (value) => {
    setTab(value);
  };
  return (
    <section className={style.singleWorker}>
      <div className={style.singleWorker_header}>
        #{id} {selectedWorker.name}
      </div>
      <div className={style.singleWorker_tabsContainer}>
        <div className={style.singleWorker_tabWrapper}>
          <SingleWorkerTabs
            onTabChange={handleTabs}
            isWorkerProfile={isWorkerProfile}
            isWorkerTickets={isWorkerTickets}
            isTransactions={isTransactions}
          />
        </div>
        {tab === 'profile' && isWorkerProfile && (
          <SingleWorkerProfile
            worker={selectedWorker}
            user={userData}
            isExecutionPlan={isExecutionPlan}
          />
        )}
        {tab === 'tickets' && isWorkerTickets && <div>Tickets</div>}
        {tab === 'transactions' && isTransactions && <div>Transactions</div>}
      </div>
    </section>
  );
};

export default observer(SingleWorkerContent);
