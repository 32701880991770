import React from 'react';
import { Typography } from 'antd';
import Preloader from '../../../common/Preloader';
import CustomChatList from '../../Components/CustomChatList/CustomChatList';

import './chatList.scss';

const ChatList = ({ chatList, dataSource, activeChatId, onClick, chatListRef, onScroll, isChatsLoading }) => {
  const getBottom = (e) => {
    return e.scrollHeight - e.scrollTop - e.offsetHeight;
  };
  const chatListOnScroll = (e) => {
    const bottom = getBottom(e.target);
    if (onScroll instanceof Function) {
      onScroll(bottom);
    }
  }
  return (
    <div ref={chatListRef} onScroll={chatListOnScroll} className='chatList'>
      { isChatsLoading ? (
        <div className='preloader-wrap'><Preloader spinColor='#9a9ac0b3' /></div>
      ) : chatList.length ? (
        <CustomChatList
          dataSource={dataSource}
          activeChatId={activeChatId}
          onClick={onClick}
        />
      ) : (
        <div className='chatList_empty_wrap'>
          <div className='chatList_empty'>
            <Typography.Title level={4}>The chat list is still empty.</Typography.Title>
            <Typography.Text className='chatList_empty_descr'>
              Chats with users will be displayed here.
            </Typography.Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatList;
