import { types } from 'mobx-state-tree';
import FilterItemModel from '../../commonModels/FilterItemModel';
import FilterAmountItemModel from '../../commonModels/FilterAmountItemModel';
import FilterDateItemModel from '../../commonModels/FilterDateItemModel';

const PositionsFiltersModel = types.model('PositionsFiltersModel', {
  id: types.optional(FilterItemModel, {}),
  amount: types.optional(FilterAmountItemModel, {}),
  trader: types.optional(FilterItemModel, {}),
  status: types.optional(FilterItemModel, {}),
  type: types.optional(FilterItemModel, {}),
  openedBy: types.optional(FilterItemModel, {}),
  openedAt: types.optional(FilterDateItemModel, {}),
  closedAt: types.optional(FilterDateItemModel, {}),
  method: types.optional(FilterItemModel, {}),
  assignedTo: types.optional(FilterItemModel, {}),
  broker: types.optional(FilterItemModel, {}),
  desk: types.optional(FilterItemModel, {}),
});

export default PositionsFiltersModel;
