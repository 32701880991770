import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EyeInvisibleTwoTone, EyeOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';
import Preloader from '../Preloader';

import languageList from '../../../constants/languageList';
import timeZoneList from '../../../constants/timeZoneList';
import userRoles from '../../../constants/userRoles';

/**
 * Represents add worker form
 * @returns {JSX.Element}
 */

const AddNewWorkerForm = () => {
  const {
    user: { userData },
    workers: { workers, addNewWorker, userDesks, isLoading, getUserDesks },
  } = useRootModel();

  const [worker, setValues] = useState({
    login: '',
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    langKey: '',
    desk: {
      id: null,
      active: true,
      createdAt: new Date().toISOString(),
      name: '',
    },
    role: '',
    executionPlan: null,
    active: true,
    timeZoneId: '',
  });

  const handleChange = (value, prop) => {
    setValues({ ...worker, [prop]: value });
  };

  const onSelectChange = (value, prop) => {
    setValues({ ...worker, [prop]: value });
  };

  const onSubmit = () => {
    addNewWorker(worker);
  };

  const validateMessages = {
    required: 'This field is required',
  };

  useEffect(() => {
    getUserDesks();
  }, [workers]);

  return (
    <div>
      <Form
        name='Add worker'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
      >
        <Form.Item
          label='Worker’s login'
          name='login'
          value={worker.login}
          onChange={(e) => handleChange(e.target.value, 'login')}
          rules={[
            { required: true },
            {
              pattern: new RegExp(/^\S*$/),
              message: 'Login must be without whitespaces',
            },
          ]}
        >
          <Input size='large' placeholder='Enter your worker username' maxLength={100} />
        </Form.Item>
        <Form.Item
          label='Worker’s email'
          name='email'
          value={worker.email}
          onChange={(e) => handleChange(e.target.value, 'email')}
          rules={[{ required: true }, { type: 'email', message: 'Email is not valid' }]}
        >
          <Input size='large' placeholder='Enter your worker email' maxLength={100} />
        </Form.Item>
        <Form.Item
          label='Worker’s password'
          name='password'
          rules={[
            { required: true },
            {
              pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/),
              min: 8,
              max: 30,
              message: 'At least 8 symbols: including number, uppercase and lowercase letter',
            },
          ]}
          value={worker.password}
          onChange={(e) => handleChange(e.target.value, 'password')}
        >
          <Input.Password
            size='large'
            type='password'
            name='password'
            placeholder='Enter your password'
            iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleTwoTone />)}
            maxLength={20}
          />
        </Form.Item>
        <Form.Item
          label='Worker’s first name'
          name='firstName'
          value={worker.firstName}
          onChange={(e) => handleChange(e.target.value, 'firstName')}
          rules={[{ required: true }]}
        >
          <Input size='large' placeholder='Enter your new worker first name' maxLength={100} />
        </Form.Item>
        <Form.Item
          label='Worker’s last name'
          name='lastName'
          value={worker.lastName}
          onChange={(e) => handleChange(e.target.value, 'lastName')}
          rules={[{ required: true }]}
        >
          <Input size='large' placeholder='Enter your new worker last name' maxLength={100} />
        </Form.Item>
        <Form.Item
          label='Select language from the list'
          name='langKey'
          value={worker.langKey}
          rules={[{ required: true }]}
        >
          <Select
            value={worker.langKey}
            placeholder='Select language...'
            onChange={(value) => onSelectChange(value, 'langKey')}
          >
            {languageList.map((el) => (
              <Select.Option value={el.key} key={el.id}>
                {el.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Select desk from the list'
          name='desk'
          value={worker.desk.name}
          rules={[{ required: true }]}
        >
          <Select
            value={worker.desk.name}
            placeholder='Select desk...'
            onChange={(value, data) =>
              onSelectChange({ ...worker.desk, name: value, id: Number(data.key) }, 'desk')
            }
          >
            {userDesks.map((el) => (
              <Select.Option value={el.name} key={el.id}>
                {el.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Select role from the list'
          name='role'
          value={worker.role}
          rules={[{ required: true }]}
        >
          <Select
            value={worker.role}
            placeholder='Select role...'
            onChange={(value) => onSelectChange(value, 'role')}
          >
            {userData.authorities === userRoles.admin && (
              <Select.Option value={userRoles.teamLead} key={userRoles.teamLead}>
                {userRoles.teamLead}
              </Select.Option>
            )}
            <Select.Option value={userRoles.worker} key={userRoles.worker}>
              {userRoles.worker}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Execution plan'
          name='executionPlan'
          value={worker.executionPlan}
          onChange={(e) => handleChange(Number(e.target.value), 'executionPlan')}
          rules={[{ required: true }]}
        >
          <Input size='large' placeholder='Enter execution plan' maxLength={100} />
        </Form.Item>
        <Form.Item
          label='Select Status'
          name='active'
          value={worker.active}
          rules={[{ required: true }]}
        >
          <Select
            value={worker.active}
            placeholder='Select status...'
            onChange={(value) => onSelectChange(value, 'active')}
          >
            <Select.Option value>Active</Select.Option>
            <Select.Option value={false}>Disabled</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Select timezone'
          name='timeZoneId'
          value={worker.timeZoneId}
          rules={[{ required: true }]}
        >
          <Select
            value={worker.timeZoneId}
            placeholder='Select timezone...'
            onChange={(value) => onSelectChange(value, 'timeZoneId')}
          >
            {timeZoneList.map((el) => (
              <Select.Option value={el.key} key={el.id}>
                {el.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          {isLoading ? (
            <Preloader />
          ) : (
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(AddNewWorkerForm);
