import React, { useEffect } from 'react';
import { Table, Typography } from 'antd';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import numeral from 'numeral';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { useRootModel } from '../../../models/RootStore';

import dateFormatter from '../../../util/dateFormatter';
import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';

import PendingIcon from '../icons/PendingIcon';

import style from './tables.module.less';

/**
 * Represents trader [positions] table
 * @returns {JSX.Element}
 */

const TraderPositionsTable = () => {
  const { id } = useParams();
  const {
    traders: {
      fetchPositions,
      positions,
      positionsPagination,
      isLoading,
      handlePositionsPageChange,
    },
  } = useRootModel();
  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handlePositionsPageChange(
      {
        page: page.current,
        size: page.pageSize,
        sortColumn: column,
        sortOrder,
      },
      id,
    );
  };
  useEffect(() => {
    fetchPositions(id);
  }, []);
  const columns = [
    {
      title: 'ID',
      dataIndex: 'openedById',
      key: 'openedById',
      width: 100,
      sorter: true,
      sortOrder:
        positionsPagination.sortColumn === 'openedById' &&
        sortOrderToString(positionsPagination.sortOrder),
      render: (openedById) => (
        <div className={style.linkCell}>
          <Typography.Text className={style.link}>#{openedById}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Instrument',
      dataIndex: 'instrument',
      key: 'instrument',
      width: 150,
      sorter: true,
      sortOrder:
        positionsPagination.sortColumn === 'instrument' &&
        sortOrderToString(positionsPagination.sortOrder),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 150,
      sorter: true,
      sortOrder:
        positionsPagination.sortColumn === 'category' &&
        sortOrderToString(positionsPagination.sortOrder),
    },
    {
      className: `${style.column}`,
      title: 'Opening date',
      dataIndex: 'openedAt',
      key: 'openedAt',
      width: 150,
      sorter: true,
      sortOrder:
        positionsPagination.sortColumn === 'openedAt' &&
        sortOrderToString(positionsPagination.sortOrder),
      render: (openedAt) => <div>{dateFormatter(openedAt)}</div>,
    },
    {
      className: `${style.column}`,
      title: 'Opening price',
      dataIndex: 'openingPrice',
      key: 'openingPrice',
      width: 150,
      sorter: true,
      sortOrder:
        positionsPagination.sortColumn === 'openingPrice' &&
        sortOrderToString(positionsPagination.sortOrder),
      render: (openingPrice) => <div>{numeral(openingPrice).format('$0,0.00')}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: true,
      sortOrder:
        positionsPagination.sortColumn === 'status' &&
        sortOrderToString(positionsPagination.sortOrder),
      render: (status) => (
        <div className={style.ticketStatus_wrapper}>
          {status === 'ACTIVE' && (
            <div className={cn(style.ticketStatus, style.ticketStatus__active)}>
              <div className={cn(style.ellipse, style.ellipse__active)} />
              <p className={cn(style.ticketText, style.ticketText__active)}>{status}</p>
            </div>
          )}
          {status === 'PENDING' && (
            <div className={cn(style.ticketStatus, style.ticketStatus__pending)}>
              <PendingIcon />
              <p className={cn(style.ticketText, style.ticketText__pending)}>{status}</p>
            </div>
          )}
          {status === 'CLOSED' && (
            <div className={cn(style.ticketStatus, style.ticketStatus__created)}>
              <div className={style.ellipse} />
              <p className={cn(style.ticketText, style.ticketText__created)}>{status}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      className: `${style.column}`,
      title: 'Opened by',
      dataIndex: 'openedByUserVM',
      key: 'openedByUserVM',
      width: 150,
      sorter: true,
      sortOrder:
        positionsPagination.sortColumn === 'openedByUserVM' &&
        sortOrderToString(positionsPagination.sortOrder),
      render: (openedByUserVM) => (
        <div className={style.linkCell}>
          {!openedByUserVM?.name ? (
            <Typography.Text>Self deposit</Typography.Text>
          ) : (
            <Typography.Text className={style.link}>{openedByUserVM?.name}</Typography.Text>
          )}
        </div>
      ),
    },
    {
      title: 'Closed',
      dataIndex: 'closedAt',
      key: 'closedAt',
      width: 143,
      sorter: true,
      sortOrder:
        positionsPagination.sortColumn === 'closedAt' &&
        sortOrderToString(positionsPagination.sortOrder),
      render: (closedAt) => (
        <>
          {closedAt === null ? (
            <Typography.Text type='danger'>Not closed yet</Typography.Text>
          ) : (
            dateFormatter(closedAt)
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        className={style.table}
        onChange={onPageChange}
        columns={columns}
        loading={isLoading}
        dataSource={toJS(positions)}
        scroll={{ x: 'max-content', y: '620px' }}
        rowKey='id'
        pagination={{
          position: ['topRight'],
          showSizeChanger: true,
          current: positionsPagination.number + 1,
          total: positionsPagination.totalElements,
          defaultPageSize: positionsPagination.size,
        }}
      />
    </>
  );
};

export default observer(TraderPositionsTable);
