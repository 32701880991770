import React from 'react';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';
import AddIcon from '../../common/icons/AddIcon';

import style from '../HeaderOptions.module.less';

/**
 * Represents  export mail template button
 * @returns {JSX.Element}
 */

const ExportTemplate = ({ emailEditorRef, handleReload }) => {
  const {
    mailLayouts: {
      createMailLayout,
      selectedLayout,
      editMailLayout,
      templateTitle,
      language,
      setTitle,
    },
  } = useRootModel();

  const handleEditedLayout = async (layout) => {
    await editMailLayout(layout);
    handleReload();
  };

  const handleCreatedLayout = async (layout) => {
    await createMailLayout(layout);
    setTitle(null);
    handleReload();
  };

  const exportHtml = () => {
    emailEditorRef.current.exportHtml((data) => {
      const { design, html } = data;
      const layout = {
        content: html,
        styles: JSON.stringify(design),
        title: templateTitle,
        language,
      };
      // eslint-disable-next-line no-console
      console.log('export HTML', html);
      // eslint-disable-next-line no-console
      console.log('export design', design);
      // eslint-disable-next-line no-console
      console.log('------------JSON design', JSON.stringify(design));
      if (selectedLayout) {
        handleEditedLayout(layout);
      }
      if (!selectedLayout) {
        handleCreatedLayout(layout);
      }
    });
  };
  return (
    <Button onClick={exportHtml} className={style.addWorkerBtn} type='primary' icon={<AddIcon />}>
      Export template
    </Button>
  );
};

export default observer(ExportTemplate);
