import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Typography } from 'antd';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useRootModel } from '../../../models/RootStore';

import dateFormatter from '../../../util/dateFormatter';
import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';
import displayOptionsFilter from '../../../util/displayOptionsFilter';

import style from './tables.module.less';

/**
 * Represents workers table
 * @returns {JSX.Element}
 */

const WorkerTable = () => {
  const history = useHistory();
  const {
    workers: {
      allWorkers,
      isLoading,
      pagination,
      handleSorterChange,
      displayOptions,
      setSelectedRows,
      fetchWorkers,
    },
  } = useRootModel();
  useEffect(() => {
    fetchWorkers();
  }, []);
  const onSelectChange = (selectedRowKeys, rows) => {
    setSelectedRows(rows);
  };
  const rowSelection = {
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };
  const handleOnRow = (id) => {
    history.push(`/worker/${id}`);
  };
  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handleSorterChange({ sortColumn: column, sortOrder });
  };
  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      width: 150,
      sorter: true,
      sortOrder: pagination.sortColumn === 'username' && sortOrderToString(pagination.sortOrder),
    },
    {
      className: `${style.column}`,
      title: 'Worker name',
      dataIndex: 'name',
      key: 'name',
      width: 196,
      sorter: true,
      sortOrder: pagination.sortColumn === 'name' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 209,
      sorter: true,
      sortOrder: pagination.sortColumn === 'email' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      width: 152,
      sorter: true,
      sortOrder: pagination.sortColumn === 'language' && sortOrderToString(pagination.sortOrder),
      render: (language) => <div>{language.toUpperCase()}</div>,
    },
    {
      title: 'Desk',
      dataIndex: 'desk',
      key: 'desk',
      width: 162,
      sorter: true,
      sortOrder: pagination.sortColumn === 'desk' && sortOrderToString(pagination.sortOrder),
      render: (desk) => <div>{desk === null ? '' : desk.name}</div>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 140,
      sorter: true,
      sortOrder: pagination.sortColumn === 'role' && sortOrderToString(pagination.sortOrder),
      render: (role) => <div>{role}</div>,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      width: 108,
      sorter: true,
      sortOrder: pagination.sortColumn === 'active' && sortOrderToString(pagination.sortOrder),
      render: (active) => (
        <div
          className={`${style.status} ${!active ? style.status__danger : style.status__success}`}
        >
          {active ? (
            <Typography.Text className={style.text__success}>Yes</Typography.Text>
          ) : (
            <Typography.Text className={style.text__danger}>No</Typography.Text>
          )}
        </div>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 143,
      render: (createdDate) => <div>{dateFormatter(createdDate)}</div>,
    },
  ];
  return (
    <>
      <Table
        className={style.table}
        onRow={(worker) => {
          return {
            onDoubleClick: () => {
              handleOnRow(worker.id);
            },
          };
        }}
        onChange={onPageChange}
        rowSelection={rowSelection}
        columns={displayOptionsFilter(displayOptions, columns)}
        loading={isLoading}
        dataSource={toJS(allWorkers)}
        scroll={{ x: 'max-content', y: '620px' }}
        rowKey='id'
        pagination={false}
      />
    </>
  );
};

export default observer(WorkerTable);
