import React from 'react';
import { Typography } from 'antd';
import numeral from 'numeral';
import { ReactComponent as IndicatorUp } from '../../../assets/image/arrow-indicator-up.svg';
// eslint-disable-next-line max-len
import { ReactComponent as IndicatorDown } from '../../../assets/image/arrow-indicator-down.svg';

import style from './style.module.less';

/**
 * Represents dashboard card component
 * @returns {JSX.Element}
 */

const DashboardCard = ({ icon, title, amount, indicator, indicatorAmount }) => {
  return (
    <div className={style.dashboardCard}>
      <div className={style.iconWrapper}>
        <div className={style.iconEllipse}>{icon}</div>
      </div>
      <div className={style.textContainer}>
        <Typography.Text className={style.title}>{title}</Typography.Text>
        <Typography.Text className={style.amount}>
          ${numeral(amount).format('0,0.00')}
        </Typography.Text>
        <div>
          {indicator === 'up' ? <IndicatorUp /> : <IndicatorDown />}{' '}
          <Typography.Text type={indicator === 'up' ? 'success' : 'danger'}>
            {numeral(indicatorAmount).format('0.00')}%
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
