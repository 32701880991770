import React from 'react';
import { Col, Pagination, Row } from 'antd';

import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import AppliedFilters from '../../components/AppliedFilters/AppliedFilters';
import TicketsTable from '../../components/common/tables/TicketsTable';

import style from './style.module.less';

/**
 * Represents tickets content component
 * @returns {JSX.Element}
 */

const TicketsContent = () => {
  const {
    tickets: { filters, fetchTickets, clearFilters, pagination, handlePageChange },
  } = useRootModel();
  const onPageChange = (page, size) => {
    handlePageChange(page, size);
  };
  return (
    <div className={style.ticketsContentWrap}>
      <Row className={style.ticketsHeader} align='middle'>
        <Col span={6}>
          <p>All tickets in CRM</p>
        </Col>
        <Col span={18} className={style.options}>
          <Pagination
            onChange={onPageChange}
            current={pagination.number + 1}
            total={pagination.totalElements}
            showSizeChanger
            defaultPageSize={pagination.size}
          />
        </Col>
      </Row>
      <AppliedFilters filters={filters} fetch={fetchTickets} clearFilters={clearFilters} />
      <TicketsTable />
    </div>
  );
};

export default observer(TicketsContent);
