import React from 'react';
import { Col, Pagination, Row } from 'antd';

import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import AppliedFilters from '../../components/AppliedFilters/AppliedFilters';
import DesksTable from '../../components/common/tables/DesksTable';

import style from './style.module.less';

/**
 * Represents desks content component
 * @returns {JSX.Element}
 */

const DesksContent = () => {
  const {
    desks: { filters, fetchDesks, clearFilters, handlePageChange, pagination },
  } = useRootModel();
  const onPageChange = (page, size) => {
    handlePageChange(page, size);
  };
  return (
    <div className={style.desksContentWrap}>
      <Row className={style.desksHeader} align='middle'>
        <Col span={6}>
          <p>Desks</p>
        </Col>
        <Col span={18} className={style.options}>
          <Pagination
            onChange={onPageChange}
            current={pagination.number + 1}
            total={pagination.totalElements}
            showSizeChanger
            defaultPageSize={pagination.size}
          />
        </Col>
      </Row>
      <AppliedFilters filters={filters} fetch={fetchDesks} clearFilters={clearFilters} />
      <DesksTable />
    </div>
  );
};

export default observer(DesksContent);
