import { flow, types } from 'mobx-state-tree';
import axios from 'axios';

import LogModel from './models/LogModel';
import PaginationModel from '../commonModels/PaginationModel';
import DisplayOptionsModel from '../commonModels/DisplayOptionsModel';
import PositionsFiltersModel from './models/LogsFiltersModel';
import logsTableOptions from './models/logsTableOptions';
import logsDefaultFilters from './models/logsDefaultFilters';

import errorHandler from '../../util/errorHandler';
import filterHandler from '../../util/filterHandler';
import queryString from '../../util/queryString';

const { REACT_APP_API } = process.env;

const LogsModel = types
  .model('LogsModel', {
    logs: types.array(LogModel),
    isLoading: types.optional(types.boolean, false),
    pagination: types.optional(PaginationModel, {}),
    filters: types.optional(PositionsFiltersModel, {}),
    displayOptions: types.array(DisplayOptionsModel),
  })
  .actions((self) => ({
    fetchLogs: flow(function* fetchPositions() {
      const { pagination } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = {
        page: pagination.number,
        size: pagination.size,
        sort: `${pagination.sortColumn},${pagination?.sortOrder}`,
      };
      const filters = filterHandler(self?.filters);

      try {
        const { data } = yield axios.post(
          queryString(REACT_APP_API, 'api/backoffice/logs'),
          filters,
          { params },
        );
        self.tickets = data.data;
        pagination.size = data.page.size;
        pagination.number = data.page.number;
        pagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    handlePageChange(page, size) {
      const { pagination, fetchTickets } = self;
      pagination.number = page - 1;
      pagination.size = size;
      fetchTickets();
    },
    handleSorterChange(sorter) {
      const { pagination, fetchTickets } = self;
      pagination.sortColumn = sorter.sortColumn;
      pagination.sortOrder = sorter.sortOrder;
      fetchTickets();
    },
    clearFilters() {
      self.filters = logsDefaultFilters;
    },
    afterCreate: () => {
      self.displayOptions = logsTableOptions;
    },
  }));

export default LogsModel;
