import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';

import Preloader from '../Preloader';

import style from './forms.module.less';

/**
 * Represents create reminder form
 * @returns {JSX.Element}
 */

const AddCommentForm = () => {
  const {
    traders: { addComment, isLoading, selectedTrader },
  } = useRootModel();
  const [comment, setValues] = useState({
    comment: '',
  });
  const handleChange = (event) => {
    setValues({ ...comment, comment: event.target.value });
  };
  const onSubmit = () => {
    addComment(selectedTrader.id, comment);
    setValues({ ...comment, comment: '' });
  };
  const validateMessages = {
    required: 'This field is required',
  };
  return (
    <div className={style.addCommentFormWrapper}>
      <Form
        className={style.addCommentForm}
        name='Add comment'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
      >
        <Form.Item
          className={style.addCommentForm_formLabel}
          label='New comment'
          name='description'
          value={comment.comment}
          rules={[{ required: true }]}
          onChange={(e) => handleChange(e)}
        >
          <Input.TextArea placeholder='Write comment...' maxLength={200} />
        </Form.Item>
        <Form.Item className={style.addCommentForm_submit_wrapper}>
          {isLoading ? (
            <Preloader />
          ) : (
            <Button className={style.btn} htmlType='submit' type='primary'>
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(AddCommentForm);
