const logsTableOptions = [
  { title: 'ID', checked: true },
  { title: 'Worker', checked: true },
  { title: 'Namespace', checked: true },
  { title: 'Action', checked: true },
  { title: 'Pretty Object View', checked: true },
  { title: 'Before', checked: true },
  { title: 'After', checked: true },
  { title: 'Executed at', checked: true },
];

export default logsTableOptions;
