import React, { useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import languageList from '../../../constants/languageList';

/**
 * Represents add mail template form
 * @returns {JSX.Element}
 */

const AddTemplateTitleForm = ({ setTitle }) => {
  const history = useHistory();

  const [template, setValues] = useState({
    title: '',
    language: {
      key: '',
      name: '',
    },
  });

  const handleChange = (event, prop) => {
    setValues({ ...template, [prop]: event.target.value });
  };
  const onSelectChange = (value, prop) => {
    setValues({ ...template, [prop]: value });
  };

  const onSubmit = () => {
    setTitle(template.title, template.language.key);
    history.push('/mail-layouts/create');
  };

  const validateMessages = {
    required: 'This field is required',
  };

  return (
    <div>
      <Form
        name='Add title'
        onFinish={onSubmit}
        layout='vertical'
        requiredMark={false}
        validateMessages={validateMessages}
      >
        <Form.Item
          label='Title'
          name='title'
          value={template.title}
          rules={[{ required: true }]}
          onChange={(e) => handleChange(e, 'title')}
        >
          <Input size='large' placeholder='Enter your title' maxLength={100} />
        </Form.Item>
        <Form.Item
          label='Language'
          name='language'
          value={template.language.key}
          rules={[{ required: true }]}
        >
          <Select
            value={template.language.name}
            onChange={(value, options) =>
              onSelectChange({ key: options.value, name: options.name }, 'language')
            }
            placeholder='Enter your language'
          >
            {languageList.map((el) => (
              <Select.Option value={el.key} key={el.id} name={el.name}>
                {el.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit' type='primary'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddTemplateTitleForm;
