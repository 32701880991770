import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { Typography } from 'antd';
import numeral from 'numeral';
import cn from 'classnames';
import style from './style.module.less';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const currentMonth = moment().format('MMM');
const randomInteger = (min, max) => {
  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
};
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};
const labels = [
  `${currentMonth} 1`,
  `${currentMonth} 3`,
  `${currentMonth} 6`,
  `${currentMonth} 9`,
  `${currentMonth} 12`,
  `${currentMonth} 15`,
  `${currentMonth} 18`,
  `${currentMonth} 21`,
  `${currentMonth} 24`,
  `${currentMonth} 27`,
  `${currentMonth} 30`,
];
const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => randomInteger(-1000, 1000)),
      borderColor: '#4671E0',
      backgroundColor: '#4671E080',
    },
  ],
};

/**
 * Represents dashboard chart component
 * @returns {JSX.Element}
 */

const DashboardChart = ({ icon, amount, role }) => {
  return (
    <div className={cn(style.chart, { [style.sales]: !role })}>
      <div className={style.chartHeader}>
        <div className={style.iconWrapper}>
          <div className={style.iconEllipse}>{icon}</div>
        </div>
        <div className={style.textContainer}>
          <Typography.Text className={style.title}>Amount of payments</Typography.Text>
          <Typography.Text className={style.amount}>
            ${numeral(amount).format('0,0.00')}
          </Typography.Text>
        </div>
      </div>
      <div className={style.chartContainer}>
        <Line options={options} data={data} type='line' width='100%' height={180} />
      </div>
    </div>
  );
};

export default DashboardChart;
