import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LoadingOutlined } from '@ant-design/icons';
import CloseIcon from '../icons/CloseIcon';
import downloadFile from '../../../util/downloadFile';

/**
 * Represents support preview modal
 * @param {string} url - file's url
 * @param {string} fileName - filename
 * @param {function} closePreview - function to close a preview modal
 * @param {boolean} previewVisible - indicator to show modal state
 * @returns {JSX.Element}
 */

const SupportPreview = ({ url, fileName, closePreview, previewVisible }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal
      title={fileName}
      visible={previewVisible}
      onCancel={closePreview}
      closeIcon={<CloseIcon />}
      footer={
        <Button
          style={{ width: '70px' }}
          type='primary'
          onClick={() => {
            downloadFile(url, setIsLoading, fileName);
          }}
        >
          {!isLoading ? (
            'Save'
          ) : (
            <Spin
              size={24}
              indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />}
            />
          )}
        </Button>
      }
      width={700}
    >
      <img style={{ width: '100%' }} src={url} alt='attached file' />
    </Modal>
  );
};

export default SupportPreview;
