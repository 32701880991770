import React from 'react';
import { Select } from 'antd';
import { ReactComponent as LanguageIcon } from '../../../assets/image/language.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/arrow-down.svg';
import style from './style.module.less';
import languageList from '../../../constants/languageList';

const LanguageSelect = ({ onChange, langKey }) => {
  return (
    <div className={style.languageSelect}>
      <LanguageIcon />
      <Select
        onChange={(value, options) => onChange({ key: options.value, name: options.name })}
        suffixIcon={<ArrowDown />}
        bordered={false}
        defaultValue={langKey.name}
      >
        {languageList.map((el) => (
          <Select.Option value={el.key} key={el.id} name={el.name}>
            {el.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default LanguageSelect;
