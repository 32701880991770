import React from 'react';
import PersonalInfo from './PersonalInfo';
import Finance from './Finance';
import style from './style.module.less';

/**
 * Represents selected worker profile
 * @param {object} worker - object with the selected worker
 * @param {object} user - object with the authenticated user
 * @param {Boolean} isExecutionPlan - boolean value to enable execution plan
 * @returns {JSX.Element}
 */

const SingleWorkerProfile = ({ worker, user, isExecutionPlan }) => {
  return (
    <div className={style.profile_container}>
      <PersonalInfo worker={worker} />
      <Finance worker={worker} user={user} isExecutionPlan={isExecutionPlan} />
    </div>
  );
};

export default SingleWorkerProfile;
