import React from 'react';
import cn from 'classnames';
import { Button, Dropdown } from 'antd';
import { ReactComponent as ArrowUp } from '../../../assets/image/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/arrow-down.svg';

import style from './AppDropdown.module.less';

/**
 * Represents AppDropdown component
 * @param {string} buttonTitle - title for dropdown the main button
 * @param {JSX.Element} ButtonIcon - the main button icon
 * @param {CSSStyleRule} menuClassName - className for menu wrapper
 * @param {JSX.Element} children - html element for dropdown menu
 * @param {boolean} showMenu - menu state
 * @param {function} setShowMenu - function for changing menu state
 * @returns {JSX.Element}
 */
const AppDropdown = ({
  buttonTitle,
  ButtonIcon,
  menuClassName,
  children,
  showMenu,
  setShowMenu,
}) => {
  const toggleMenu = () => setShowMenu((prev) => !prev);

  const overlay = <div className={cn(style.menu, menuClassName)}>{children}</div>;
  return (
    <Dropdown visible={showMenu} onVisibleChange={toggleMenu} overlay={overlay} trigger={['click']}>
      <Button type='link' className={cn(style.button, { [style.active]: showMenu })}>
        <p className={style.buttonTextWithIcon}>
          {ButtonIcon && (
            <span className={style.buttonIconWrapper}>
              <ButtonIcon />
            </span>
          )}
          <span className={style.text}>{buttonTitle}</span>
          {showMenu ? <ArrowUp /> : <ArrowDown />}
        </p>
      </Button>
    </Dropdown>
  );
};

export default AppDropdown;
