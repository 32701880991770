import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Chat from '../../components/Chat/Containers/Chat';

import style from './Chats.module.less';
import { useRootModel } from '../../models/RootStore';
import permission from '../../constants/permission';

/**
 * Represents Chats page
 * @returns {JSX.Element}
 */

const Chats = () => {
  const {
    chat: { getPermission, isChat },
  } = useRootModel();
  useEffect(() => {
    getPermission(permission.CHAT_SECTION);
  }, []);
  const isOperator = true;
  return (
    <section className={style.chat_page}>
      <div className={style.pageContainer}>{isChat && <Chat isOperator={isOperator} />}</div>
    </section>
  );
};

export default observer(Chats);
