import React from 'react';
import { Card, Typography } from 'antd';
import { ReactComponent as CloseIcon } from '../../../assets/image/closeIcon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/image/infoIconMini.svg';
import { ReactComponent as InfoReadIcon } from '../../../assets/image/infoIconMiniRead.svg';
import dateFormatter from '../../../util/dateFormatter';

import style from './style.module.less';

/**
 * @desc Represents notification card component
 * @returns {JSX.Element}
 */

const NotificationCard = ({ card, onClick }) => {
  const handleClick = (notification) => {
    onClick(notification);
  };
  return (
    <div className={style.cardWrapper}>
      <Card
        headStyle={{ padding: '0px 11px' }}
        bodyStyle={{ padding: '14px 11px 9px 11px' }}
        title={
          <div className={style.cardHeader}>
            <div className={style.iconWrapper}>{card.isRead ? <InfoIcon /> : <InfoReadIcon />}</div>
            <Typography.Text>{card?.title}</Typography.Text>
          </div>
        }
        extra={<CloseIcon onClick={() => handleClick(card)} />}
      >
        <div className={style.cardTextWrapper}>
          <p className={style.cardText}>{card?.body}</p>
        </div>
        <div className={style.cardDate}>
          <span>{dateFormatter(card.notificationDate)}</span>
        </div>
      </Card>
    </div>
  );
};

export default NotificationCard;
