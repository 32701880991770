import React, { useState } from 'react';
import { Button, Form, Input, DatePicker } from 'antd';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useRootModel } from '../../../models/RootStore';
import Preloader from '../Preloader';

import style from './forms.module.less';

/**
 * Represents trader reminder form
 * @returns {JSX.Element}
 */

const TraderReminderForm = () => {
  const { id } = useParams();
  const {
    traders: { addReminder, isLoading },
  } = useRootModel();
  const [reminder, setValues] = useState({
    date: '',
    text: '',
  });
  const handleChange = (event, prop) => {
    setValues({ ...reminder, [prop]: event.target.value });
  };
  const onDatePick = (value, date, prop) => {
    if (date) {
      setValues({ ...reminder, [prop]: new Date(date).toISOString() });
    }
  };
  const onSubmit = () => {
    addReminder(id, reminder);
  };
  const validateMessages = {
    required: 'This field is required',
  };
  return (
    <div>
      <Form
        name='Create reminder'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
        requiredMark={false}
      >
        <Form.Item
          label='Reminder'
          name='description'
          value={reminder.text}
          onChange={(e) => handleChange(e, 'text')}
        >
          <Input.TextArea rows={8} placeholder='Enter description...' maxLength={300} />
        </Form.Item>
        <Form.Item
          label='Date & time'
          name='date'
          value={reminder.date}
          rules={[{ required: true }]}
        >
          <DatePicker
            className={style.reminderDatePicker}
            format='YYYY-MM-DD HH:mm'
            showTime={{ format: 'HH:mm' }}
            onChange={(value, date) => onDatePick(value, date, 'date')}
          />
        </Form.Item>
        <Form.Item>
          {isLoading ? (
            <Preloader />
          ) : (
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(TraderReminderForm);
