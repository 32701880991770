/**
 * Represents function for local sorting
 * @param {object} sorter - object with a sorting method
 * @returns {function}
 */
const getSortOrder = (sorter) => {
  let sort;
  if (sorter.order === 'descend') {
    sort = 'DESC';
  }
  if (sorter.order === 'ascend') {
    sort = 'ASC';
  }
  if (sorter.order === undefined) {
    sort = '';
  }

  return sort;
};

export default getSortOrder;
