import React from 'react';
import { observer } from 'mobx-react';
import AppSelect from '../../../ui/AppSelect/AppSelect';

/**
 * Represents retention status filter component
 * @param {Object} filter - retention status filter
 * @param {String} title - filter title
 * @param {Array} data - select data
 * @param {String} param - filter parameter
 * @param {String} placeholder - filter placeholder
 * @returns {JSX.Element}
 */

const FilterSelectItem = ({ filter, title, data, param, placeholder }) => {
  const onChange = (value, info) => {
    filter.setValue(value, param, info.name);
  };

  return (
    <AppSelect
      filter={filter}
      title={title}
      data={data}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default observer(FilterSelectItem);
