import React from 'react';
import { observer } from 'mobx-react';
import AppSelect from '../../../ui/AppSelect/AppSelect';

/**
 * Represents filter by key item component
 * @param {Object} filter - filter by key
 * @param {Array} data - array of objects (list of entities)
 * @param {String} title - filter's title
 * @param {String} param - filter's parameter
 * @param {String} placeholder - filter's placeholder
 * @returns {JSX.Element}
 */
const FilterByKeyItem = ({ filter, data, title, param, placeholder }) => {
  const onChange = (value, info) => {
    filter.setValue(info.key, param, info.name);
  };
  return (
    <AppSelect
      filter={filter}
      title={title}
      data={data}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default observer(FilterByKeyItem);
