import React from 'react';
import { Typography } from 'antd';

import style from '../style.module.less';
import DocumentCard from '../DocumentCard';

/**
 * Represents trader's approved documents
 * @param {object} firstPage - first page of the document
 * @param {object} secondPage - second page of the document
 * @returns {JSX.Element}
 */

const ApprovedDocs = ({ firstPage, secondPage }) => {
  return (
    <div className={style.docsContainer}>
      <div>
        <Typography.Text>Documents (Approved)</Typography.Text>
      </div>
      <div className={style.cardsContainer}>
        {firstPage.status === 'APPROVED' || secondPage.status === 'APPROVED' ? (
          <>
            {firstPage.status === 'APPROVED' && (
              <DocumentCard docsType={firstPage?.status} image={firstPage?.path} />
            )}
            {secondPage.status === 'APPROVED' && (
              <DocumentCard docsType={secondPage?.status} image={secondPage?.path} />
            )}
          </>
        ) : (
          <div>
            <Typography.Text>No Data</Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovedDocs;
