import React from 'react';
import { Typography } from 'antd';

import { observer } from 'mobx-react';
import { useRootModel } from '../../../../models/RootStore';

import ProfileCard from '../ProfileCard';
import AddCommentForm from '../../../../components/common/forms/AddCommentForm';
// eslint-disable-next-line max-len
import { ReactComponent as CommunicationIcon } from '../../../../assets/image/communicationIcon.svg';

import style from './style.module.less';

/**
 * Represents trader communication
 * @returns {JSX.Element}
 */

const TraderCommunication = () => {
  const {
    traders: { comments },
  } = useRootModel();
  return (
    <div className={style.communicationContainer}>
      <div className={style.communicationHeader}>
        <CommunicationIcon />
        <Typography.Text>Communication</Typography.Text>
      </div>
      <div className={style.communicationBody}>
        <div className={style.scrollContainer}>
          {comments.map((comment) => (
            <ProfileCard comment={comment} key={comment.id} />
          ))}
        </div>
        <AddCommentForm />
      </div>
    </div>
  );
};

export default observer(TraderCommunication);
