import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrowLeftOutlined } from '@ant-design/icons';

import { observer } from 'mobx-react';

import SingleTraderContent from '../../containers/SingleTraderContent';
import HeaderOptions from '../../components/HeaderOptions/HeaderOptions';
import Actions from '../../components/HeaderOptions/Actions/Actions';
import CreateCallHistory from '../../components/HeaderOptions/CreateCallHistory/CreateCallHistory';
import CreateReminder from '../../components/HeaderOptions/CreateReminder/CreateReminder';
import SendEmailByTemplate from '../../components/HeaderOptions/SendEmailByTemplate';
import TraderActionsModal from '../../components/common/modals/TraderActionsModal';
import { useRootModel } from '../../models/RootStore';
import permission from '../../constants/permission';

/**
 * Represents Single trader page
 * @returns {JSX.Element}
 */

const SingleTrader = () => {
  const {
    traders: { getPermission, isCreateCallHistory, isCreateReminder, isSendEmail },
  } = useRootModel();

  useEffect(() => {
    getPermission(permission.TRADERS_PROFILE);
  }, []);

  return (
    <section className='page'>
      <div className='page__header'>
        <HeaderOptions>
          <Link to='/traders' className='page__link'>
            <ArrowLeftOutlined />
            <span className='page__link__text'>All Traders</span>
          </Link>
          <Actions className='page__actions-item' />
          {isCreateCallHistory && <CreateCallHistory />}
          {isCreateReminder && <CreateReminder />}
          {isSendEmail && <SendEmailByTemplate />}
        </HeaderOptions>
      </div>
      <div className='page__content'>
        <SingleTraderContent />
      </div>
      <TraderActionsModal />
    </section>
  );
};

export default observer(SingleTrader);
