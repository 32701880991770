import React from 'react';
import { observer } from 'mobx-react';
import AppSelect from '../../../ui/AppSelect/AppSelect';

/**
 * Represents country select filter component
 * @param {Object} filter - filter object
 * @param {String} title - filter object
 * @param {String} placeholder - select placeholder
 * @param {String} param - filter param
 * @param {Object} data - filter data
 * @returns {JSX.Element}
 */
const FilterCountrySelectItem = ({ filter, title, placeholder, param, data }) => {
  const onChange = (value, info) => {
    filter.setValue(info.data, param, info.name);
  };
  return (
    <AppSelect
      filter={filter}
      title={title}
      data={data}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default observer(FilterCountrySelectItem);
