import React from 'react';
import SettingsContent from '../../containers/SettingsContent';

/**
 * Represents Index page
 * @returns {JSX.Element}
 */
const Settings = () => {
  return (
    <section className='page'>
      <div className='page__content'>
        <SettingsContent />
      </div>
    </section>
  );
};

export default Settings;
