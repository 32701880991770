import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';

import Preloader from '../Preloader';

/**
 * Represents create new role form
 * @returns {JSX.Element}
 */

const AddNewRoleForm = () => {
  const [role, setValues] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setValues(event.target.value);
  };
  const onSubmit = () => {
    setIsLoading(true);
    // eslint-disable-next-line no-console
    console.log('New role created =>', role);
    setValues('');
    setIsLoading(false);
  };

  const validateMessages = {
    required: 'This field is required',
  };

  return (
    <div>
      <Form
        name='Add new role'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
      >
        <Form.Item
          label='New role'
          name='role'
          value={role}
          rules={[{ required: true }]}
          onChange={(e) => handleChange(e)}
        >
          <Input size='large' placeholder='Enter name for a new role' maxLength={100} />
        </Form.Item>
        <Form.Item>
          {isLoading ? (
            <Preloader />
          ) : (
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddNewRoleForm;
