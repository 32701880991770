import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button } from 'antd';

/**
 * Represents Error boundary page component
 * @returns {JSX.Element}
 */

const ErrorPageBoundary = () => {
  const history = useHistory();
  const handleHome = () => {
    history.push('/');
    window.location.reload();
  };
  return (
    <Result
      status='500'
      title='500'
      subTitle='Sorry, something went wrong.'
      extra={
        <Button onClick={handleHome} type='primary'>
          Back to Dashboard
        </Button>
      }
    />
  );
};

export default ErrorPageBoundary;
