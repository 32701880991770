import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const CalendarIconSvg = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M11.2222 1V3.66667M1 2.33333V13H13.4444V2.33333H1ZM1 6.33333H13.4444H1ZM3.22222 1V3.66667V1ZM5.88889 1V3.66667V1ZM8.55556 1V3.66667V1Z'
      stroke='#9A9AC0'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const CalendarIcon = (props) => <Icon component={CalendarIconSvg} {...props} />;

export default CalendarIcon;
