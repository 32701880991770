import { types } from 'mobx-state-tree';

const FilterBoolItemModel = types
  .model('FilterBoolItemModel', {
    field: types.optional(types.string, ''),
    value: types.maybeNull(types.boolean),
    name: types.optional(types.string, ''),
  })
  .actions((filter) => ({
    setValue(value, field, name) {
      filter.value = value;
      filter.field = field;
      filter.name = name;
    },
    removeFilter() {
      filter.value = null;
      filter.field = '';
      filter.name = '';
    },
  }));

export default FilterBoolItemModel;
