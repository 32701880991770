import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react';
import HeaderOptions from '../../components/HeaderOptions/HeaderOptions';
import MailLayoutsContainer from '../../containers/MailLayoutsContainer';
import AddNewTemplate from '../../components/HeaderOptions/AddNewTemplate';
import AddTemplateTitleModal from '../../components/common/modals/AddTemplateTitleModal';
import { useRootModel } from '../../models/RootStore';
import Preloader from '../../components/common/Preloader';
import LanguageSelect from '../../components/HeaderOptions/LanguageSelect';
import permission from '../../constants/permission';

/**
 * Represents MailLayouts page
 * @returns {JSX.Element}
 */

const MailLayouts = () => {
  const {
    mailLayouts: {
      fetchMailLayouts,
      mailLayouts,
      setSelectedLayout,
      isModalOpen,
      setIsModalOpen,
      setTitle,
      isLoading,
      setLangKey,
      langKey,
      getPermission,
      isAddNewTemplate,
      isMailLayouts,
      isEditMailLayouts,
    },
  } = useRootModel();

  useEffect(() => {
    getPermission(permission.MAIL_LAYOUTS_SECTION);
    fetchMailLayouts();
  }, [langKey.key]);

  const onLangKeyChange = (value) => {
    setLangKey(value);
  };

  return (
    <section className='page'>
      <div className='page__header'>
        <HeaderOptions>
          <Typography.Text>Mail Layouts</Typography.Text>
          {isAddNewTemplate && <AddNewTemplate setIsModalOpen={setIsModalOpen} />}
          <LanguageSelect onChange={onLangKeyChange} langKey={langKey} />
        </HeaderOptions>
      </div>
      <div className='page__content'>
        {isMailLayouts && (
          <MailLayoutsContainer
            isEditMailLayouts={isEditMailLayouts}
            mailLayouts={mailLayouts}
            setSelectedLayout={setSelectedLayout}
          />
        )}
      </div>
      {isLoading && (
        <div className='page__preloader'>
          <Preloader size={60} />
        </div>
      )}
      <AddTemplateTitleModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setTitle={setTitle}
      />
    </section>
  );
};

export default observer(MailLayouts);
