import React, { useEffect } from 'react';
import { Button, Typography } from 'antd';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import dateFormatter from '../../../util/dateFormatter';
import { ReactComponent as Ticks } from '../../../assets/image/done-ticks.svg';
import { useRootModel } from '../../../models/RootStore';
import SupportMessageFile from '../SupportMessageFile';

import style from './style.module.less';

/**
 * Represents support message item component
 * @returns {JSX.Element}
 */

const SupportMessageItem = () => {
  const { id } = useParams();
  const {
    support: { fetchSelectedEmail, selectedEmail },
  } = useRootModel();
  useEffect(() => {
    fetchSelectedEmail(id);
  }, []);
  const getFileType = (str) => {
    return str.split('.')[1];
  };
  const changeStatus = () => {
    if (!selectedEmail.isProcessed) selectedEmail.setProcessed();
  };
  return (
    <div className={style.supportItem}>
      <div className={style.header}>
        <div>
          <div className={style.header_item}>
            <Typography.Text className={style.header_item_heading}>From:</Typography.Text>
            <Typography.Text className={style.header_item_name}>
              {selectedEmail?.trader.name}
            </Typography.Text>
            <Typography.Text className={style.header_item_address}>
              {selectedEmail?.trader.email}
            </Typography.Text>
          </div>
          <div className={style.header_item}>
            <Typography.Text className={style.header_item_heading}>Type:</Typography.Text>
            <Typography.Text>{selectedEmail?.type}</Typography.Text>
          </div>
        </div>
        <div className={style.statusBtnWrapper}>
          <Button
            icon={<Ticks />}
            className={cn(style.statusBtn, {
              [style.statusBtn__processing]: !selectedEmail?.isProcessed,
            })}
            onClick={changeStatus}
            type={selectedEmail?.isProcessed && 'primary'}
          >
            {selectedEmail?.isProcessed ? 'Processed' : 'Processing'}
          </Button>
        </div>
      </div>
      <div className={style.headingWrap}>
        <Typography.Text className={style.textHeading}>Subject</Typography.Text>
        <Typography.Text className={style.text}>{selectedEmail?.subject}</Typography.Text>
      </div>
      <div className={style.headingWrap}>
        <Typography.Text className={style.textHeading}>Description:</Typography.Text>
        <Typography.Text className={style.text}>{selectedEmail?.description}</Typography.Text>
      </div>
      <div className={style.headingWrap}>
        <Typography className={style.textHeading}>
          {dateFormatter(selectedEmail?.createdAt)}
        </Typography>
      </div>
      <div className={style.filesWrapper}>
        {selectedEmail?.attachedFiles.map((file) => (
          <SupportMessageFile
            key={file.url}
            url={file.url}
            type={getFileType(file.originalFilename)}
            name={file.originalFilename}
            setIsModal={file?.setIsModal}
            isModal={file?.isModalOpen}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(SupportMessageItem);
