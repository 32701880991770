import { types } from 'mobx-state-tree';

const NotificationModel = types.model('NotificationModel', {
  body: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  isRead: types.maybeNull(types.boolean),
  notificationDate: types.maybeNull(types.string),
  recipientId: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
});

export default NotificationModel;
