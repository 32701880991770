import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { Avatar } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import { ReactComponent as Dashboard } from '../../assets/image/dashboard.svg';
import { ReactComponent as Traders } from '../../assets/image/tradersIcon.svg';
import { ReactComponent as Desks } from '../../assets/image/desks.svg';
import { ReactComponent as Tickets } from '../../assets/image/tickets.svg';
import { ReactComponent as Workers } from '../../assets/image/workers.svg';
import { ReactComponent as Analytics } from '../../assets/image/analytics.svg';
import { ReactComponent as Campaigns } from '../../assets/image/campaigns.svg';
import { ReactComponent as Mail } from '../../assets/image/mail.svg';
import { ReactComponent as Chats } from '../../assets/image/chat.svg';
import { ReactComponent as Logs } from '../../assets/image/logs.svg';
import { ReactComponent as Positions } from '../../assets/image/positions.svg';
import { ReactComponent as Assets } from '../../assets/image/assets.svg';
import { ReactComponent as Support } from '../../assets/image/support.svg';
import { ReactComponent as Toggle } from '../../assets/image/toggle.svg';
import { ReactComponent as Settings } from '../../assets/image/settings.svg';

import Logo from '../ui/Logo/Logo';
import getAnimationClasses from '../../util/getAnimationClasses';
import NavigationItem from '../NavigationItem/NavigationItem';

import useOnClick from '../../hooks/useOnClick';
import useExecutorForKey from '../../hooks/useExecutorForKey';

import style from './Sidebar.module.less';

const mainElements = [
  { id: 1, text: 'Dashboard', icon: Dashboard, url: '/' },
  { id: 2, text: 'Traders', icon: Traders, url: '/traders' },
  { id: 3, text: 'Desks', icon: Desks, url: '/desks' },
  { id: 4, text: 'Tickets', icon: Tickets, url: '/tickets' },
  { id: 5, text: 'Workers', icon: Workers, url: '/workers' },
  { id: 6, text: 'Analytics', icon: Analytics, url: '/analytics' },
  { id: 7, text: 'Campaigns', icon: Campaigns, url: '/campaigns' },
  { id: 8, text: 'Mail Layouts', icon: Mail, url: '/mail-layouts' },
  { id: 9, text: 'Chats', icon: Chats, url: '/chats' },
  { id: 10, text: 'Logs', icon: Logs, url: '/logs' },
  { id: 11, text: 'Positions', icon: Positions, url: '/positions' },
  { id: 12, text: 'Market info', icon: Assets, url: '/market-info' },
  { id: 13, text: 'Support', icon: Support, url: '/support' },
  { id: 14, text: 'Settings', icon: Settings, url: '/settings' },
];

const Sidebar = () => {
  const {
    user: { userData },
    chat: { unreadMessages },
    support: { fetchUnprocessedTickets, unprocessedTicketsAmount },
  } = useRootModel();

  useEffect(() => {
    // getUser();
    fetchUnprocessedTickets();
  }, []);

  const { pathname } = useLocation();
  const wrapperRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => setIsOpen((prev) => !prev);
  const closeSidebar = () => setIsOpen(false);

  useOnClick(wrapperRef, closeSidebar);
  useExecutorForKey('Escape', closeSidebar);

  return (
    <aside className={cn(style.sidebar, { [style.open]: isOpen })} ref={wrapperRef}>
      <div>
        <div className={cn(style.logoAndUserWrapper, { [style.withBorder]: isOpen })}>
          <div className={style.logoWrapper}>
            <div className={style.logo}>
              <Logo />
            </div>
            <CSSTransition
              in={isOpen}
              unmountOnExit
              timeout={500}
              classNames={getAnimationClasses(style)}
            >
              <span className={style.title}>CRM</span>
            </CSSTransition>
          </div>
        </div>

        <nav className={style.nav}>
          <div className={style.avatarWrapper}>
            <div className={style.avatar}>
              {userData?.imageUrl === null ? (
                <Avatar size={40}>
                  {userData?.firstName?.charAt(0)} {userData?.lastName?.charAt(0)}
                </Avatar>
              ) : (
                <Avatar size={40} src={userData?.imageUrl} />
              )}
            </div>
            <CSSTransition
              in={isOpen}
              unmountOnExit
              timeout={500}
              classNames={getAnimationClasses(style)}
            >
              <div className={style.userInfo}>
                <span className={style.userName}>
                  {userData?.firstName} {userData?.lastName}
                </span>
                <span className={style.userEmail}>{userData?.email}</span>
              </div>
            </CSSTransition>
          </div>

          <ul className={style.list}>
            {mainElements.map(({ id, text, icon, url }) => (
              <li key={id}>
                <NavigationItem
                  onClick={closeSidebar}
                  text={text}
                  icon={icon}
                  url={url}
                  showText={isOpen}
                  unreadMessages={unreadMessages}
                  unprocessedTicketsAmount={unprocessedTicketsAmount}
                />
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <ul className={cn(style.list, style.bottomList)}>
        <NavigationItem
          icon={() => (
            <span className={style.toggleIcon}>
              <Toggle />
            </span>
          )}
          showText={isOpen}
          onClick={toggleSidebar}
          text='Toggle sidebar'
          url={pathname}
          className={cn(style.toggle, { [style.toggleActive]: isOpen })}
        />
      </ul>
    </aside>
  );
};

export default observer(Sidebar);
