import { flow, getParent, types } from 'mobx-state-tree';
import axios from 'axios';
import WorkersListModel from '../../commonModels/WorkersListModel';
import errorHandler from '../../../util/errorHandler';

const AttachedFilesModel = types
  .model('AttachedFilesModel', {
    originalFilename: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    isModalOpen: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setIsModal() {
      self.isModalOpen = !self.isModalOpen;
    },
  }));

const SupportItemModel = types
  .model('SupportItemModel', {
    attachedFiles: types.array(AttachedFilesModel),
    createdAt: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    isProcessed: types.maybeNull(types.boolean),
    isRead: types.maybeNull(types.boolean),
    subject: types.maybeNull(types.string),
    trader: types.maybeNull(WorkersListModel),
    type: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setProcessed: flow(function* setProcessed() {
      const { decrementUnprocessed } = getParent(self);
      const { id } = self;
      if (!localStorage.crm_token) return;

      try {
        yield axios.put(`/api/support/${id}/processed`);
        self.isProcessed = true;
        decrementUnprocessed();
      } catch (e) {
        errorHandler(self, e);
      }
    }),
  }));

export default SupportItemModel;
