import React from 'react';
import { ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import './PhotoMessage.css';

export class PhotoMessage extends React.PureComponent {
  render() {
    const error = this.props.data.status && this.props.data.status.error === true;

    return (
      <div className='rce-mbox-photo'>
        <div
          className='rce-mbox-photo--img'
          style={
            this.props.data.width &&
            this.props.data.height && {
              width: this.props.data.width,
              height: this.props.data.height,
            }
          }
        >
          <img
            src={this.props.data.uri}
            alt={this.props.data.alt}
            onClick={this.props.onOpen}
          />
          {error && (
            <div className='rce-mbox-photo--img__block'>
              <span className='rce-mbox-photo--img__block-item rce-mbox-photo--error'>
                <ExclamationCircleOutlined />
              </span>
            </div>
          )}
          {!error && (
            <div className='rce-mbox-photo--img__block'
                 onClick={this.props.onOpen}
            >
              <div className='preview-wrapper'>
                <span className='preview-text'>Preview</span>
                <EyeOutlined className='preview-icon'/>
              </div>
            </div>
          )}
        </div>
        {this.props.text && <div className='rce-mbox-text'><p dangerouslySetInnerHTML={{ __html: this.props.text }} /></div>}
      </div>
    );
  }
}

PhotoMessage.defaultProps = {
  text: '',
  data: {},
  onDownload: null,
  onOpen: null,
  onLoad: null,
  onPhotoError: null,
};

export default PhotoMessage;
