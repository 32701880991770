import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const PhoneIconSvg = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M5.85396 8.09147C6.66767 8.91694 7.65225 9.55425 8.73853 9.95864L10.2081 8.78922C10.2517 8.75922 10.3033 8.74315 10.3562 8.74315C10.4091 8.74315 10.4608 8.75922 10.5043 8.78922L13.233 10.5473C13.3366 10.6095 13.4243 10.695 13.4891 10.797C13.554 10.899 13.5943 11.0147 13.6067 11.1349C13.6192 11.2552 13.6036 11.3766 13.561 11.4898C13.5185 11.6029 13.4502 11.7046 13.3616 11.7868L12.0831 13.0498C11.9 13.2307 11.6749 13.3635 11.428 13.4363C11.1811 13.5091 10.92 13.5196 10.6681 13.4669C8.15468 12.9478 5.83803 11.7321 3.98288 9.95864C2.16891 8.16758 0.913654 5.88861 0.369377 3.3982C0.31561 3.15014 0.326832 2.8924 0.401956 2.64995C0.47708 2.40751 0.613558 2.18858 0.798165 2.01438L2.12351 0.735819C2.20552 0.651538 2.30554 0.586903 2.41608 0.546754C2.52661 0.506605 2.64479 0.491982 2.76178 0.503981C2.87877 0.515979 2.99152 0.554288 3.09161 0.616039C3.19169 0.67779 3.27651 0.761382 3.3397 0.860557L5.1562 3.55412C5.18753 3.59642 5.20445 3.64766 5.20445 3.7003C5.20445 3.75294 5.18753 3.80418 5.1562 3.84648L3.9595 5.28486C4.37487 6.3494 5.02201 7.30812 5.85396 8.09147Z'
      fill='#4671E0'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const PhoneIcon = (props) => <Icon component={PhoneIconSvg} {...props} />;

export default PhoneIcon;
