import React, { useEffect } from 'react';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { Button, Popover, Table, Typography, Divider } from 'antd';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useRootModel } from '../../../models/RootStore';

import dateFormatter from '../../../util/dateFormatter';
import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';
import displayOptionsFilter from '../../../util/displayOptionsFilter';

import DeclineIcon from '../icons/DeclineIcon';
import SuccessIcon from '../icons/SuccessIcon';
import PendingIcon from '../icons/PendingIcon';

import style from './tables.module.less';

/**
 * Represents tickets table
 * @returns {JSX.Element}
 */

const TicketsTable = () => {
  const {
    tickets: {
      tickets,
      isLoading,
      pagination,
      handleSorterChange,
      displayOptions,
      fetchTickets,
      changeTicketStatus,
    },
  } = useRootModel();
  const handleChangeStatus = (value, id) => {
    changeTicketStatus(value, id);
  };
  useEffect(() => {
    fetchTickets();
  }, []);
  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handleSorterChange({ sortColumn: column, sortOrder });
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: true,
      sortOrder: pagination.sortColumn === 'id' && sortOrderToString(pagination.sortOrder),
      render: (id) => (
        <div className={style.linkCell}>
          <Typography.Text className={style.link}>#{id}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      sorter: true,
      sortOrder: pagination.sortColumn === 'amount' && sortOrderToString(pagination.sortOrder),
      render: (amount) => <div>USD {numeral(amount).format('0,0.00')}</div>,
    },
    {
      title: 'Trader',
      dataIndex: 'trader',
      key: 'trader',
      width: 210,
      sorter: true,
      sortOrder: pagination.sortColumn === 'trader' && sortOrderToString(pagination.sortOrder),
      render: (trader) => (
        <div className={style.linkCell}>
          <Link to='traders' className={style.link}>
            {trader?.name}
          </Link>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: true,
      sortOrder: pagination.sortColumn === 'status' && sortOrderToString(pagination.sortOrder),
      render: (status, ticket) => (
        <div className={style.ticketStatus_wrapper}>
          {status === 'CREATED' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__created}`}>
              <div className={style.ellipse} />
              <p className={`${style.ticketText} ${style.ticketText__created}`}>{status}</p>
            </div>
          )}
          {status === 'PENDING' && (
            <Popover
              overlayStyle={{ width: '200px', textAlign: 'center' }}
              visible={status?.popover}
              trigger='click'
              onVisibleChange={status.setPopover}
              placement='right'
              content={
                <div className={style.statusActionsWrapper}>
                  <Typography.Text
                    className={style.statusActionsText}
                    onClick={() => handleChangeStatus('APPROVED', ticket?.id)}
                    type='success'
                  >
                    Approve
                  </Typography.Text>
                  <Divider type='vertical' />
                  <Typography.Text
                    className={style.statusActionsText}
                    onClick={() => handleChangeStatus('DECLINED', ticket?.id)}
                    type='danger'
                  >
                    Decline
                  </Typography.Text>
                </div>
              }
            >
              <Popover placement='right' trigger='hover' content={<p>Click to change status</p>}>
                <Button
                  icon={<PendingIcon />}
                  className={`${style.ticketStatus} ${style.ticketStatus__pending}`}
                >
                  <p className={`${style.ticketText} ${style.ticketText__pending}`}>{status}</p>
                </Button>
              </Popover>
            </Popover>
          )}
          {status === 'APPROVED' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__approved}`}>
              <SuccessIcon />
              <p className={`${style.ticketText} ${style.ticketText__approved}`}>{status}</p>
            </div>
          )}
          {status === 'DECLINED' && (
            <div className={`${style.ticketStatus} ${style.ticketStatus__declined}`}>
              <DeclineIcon />
              <p className={`${style.ticketText} ${style.ticketText__declined}`}>{status}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 140,
      sorter: true,
      sortOrder: pagination.sortColumn === 'type' && sortOrderToString(pagination.sortOrder),
    },
    {
      className: `${style.column}`,
      title: 'Opened by',
      dataIndex: 'openedBy',
      key: 'openedBy',
      width: 150,
      sorter: true,
      sortOrder: pagination.sortColumn === 'openedBy' && sortOrderToString(pagination.sortOrder),
      render: (openedBy) => (
        <div className={style.linkCell}>
          {!openedBy ? (
            <Typography.Text>Self deposit</Typography.Text>
          ) : (
            <Typography.Text className={style.link}>{openedBy?.name}</Typography.Text>
          )}
        </div>
      ),
    },
    {
      title: 'Opened',
      dataIndex: 'openedAt',
      key: 'openedAt',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'openedAt' && sortOrderToString(pagination.sortOrder),
      render: (openedAt) => <div>{dateFormatter(openedAt)}</div>,
    },
    {
      title: 'Closed',
      dataIndex: 'closedAt',
      key: 'closedAt',
      width: 143,
      sorter: true,
      sortOrder: pagination.sortColumn === 'closedAt' && sortOrderToString(pagination.sortOrder),
      render: (closedAt) => (
        <>
          {closedAt === null ? (
            <Typography.Text type='danger'>Not closed yet</Typography.Text>
          ) : (
            dateFormatter(closedAt)
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        className={style.table}
        onChange={onPageChange}
        columns={displayOptionsFilter(displayOptions, columns)}
        loading={isLoading}
        dataSource={toJS(tickets)}
        scroll={{ x: 'max-content', y: '620px' }}
        rowKey='id'
        pagination={false}
      />
    </>
  );
};

export default observer(TicketsTable);
