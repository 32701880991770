import React from 'react';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';
import NotificationCard from './NotificationCard';

import { ReactComponent as MarkIcon } from '../../assets/image/markRead.svg';
import { ReactComponent as ArrowLeft } from '../../assets/image/arrowSquareLeft.svg';
import { ReactComponent as ArrowRight } from '../../assets/image/arrowSquareRight.svg';

import style from './style.module.less';
import prettyLog from '../../util/prettyLog';
import Preloader from '../../components/common/Preloader';

/**
 * Represents notifications container component
 * @returns {JSX.Element}
 */

const NotificationsContent = () => {
  const {
    notifications: {
      notifications,
      setAllIsRead,
      deleteNotification,
      isFirstPage,
      isLastPage,
      setNextPage,
      setPreviousPage,
      isLoading,
    },
  } = useRootModel();
  prettyLog('HERE', isLastPage);
  const handleDelete = (notification) => {
    deleteNotification([notification]);
  };
  return (
    <div className={style.notificationsContainer}>
      <div className={style.notificationsContainer__actions}>
        <Button onClick={setAllIsRead} className={style.btn} icon={<MarkIcon />} type='primary'>
          Mark all as read
        </Button>
        <div className={style.paginationWrapper}>
          <button
            onClick={setPreviousPage}
            className={style.paginationButton}
            disabled={isFirstPage}
            type='button'
          >
            <ArrowLeft />
          </button>
          <button
            onClick={setNextPage}
            className={style.paginationButton}
            disabled={isLastPage}
            type='button'
          >
            <ArrowRight />
          </button>
        </div>
      </div>
      {isLoading ? (
        <Preloader />
      ) : (
        <div>
          {notifications.map((el) => (
            <NotificationCard key={el.id} card={el} onClick={handleDelete} />
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(NotificationsContent);
