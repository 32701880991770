import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Input } from 'antd';
import { toJS } from 'mobx';

import { observer } from 'mobx-react';
import { ReactComponent as EditIcon } from '../../../assets/image/edit-icon.svg';
import { ReactComponent as SaveIcon } from '../../../assets/image/saveIcon.svg';

import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';

import style from './tables.module.less';

/**
 * Represents market info table
 * @returns {JSX.Element}
 */

const MarketInfoTable = ({
  fetchMarket,
  marketInfo,
  isLoading,
  pagination,
  handleSorterChange,
  setEditing,
  updateMarketInfo,
  setSearchString,
  debouncedSearchTerm,
}) => {
  const [fields, setFields] = useState({
    maxMultiplier: null,
    overnightCommission: null,
    swapBuyCommission: null,
    swapSellCommission: null,
  });

  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handleSorterChange({ sortColumn: column, sortOrder });
  };

  const handleSetEditing = (id, row) => {
    setFields({
      maxMultiplier: row.maxMultiplier,
      overnightCommission: row.overnightCommission,
      swapBuyCommission: row.swapBuyCommission,
      swapSellCommission: row.swapSellCommission,
    });
    setEditing(id);
  };

  const handleChangeFields = (prop, value) => {
    setFields({ ...fields, [prop]: value });
  };

  const handleSubmit = (data) => {
    if (!Object.values(fields).includes(null)) updateMarketInfo(data);
  };

  useEffect(() => {
    fetchMarket();
  }, []);

  useEffect(() => {
    setSearchString(debouncedSearchTerm);
    fetchMarket();
  }, [debouncedSearchTerm]);

  const columns = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      width: 100,
      render: (icon) => (
        <div>
          <img style={{ width: '25px', height: '25px' }} src={icon} alt='instrument' />
        </div>
      ),
    },
    {
      title: 'Instrument (as in terminal)',
      dataIndex: 'symbolCode',
      key: 'symbolCode',
      className: `${style.column}`,
      width: 190,
      sorter: true,
      sortOrder: pagination.sortColumn === 'symbolCode' && sortOrderToString(pagination.sortOrder),
    },
    {
      title: 'Maximum multiplier',
      dataIndex: 'maxMultiplier',
      key: 'maxMultiplier',
      className: `${style.column}`,
      width: 150,
      sorter: true,
      sortOrder:
        pagination.sortColumn === 'maxMultiplier' && sortOrderToString(pagination.sortOrder),
      render: (maxMultiplier, row) => (
        <div style={{ height: '21px' }}>
          {row.isEditing ? (
            <Input
              value={fields.maxMultiplier}
              onChange={(e) => handleChangeFields('maxMultiplier', e.target.value)}
            />
          ) : (
            <div>{maxMultiplier}</div>
          )}
        </div>
      ),
    },
    {
      title: 'Commission (%)',
      dataIndex: 'overnightCommission',
      key: 'overnightCommission',
      className: `${style.column}`,
      width: 140,
      sorter: true,
      sortOrder:
        pagination.sortColumn === 'overnightCommission' && sortOrderToString(pagination.sortOrder),
      render: (overnightCommission, row) => (
        <div>
          {row.isEditing ? (
            <Input
              value={fields.overnightCommission}
              onChange={(e) => handleChangeFields('overnightCommission', e.target.value)}
            />
          ) : (
            <div>{overnightCommission}</div>
          )}
        </div>
      ),
    },
    {
      title: 'Swap buy (%)',
      dataIndex: 'swapBuyCommission',
      key: 'swapBuyCommission',
      className: `${style.column}`,
      width: 150,
      sorter: true,
      sortOrder:
        pagination.sortColumn === 'swapBuyCommission' && sortOrderToString(pagination.sortOrder),
      render: (swapBuyCommission, row) => (
        <div>
          {row.isEditing ? (
            <Input
              value={fields.swapBuyCommission}
              onChange={(e) => handleChangeFields('swapBuyCommission', e.target.value)}
            />
          ) : (
            <div>{swapBuyCommission}</div>
          )}
        </div>
      ),
    },
    {
      title: 'Swap sell (%)',
      dataIndex: `swapSellCommission`,
      key: `swapSellCommission`,
      className: `${style.column}`,
      width: 143,
      sorter: true,
      sortOrder:
        pagination.sortColumn === `swapSellCommission` && sortOrderToString(pagination.sortOrder),
      render: (swapSellCommission, row) => (
        <div>
          {row.isEditing ? (
            <Input
              value={fields.swapSellCommission}
              onChange={(e) => handleChangeFields('swapSellCommission', e.target.value)}
            />
          ) : (
            <div>{swapSellCommission}</div>
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      width: 143,
      render: (_, row) => (
        <div className={style.actionsContainer}>
          <Tooltip title='Edit'>
            <EditIcon onClick={() => handleSetEditing(row.id, row)} />
          </Tooltip>
          <Tooltip title='Save'>
            <SaveIcon onClick={() => handleSubmit(fields)} />
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        className={style.table}
        onChange={onPageChange}
        columns={columns}
        loading={isLoading}
        dataSource={toJS(marketInfo)}
        scroll={{ x: 'max-content', y: '620px' }}
        rowKey='id'
        pagination={false}
      />
    </>
  );
};

export default observer(MarketInfoTable);
