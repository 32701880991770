import React from 'react';
import { Input } from 'antd';
import { ReactComponent as SearchIcon } from '../../../assets/image/searchIcon.svg';
import style from './style.module.less';

const AppSearch = ({ search, setSearch, placeholder, searchWidth }) => {
  const handleSearch = (value) => {
    setSearch(value);
  };
  return (
    <div className={style.appSearch} style={{ width: searchWidth }}>
      <Input
        prefix={<SearchIcon />}
        bordered={false}
        placeholder={placeholder}
        value={search}
        allowClear
        onChange={(e) => handleSearch(e.target.value)}
        style={{ width: searchWidth }}
      />
    </div>
  );
};

export default AppSearch;
