import React from 'react';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';
import CloseIcon from '../icons/CloseIcon';
import EditCalendarEventForm from '../forms/EditCalendarEventForm';

/**
 * Represents edit reminder modal component
 * @returns {JSX.Element}
 */

const EditReminderModal = () => {
  const {
    calendar: { isEditModalOpen, setIsEditModalOpen },
  } = useRootModel();
  const closeModal = () => {
    setIsEditModalOpen(false);
  };
  return (
    isEditModalOpen && (
      <Modal
        title='Edit reminder'
        onCancel={closeModal}
        visible={isEditModalOpen}
        bodyStyle={{ padding: 0 }}
        width={550}
        closeIcon={<CloseIcon />}
        footer={null}
      >
        <div>
          <EditCalendarEventForm />
        </div>
      </Modal>
    )
  );
};

export default observer(EditReminderModal);
