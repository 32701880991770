import React from 'react';
import { Typography } from 'antd';

/**
 * @desc component for handling and display errors
 * @param {object} error - object with error
 * @param {object} className - object with styles
 * @returns {JSX.Element}
 */
const ErrorHandler = ({ error, className }) => {
  return (
    <Typography.Text type='danger' className={className}>
      {`Error: ${error}`}
    </Typography.Text>
  );
};

export default ErrorHandler;
