import React from 'react';
import { Modal } from 'antd';
import CloseIcon from '../icons/CloseIcon';
import AddTemplateTitleForm from '../forms/AddTemplateTitleForm';

/**
 * Represents add template title modal
 * @returns {JSX.Element}
 */

const AddTemplateTitleModal = ({ isModalOpen, setIsModalOpen, setTitle }) => {
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    isModalOpen && (
      <Modal
        title='Template title'
        onCancel={closeModal}
        visible={isModalOpen}
        bodyStyle={{ padding: 0 }}
        width={550}
        closeIcon={<CloseIcon />}
        footer={null}
      >
        <div>
          <AddTemplateTitleForm setTitle={setTitle} />
        </div>
      </Modal>
    )
  );
};

export default AddTemplateTitleModal;
