import React from 'react';
import style from './Analytics.module.less';

/**
 * Represents Analytics page
 * @returns {JSX.Element}
 */
const Analytics = () => {
  return <main className={style.analytics}>Analytics</main>;
};

export default Analytics;
