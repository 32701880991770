import React from 'react';
import { Divider, Input } from 'antd';

import style from '../AppSelect/AppSelect.module.less';

/**
 * Represents AppInput component
 * @param {string} title - select title
 * @param {object} amount - amount state
 * @param {function} setAmount - function for updating amount state
 * @param {object} filter - object with filters
 * @returns {JSX.Element}
 */

const AppRangeInput = ({ title, amount, setAmount, filter }) => {
  const handleChange = (e, prop) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    // eslint-disable-next-line no-restricted-globals
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setAmount({ ...amount, [prop]: value });
    }
  };
  return (
    <fieldset className={style.SelectRangeFieldset}>
      <legend className={style.newSelectLegend}>{title}</legend>
      <Input
        allowClear
        value={filter?.fromValue}
        bordered={false}
        className={style.AppRangeSelect}
        placeholder='From...'
        onChange={(e) => handleChange(e, 'fromValue')}
      />
      <Divider type='vertical' />
      <Input
        allowClear
        value={filter?.toValue}
        bordered={false}
        className={style.AppRangeSelect}
        placeholder='To...'
        onChange={(e) => handleChange(e, 'toValue')}
      />
    </fieldset>
  );
};

export default AppRangeInput;
