import axios from 'axios';

const token = localStorage.getItem('id_token');
const host = `${process.env.REACT_APP_CHAT}/`;
const chatQuery = axios.create({
  baseURL: host,
  // timeout: 8000,
  headers: {
    'access-token': `Bearer ${token}`,
    Authorization: `Bearer ${token}`,
  },
});

export default chatQuery;
