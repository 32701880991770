import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

const LoginAsIconSvg = () => (
  <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M7.08926 13H10.6248C11.2957 13 11.8577 12.7404 12.2929 12.2412C12.728 11.7619 13 11.1229 13 10.404V2.59601C13 1.87711 12.728 1.2381 12.2929 0.758833C11.8577 0.27957 11.2775 0 10.6248 0H7.08926V1.63748H10.6248C11.1325 1.63748 11.5132 2.05684 11.5132 2.59601V10.404C11.5132 10.9432 11.1325 11.3825 10.6248 11.3825H7.08926V13ZM0 4.95238V8.06759C0 8.42704 0.271967 8.72657 0.598326 8.72657H3.89819V11.1828C3.89819 11.4025 3.98884 11.5822 4.17015 11.682C4.24268 11.702 4.33333 11.702 4.3696 11.702C4.49651 11.702 4.6053 11.6621 4.69596 11.5622L8.95676 6.86943C9.11994 6.68971 9.10181 6.33026 8.95676 6.13057L4.69596 1.45776C4.55091 1.298 4.3696 1.27803 4.17015 1.33794C3.98884 1.43779 3.89819 1.59754 3.89819 1.8172V4.2934H0.598326C0.271967 4.2934 0 4.59293 0 4.95238Z'
      fill='#334D6E'
    />
  </svg>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const LoginAsIcon = (props) => <Icon component={LoginAsIconSvg} {...props} />;

export default LoginAsIcon;
