import React, { useState } from 'react';
import cn from 'classnames';
import { Button, Divider, Image, Typography, Spin } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LoadingOutlined } from '@ant-design/icons';

import { ReactComponent as DownloadIcon } from '../../../../assets/image/downloadIcon.svg';
import SuccessIcon from '../../../../components/common/icons/SuccessIcon';
import downloadFile from '../../../../util/downloadFile';

import style from './style.module.less';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

/**
 * Represents trader's document card
 * @param {string} docsType - document's status
 * @param {string} image - document's image url
 * @param {function} setDocumentStatus - api for setting document's status
 * @param {string} page - type of page
 * @param {number} selectedTrader - selected trader id
 * @returns {JSX.Element}
 */

const DocumentCard = ({ docsType, image, setDocumentStatus, page, selectedTrader }) => {
  const [loading, setLoading] = useState();
  const handleApprove = () => {
    setDocumentStatus({ page, traderDocumentStatus: 'APPROVED' }, selectedTrader);
  };
  const handleDecline = () => {
    setDocumentStatus({ page, traderDocumentStatus: 'DECLINED' }, selectedTrader);
  };
  return (
    <div className={style.cardWrapper}>
      <div>
        <div className={style.cardImageContainer}>
          <div className={style.cardImage}>
            <Image
              src={image}
              alt='passport'
              fallback='../../../assets/image/common/placeholder-photo.png'
              placeholder={
                <Image preview={false} src='../../../assets/image/common/preloader-photo.png' />
              }
            />
          </div>
          <div>
            <div className={style.docsName}>
              <Typography.Text>Image</Typography.Text>
            </div>
            <div>
              {docsType === 'IN_PROCESSING' ? (
                <div className={style.statusActionsWrapper}>
                  <Button
                    onClick={handleApprove}
                    className={cn(style.actionButton, style.actionButton__success)}
                  >
                    <Typography.Text className={style.statusActionsText} type='success'>
                      Approve
                    </Typography.Text>
                  </Button>
                  <Divider type='vertical' />
                  <Button
                    onClick={handleDecline}
                    className={cn(style.actionButton, style.actionButton__danger)}
                  >
                    <Typography.Text className={style.statusActionsText} type='danger'>
                      Decline
                    </Typography.Text>
                  </Button>
                </div>
              ) : (
                <div className={style.statusActionSuccess}>
                  <SuccessIcon />
                  <Typography.Text className={style.statusActionsText} type='success'>
                    Approved
                  </Typography.Text>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Spin indicator={antIcon} />
      ) : (
        <DownloadIcon
          className={style.downloadBtn}
          onClick={() => downloadFile(image, setLoading)}
        />
      )}
    </div>
  );
};

export default DocumentCard;
