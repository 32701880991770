import React from 'react';
import { Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import MailLayoutItem from '../../components/MailLayoutItem';

/**
 * Represents MailLayouts container
 * @returns {JSX.Element}
 */

const MailLayoutsContainer = ({ mailLayouts, setSelectedLayout, isEditMailLayouts }) => {
  const history = useHistory();

  const handleEditLayout = (layoutId, title) => {
    setSelectedLayout(layoutId, title);
    history.push('/mail-layouts/create');
  };

  return (
    <>
      <Row gutter={[16, 24]}>
        {mailLayouts.map((layout) => (
          <MailLayoutItem
            isEditMailLayouts={isEditMailLayouts}
            handleEditLayout={handleEditLayout}
            key={layout.id}
            layout={layout}
          />
        ))}
      </Row>
    </>
  );
};

export default observer(MailLayoutsContainer);
