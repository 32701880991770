import React from 'react';
import TraderTicketsTable from '../../../components/common/tables/TraderTicketsTable';

/**
 * Represents trader tickets component
 * @returns {JSX.Element}
 */

const TraderTickets = () => {
  return (
    <div>
      <TraderTicketsTable />
    </div>
  );
};

export default TraderTickets;
