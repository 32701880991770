import { flow, types } from 'mobx-state-tree';
import axios from 'axios';

import PositionModel from './models/PositionModel';
import PaginationModel from '../commonModels/PaginationModel';
import DisplayOptionsModel from '../commonModels/DisplayOptionsModel';
import PositionsFiltersModel from './models/PositionsFiltersModel';
import positionsTableOptions from './models/positionsTableOptions';
import positionsDefaultFilters from './models/positionsDefaultFilters';

import errorHandler from '../../util/errorHandler';
import filterHandler from '../../util/filterHandler';
import queryString from '../../util/queryString';

const { REACT_APP_API } = process.env;

const myPositions = [
  {
    id: 1,
    tradingType: true,
    type: 'ACTIVE',
    status: false,
    asset: 'BTCUSDT',
    openedAt: '2021-12-29T14:14:58.622035Z',
    openingPrice: 2000,
    usedFunds: 200,
    profit: 10,
    conditions: '',
    profitLimit: 300,
    lossLimit: 200,
    results: 400,
    amount: 100,
    closedAt: '2021-12-29T14:14:58.622035Z',
  },
  {
    id: 2,
    tradingType: false,
    type: 'PENDING',
    status: true,
    asset: 'ATOMUSD',
    openedAt: '2021-12-29T14:14:58.622035Z',
    openingPrice: 76890,
    usedFunds: 7678,
    profit: 875,
    conditions: '',
    profitLimit: 354,
    lossLimit: 987,
    results: 400,
    amount: 875,
    closedAt: '2021-12-29T14:14:58.622035Z',
  },
  {
    id: 3,
    tradingType: true,
    type: 'CLOSED',
    status: false,
    asset: 'EOSETH',
    openedAt: '2021-12-29T14:14:58.622035Z',
    openingPrice: 1230,
    usedFunds: 456,
    profit: 2334,
    conditions: '',
    profitLimit: 2345,
    lossLimit: 456,
    results: 400,
    amount: 897,
    closedAt: '2021-12-29T14:14:58.622035Z',
  },
];

const PositionsModel = types
  .model('PositionsModel', {
    positions: types.array(PositionModel),
    isLoading: types.optional(types.boolean, false),
    pagination: types.optional(PaginationModel, {}),
    filters: types.optional(PositionsFiltersModel, {}),
    displayOptions: types.array(DisplayOptionsModel),
  })
  .actions((self) => ({
    fetchPositions: flow(function* fetchPositions() {
      const { pagination } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = {
        page: pagination.number,
        size: pagination.size,
        sort: `${pagination.sortColumn},${pagination?.sortOrder}`,
      };
      const filters = filterHandler(self?.filters);

      try {
        const { data } = yield axios.post(
          queryString(REACT_APP_API, 'api/backoffice/positions'),
          filters,
          { params },
        );
        self.tickets = data.data;
        pagination.size = data.page.size;
        pagination.number = data.page.number;
        pagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    handlePageChange(page, size) {
      const { pagination, fetchTickets } = self;
      pagination.number = page - 1;
      pagination.size = size;
      fetchTickets();
    },
    handleSorterChange(sorter) {
      const { pagination, fetchTickets } = self;
      pagination.sortColumn = sorter.sortColumn;
      pagination.sortOrder = sorter.sortOrder;
      fetchTickets();
    },
    clearFilters() {
      self.filters = positionsDefaultFilters;
    },
    afterCreate: () => {
      self.displayOptions = positionsTableOptions;
      self.positions = myPositions;
    },
  }));

export default PositionsModel;
