import React from 'react';
import { Select } from 'antd';

import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';

import style from './AppSelect.module.less';

/**
 * @typedef {Object} SelectItem
 * @property {string} value - item value
 * @property {title} title - item title
 */
/**
 * Represents AppSelect component
 * @param {string} title - select title
 * @param {function} onChange - change handler
 * @param {string} placeholder - select placeholder
 * @param {Array<SelectItem>} data - select elements
 * @param {Object} filter - filter value
 * @returns {JSX.Element}
 */

const AppSelect = ({ title, onChange, placeholder, data, filter }) => {
  const {
    workers: { setPicker },
  } = useRootModel();
  const handleChange = (value, info) => {
    onChange(value, info);
  };
  const onOpenChange = (open) => {
    setPicker(open);
  };

  return (
    <fieldset className={style.selectFieldset}>
      <legend className={style.selectLegend}>{title}</legend>
      <Select
        value={filter?.name === '' ? null : filter?.name}
        bordered={false}
        className={style.appSelect}
        showSearch
        placeholder={placeholder}
        optionFilterProp='children'
        onChange={handleChange}
        onDropdownVisibleChange={onOpenChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {data.map((item) => (
          <Select.Option
            name={item?.name ? item?.name : null}
            data={item?.key ? item?.key : null}
            key={item?.id || item.value}
            value={item.value || item?.name}
          >
            {item?.name || item.value}
          </Select.Option>
        ))}
      </Select>
    </fieldset>
  );
};

export default observer(AppSelect);
