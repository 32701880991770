import React, { useEffect } from 'react';
import { Table, Typography } from 'antd';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import numeral from 'numeral';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { useRootModel } from '../../../models/RootStore';

import dateFormatter from '../../../util/dateFormatter';
import getSortOrder from '../../../util/getSorterOrder';
import sortOrderToString from '../../../util/sortOrderToString';

import style from './tables.module.less';

/**
 * Represents trader [balance history] table
 * @returns {JSX.Element}
 */

const TraderBalanceHistoryTable = () => {
  const {
    traders: {
      traderBalanceHistory,
      balanceHistoryPagination,
      isLoading,
      handleBalanceHistoryPageChange,
      fetchTraderBalanceHistory,
    },
  } = useRootModel();
  const { id } = useParams();
  useEffect(() => {
    fetchTraderBalanceHistory(id);
  }, [balanceHistoryPagination]);
  const onPageChange = (page, myFilters, sorter) => {
    const sortOrder = getSortOrder(sorter);
    const column = sortOrder ? sorter.field : '';
    handleBalanceHistoryPageChange(
      {
        page: page.current,
        size: page.pageSize,
        sortColumn: column,
        sortOrder,
      },
      id,
    );
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: true,
      sortOrder:
        balanceHistoryPagination.sortColumn === 'id' &&
        sortOrderToString(balanceHistoryPagination.sortOrder),
      render: (balanceId) => (
        <div className={style.linkCell}>
          <Typography.Text className={style.link}>#{balanceId}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      sorter: true,
      sortOrder:
        balanceHistoryPagination.sortColumn === 'amount' &&
        sortOrderToString(balanceHistoryPagination.sortOrder),
      render: (amount) => <div>{numeral(amount).format('$0,0.00')}</div>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      sorter: true,
      sortOrder:
        balanceHistoryPagination.sortColumn === 'type' &&
        sortOrderToString(balanceHistoryPagination.sortOrder),
      render: (type) => (
        <div className={style.ticketStatus_wrapper}>
          {type === 'WITHDRAWAL' && (
            <div className={cn(style.ticketStatus, style.ticketStatus__declined)}>
              <div className={cn(style.ellipse, style.ellipse__danger)} />
              <p className={cn(style.ticketText, style.ticketText__declined)}>{type}</p>
            </div>
          )}
          {type === 'DEPOSIT' && (
            <div className={cn(style.ticketStatus, style.ticketStatus__approved)}>
              <div className={cn(style.ellipse, style.ellipse__success)} />
              <p className={cn(style.ticketText, style.ticketText__approved)}>{type}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      className: `${style.column}`,
      title: 'Assigned to',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      width: 150,
      sorter: true,
      sortOrder:
        balanceHistoryPagination.sortColumn === 'assignedTo' &&
        sortOrderToString(balanceHistoryPagination.sortOrder),
      render: (assignedTo) => (
        <div className={style.linkCell}>
          <Typography.Text className={style.link}>[S] {assignedTo}</Typography.Text>
        </div>
      ),
    },
    {
      className: `${style.column}`,
      title: 'Balance before',
      dataIndex: 'balanceBefore',
      key: 'balanceBefore',
      width: 150,
      sorter: true,
      sortOrder:
        balanceHistoryPagination.sortColumn === 'balanceBefore' &&
        sortOrderToString(balanceHistoryPagination.sortOrder),
      render: (openingPrice) => <div>USD {numeral(openingPrice).format('0,0.00')}</div>,
    },
    {
      className: `${style.column}`,
      title: 'Transaction time',
      dataIndex: 'transactionTime',
      key: 'transactionTime',
      width: 150,
      sorter: true,
      sortOrder:
        balanceHistoryPagination.sortColumn === 'transactionTime' &&
        sortOrderToString(balanceHistoryPagination.sortOrder),
      render: (time) => <div>{dateFormatter(time)}</div>,
    },
  ];
  return (
    <>
      <Table
        className={style.table}
        onChange={onPageChange}
        columns={columns}
        loading={isLoading}
        dataSource={toJS(traderBalanceHistory)}
        scroll={{ x: 'max-content', y: '620px' }}
        rowKey='id'
        pagination={{
          position: ['topRight'],
          showSizeChanger: true,
          current: balanceHistoryPagination.number + 1,
          total: balanceHistoryPagination.totalElements,
          defaultPageSize: balanceHistoryPagination.size,
        }}
      />
    </>
  );
};

export default observer(TraderBalanceHistoryTable);
