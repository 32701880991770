const permissionList = [
  { key: 'DASHBOARD', value: 'Dashboard' },

  { key: 'TRADERS_FILTERS', value: 'Filters' },
  { key: 'TRADERS_DISPLAY_OPTIONS', value: 'Display options' },
  { key: 'TRADERS_BLOCK_TRADER_LOGIN', value: 'Block trader login' },
  { key: 'TRADERS_UNBLOCK_TRADER_LOGIN', value: 'Unblock trader login' },
  { key: 'TRADERS_BLOCK_TRADER_TRADING', value: 'Block trader trading' },
  { key: 'TRADERS_UNBLOCK_TRADER_TRADING', value: 'Unblock trader trading' },
  { key: 'TRADERS_SEND_PUSH_NOTIFICATION', value: 'Send push notification' },
  { key: 'TRADERS_SEND_EMAIL_BY_TEMPLATE', value: 'Send email by template' },
  { key: 'TRADERS_CHANGE_DESK', value: 'Change desk' },
  { key: 'TRADERS_CHANGE_BROKER', value: 'Change broker' },
  { key: 'TRADERS_CHANGE_AFFILIATE', value: 'Change affiliate' },
  { key: 'TRADERS_CREATE_CALL_HISTORY', value: 'Create call history' },
  { key: 'TRADERS_CHANGE_CAMPAIGN', value: 'Change campaign' },
  { key: 'TRADERS_CREATE_REMINDER', value: 'Create reminder' },
  { key: 'TRADERS_PROFILE_INFO', value: 'Profile info' },
  { key: 'TRADERS_TICKETS', value: 'Tickets' },
  { key: 'TRADERS_POSITIONS', value: 'Positions' },
  { key: 'TRADERS_BALANCE_HISTORY', value: 'Balance history' },
  { key: 'TRADERS_DOCUMENTS', value: 'Documents' },
  { key: 'TRADERS_CALENDAR', value: 'Calendar' },

  { key: 'DESKS_FILTERS', value: 'Filters' },
  { key: 'DESKS_DISPLAY_OPTIONS', value: 'Display options' },
  { key: 'DESKS_ADD_NEW', value: 'Add new' },

  { key: 'TICKETS_DISPLAY_OPTIONS', value: 'Display options' },
  { key: 'TICKETS_FILTERS', value: 'Filters' },

  { key: 'WORKERS_FILTERS', value: 'Filters' },
  { key: 'WORKERS_DISPLAY_OPTIONS', value: 'Display options' },
  { key: 'WORKERS_ADD_NEW', value: 'Add new' },
  { key: 'WORKERS_PROFILE_INFO', value: 'Profile info' },
  { key: 'WORKERS_FINANCE_EXECUTION_PLAN', value: 'Finance execution plan' },
  { key: 'WORKERS_TICKETS', value: 'Tickets' },
  { key: 'WORKERS_TRANSACTIONS', value: 'Transactions' },
  { key: 'WORKERS_CHANGE_ROLE', value: 'Change role' },

  { key: 'ANALYTICS', value: 'Analytics' },

  { key: 'MAIL_LAYOUTS', value: 'Mail layouts' },
  { key: 'MAIL_LAYOUTS_ADD_NEW', value: 'Add new' },

  { key: 'CHAT', value: 'Chat' },
  { key: 'CHAT_CREATE_NEW', value: 'Create new chat' },

  { key: 'MARKET_INFO', value: 'Market info' },

  { key: 'SUPPORT', value: 'Support' },

  { key: 'CALENDAR', value: 'Calendar' },
];

export default permissionList;
