const desksTableOptions = [
  { title: 'ID', checked: true },
  { title: 'Desk name', checked: true },
  { title: 'Team Lead', checked: true },
  { title: 'Active', checked: true },
  { title: 'Traders count', checked: true },
  { title: 'Affiliate/Campaign', checked: true },
  { title: 'Created', checked: true },
];

export default desksTableOptions;
