import { types } from 'mobx-state-tree';

const SingleMarketModel = types.model('SingleMarketModel', {
  icon: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  maxMultiplier: types.maybeNull(types.number),
  overnightCommission: types.maybeNull(types.number),
  symbolCode: types.maybeNull(types.string),
  swapBuyCommission: types.maybeNull(types.number),
  swapSellCommission: types.maybeNull(types.number),
  isEditing: types.optional(types.boolean, false),
});
export default SingleMarketModel;
