import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Typography } from 'antd';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useRootModel } from '../../../models/RootStore';
import Preloader from '../Preloader';

/**
 * Represents send email by template form
 * @returns {JSX.Element}
 */

const SendEmailForm = () => {
  const { id } = useParams();

  const {
    traders: { sendEmail, isLoading, emailTemplates, fetchEmailTemplates },
  } = useRootModel();

  useEffect(() => {
    fetchEmailTemplates();
  }, []);

  const [email, setValues] = useState({
    subject: '',
    templateId: '',
    recipientType: 'EMAIL',
  });

  const handleChange = (event, prop) => {
    setValues({ ...email, [prop]: event.target.value });
  };

  const onSelectChange = (value, prop) => {
    setValues({ ...email, [prop]: value });
  };

  const onSubmit = () => {
    sendEmail(id, email);
  };
  const validateMessages = {
    required: 'This field is required',
  };

  return (
    <div>
      <Form
        name='Send email by template'
        onFinish={onSubmit}
        layout='vertical'
        validateMessages={validateMessages}
        requiredMark={false}
      >
        <Form.Item
          label='Subject'
          name='subject'
          value={email.subject}
          rules={[{ required: true }]}
        >
          <Input placeholder='Subject...' onChange={(event) => handleChange(event, 'subject')} />
        </Form.Item>
        <Form.Item
          label='Email template'
          name='emailTemplate'
          value={email.templateId}
          rules={[{ required: true }]}
        >
          <Select
            value={email.templateId}
            placeholder='Select template...'
            onChange={(value) => onSelectChange(value, 'templateId')}
          >
            {emailTemplates.map((template) => (
              <Select.Option key={template.id} value={template.id}>
                <Typography.Text>{template.name}</Typography.Text>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          {isLoading ? (
            <Preloader />
          ) : (
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(SendEmailForm);
