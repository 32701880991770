import React from 'react';
import { Button } from 'antd';
import AddIcon from '../../common/icons/AddIcon';
import { useRootModel } from '../../../models/RootStore';

import style from '../HeaderOptions.module.less';

/**
 * Represents Add worker button
 * @returns {JSX.Element}
 */
const AddWorkerBtn = () => {
  const {
    workers: { setIsModalOpen },
  } = useRootModel();
  const openModal = () => {
    setIsModalOpen(true);
  };
  return (
    <Button onClick={openModal} className={style.addWorkerBtn} type='primary' icon={<AddIcon />}>
      Add New Worker
    </Button>
  );
};

export default AddWorkerBtn;
