import firebase from 'firebase/app';
import 'firebase/messaging';
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyADmit7NVzZ9wAvmoL6u8Ov4QVDgLYQBBc',
  authDomain: 'web-i88-27ee7.firebaseapp.com',
  projectId: 'web-i88-27ee7',
  storageBucket: 'web-i88-27ee7.appspot.com',
  messagingSenderId: '787657474439',
  appId: '1:787657474439:web:7a7f4d30abb0ee92165322',
  measurementId: 'G-NMGPQLRN63',
};
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();
const publicKey =
  'BKYbGo5S0dYz2TGUuGrVEfIUglaPC7SL0liYwRTW88LDICm0u04ys2KZ2Em2lStuOBwtW9CPXOtrz52drR3nyBk';
export const getToken = async (setTokenFound) => {
  let currentToken = '';
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('An error occurred while retrieving token.', error);
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
