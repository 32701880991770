import React from 'react';
import { Typography } from 'antd';

import dateFormatter from '../../../../util/dateFormatter';
import style from '../style.module.less';

/**
 * Represents trader profile card
 * @param {boolean} activityCard - flag for activity component
 * @param {object} comment - comment object
 * @param {object} activity - activity object
 * @returns {JSX.Element}
 */
const ProfileCard = ({ activityCard, comment, activity }) => {
  return (
    <div className={style.profileCard}>
      <div className={style.profileCardHeader}>
        <div>
          <Typography.Text className={style.profileCardHeader_name}>
            {/* {comment?.author?.role && `[${comment?.author?.role}]`} {comment?.author?.name} */}
            {comment?.title}
          </Typography.Text>
        </div>
        <div>
          <Typography.Text className={style.profileCardHeader_date}>
            {dateFormatter(comment?.date || activity?.deliveryDate)}
          </Typography.Text>
        </div>
      </div>
      {activityCard && (
        <div className={style.profileStatus}>
          <Typography.Text>Status</Typography.Text>
        </div>
      )}
      <div className={style.profileCardBody}>
        <Typography.Text>{comment?.text || comment?.description || activity?.text}</Typography.Text>
      </div>
    </div>
  );
};

export default ProfileCard;
