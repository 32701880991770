import React from 'react';
import moment from 'moment';
import { Divider, Typography } from 'antd';

const renderDay = (currentMessage, previousMessage) => {
  const currentDate = moment(currentMessage.createdAt).format('DD-MM-YYYY');
  const previousDate = previousMessage && moment(previousMessage.createdAt).format('DD-MM-YYYY');
  const isTheSameDays = currentDate === previousDate;
  const today = moment().format('DD-MM-YYYY');
  const dateToDisplay = moment(currentMessage.createdAt).format('DD-MM-YYYY HH:MM');

  if (!isTheSameDays) {
    return (
      <Divider style={{ borderTopColor: '#D7D7E8' }}>
        <Typography.Text style={{ fontSize: '14px', fontWeight: 'normal' }} type='secondary'>
          {currentDate === today ? 'Today' : dateToDisplay}
        </Typography.Text>
      </Divider>
    );
  }

  return null;
};

export default renderDay;
