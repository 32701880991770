import { flow, types } from 'mobx-state-tree';
import axios from 'axios';

import SupportItemModel from './models/SupportItemModel';
import PaginationModel from '../commonModels/PaginationModel';

import errorHandler from '../../util/errorHandler';
import eventSource from '../../eventSource';
import queryString from '../../util/queryString';

const { REACT_APP_API } = process.env;

const SupportModel = types
  .model('SupportModel', {
    support: types.array(SupportItemModel),
    selectedEmail: types.maybeNull(SupportItemModel),
    isLoading: types.optional(types.boolean, false),
    unprocessedTicketsAmount: types.maybeNull(types.number),
    pagination: types.optional(PaginationModel, {}),
  })
  .actions((self) => ({
    fetchSupport: flow(function* fetchSupport() {
      const { pagination } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;
      const params = {
        page: pagination.number,
        size: pagination.size,
        sort: `${pagination.sortColumn},${pagination?.sortOrder}`,
      };

      try {
        const { data } = yield axios.get(queryString(REACT_APP_API, 'api/support'), { params });
        self.support = data.data;
        pagination.size = data.page.size;
        pagination.number = data.page.number;
        pagination.totalElements = data.page.totalElements;
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchSelectedEmail: flow(function* fetchSelectedEmail(id) {
      const { setRead } = self;
      self.isLoading = true;
      if (!localStorage.crm_token) return;

      try {
        const { data } = yield axios.get(queryString(REACT_APP_API, `/api/support/${id}`));
        self.selectedEmail = data;
        if (!self.selectedEmail.isRead) {
          setRead(id);
        }
      } catch (e) {
        errorHandler(self, e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchUnprocessedTickets: flow(function* fetchUnprocessedTickets() {
      if (!localStorage.crm_token) return;

      try {
        const { data } = yield axios.get(queryString(REACT_APP_API, '/api/support/status'));
        self.unprocessedTicketsAmount = data.unprocessedTicketsAmount;
      } catch (e) {
        errorHandler(self, e);
      }
    }),
    decrementUnprocessed() {
      self.unprocessedTicketsAmount -= 1;
    },
    incrementUnprocessed() {
      self.unprocessedTicketsAmount += 1;
    },
    setRead: flow(function* setRead(id) {
      const { selectedEmail } = self;
      if (!localStorage.crm_token) return;

      try {
        yield axios.put(queryString(REACT_APP_API, `/api/support/${id}/read`));
        selectedEmail.isRead = true;
      } catch (e) {
        errorHandler(self, e);
      }
    }),
    fetchSupportUpdates() {
      const { incrementUnprocessed } = self;
      try {
        const event = eventSource.createEventSourceNotifications();
        // eslint-disable-next-line func-names
        event.addEventListener('support-ticket-message', function (data) {
          const parsedData = JSON.parse(data.data);
          // eslint-disable-next-line no-console
          console.log('HERE WE GO', parsedData);
          incrementUnprocessed();
        });
      } catch (e) {
        errorHandler(self, e);
      }
    },
    handlePageChange(page, size) {
      const { pagination, fetchSupport } = self;
      pagination.number = page - 1;
      pagination.size = size;
      fetchSupport();
    },
    handleSorterChange(sorter) {
      const { pagination, fetchSupport } = self;
      pagination.sortColumn = sorter.sortColumn;
      pagination.sortOrder = sorter.sortOrder;
      fetchSupport();
    },
  }));

export default SupportModel;
